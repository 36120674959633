import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Sistemas() {

    const [sistemas, setSistemas] = useState([])
    const [valorTotal, setValorTotal] = useState(0)
    const [sistemasTemp, setSistemasTemp] = useState([])
    const [servicos] = useState({
        sistemasExternos: firebase.firestore().collection('sistemasExternos')
    })

    useEffect(() => {
        servicos.sistemasExternos.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            let val = 0
            data.forEach((d) => {
                if (d.valorPagamento) {
                    val = parseFloat(d.valorPagamento) + parseFloat(val)
                }
            })
            setValorTotal(val)
            setSistemas(data)
            setSistemasTemp(data)
        })
    }, [servicos.sistemasExternos])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando ocorrências')
            servicos.sistemasExternos.where('status', '==', event.target.value).get().then((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())

                setSistemas(data)
                setSistemasTemp(data)
            })
        } else {
            setSistemas(sistemasTemp);
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-1">
                                                    <i className="pe-7s-check"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{sistemas.filter((d) => d.status === 'ATIVO').length}</div>
                                                        <div className="stat-heading">Ativos</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-3">
                                                    <i className="pe-7s-info"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{sistemas.filter((d) => d.status === 'POC').length}</div>
                                                        <div className="stat-heading">Em Aanálise</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-4">
                                                    <i className="pe-7s-attention"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{sistemas.filter((d) => d.status === 'INATIVO').length}</div>
                                                        <div className="stat-heading">Inativos</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Valor total de Custos</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <h4 className="box-title">{valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Sistemas</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastroSistemasExterno' }} >
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Nova Sistema
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="ATIVO">Ativos </option>
                                                        <option value="INATIVO">Inativos </option>
                                                        <option value="ANALISE">Em Análise </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Sistema</th>
                                                            <th>Descrição</th>
                                                            <th>Setor</th>
                                                            <th>Período</th>
                                                            <th>Responsável</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((sistema) =>
                                                            <tr key={sistema.id} >
                                                                <td>{sistema.nome}</td>
                                                                <td>{sistema.descricao.substr(0, 50)}</td>
                                                                <td>{sistema.setor}</td>
                                                                <td>{sistema.periodo}</td>
                                                                <td>{sistema.responsavel}</td>
                                                                {sistema.status === "ATIVO" ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-success">ATIVO</span>
                                                                        </button>
                                                                    </td>
                                                                    : sistema.status === "INATIVO" ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-danger">INATIVO</span>
                                                                            </button>
                                                                        </td>
                                                                        : sistema.status === "POC" ?
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-info">POC Análise</span>
                                                                                </button>
                                                                            </td>
                                                                            :
                                                                            null

                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/cadastroSistemasExterno', state: { sistema: sistema } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem sistemas cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
