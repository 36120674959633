import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco } from '../../../../services/Localidades'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Filial(props) {
    const [dado] = useState(props.location.state ? props.location.state.filial : '')
    const [servicos] = useState({
        filiais: firebase.firestore().collection('filiais'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuarios, setUsuarios] = useState([])
    const [imagens, setImagens] = useState([])


    const [nome, setNome] = useState(dado ? dado.nome : '')
    const [contato, setContato] = useState(dado ? dado.contato : '')
    const [responsavel, setResponsavel] = useState(dado ? dado.responsavel.id : {})
    const [cep, setCep] = useState(dado ? dado.endereco.cep : '')
    const [endereco, setEndereco] = useState(dado ? dado.endereco.endereco : '')
    const [bairro, setBairro] = useState(dado ? dado.endereco.bairro : '')
    const [numero, setNumero] = useState(dado ? dado.endereco.numero : '')
    const [complemento, setComplemento] = useState(dado ? dado.endereco.complemento : '')
    const [uf, setUf] = useState(dado ? dado.endereco.uf : '')
    const [cidade, setCidade] = useState(dado ? dado.endereco.cidade : '')
    const [sobre, setSobre] = useState(dado ? dado.sobre : '')
    const [mapa, setMapa] = useState(dado ? dado.mapa : '')
    const [status, setStatus] = useState(dado ? dado.status : '')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState(dado ? dado.avatar : '');
    const [upload, setUpload] = useState('');
    const [titulo, setTitulo] = useState('');

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.where('status', '==', 1).get().then((doc) => {
                const data = doc.docs.map((d) => d.data())
                setUsuarios(data);
            })
            servicos.filiais.doc(dado.id).collection('imagens').onSnapshot((snap) => {
                const data = snap.docs.map((d) => d.data())
                setImagens(data);
            })
        })
    }, [servicos.filiais, servicos.usuarios])

    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem do Avatar...')
        var uploadTask = firebase.storage().ref().child('filiais' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.filiais.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    if (dado) {
                        mensagemSucesso('Filial atualizada com sucesso ')
                    } else {
                        mensagemSucesso('Filial cadastrado com sucesso! ')
                    }
                })
            });
        });
    }

    function cadastraFilial() {
        if (nome === '') {
            mensagemAlerta('Favor digitar o nome da Filial')
        } else if (!responsavel) {
            mensagemAlerta('Favor escolha o responsável')
        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
        } else if (complemento === '') {
            mensagemAlerta('Favor digite o Complemento')
        } else if (numero === '') {
            mensagemAlerta('Favor digite o número')
        } else if (sobre === '') {
            mensagemAlerta('Por favor fale um pouco sobre a filial')
        } else if (mapa === '') {
            mensagemAlerta('Por favor cole o link do mapa')
        } else {
            mensagemLoading('Cadastrando filial...')
            if (dado) {
                servicos.filiais.doc(dado.id).update({
                    nome: nome,
                    contato: contato,
                    responsavel: {
                        nome: responsavel.nome ? responsavel.nome : dado.responsavel.nome,
                        contato: responsavel.nome ? responsavel.contato : dado.responsavel.contato,
                        id: responsavel.nome ? responsavel.id : dado.responsavel.id
                    },
                    sobre: sobre,
                    mapa: mapa,
                    endereco: {
                        endereco: endereco,
                        bairro: bairro,
                        numero: numero,
                        complemento: complemento,
                        uf: uf,
                        cidade: cidade,
                        cep: cep
                    },
                    status: status,
                    dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
                }).then((docRef) => {
                    if (upload) {
                        cadastraAvatar(dado.id)
                    } else {
                        mensagemSucesso('Filial atualizada com sucesso')
                    }
                }).catch(() => {
                    mensagemAlerta('Erro ao atualizar a filial')
                })
            } else {
                servicos.filiais.add({
                    nome: nome,
                    contato: contato,
                    responsavel: {
                        nome: responsavel.nome,
                        contato: responsavel.contato,
                        id: responsavel.id
                    },
                    sobre: sobre,
                    mapa: mapa,
                    endereco: {
                        endereco: endereco,
                        bairro: bairro,
                        numero: numero,
                        complemento: complemento,
                        uf: uf,
                        cidade: cidade,
                        cep: cep
                    },
                    status: status,
                    dataCadastro: moment().format('DD/MM/YYYY HH:mm'),
                    dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
                }).then((docRef) => {
                    servicos.filiais.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        cadastraAvatar(docRef.id)
                    }).catch(() => {
                        mensagemAlerta('Erro ao atualizar a filial')
                    })
                }).catch(() => {
                    mensagemAlerta('Erro ao cadastrar a filial')
                })
            }

        }
    }

    function cadastraImagem() {
        if (titulo === '') {
            mensagemAlerta('Por favor digite o título da Imagem')
        } else if (avatar === '') {
            mensagemAlerta('Favor escolha a imagem')
        } else {
            mensagemLoading('Cadastrando imagem...')
            servicos.filiais.doc(dado.id).collection('imagens').add({
                titulo: titulo,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm')
            }).then((docRef) => {
                servicos.filiais.doc(dado.id).collection('imagens').doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    var uploadTask = firebase.storage().ref().child('imagensFiliais/' + docRef.id).put(avatar[0], metadata);
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                console.log('Upload is running');
                                break;
                            default:
                                console.log('Passou')
                        }
                    }, (error) => {
                        switch (error.code) {
                            case 'storage/unauthorized':
                                break;
                            case 'storage/canceled':
                                break;
                            case 'storage/unknown':
                                break;
                            default:
                                console.log('Passou')
                        }
                    }, () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            servicos.filiais.doc(dado.id).collection('imagens').doc(docRef.id).update({
                                avatar: downloadURL
                            }).then(() => {
                                mensagemSucessoImagem('Imagem cadastrada com sucesso!!')
                            })
                        });
                    });
                })
            }).catch(() => {
                mensagemAlerta('Erro ao atualizar a filial')
            })
        }
    }

    function removeImagem(img) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remoover a imagem ' + img.titulo + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Removendo imagem ...')
                firebase.storage().ref().child('imagensFiliais/' + img.id).delete().then(() => {
                    servicos.filiais.doc(dado.id).collection('imagens').doc(img.id).delete().then(() => {
                        mensagemSucessoImagem('Imagem removida com sucesso!')
                    })
                })
            }
        });
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleResponsavel = (event) => {
        event.persist();
        if (event.target.value) {
            setResponsavel(usuarios[event.target.value]);
        }
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaEndereco(event.target.value).then((doc) => {
                mensagemLoading('Buscando CEP...')
                if (doc.erro === true) {
                    Swal.close();
                    alert('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    Swal.close();
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleSobre = (event) => {
        event.persist();
        setSobre(event.target.value);
    }
    const handleMapa = (event) => {
        event.persist();
        setMapa(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value)
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/filiais'
        })
    }
    function mensagemSucessoImagem(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            setTitulo('')
            setAvatar('')
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                {dado.id ?
                                                    <h4 className="box-title">{dado.nome}</h4>
                                                    :
                                                    <h4 className="box-title">Nova Filial</h4>
                                                }

                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        : avatar ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={avatar} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://conquiste.com.br/img/industria1.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem da Filial</label>
                                                    <p>Para mudar a foto da filial é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>
                                                    <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome da Filial</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Responsável</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleResponsavel} >
                                                        <option value="">Escolha </option>
                                                        {usuarios.map((usuario, index) =>
                                                            <option value={index} selected={usuario.id === responsavel}>{usuario.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-2'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label>Sobre a Filial</label>
                                                    <div class="form-group">
                                                        <textarea type="text" value={sobre} id="company" class="form-control" onChange={handleSobre} />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label>Link do Mapa</label>
                                                    <div class="form-group">
                                                        <input type="text" value={mapa} id="company" class="form-control" onChange={handleMapa} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha  </option>
                                                        <option value="ATIVO" selected={dado && dado.status === "ATIVO"?"selected":null}>Ativo </option>
                                                        <option value="INATIVO" selected={dado && dado.status === "INATIVO"?"selected":null}>Inativo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {dado.id ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando filial ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraFilial()}>
                                                            Atualizar Filial
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando filial ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraFilial()}>
                                                            Cadastra Filial
                                                        </button>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagens da Filial</h4>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className='col-md-8'>
                                                    <label>Titulo da Imagem</label>
                                                    <div class="form-group">
                                                        <input type="text" value={titulo} id="company" class="form-control" onChange={handleTitulo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4' style={{ marginTop: 30 }}>
                                                    <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                </div>
                                                <div className='col-md-4'>
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraImagem()}>
                                                        Cadastra Imagem
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Imagem</th>
                                                            <th>Titulo</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {imagens.map((imagem) =>
                                                            <tr key={imagem.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={imagem.avatar} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{imagem.titulo}</td>
                                                                <td>
                                                                    <button onClick={() => removeImagem(imagem)}>
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {imagens.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem imiagens cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
