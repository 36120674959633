import React, { useEffect, useState } from 'react';
import Header from '../../../../comum/componentes/Header'
import Sidebar from '../../../../comum/componentes/Sidebar'
import Swal from 'sweetalert2'
import axios from 'axios';
import * as JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver';
var JSZip = require("jszip");
var XMLParser = require('react-xml-parser');

export default function Ocorrencias() {

    const [arquivos, setArquivos] = useState([])
    const [arquivosTemp, setArquivosTemp] = useState([])
    const [arquivosDown, setArquivosDown] = useState([])
    const [cidades, setCidades] = useState([])
    const [filtro, setFiltro] = useState("")
    const [tipoArquivo, setTipoArquivo] = useState("")
    const [cidade, setCidade] = useState("")

    useEffect(() => {

    }, [arquivos, arquivosTemp])

    const handleArquivosNFE = (event) => {
        event.persist();
        setArquivos([])
        setCidades([])
        setArquivosDown([])
        let files = event.target.files;
        const list = []
        const listCidades = []
        for (let i = 0; i < files.length; i++) {
            axios.get(URL.createObjectURL(event.target.files[i]), {
                "Content-Type": "application/xml; charset=utf-8"
            }).then((response) => {
                var xml = new XMLParser().parseFromString(response.data);
                // Código da NFE
                const nf = xml.children[0].children[0].children[0].children[5].value
                const tipo = xml.children[0].children[0].children[0].children[2].value

                // Informações do Destinatário
                const cpf = xml.children[0].children[0].children[2].children[0].value
                const nome = xml.children[0].children[0].children[2].children[1].value
                const cidade = xml.children[0].children[0].children[2].children[2].children.filter((d) => d.name === "xMun")[0].value
                const uf = xml.children[0].children[0].children[2].children[2].children.filter((d) => d.name === "UF")[0].value
                listCidades.push(
                    ...cidades,
                    cidade
                )
                const dados = {
                    nf: {
                        nf: nf,
                        tipo: tipo
                    },
                    destinatario: {
                        cpf: cpf,
                        nome: nome,
                        cidade: cidade,
                        uf: uf
                    },
                    arquivo: {
                        url: event.target.files[i]
                    }
                }
                if (arquivosTemp.length > 0) {
                    list.push(
                        ...arquivosTemp,
                        dados
                    )
                } else {
                    list.push(dados)
                }
                setArquivos(list)
                setCidades([...new Set(listCidades)])
                setArquivosDown(list)
            });
        }
    }

    const handleArquivosCTE = (event) => {
        event.persist();
        setArquivos([])
        setCidades([])
        setArquivosDown([])
        let files = event.target.files;
        const list = []
        const listCidades = []
        for (let i = 0; i < files.length; i++) {
            axios.get(URL.createObjectURL(event.target.files[i]), {
                "Content-Type": "application/xml; charset=utf-8"
            }).then((response) => {
                var xml = new XMLParser().parseFromString(response.data);
                // console.log(xml.children[0].children[0])
                // // Código da NFE
                const nf = xml.children[0].children[0].children[0].children[1].value
                const tipo = "PRESTACAO SERVICO"

                // // Informações do Destinatário
                const cpf = xml.children[0].children[0].children[6].children[0].value
                const nome = xml.children[0].children[0].children[6].children[2].value
                const cidade = xml.children[0].children[0].children[6].children[2].name === "enderDest"? xml.children[0].children[0].children[6].children[2].children.filter((d) => d.name === "xMun")[0].value: xml.children[0].children[0].children[6].children[3].name === "enderDest"? xml.children[0].children[0].children[6].children[3].children.filter((d) => d.name === "xMun")[0].value:xml.children[0].children[0].children[6].children[4].children.filter((d) => d.name === "xMun")[0].value
                const uf = xml.children[0].children[0].children[6].children[2].name === "enderDest"? xml.children[0].children[0].children[6].children[2].children.filter((d) => d.name === "UF")[0].value: xml.children[0].children[0].children[6].children[3].name === "enderDest"? xml.children[0].children[0].children[6].children[3].children.filter((d) => d.name === "UF")[0].value:xml.children[0].children[0].children[6].children[4].children.filter((d) => d.name === "UF")[0].value
                // console.log(xml.children[0].children[0].children[6].children[3])
                listCidades.push(
                    ...cidades,
                    cidade
                )
                const dados = {
                    nf: {
                        nf: nf,
                        tipo: tipo
                    },
                    destinatario: {
                        cpf: cpf,
                        nome: nome,
                        cidade: cidade,
                        uf: uf
                    },
                    arquivo: {
                        url: event.target.files[i]
                    }
                }
                if (arquivosTemp.length > 0) {
                    list.push(
                        ...arquivosTemp,
                        dados
                    )
                } else {
                    list.push(dados)
                }
                setArquivos(list)
                setCidades([...new Set(listCidades)])
                setArquivosDown(list)
            });
        }
    }

    function download() {
        if (filtro) {
            const list = []
            var zip = new JSZip();
            var count = 0;
            arquivosDown.forEach((d) => {
                console.log(d.nf.tipo)
                if (d.nf.tipo === filtro && d.destinatario.cidade === cidade) {
                    var filename = d.arquivo.url.name;
                    JSZipUtils.getBinaryContent(window.URL.createObjectURL(new Blob([d.arquivo.url])), (err, data) => {
                        if (err) {
                            throw err;
                        }
                        zip.file(filename, data, { binary: true });
                        count++;
                        if (count === arquivosDown.length) {
                            zip.generateAsync({ type: "blob" }).then(function (blob) {
                                saveAs(blob, "Arquivos de " + filtro + " de " + cidade);
                                Swal.close();
                            }, function (err) {
                                console.log(err)
                            });
                        }
                    });
                }
            })
        }
    }
    const handleFiltro = (event) => {
        event.persist();
        setFiltro(event.target.value)
    }
    const handleTipoarquivo = (event) => {
        event.persist();
        setTipoArquivo(event.target.value)
    }
    const handleCidade = (event) => {
        event.persist();
        setArquivosDown([])
        setArquivosTemp([])
        if (event.target.value) {
            if (filtro === "VENDA") {
                setCidade(event.target.value)
                setArquivosDown(arquivos.filter((d) => d.nf.tipo === filtro && d.destinatario.cidade === event.target.value))
                setArquivosTemp(arquivos.filter((d) => d.nf.tipo === filtro && d.destinatario.cidade === event.target.value))
            } else if(filtro === "TRANSFERENCIA"){
                setCidade(event.target.value)
                setArquivosDown(arquivos.filter((d) => d.nf.tipo !== "VENDA" && d.destinatario.cidade === event.target.value))
                setArquivosTemp(arquivos.filter((d) => d.nf.tipo !== "VENDA" && d.destinatario.cidade === event.target.value))
            }else {
                setCidade(event.target.value)
                setArquivosDown(arquivos.filter((d) => d.destinatario.cidade === event.target.value))
                setArquivosTemp(arquivos.filter((d) => d.destinatario.cidade === event.target.value))
            }
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Exportação de Arquivos XML</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className='col-md-3' >
                                                    <label>Escolha o Tipo de Arquivo</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTipoarquivo}  >
                                                        <option value="">Selecione </option>
                                                        <option value="nfe">Nota Fiscal </option>
                                                        <option value="cte">CTE </option>
                                                    </select>
                                                </div>
                                                {tipoArquivo ?
                                                    <div className="col-sm-6" style={{ marginTop: 10 }}>
                                                        <label>Selecione os arquivos {tipoArquivo.toUpperCase()}</label>
                                                        {tipoArquivo === "nfe" ?
                                                            <input multiple onChange={handleArquivosNFE} accept=".xml" type="file" name="name" placeholder="Escolha os arquivos " />
                                                            : tipoArquivo === "cte" ?
                                                                <input multiple onChange={handleArquivosCTE} accept=".xml" type="file" name="name" placeholder="Escolha os arquivos " />
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        {arquivos.length > 0 ?
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div className='col-md-3' >
                                                        <label>Escolha o Filtro</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleFiltro}  >
                                                            <option value="">Selecione </option>
                                                            <option value="VENDA">Venda </option>
                                                            <option value="TRANSFEFRENCIA">Transferencia </option>
                                                            <option value="PRESTACAO SERVICO">Prestação de Serviço </option>
                                                        </select>
                                                    </div>
                                                    {filtro ?
                                                        <div className='col-md-3' >
                                                            <label>Escolha a Cidade</label>
                                                            <select name="select" id="select" class="form-control" onChange={handleCidade}  >
                                                                <option value="">Selecione </option>
                                                                {cidades.map((c) =>
                                                                    <option value={c}>{c}</option>
                                                                )}
                                                                {/* <option value="CASTANHAL">CASTANHAL </option> */}
                                                            </select>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className="col-sm-6" style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => download()} >
                                                            Baixar Arquivos
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>NFE</th>
                                                            <th>Tipo</th>
                                                            <th>CPF</th>
                                                            <th>Destinatário</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {arquivosTemp.map((filial) =>
                                                            <tr key={filial.nf.nf} >
                                                                <td>{filial.nf.nf}</td>
                                                                <td>{filial.nf.tipo}</td>
                                                                <td>{filial.destinatario.cpf}</td>
                                                                <td>{filial.destinatario.nome}</td>
                                                                <td>{filial.destinatario.cidade}</td>
                                                                <td>{filial.destinatario.uf}</td>

                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {arquivosTemp.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem arquivos cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
