import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { carregaEstados, carregaCidade } from '../../../../../services/Localidades'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function CadastraOcorrencia() {

    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        ocorrencias: firebase.firestore().collection('ocorrencias'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [usuario, setUsuario] = useState({})

    const [ufs, setUfs] = useState([])
    const [cidades, setCidades] = useState([])
    const [filiais, setFiliais] = useState([])

    const [ocorrencia, setOcorrencia] = useState('')
    const [gravidade, setGravidade] = useState('')
    const [urgencia, setUrgencia] = useState('')
    const [tendencia, setTendencia] = useState('')

    const [favorecido, setFavorecido] = useState('')
    const [contato, setContato] = useState('')
    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [uf, setUf] = useState({})
    const [cidade, setCidade] = useState({})
    const [base, setBase] = useState({})


    const [descricao, setDescricao] = useState('')
    const [dataCadastro, setDataCadastro] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            carregaEstados().then((res) => {
                setUfs(res);
            })
            servicos.filiais.get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFiliais(data)
            })
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios, servicos.pendencia])


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem da Ocorrência...')
        var uploadTask = firebase.storage().ref().child('ocorrencia/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.ocorrencias.doc(id).update({
                    documento: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Ocorrência cadastrada com sucesso!')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraCliente() {
        if (!ocorrencia) {
            mensagemAlerta('Favor escolha a Ocorrência')
            return
        }
        if (!gravidade) {
            mensagemAlerta('Favor escolha o nível da Gravidade')
            return
        }
        if (!urgencia) {
            mensagemAlerta('Favor escolha o nível de Urgẽncia')
            return
        }
        if (!tendencia) {
            mensagemAlerta('Favor escolha o nível de Tendência')
            return
        }
        if (!favorecido) {
            mensagemAlerta('Favor escolha o tipo de Favorecido')
            return
        }
        if (!contato) {
            mensagemAlerta('Favor escolha por onde foi realizado o contato')
            return
        }
        if (!nome) {
            mensagemAlerta('Favor digite o nome do favorecido')
            return
        }
        if (!telefone) {
            mensagemAlerta('Favor digite o Celular do favorecido')
            return
        }
        if (!uf.id) {
            mensagemAlerta('Favor escolha o Estado')
            return
        }
        if (!cidade.id) {
            mensagemAlerta('Favor escolha a Cidade')
            return
        }
        if (!base.id) {
            mensagemAlerta('Favor escolha a base')
            return
        }
        if (!descricao) {
            mensagemAlerta('Digite o motivo da ocorrência')
            return
        }
        if (!dataCadastro) {
            mensagemAlerta('Favor escolha a data da Ocorrência')
            return
        }
        mensagemLoading('Cadastrando ocorrência...')
        servicos.ocorrencias.add({
            ocorrencia: ocorrencia,
            gravidade: parseInt(gravidade),
            urgencia: parseInt(urgencia),
            tendencia: parseInt(tendencia),
            nivel: parseInt(gravidade) * parseInt(urgencia) * parseInt(tendencia),
            protocolo: "OC" + Math.floor(10000 + Math.random() * 900000),
            favorecido: {
                favorecido: favorecido,
                contato: contato,
                nome: nome,
                telefone: telefone,
                email: email,
            },
            uf: uf.sigla,
            cidade: cidade.nome,
            base: {
                id: base.id,
                nome: base.nome
            },
            descricao: descricao,
            dataOcorrencia: dataCadastro,
            dataCadastro: moment().format('YYYY-MM-DD'),
            horaCadastro: moment().format('HH:mm'),
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            usuario: {
                id: usuario.id,
                nome: usuario.nome,
                avatar: usuario.avatar,
                email: usuario.email,
                contato: usuario.contato
            },
            status: 'ABERTO'
        }).then((docRef) => {
            servicos.ocorrencias.doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                servicos.ocorrencias.doc(docRef.id).collection('historico').add({
                    usuario: {
                        id: usuario.id,
                        nome: usuario.nome,
                        avatar: usuario.avatar,
                        email: usuario.email,
                        contato: usuario.contato
                    },
                    status: 'CADASTRO',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                }).then((docId) => {
                    servicos.ocorrencias.doc(docRef.id).collection('historico').doc(docId.id).update({
                        id: docId.id
                    }).then((docId) => {
                        if (upload) {
                            cadastraAvatar(docRef.id)
                        } else {
                            mensagemSucesso('Ocorrência cadastrada com sucesso')
                        }
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar o Histórico')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar o Histórico')
                })
            }).catch(() => {
                mensagemErro('Erro ao atualizar a Ocorrência')
            })
        }).catch(() => {
            mensagemErro('Erro ao cadastrar a Ocorrência')
        })
    }


    const handleOcorrencia = (event) => {
        event.persist();
        setOcorrencia(event.target.value);
    }
    const handleGravidade = (event) => {
        event.persist();
        setGravidade(event.target.value);
    }
    const handleUrgencia = (event) => {
        event.persist();
        setUrgencia(event.target.value);
    }
    const handleTendencia = (event) => {
        event.persist();
        setTendencia(event.target.value);
    }

    const handleFavorecido = (event) => {
        event.persist();
        setFavorecido(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleTelefone = (event) => {
        event.persist();
        setTelefone(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleUf = (event) => {
        mensagemLoading('Buscando cidades...')
        event.persist();
        setUf(event.target.value);
        if (event.target.value) {
            setUf(ufs[event.target.value])
            carregaCidade(ufs[event.target.value].sigla).then((res) => {
                Swal.close();
                setCidades(res);
            })
        }
    }
    const handleCidade = (event) => {
        event.persist();
        if (event.target.value) {
            setCidade(cidades[event.target.value]);
        }
    }
    const handleBase = (event) => {
        event.persist();
        if (event.target.value) {
            setBase(filiais[event.target.value]);
        }
    }

    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleDataCadastro = (event) => {
        event.persist();
        setDataCadastro(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    {erro ?
                        <div class="alert alert-danger" role="alert">{erro}</div>
                        :
                        null
                    }
                    {sucesso ?
                        <div class="alert alert-success" role="alert">{sucesso}</div>
                        :
                        null
                    }
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Ocorrência</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-3'>
                                                    <label>Tipo da Ocorrência</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleOcorrencia} >
                                                        <option value="">Escolha  </option>
                                                        <option value="SOLICITACAO">Solicitação </option>
                                                        <option value="RECLAMACAO">Reclamação </option>
                                                        <option value="SUGESTAO">Sugestão </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Gravidade</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleGravidade} >
                                                        <option value="">Escolha o nível</option>
                                                        <option value="1">Sem Gravidade </option>
                                                        <option value="2">Pouco Grave </option>
                                                        <option value="3">Grave </option>
                                                        <option value="4">Muito Grave </option>
                                                        <option value="5">Extremamente Grave </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Urgência</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleUrgencia} >
                                                        <option value="">Escolha o nível</option>
                                                        <option value="1">Sem Urgência </option>
                                                        <option value="2">Pouco Urgente </option>
                                                        <option value="3">Urgente </option>
                                                        <option value="4">Muito Urgente </option>
                                                        <option value="5">Extremamente Urgente </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Tendência</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTendencia} >
                                                        <option value="">Escolha o nível</option>
                                                        <option value="1">Sem tendência de piorar </option>
                                                        <option value="2">Piorar em longo prazo </option>
                                                        <option value="3">Piorar em médio prazo </option>
                                                        <option value="4">Piorar em curto prazo </option>
                                                        <option value="5">Agravar rápido </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body" >
                                            <div class="row m-0">
                                                <h4 className="box-title">Detalhes do Favorecido</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-3'>
                                                    <label>Favorecido</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFavorecido} >
                                                        <option value="">Escolha o favorecido </option>
                                                        <option value="MOTORISTA">Motorista </option>
                                                        <option value="CLIENTE">Cliente </option>
                                                        <option value="EXTERNO">Usuário Externo </option>
                                                        <option value="FILIAL">Base / Filial </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipo de Contato</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleContato} >
                                                        <option value="">Escolha </option>
                                                        <option value="WHATSAPP">Whatsapp </option>
                                                        <option value="LIGAÇÃO">Ligação </option>
                                                        <option value="EMAIL">Email </option>
                                                        <option value="PESSOALMENTE">Pessoalmente </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Nome</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="text" value={telefone} id="company" class="form-control" onChange={handleTelefone} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Email</label>
                                                    <div class="form-group">
                                                        <input type="text" value={email} id="company" class="form-control" onChange={handleEmail} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>UF</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleUf} >
                                                        <option value="">Escolha </option>
                                                        {ufs.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((uf, index) =>
                                                            <option value={index}>{uf.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cidade</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleCidade} >
                                                        <option value="">Escolha </option>
                                                        {cidades.map((cidade, index) =>
                                                            <option value={index}>{cidade.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Base</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleBase} >
                                                        <option value="">Escolha </option>
                                                        {filiais.map((f, index) =>
                                                            <option value={index}>{f.nome}</option>
                                                        )}
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Detalhes da Ocorrência</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-12'>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <textarea type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Data da Ocorrência</label>
                                                    <div class="form-group">
                                                        <input type="date" value={dataCadastro} id="company" class="form-control" onChange={handleDataCadastro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Arquivo ou Print</label>
                                                    <div class="form-group">
                                                        <input type="file" id="company" class="form-control" onChange={handleAvatar} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="page-header col-md-3" >
                                        {loading ?
                                            <button type="button" class="btn btn-default mb-1" >
                                                Cadastrando Ocorrência ...
                                            </button>
                                            :
                                            <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                Cadastrar Ocorrência
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
