import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Saidas() {

    const [usuarios, setUsuarios] = useState([])
    const [servicos] = useState({
        saidas: firebase.firestore().collection('saidas')
    })

    useEffect(() => {
        servicos.saidas.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setUsuarios(data)
        })
    }, [servicos.usuarios])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Saídas</h4>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Link to='/cadastraSaidaPromocional'>
                                                        <button type="button" class="btn btn-success mb-1" >Nova Saída</button>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>N° Pedido</th>
                                                            <th>Base</th>
                                                            <th>Cliente</th>
                                                            <th>Promotor</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {usuarios.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((filial) =>
                                                            <tr key={filial.id} >
                                                                <td>{filial.pedido}</td>
                                                                <td>{filial.filial.nome}</td>
                                                                <td>{filial.cliente.nome}</td>
                                                                <td>{filial.destino.nome}</td>
                                                                <td>
                                                                    {filial.status === "GERADO" ?
                                                                        <button>
                                                                            <span className="badge badge-warning" title="Gerado"><i className='fa fa-clock-o'></i></span>
                                                                        </button>
                                                                        : filial.status === "FINALIZADO" ?
                                                                            <button>
                                                                                <span className="badge badge-success" title="Finalizado"><i className='fa fa-check'></i></span>
                                                                            </button>
                                                                            :
                                                                            <button>
                                                                                <span className="badge badge-danger" title="Cancelado"><i className='fa fa-close'></i></span>
                                                                            </button>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheSaidaPromocional', state: { saida: filial } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {usuarios.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem usuários cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
