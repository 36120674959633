import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import BlocoRodape from '../../services/Ads/BlocoRodape'

export default function Setor() {
    const [usuarios, setUsuarios] = useState([])
    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data())
                servicos.usuarios.where('setor', '==', doc.data().setor).onSnapshot((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setUsuarios(data)
                })
            })
        })
    }, [servicos.usuarios])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <BlocoRodape />
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Bem vindo, {usuario.id ? usuario.nome : null}</h4>
                                                    <p>{usuario.cargo} em <b>{usuario.id ? usuario.setor : null}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Meu setor - {usuario.setor}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Nome</th>
                                                            <th>Email</th>
                                                            <th>Contato</th>
                                                            <th>Cargo</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {usuarios.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).filter((d) => d.id !== usuario.id).map((usuario) =>
                                                            <tr key={usuario.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={usuario.avatar ? usuario.avatar : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png"} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{usuario.nome}</td>
                                                                <td>{usuario.email}</td>
                                                                <td>{usuario.contato}</td>
                                                                <td>{usuario.cargo}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/perfil', state: { usuario: usuario } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {usuarios.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem usuários cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
