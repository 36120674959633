import moment from 'moment';
import React, { useState } from 'react';

export default function Contrato(props) {
    const [dado] = useState(props.location.state ? props.location.state.sistema : "");
    return (
        <div>
            <div id="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h1 className="box-title" style={{ marginTop: 20,fontSize:50 }}>Equipamento aguardando Manutenção</h1>
                                                <h5 className="box-title" style={{ marginTop: 20 }}>Para mais informações contate a Equipe Labs da Entrega Já</h5>
                                                <h1 className="box-title" style={{ marginTop: 20 }}>labs@entregaja.com.br</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h1 className="box-title" style={{ marginTop: 20,fontSize:50 }}>Equipamento aguardando Manutenção</h1>
                                                <h5 className="box-title" style={{ marginTop: 20 }}>Para mais informações contate a Equipe Labs da Entrega Já</h5>
                                                <h1 className="box-title" style={{ marginTop: 20 }}>labs@entregaja.com.br</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h1 className="box-title" style={{ marginTop: 20,fontSize:50 }}>Equipamento aguardando Manutenção</h1>
                                                <h5 className="box-title" style={{ marginTop: 20 }}>Para mais informações contate a Equipe Labs da Entrega Já</h5>
                                                <h1 className="box-title" style={{ marginTop: 20 }}>labs@entregaja.com.br</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h1 className="box-title" style={{ marginTop: 20,fontSize:50 }}>Equipamento aguardando Manutenção</h1>
                                                <h5 className="box-title" style={{ marginTop: 20 }}>Para mais informações contate a Equipe Labs da Entrega Já</h5>
                                                <h1 className="box-title" style={{ marginTop: 20 }}>labs@entregaja.com.br</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h1 className="box-title" style={{ marginTop: 20,fontSize:50 }}>Equipamento aguardando Manutenção</h1>
                                                <h5 className="box-title" style={{ marginTop: 20 }}>Para mais informações contate a Equipe Labs da Entrega Já</h5>
                                                <h1 className="box-title" style={{ marginTop: 20 }}>labs@entregaja.com.br</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};