import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Home(props) {
    const [dado] = useState(props.location.state.filial);
    const [ruas, setRuas] = useState([])
    const [nome, setNome] = useState('')
    const [servicos] = useState({
        filiais: firebase.firestore().collection('filiais').doc(dado.id).collection('ruas'),
        gaiolas: firebase.firestore().collection('gaiolas')
    })

    useEffect(() => {
        servicos.filiais.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setRuas(data)
        })
    }, [servicos.ruas])

    function cadastraRua() {
        if (!nome) {
            mensagemAlerta('Favor cadastre nome')
            return
        }
        mensagemLoading('Cadstrando rua...')
        servicos.filiais.add({
            nome: nome,
            filial: {
                id: dado.id,
                nome: dado.nome
            },
            dataCadastro: moment().format('YYYY-MM-DD HH:mm')
        }).then((docRef) => {
            servicos.filiais.doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                Swal.close();
                mensagemSucesso('Rua cadastrada com sucesso')
            }).catch(() => {
                mensagemErro('Erro ao atualizar a rua! Favor verifique com o administrador')
                Swal.close();
            })
        }).catch((docRef) => {
            mensagemErro('Erro ao cadastrar a Rua! Favor verifique com o administradors')
            Swal.close();
        })
    }

    function removeRua(rua) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover a Rua ' + rua.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('removendo rua...')
                servicos.gaiolas.where('ruaId', '==', rua.id).get().then((snap) => {
                    if (snap.size > 0) {
                        mensagemAlerta('Não é possível excluir a Rua! Já existe gaiolas caastradas')
                    } else {
                        servicos.filiais.doc(rua.id).delete().then(() => {
                            mensagemSucesso('Rua removida com sucesso')
                        }).catch(() => {
                            mensagemErro('Erro ao remover a rua')
                        })
                    }
                })
            }
        });
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            // window.location = '/filiaisLogisticaPromocional'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Ruas da {dado.nome}</h4>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className='col-md-10'>
                                                    <label>Nome</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraRua()}>
                                                        Cadastrar Rua
                                                        </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Filial</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ruas.map((rua) =>
                                                            <tr key={rua.id} >
                                                                <td>{rua.nome}</td>
                                                                <td>{rua.filial.nome}</td>
                                                                <td>
                                                                    <button onClick={() => removeRua(rua)}>
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {ruas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem ruas cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
