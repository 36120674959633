import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom'

export default function DetalheProduto(props) {
    const [dado] = useState(props.location.state.produto);
    const [catalogo, setCatalogo] = useState({})
    const [usuario, setUsuario] = useState({})
    const [contrato, setContrato] = useState('')
    var metadata = { contentType: 'image/jpeg' };

    const [servicos] = useState({
        auth: firebase.auth(),
        reembalados: firebase.firestore().collection('reembalados'),
        usuarios: firebase.firestore().collection('usuarios'),
        vendas: firebase.firestore().collection('vendas'),
    })

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.reembalados.doc(dado.produto.id).get().then((snap) => {
                    setCatalogo(snap.data())
                })
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
            }
        })
    }, [])

    function enviaContrato() {
        Swal.close();
        mensagemLoading('Cadastrando o contrato assinado...')
        var uploadTask = firebase.storage().ref().child('venda/contrato' + dado.id).put(contrato[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.vendas.doc(dado.id).update({
                    contrato: downloadURL
                }).then(() => {
                    mensagemSucesso('Produto vendido com sucesso!')
                })
            });
        });
    }

    function validaCompra() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja finalizar venda para ' + dado.cliente.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                servicos.reembalados.doc(dado.produto.id).update({
                    qtd: parseInt(catalogo.qtd) - parseInt(dado.qtd),
                    solicitacao: dado.id
                }).then(() => {
                    servicos.reembalados.doc(dado.produto.id).collection('historico').add({
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        acao: 'Produto Vendido',
                        filial: {
                            id: dado.filial.id,
                            nome: dado.filial.nome
                        },
                        usuario: {
                            id: usuario.id,
                            nome: usuario.nome
                        }
                    }).then((docId) => {
                        servicos.reembalados.doc(dado.produto.id).collection('historico').doc(docId.id).update({
                            id: docId.id
                        }).then(() => {
                            servicos.vendas.doc(dado.id).update({
                                status: 'VENDIDO'
                            }).then(() => {
                                Swal.close();
                                // enviaContrato();
                                mensagemSucesso('Produto vendido com sucesso!')
                            }).catch(() => {
                                mensagemErro('Erro ao atualizar a venda')
                            })
                        }).catch(() => {
                            mensagemErro('Erro ao atualizar histórico')
                        })
                    }).catch(() => {
                        mensagemErro('rro ao cadatrar o historico')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar venda')
                })
                // if (!contrato) {
                //     mensagemAlerta('Favor para finalizar a compra, envie o comprovante de assinatura do cliente')
                // } else {
                //     servicos.reembalados.doc(dado.produto.id).update({
                //         // qtd: parseInt(catalogo.qtd) - parseInt(dado.qtd),
                //         solicitacao: dado.id
                //     }).then(() => {
                //         servicos.reembalados.doc(dado.produto.id).collection('historico').add({
                //             dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                //             acao: 'Produto Vendido',
                //             filial: {
                //                 id: dado.filial.id,
                //                 nome: dado.filial.nome
                //             },
                //             usuario: {
                //                 id: usuario.id,
                //                 nome: usuario.nome
                //             }
                //         }).then((docId) => {
                //             servicos.reembalados.doc(dado.produto.id).collection('historico').doc(docId.id).update({
                //                 id: docId.id
                //             }).then(() => {
                //                 servicos.vendas.doc(dado.id).update({
                //                     status: 'VENDIDO'
                //                 }).then(() => {
                //                     Swal.close();
                //                     enviaContrato();
                //                 }).catch(() => {
                //                     mensagemErro('Erro ao atualizar a venda')
                //                 })
                //             }).catch(() => {
                //                 mensagemErro('Erro ao atualizar histórico')
                //             })
                //         }).catch(() => {
                //             mensagemErro('rro ao cadatrar o historico')
                //         })
                //     }).catch(() => {
                //         mensagemErro('Erro ao cadastrar venda')
                //     })
                // }

            }
        });
    }

    function cancelaCompra() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja cancelar venda para ' + dado.cliente.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                servicos.vendas.doc(dado.id).delete().then(() => {
                    Swal.close();
                    mensagemSucesso('Produto cancelado com sucesso!');
                }).catch(() => {
                    mensagemErro('Erro ao cancelar a venda')
                })
            }
        });
    }

    const handleContrato = (event) => {
        event.persist();
        setContrato(event.target.files)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/reembalados'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className="col-xl-12">
                                                    <h4 className="box-title">Produto</h4>
                                                </div>
                                                <div className="col-xl-12">
                                                    <h3 >{dado.produto.descricao}</h3>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Filial</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.filial.nome}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Responsável</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.filial.responsavel}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Cliente</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.cliente.nome}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">CPF</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.cliente.cpf}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Contato</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.cliente.contato}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Status</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.status}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Data Solicitação</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{moment(dado.dataCadastro).format("DD/MM/YYYY - HH:mm")}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Qtd</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.qtd}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-4">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Valor</h4>
                                                    </div>
                                                    <div >
                                                        <h2 >{(dado.valor * dado.qtd).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                    </div>
                                                </div>
                                                {dado.pagamento === "vista" ?
                                                    <>
                                                        <div className="col-xl-4">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Desconto 10%</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{(((dado.valor * dado.qtd) * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Valor total com Desconto</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{((dado.valor * dado.qtd) - ((dado.valor * dado.qtd) * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-xl-3">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Pagamento</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{dado.pagamento}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-1">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Parc.</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{dado.parcelamento} x</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Valor Total</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{((dado.valor * dado.qtd)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-xl-12">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Observação</h4>
                                                    </div>
                                                    <div >
                                                        {dado.produto.observacao ?
                                                            <h2 >{dado.produto.observacao}</h2>
                                                            :
                                                            <h2 >Sem observações</h2>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagens</h4>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={catalogo.avatar} alt='carregando...' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem do Avatar</label>
                                                    <p>Cadastrar aqui a imagem que será visualizada na lista</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={catalogo.avaria} alt='carregando...' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem da Avaria</label>
                                                    <p>Cadastrar aqui a imagem que será visualizada para avaria</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            {dado.status === 'ABERTO' ?
                                <div className='row col-md-12'>
                                    <div className='col-md-12'>
                                        <label>Imagem do Contrato</label>
                                        <p>Envie para conclusão do pagamento a imagem scanneada do contrato</p>
                                        <input accept=".png, .jpg, .jpeg" onChange={handleContrato} type="file" name="name" placeholder="Escolha a imagem " />

                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                {dado.status === 'ABERTO' ?
                                    <div className='col-md-2'>
                                        <button type="button" class="btn btn-success mb-1" onClick={() => validaCompra()}>
                                            Finalizar Compra
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                                {dado.status === 'ABERTO' ?
                                    <div className='col-md-2'>
                                        <button type="button" class="btn btn-danger mb-1" onClick={() => cancelaCompra()}>
                                            Cancelar Compra
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                                {dado.status === 'ABERTO' ?
                                    <div className='col-md-2'>
                                        <Link to={{ pathname: '/contrato', state: { venda: dado, catalogo: catalogo } }} >
                                            <button type="button" class="btn btn-info mb-1" >
                                                Imprimir Contrato
                                            </button>
                                        </Link>

                                    </div>
                                    :
                                    <div className='col-md-2'>
                                        <a href={dado.contato} target="_blank">
                                            <button type="button" class="btn btn-info mb-1" >
                                                Ver contrato assinado
                                            </button>
                                        </a>
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
