import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment';

export default function Home() {

    const [agregados, setAgregados] = useState([])
    const [agregadosTemp, setAgregadosTemp] = useState([])
    const [last, setLast] = useState('')
    const [servicos] = useState({
        agregados: firebase.firestore().collection('formulario')
    })

    useEffect(() => {
        servicos.agregados.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setLast(snap.docs[snap.docs.length - 1])
            setAgregados(data.filter((d) => d.status === 1 || d.status === 3))
            setAgregadosTemp(data)
        })
    }, [servicos.agregados])

    function carrega() {
        if (last) {
            servicos.agregados.startAfter(last).get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setLast(snap.docs[snap.docs.length - 1])
                const list = agregados
                data.forEach((d) => {
                    list.push(d)
                })
                setAgregados([])
                setAgregadosTemp([])
                setAgregados(list)
                setAgregadosTemp(list)
            })
        }

    }

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            if (event.target.value === '0') {
                setAgregados(agregadosTemp.filter((d) => !d.status));
            } else if (event.target.value === '1') {
                setAgregados(agregadosTemp.filter((d) => d.status === 1));
            } else if (event.target.value === '3') {
                setAgregados(agregadosTemp.filter((d) => d.status === 3));
            } else {
                setAgregados(agregadosTemp);
            }
        } else {
            setAgregados(agregadosTemp);
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">

                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{agregados.filter((d) => d.status === 1).length}</div>
                                                    <div className="stat-heading">Cadastros Recebidos</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-4">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{agregados.filter((d) => d.status === 3).length}</div>
                                                    <div className="stat-heading">Pendentes de Documentação</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h4 className="box-title">Cadastros Recebidos</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-4'>
                                                    <label>Filtros</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="1">Recebidos </option>
                                                        <option value="3">Pendentes </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Protocolo</th>
                                                            <th>Nome</th>
                                                            <th>Celular</th>
                                                            <th>Email</th>
                                                            <th>Cidade</th>
                                                            <th>Tipo de Veículo</th>
                                                            <th>Data Cadastro</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {agregados.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((agregado) =>
                                                            <tr key={agregado.id} >
                                                                <td>{agregado.protocolo}</td>
                                                                <td>{agregado.nome}</td>
                                                                <td>{agregado.ddd} {agregado.celular}</td>
                                                                <td>{agregado.email}</td>
                                                                <td>{agregado.cidade}</td>
                                                                <td>{agregado.veiculo}</td>
                                                                <td>{moment(agregado.dataCadastro).format('DD/MM/YYYY HH:mm')}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/agregado', state: { agregado: agregado } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {agregados.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem casatrados recebidos</p>
                                                    :
                                                    null
                                                }
                                                {/* {last ?
                                                    <div className="col-sm-2">
                                                        <button style={{ marginLeft: 10 }} onClick={() => carrega()} type="button" class="btn btn-info mb-1">
                                                            Carrega Mais
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
