import React, { Component } from 'react'

class MyLeaderBoardAd extends Component {

    componentDidMount() {
        (window.adsbygoogleRodape = window.adsbygoogleRodape || []).push({})
    }

    render() {
        return (
            <ins className="adsbygoogleRodape"
                style={{ display: 'block', width: '100%', height: 90 }}
                data-ad-client="ca-pub-9383816332258420"
                data-ad-slot="8682041194"
                data-ad-format="auto"
                data-adtest="on"
                data-full-width-responsive="true"></ins>
        )
    }
}

export default MyLeaderBoardAd