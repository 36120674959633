import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import moment from 'moment'

export default function Usuario(props) {
    const [dado] = useState(props.location.state.usuario);

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">

                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {dado.avatar ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={dado.avatar} alt='carregando...' />
                                                        :
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-8'>
                                                    <div className='col-md-12'>
                                                        <h3><b>{dado.nome} {dado.sobrenome}</b></h3>
                                                    </div>
                                                    <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                        <div className='col-md-6' >
                                                            <label>Cargo</label>
                                                            <h4><b>{dado.cargo}</b></h4>
                                                        </div>
                                                        <div className='col-md-6' >
                                                            <label>Email</label>
                                                            <h4><b>{dado.email}</b></h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                        <div className='col-md-4' >
                                                            <label>Contato</label>
                                                            <h4><b>{dado.contato}</b></h4>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label>Adm Sistem</label>
                                                            <h4><b>{dado.usuario}</b></h4>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label>Cadastro</label>
                                                            <h4><b>{moment(dado.dataCadastro).format('DD/MM/YYYY - HH:mm') }</b></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Endereço</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4' >
                                                    <label>Endereço</label>
                                                    <h4><b>{dado.endereco.endereco}</b></h4>
                                                </div>
                                                <div className='col-md-4' >
                                                    <label>Bairro</label>
                                                    <h4><b>{dado.endereco.bairro}</b></h4>
                                                </div>
                                                <div className='col-md-2' >
                                                    <label>N°</label>
                                                    <h4><b>{dado.endereco.numero}</b></h4>
                                                </div>
                                                <div className='col-md-2' >
                                                    <label>CEP</label>
                                                    <h4><b>{dado.endereco.cep}</b></h4>
                                                </div>
                                            </div>
                                            <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                <div className='col-md-4' >
                                                    <label>Cidade</label>
                                                    <h4><b>{dado.endereco.cidade}</b></h4>
                                                </div>
                                                <div className='col-md-2' >
                                                    <label>UF</label>
                                                    <h4><b>{dado.endereco.uf}</b></h4>
                                                </div>
                                                <div className='col-md-6' >
                                                    <label>Cmplemento</label>
                                                    <h4><b>{dado.endereco.complemento}</b></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
