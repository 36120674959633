import React, { useEffect, useState } from 'react';
import Sidebar from './componentes/Sidebar'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2';

export default function DetalheProduto(props) {
    const [dado] = useState(props.location.state.produto);
    const [catalogo, setCatalogo] = useState({})
    const [usuario, setUsuario] = useState({})
    const [avatar, setAvatar] = useState('')
    const [avaria, setAvaria] = useState('')

    const [servicos] = useState({
        auth: firebase.auth(),
        reembalados: firebase.firestore().collection('reembalados'),
        usuarios: firebase.firestore().collection('usuarios'),
        vendas: firebase.firestore().collection('vendas'),
    })

    useEffect(() => {
        servicos.reembalados.doc(dado.id).get().then((snap) => {
            setCatalogo(snap.data())
        })
    }, [])

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className="col-xl-12">
                                                    <h4 className="box-title">Produto</h4>
                                                </div>
                                                <div className="col-xl-12">
                                                    <h3 >{dado.descricao}</h3>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-4">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Valor Total </h4>
                                                    </div>
                                                    <div >
                                                        <h5 >{(dado.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Valor A vista - (com desconto) </h4>
                                                    </div>
                                                    <div >
                                                        <h5 >{(dado.valor - (dado.valor * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Observação</h4>
                                                    </div>
                                                    <div >
                                                        <h5 >{dado.observacao}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagens</h4>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={catalogo.avatar} alt='carregando...' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem Original</label>
                                                    <p>Imagegm meramento ilustrativa.</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={catalogo.avaria} alt='carregando...' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem da Avaria</label>
                                                    <p>Imagem da avaria no produto a ser vendido</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
