import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

export default function DetalheSaida(props) {
    const [dados] = useState(props.location.state ? props.location.state.saida : null)
    const [servicos] = useState({
        clientes: firebase.firestore().collection('clientes'),
        saidas: firebase.firestore().collection('saidas'),
        usuarios: firebase.firestore().collection('usuarios'),
        gaiolas: firebase.firestore().collection('gaiolas'),
    })
    const [listaProdutos, setListaProdutos] = useState([])
    const [usuario, setUsuario] = useState({})
    const [motivo, setMotivo] = useState('')
    const [arquivo, setArquivo] = useState('')
    var metadata = { contentType: 'image/jpeg' };


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
            servicos.saidas.doc(dados.id).collection('produtos').get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setListaProdutos(data)
            })
        })
    }, [servicos.clientes])

    function cadastraAvatar() {
        Swal.close();
        mensagemLoading('Cadastrando Imagem...')
        var uploadTask = firebase.storage().ref().child('saidas/' + dados.id).put(arquivo[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.saidas.doc(dados.id).update({
                    arquivo: downloadURL,
                    dataAtualizacao: moment().format('YYYY-MM-DD'),
                    horaAtualizacao: moment().format('HH:mm'),
                    status: 'FINALIZADO'
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Saída confirmada com sucesso')
                })
            });
        });
    }

    function confirmaSaida() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja finalizar a saída deste serviço?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                if (!arquivo) {
                    mensagemAlerta('Favor escolha o arquivo')
                    return
                }
                listaProdutos.forEach((doc) => {
                    servicos.gaiolas.doc(doc.gaiolaId).collection('produtos').doc(doc.produtoId).update({
                        qtd: parseInt(doc.qtdProduto) - parseInt(doc.qtd)
                    })
                })
                cadastraAvatar();
            }
        });
    }

    function removeDados() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover todos os Dados? Ao remover, você apagará todos os dados inseridos até o momento',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Removendo Dados ...')
                servicos.saidas.doc(dados.id).collection('produtos').onSnapshot((snap) => {
                    if (snap.size > 0) {
                        snap.forEach((doc) => {
                            servicos.saidas.doc(dados.id).collection('produtos').doc(doc.data().id).delete()
                        })
                    } else {
                        servicos.saidas.doc(dados.id).delete().then(() => {
                            Swal.close();
                            mensagemSucesso('Dados removidos com sucesso!')
                        })
                    }
                })
            }
        });
    }

    function cancelaSaida() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja cancelar esta saída? Ao cancelar, ela não sairá da sua lista de requisições, porém não poderá alterar e nem remover',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                if (!motivo) {
                    mensagemAlerta('Por favor diga o motivo do cancelamento!')
                    return;
                } else {
                    mensagemLoading('Cancelando saída...')
                    servicos.saidas.doc(dados.id).update({
                        motivo: motivo,
                        dataAtualizacao: moment().format('YYYY-MM-DD'),
                        horaAtualizacao: moment().format('HH:mm'),
                        status: 'CANCELADO'
                    }).then(() => {
                        mensagemSucesso('Saída cancelada com sucesso!')
                    })
                }
            }
        });
    }

    const handleMotivo = (event) => {
        event.persist();
        setMotivo(event.target.value);
    }
    const handleArquivo = (event) => {
        event.persist();
        setArquivo(event.target.files)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                {dados.status === 'GERADO' ?
                                    <div className='row col-md-12' style={{ marginBottom: 20, marginTop: 20 }}>
                                        <div className='col-md-2'>
                                            <Link to={{ pathname: '/contratoSaidaPromocional', state: { saida: dados } }} type="button" class="btn btn-info mb-1">
                                                Iprimir Contrato
                                            </Link>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados da Base</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' style={{ marginBottom: 20, marginTop: 20 }}>
                                                <div className='col-md-4' >
                                                    <label>Nome</label>
                                                    <p><b>{dados.filial.nome}</b></p>
                                                </div>
                                                <div className='col-md-2' >
                                                    <label>CNPJ</label>
                                                    <p><b>{dados.filial.cnpj}</b></p>
                                                </div>
                                                <div className='col-md-4' >
                                                    <label>Resonsável</label>
                                                    <p><b>{dados.filial.responsavel.nome}</b></p>
                                                </div>
                                                <div className='col-md-2' >
                                                    <label>Contato</label>
                                                    <p><b>{dados.filial.contato}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados do Cliente</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' style={{ marginBottom: 20, marginTop: 20 }}>
                                                <div className='col-md-6' >
                                                    <label>Nome</label>
                                                    <p><b>{dados.cliente.nome}</b></p>
                                                </div>
                                                <div className='col-md-3' >
                                                    <label>CNPJ</label>
                                                    <p><b>{dados.cliente.cnpj}</b></p>
                                                </div>
                                                <div className='col-md-3' >
                                                    <label>Contato</label>
                                                    <p><b>{dados.cliente.contato}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados do Destino</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' style={{ marginBottom: 20, marginTop: 20 }}>
                                                <div className='col-md-6' >
                                                    <label>Nome</label>
                                                    <p><b>{dados.destino.nome}</b></p>
                                                </div>
                                                <div className='col-md-3' >
                                                    <label>CPF</label>
                                                    <p><b>{dados.destino.cpf}</b></p>
                                                </div>
                                                <div className='col-md-3' >
                                                    <label>Contato</label>
                                                    <p><b>{dados.destino.contato}</b></p>
                                                </div>
                                                <div className='col-md-3' style={{ marginTop: 10 }}>
                                                    <label>Endereço</label>
                                                    <p><b>{dados.destino.endereco.endereco}</b></p>
                                                </div>
                                                <div className='col-md-3' style={{ marginTop: 10 }}>
                                                    <label>Bairro/N°</label>
                                                    <p><b>{dados.destino.endereco.bairro} - {dados.destino.endereco.numero}</b></p>
                                                </div>
                                                <div className='col-md-3' style={{ marginTop: 10 }}>
                                                    <label>Cidade</label>
                                                    <p><b>{dados.destino.endereco.cidade}</b></p>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 10 }}>
                                                    <label>UF</label>
                                                    <p><b>{dados.destino.endereco.uf}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados do Produto</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Produto</th>
                                                            <th>Gaiola</th>
                                                            <th>Qtd</th>
                                                            <th>Valor Uni</th>
                                                            <th>Valor Total</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listaProdutos.map((pr) =>
                                                            <tr key={pr.id} >
                                                                <td>{pr.codigoItem}</td>
                                                                <td>{pr.produto}</td>
                                                                <td>{pr.gaiola}</td>
                                                                <td>{pr.qtd}</td>
                                                                <td>{pr.valorUnidade.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                <td>{(parseFloat(pr.valorTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/estoqueSaidaPromocional', state: { produto: pr, saida: dados } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {listaProdutos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem produtos cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {dados.status === "GERADO" ?
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Confirmar Saída</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12'>
                                                    <div className='col-md-12'>
                                                        <label>Imagem do Contrato</label>
                                                        <p>Envie para conclusão da saída favor envie o comprovante após a assinatura</p>
                                                        <input accept=".png, .jpg, .jpeg" type="file" onChange={handleArquivo} name="name" placeholder="Escolha a imagem " />

                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 20, marginBottom: 10 }}>
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => confirmaSaida()}>
                                                        Confirmar Saída
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {dados.status !== 'GERADO' ?
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Status da Saída</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='col-md-12'>
                                                    <div class="form-group">
                                                        <label><b>{dados.status}</b></label>
                                                        {dados.status === "CANCELADO" ?
                                                            <p>{dados.motivo}</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    {dados.status === "FINALIZADO" ?
                                                        <a href={dados.arquivo} target="_blank" type="button" class="btn btn-info mb-1" >
                                                            Visualizar Arquivo
                                                        </a>
                                                        :
                                                        null
                                                    }
                                                    <div class="form-group" style={{ marginTop: 20 }}>
                                                        <label><b>Data / Hora</b></label>
                                                        <p>Dia {moment(dados.dataAtualizacao).format("DD/MM/YYYY")} às {dados.horaAtualizacao}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
