import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function SistemasArquivos() {

    const [sistemas, setSistemas] = useState([])
    const [usuario, setUsuario] = useState({})
    const [sistemasTemp, setSistemasTemp] = useState([])
    const [servicos] = useState({
        documentos: firebase.firestore().collection('documentacao'),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                servicos.documentos.get().then((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setSistemas(data)
                    setSistemasTemp(data)

                })
            }
        })

    }, [servicos.documentos])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando Documentos')
            servicos.documentos.where('status', '==', event.target.value).get().then((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())
                setSistemas(data)
                setSistemasTemp(data)
            })
        } else {
            setSistemas(sistemasTemp);
        }
    }

    function detalheProduto(img) {
        Swal.fire({
            title: "Submit your Github username",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Look up",
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    const githubUrl = `
                  https://api.github.com/users/${login}
                `;
                    const response = await fetch(githubUrl);
                    if (!response.ok) {
                        return Swal.showValidationMessage(`
                    ${JSON.stringify(await response.json())}
                  `);
                    }
                    return response.json();
                } catch (error) {
                    Swal.showValidationMessage(`
                  Request failed: ${error}
                `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `${result.value.login}'s avatar`,
                    imageUrl: result.value.avatar_url
                });
            }
        });
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-9">
                                                    <h4 className="box-title">Documentação</h4>
                                                </div>
                                                <div className="col-sm-3">
                                                    <Link to={{ pathname: '/cadastraDocumentacao' }} >
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Novo Documento
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="ATIVO">Ativos </option>
                                                        <option value="INATIVO">Inativos </option>
                                                        <option value="ANALISE">Em Análise </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Documentação</th>
                                                            <th>Setor</th>
                                                            <th>Descrição</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((produto) =>
                                                            <tr key={produto.id} >
                                                                <td>{produto.nome}</td>
                                                                <td>{produto.setor}</td>
                                                                <td>{produto.descricao.substr(0, 50)}</td>
                                                                {produto.status === "ATIVO" ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-success">ATIVO</span>
                                                                        </button>
                                                                    </td>
                                                                    : produto.status === "INATIVO" ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-danger">INATIVO</span>
                                                                            </button>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                <td>
                                                                    {produto.usuario.id === usuario.id ?
                                                                        <Link to={{ pathname: '/cadastraDocumentacao', state: { produto: produto } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                        :
                                                                        <Link to={{ pathname: '/detalheDocumentacao', state: { produto: produto } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                    }

                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem sistemas cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
