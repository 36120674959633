import React, { useEffect, useState } from 'react';
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function ContratoSaida(props) {
    const [dados] = useState(props.location.state ? props.location.state.saida : null)
    const [servicos] = useState({
        clientes: firebase.firestore().collection('clientes'),
        saidas: firebase.firestore().collection('saidas'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [listaProdutos, setListaProdutos] = useState([])


    const [nome, setNome] = useState('')


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.saidas.doc(dados.id).collection('produtos').get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setListaProdutos(data)
            })
        })
    }, [servicos.clientes])

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div id="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h2 className="box-title" >Pedido N° {dados.pedido}</h2>
                                                <h5 className="box-title">Modalidade | RETIRA</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" ><b>Dados da Base</b></h4>
                                            </div>
                                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>CNPJ</th>
                                                            <th>Responsavel</th>
                                                            <th>Contato</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{dados.filial.nome}</td>
                                                            <td>{dados.filial.cnpj}</td>
                                                            <td>{dados.filial.responsavel.nome}</td>
                                                            <td>{dados.filial.contato}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" ><b>Dados do Destino</b></h4>
                                            </div>
                                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>CPF</th>
                                                            <th>Contato</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{dados.destino.nome}</td>
                                                            <td>{dados.destino.cpf}</td>
                                                            <td>{dados.destino.contato}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Endereço</th>
                                                            <th>Bairro</th>
                                                            <th>N°</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{dados.destino.endereco.endereco}</td>
                                                            <td>{dados.destino.endereco.bairro}</td>
                                                            <td>{dados.destino.endereco.numero}</td>
                                                            <td>{dados.destino.endereco.cidade}</td>
                                                            <td>{dados.destino.endereco.uf}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" ><b>Dados do Produto</b></h4>
                                            </div>
                                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Produto</th>
                                                            <th>Gaiola</th>
                                                            <th>Qtd</th>
                                                            <th>Valor Uni</th>
                                                            <th>Valor Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listaProdutos.map((pr) =>
                                                            <tr key={pr.id} >
                                                                <td>{pr.codigoItem}</td>
                                                                <td>{pr.nome}</td>
                                                                <td>{pr.gaiola}</td>
                                                                <td>{pr.qtd}</td>
                                                                <td>{pr.valorUnidade.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                <td>{(parseFloat(pr.valorTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center",marginTop:30 }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" ><b>_______________________________________________</b></h4>
                                            </div>
                                            <div className="col-sm-12">
                                                <h4 className="box-title" ><b>{dados.destino.nome}</b></h4>
                                                <h5 className="box-title" >{dados.destino.cpf}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
