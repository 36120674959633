import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco, carregaEstados, carregaCidade } from '../../../../services/Localidades'
import moment from 'moment'
import { encode } from 'js-base64'
import Swal from 'sweetalert2'

export default function CadastraCliente() {

    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        pendencia: firebase.firestore().collection('pendencia'),
    })
    const [usuario, setUsuario] = useState({})

    const [ufs, setUfs] = useState([])
    const [cidades, setCidades] = useState([])

    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [contato, setContato] = useState('')
    const [cpf, setCpf] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [perfil, setPerfil] = useState('')
    const [setor, setSetor] = useState('')
    const [cargo, setCargo] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)
    const [verificaCpf, setVerificaCpf] = useState(false)
    const [loadingCpf, setLoadingCpf] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            carregaEstados().then((res) => {
                setUfs(res);
            })
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios, servicos.pendencia])


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem do Avatar...')
        var uploadTask = firebase.storage().ref().child('usuarios/avatar' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Usuário cadastrado com sucesso! ')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraCliente() {
        if (nome === '') {
            mensagemAlerta('Favor digitar o nome ')
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar o sobrenome ')
        } else if (contato === '' || contato.length < 11) {
            mensagemAlerta('Contato vazio ou incompleto')
        } else if (verificaCpf === 1) {
            mensagemAlerta('CPF já existe no sistema')
        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
        } else if (uf === '') {
            mensagemAlerta('Favor escolha o Estado')
        } else if (cidade === '') {
            mensagemAlerta('Favor escolha a Cidade')
        } else if (numero === '') {
            mensagemAlerta('Favor digite o número')
        } else if (setor === '') {
            mensagemAlerta('Favor escolha o Setor')
        } else if (setor !== 'MOTORISTA' && cargo === '') {
            mensagemAlerta('Favor diga o cargo do usuário no setor')
        } else if (perfil === '') {
            mensagemAlerta('Escolha se o usuário é administrador do Perfil')
        } else if (email === '') {
            mensagemAlerta('Favor diite o Email para Login')
        } else if (senha === '') {
            mensagemAlerta('Favor digite uma senha para acesso')
        } else if (confirmaSenha === '') {
            mensagemAlerta('Favor confirme a senha')
        } else if (senha !== confirmaSenha) {
            mensagemAlerta('As senhas não conferem!')
        } else {
            mensagemLoading('Aguarde.. Cadastrando informações')
            servicos.usuarios.where('email', '==', email).get().then((res) => {
                if (res.size > 0) {
                    mensagemAlerta('Este email já está em uso pelo sistema')
                    return
                } else {
                    servicos.usuarios.add({
                        nome: nome,
                        sobrenome: sobrenome,
                        contato: contato,
                        cpf: cpf,
                        endereco: {
                            endereco: endereco,
                            bairro: bairro,
                            numero: numero,
                            complemento: complemento,
                            uf: uf,
                            cidade: cidade,
                            cep: cep
                        },
                        status: parseInt(0),
                        admin: {
                            id: usuario.id,
                            nome: usuario.nome
                        },
                        email: email,
                        usuario: perfil,
                        setor: setor,
                        cargo: cargo,
                        pass: encode(email + confirmaSenha),
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                    }).then((docRef) => {
                        servicos.usuarios.doc(docRef.id).update({
                            id: docRef.id
                        }).then(() => {
                            Swal.close()
                            if (upload) {
                                cadastraAvatar(docRef.id)
                            } else {
                                mensagemSucesso('Usuário cadastrado com sucesso!')
                            }
                        }).catch(() => {
                            mensagemErro('Erro ao atualizar o usuário')
                        })
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar o usuário')
                    })
                }
            })
        }
    }


    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            mensagemLoading('Verificando CPF...')
            servicos.usuarios.where('cpf', '==', event.target.value).onSnapshot((snap) => {
                if (snap.size > 0) {
                    Swal.close()
                    setLoadingCpf(false)
                    setVerificaCpf(1)
                } else {
                    Swal.close();
                    setLoadingCpf(false)
                    setVerificaCpf(0)
                }
            })
        } else {
            setVerificaCpf(false)
        }
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaEndereco(event.target.value).then((doc) => {
                if (doc.erro === true) {
                    mensagemAlerta('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handlePerfil = (event) => {
        event.persist();
        setPerfil(event.target.value);
    }
    const handleCargo = (event) => {
        event.persist();
        setCargo(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    const handleUf = (event) => {
        mensagemLoading('Buscando cidades...')
        event.persist();
        setUf(event.target.value);
        if (event.target.value) {
            setUf(ufs[event.target.value])
            carregaCidade(ufs[event.target.value].sigla).then((res) => {
                Swal.close();
                setCidades(res);
            })
        }
    }
    const handleCidade = (event) => {
        event.persist();
        if (event.target.value) {
            setCidade(cidades[event.target.value]);
        }
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep() {
        setLoadingCep(true)
        if (cep.length < 8) {
            setLoadingCep(false)
            mensagemAlerta('Favor digite o CEP para realizar a busca')
        } else {
            buscaEndereco(cep).then((doc) => {
                if (doc.erro === true) {
                    setLoadingCep(false)
                    mensagemAlerta('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setLoadingCep(false)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/usuarios'
        })
    }
    function sucessoCpf(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Usuário</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        :
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Usuário</label>
                                                    <p>Para mudar a foto do usuário é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>

                                                    {loadingImage ?
                                                        <div class="progress-box progress-1" style={{ marginTop: 10 }}>
                                                            <div class="por-txt">Carregando... ({progresso})</div>
                                                            <div class="progress mb-2" >
                                                                <div class="progress-bar bg-flat-color-1" role="progressbar" style={{ width: progresso }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                    }

                                                </div>
                                            </div>

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Sobrenome</label>
                                                    <div class="form-group">
                                                        <input type="text" value={sobrenome} id="company" class="form-control" onChange={handleSobrenome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>CPF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cpf} id="company" class="form-control" onChange={handleCpf} />
                                                    </div>
                                                    {loadingCpf ?
                                                        <p style={{ color: "blue", fontSize: 10 }}>Aguarde.. Verifcando CPF no sistema </p>
                                                        :
                                                        null
                                                    }
                                                    {verificaCpf === 1 ?
                                                        <p style={{ color: "red", fontSize: 10 }}>CPF já cadastrado </p>
                                                        : verificaCpf === 0 ?
                                                            <p style={{ color: "green", fontSize: 10 }}>CPF disponível </p>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-2'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input disabled type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input disabled type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Setor</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleSetor} >
                                                        <option value="">Escolha o setor </option>
                                                        <option value="DIRETORIA">Diretoria </option>
                                                        <option value="CONTROLADORIA">Controladoria </option>
                                                        <option value="CONTROLE">Torre de Controle </option>
                                                        <option value="LOGISTICA">Logística </option>
                                                        <option value="LABS">Labs </option>
                                                        <option value="MOTORISTA">Motoristas </option>
                                                        <option value="MARKETING">Marketing </option>
                                                        <option value="COMERCIAL">Comercial </option>
                                                    </select>
                                                </div>
                                                {setor && setor !== 'MOTORISTA' ?
                                                    <div className='col-md-4'>
                                                        <label>Cargo</label>
                                                        <div class="form-group">
                                                            <input type="text" value={cargo} id="company" class="form-control" onChange={handleCargo} />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className='col-md-3'>
                                                    <label>Administrador do Sistema?</label>
                                                    <select name="select" id="select" class="form-control" onChange={handlePerfil} >
                                                        <option value="">Escolha  </option>
                                                        <option value="MASTER">Sim </option>
                                                        <option value="COMUM">Não </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Email</label>
                                                    <div class="form-group">
                                                        <input type="text" value={email} id="company" class="form-control" onChange={handleEmail} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Senha</label>
                                                    <div class="form-group">
                                                        <input type="password" value={senha} id="company" class="form-control" onChange={handleSenha} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Confirma Senha</label>
                                                    <div class="form-group">
                                                        <input type="password" value={confirmaSenha} id="company" class="form-control" onChange={handleConfirmaSenha} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Cadastrando Usuário ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                        Cadastrar Usuário
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
