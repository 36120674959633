import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import { Link } from 'react-router-dom'

export default function Sistemas() {
    const [sistemas] = useState([
        { titulo: "Reembalados", descricao: "Sistema de produtos em estoque", ico: "pe-7s-box2", link: '/reembalados', setor: 'LOGISTICA' },
        { titulo: "Promocional", descricao: "Organização de Gaiolas", ico: "pe-7s-ticket", link: '/logisticaPromocional', setor: 'LOGISTICA' },
        { titulo: "Ocorrências", descricao: "Administração de Ocorrências", ico: "pe-7s-graph3", link: '/ocorrencias', setor: 'TORRE CONTROLE' },
        { titulo: "Cupons ", descricao: "Sistema de cupons sobre combustíveis", ico: "pe-7s-file", link: '/cupons', setor: 'CONTROLADORIA' },
        { titulo: "Estoque", descricao: "Sistema gerencial de produtos", ico: "pe-7s-drawer", link: '/estoque', setor: 'CONTROLADORIA' },
        { titulo: "Avarias", descricao: "Sistema de controle de avarias", ico: "pe-7s-box1", link: '/avarias', setor: 'LOGISTICA' },
        { titulo: "Notas Fiscais", descricao: "Exportação de dados", ico: "pe-7s-file", link: '/notasFiscais', setor: 'TORRE CONTROLE' },
        { titulo: "Blog", descricao: "Criação de conteúdo", ico: "pe-7s-news-paper", link: '/blogApp', setor: 'LABS' },
        { titulo: "Produtos", descricao: "Conteúdo de Segurança", ico: "pe-7s-like2", link: '/produtosSeguranca', setor: 'LABS' },
        { titulo: "Funil", descricao: "Status de prospecção", ico: "pe-7s-graph1", link: '/funil', setor: 'COMERCIAL' },
        { titulo: "Sistemas", descricao: "Controle Sistemas Externos", ico: "pe-7s-display1", link: '/sistemasExternos', setor: 'LABS' },
        { titulo: "Equipamentos", descricao: "Equipamentos de Tecnologia", ico: "pe-7s-print", link: '/equipamentosTecnologia', setor: 'LABS' },
        { titulo: "Chamados", descricao: "Sistemas de Chamados", ico: "pe-7s-airplay", link: '/chamados', setor: 'CONTROLADORIA' },
        { titulo: "Contra Cheques", descricao: "Sistema", ico: "pe-7s-id", link: '/contraCheques', setor: 'CONTROLADORIA' },
        { titulo: "Leads", descricao: "Sistema", ico: "pe-7s-id", link: '/leads', setor: 'COMERCIAL' },
        { titulo: "Pagamentos", descricao: "Parceiros", ico: "pe-7s-cash", link: '/pagamentos', setor: 'CONTROLADORIA' },
    ])
    const [temp, setTemp] = useState([])

    useEffect(() => {
        setTemp(sistemas)
    }, [])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            if (event.target.value === "geral") {
                setTemp(sistemas)
            } else {
                setTemp(sistemas.filter((d) => d.setor === event.target.value))
            }
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Sistemas do Setor</h4>
                                                    <p>Lista de sistemas internos de organização da Entrega Já</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-4'>
                                                    <label>Filtro por área</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="geral">Todos  </option>
                                                        <option value="DIRETORIA">Diretoria  </option>
                                                        <option value="CONTROLADORIA">Controladoria </option>
                                                        <option value="TORRE CONTROLE">Torre de Controle </option>
                                                        <option value="LOGISTICA">Logística </option>
                                                        <option value="LABS">Labs </option>
                                                        <option value="MARKETING">Marketing </option>
                                                        <option value="COMERCIAL">Comercial </option>
                                                    </select>
                                                </div>
                                                <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                                    {temp.map((sistema) =>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: sistema.link }} >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="stat-widget-five">
                                                                            <div className="stat-icon dib flat-color-3">
                                                                                <i className={sistema.ico}></i>
                                                                            </div>
                                                                            <div className="stat-content">
                                                                                <div className="text-left dib">
                                                                                    <div className="stat-text">{sistema.titulo}</div>
                                                                                    <div className="stat-heading">{sistema.descricao}</div>
                                                                                    <p>{sistema.setor}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {temp.length === 0 ?
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-12" style={{ alignItems: "center" }}>
                                                        <h4 className="box-title">Sem sistemas</h4>
                                                        <p>O setor ainda não possui sistema</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
