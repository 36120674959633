import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { carregaEstados, carregaCidade } from '../../../../../services/Localidades'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function CadastraOcorrencia() {

    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        chamados: firebase.firestore().collection('chamados'),
        categorias: firebase.firestore().collection('categoriaChamados'),
    })
    const [usuario, setUsuario] = useState({})
    const [categorias, setCategorias] = useState([])

    const [departamento, setDepartamento] = useState('')
    const [categoria, setCategoria] = useState('')
    const [assunto, setAssunto] = useState('')
    const [descricao, setDescricao] = useState('')
    const [prioridade, setPrioridade] = useState('')
    const [tipoEmail, setTipoEmail] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios, servicos.pendencia])


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a anexo...')
        var uploadTask = firebase.storage().ref().child('chamado/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.chamados.doc(id).update({
                    documento: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Chamado cadastrado com sucesso!')
                })
            });
        });
    }

    function cadastraCliente() {
        if (!departamento) {
            mensagemAlerta('Favor escolha o departamento')
            return
        }
        if (!categoria) {
            mensagemAlerta('Favor escolha a Categoria')
            return
        }
        if (!assunto) {
            mensagemAlerta('Favor digite seu Assunto')
            return
        }
        if (!descricao) {
            mensagemAlerta('Favor digite o motivo do chamado')
            return
        }
        if (!prioridade) {
            mensagemAlerta('Favor escolha o tipo de prioridade')
            return
        }
        if (!tipoEmail) {
            mensagemAlerta('Favor escolha se receber todas as mensagens por email')
            return
        }
        servicos.chamados.where('departamento', '==', departamento).get().then((sn) => {
            if (sn.size > 0) {
                let prot = ''
                if (sn.size < 10) {
                  prot =  departamento.substring(0, 3).toLocaleUpperCase() + '000' + parseInt(sn.size + 1)
                } else if (sn.size > 10 && sn.size < 100) {
                   prot = departamento.substring(0, 3).toLocaleUpperCase() + '00' + parseInt(sn.size + 1)
                } else if (sn.size > 100 && sn.size < 1000) {
                   prot = departamento.substring(0, 3).toLocaleUpperCase() + '0' + parseInt(sn.size + 1)
                }else{
                  prot =  departamento.substring(0, 3).toLocaleUpperCase() + parseInt(sn.size + 1)
                }
                mensagemLoading('Cadastrando ocorrência...')
                servicos.chamados.add({
                    protocolo: prot,
                    tipoEmail: tipoEmail,
                    categoria: categoria,
                    departamento: departamento,
                    assunto: assunto,
                    descricao: descricao,
                    prioridade:prioridade,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    usuario: {
                        id: usuario.id,
                        nome: usuario.nome,
                        email: usuario.email
                    },
                    status:'ABERTO'
                }).then((docRef) => {
                    servicos.chamados.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        if (avatar) {
                            cadastraAvatar(docRef.id)
                        } else {
                            mensagemSucesso('Chamado criado com sucesso!')
                        }
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar a Ocorrência')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar a Ocorrência')
                })
            } else {
                mensagemLoading('Cadastrando ocorrência...')
                servicos.chamados.add({
                    protocolo: departamento.substring(0, 3).toLocaleUpperCase() + '0001',
                    tipoEmail: tipoEmail,
                    categoria: categoria,
                    departamento: departamento,
                    assunto: assunto,
                    descricao: descricao,
                    prioridade:prioridade,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    usuario: {
                        id: usuario.id,
                        nome: usuario.nome,
                        email: usuario.email
                    },
                    status:'ABERTO'
                }).then((docRef) => {
                    servicos.chamados.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        if (avatar) {
                            cadastraAvatar(docRef.id)
                        } else {
                            mensagemSucesso('Chamado criado com sucesso!')
                        }
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar a Ocorrência')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar a Ocorrência')
                })
            }
        })

    }


    const handleDepartamento = (event) => {
        event.persist();
        setDepartamento(event.target.value);
        mensagemLoading('Buscando categorias')
        servicos.categorias.where('setor', '==', event.target.value).get().then((doc) => {
            Swal.close();
            const data = doc.docs.map((d) => d.data())
            setCategorias(data)
        })
    }
    const handleCategoria = (event) => {
        event.persist();
        setCategoria(event.target.value);
    }
    const handlePrioridade = (event) => {
        event.persist();
        setPrioridade(event.target.value);
    }
    const handleAssunto = (event) => {
        event.persist();
        setAssunto(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleTipoEmail = (event) => {
        event.persist();
        setTipoEmail(event.target.value);
    }

    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Chamado</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-3'>
                                                    <label>Departamento</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleDepartamento} >
                                                        <option value="">Escolha  </option>
                                                        <option value="CONTROLADORIA">Controladoria / Financeiro </option>
                                                        <option value="CONTROLE">Torre de Controle / Monitoramento </option>
                                                        <option value="LOGISTICA">Logística / Operação </option>
                                                        <option value="LABS">Labs / Tecnologia </option>
                                                        <option value="MARKETING">Marketing </option>
                                                        <option value="COMERCIAL">Comercial </option>
                                                    </select>
                                                </div>
                                                {departamento ?
                                                    <div className='col-md-3'>
                                                        <label>Categorias</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleCategoria} >
                                                            <option value="">Escolha o nível</option>
                                                            {categorias.map((c, index) =>
                                                                <option value={c.categoria}>{c.categoria}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Detalhes da Ocorrência</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-12'>
                                                    <label>Assunto</label>
                                                    <div class="form-group">
                                                        <input type="text" value={assunto} id="company" class="form-control" onChange={handleAssunto} />
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <textarea type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Prioridade</label>
                                                    <select name="select" id="select" class="form-control" onChange={handlePrioridade} >
                                                        <option value="">Escolha o nível</option>
                                                        <option value="BAIXA">Baixa </option>
                                                        <option value="NORMAL">Normal </option>
                                                        <option value="ALTA">Alta </option>
                                                        <option value="URGENTE">Urgente </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Receber Email?</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTipoEmail} >
                                                        <option value="">Escolha o nível</option>
                                                        <option value="SIM">Sim </option>
                                                        <option value="NAO">Não </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Arquivo ou Print</label>
                                                    <div class="form-group">
                                                        <input type="file" id="company" class="form-control" onChange={handleAvatar} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                            {loading ?
                                                <button type="button" class="btn btn-default mb-1" >
                                                    Cadastrando Chamado ...
                                                </button>
                                                :
                                                <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                    Cadastrar Chamado
                                                </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
