import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Sistemas() {
    const [anos] = useState([
        { ano: '2022' },
        { ano: '2023' },
        { ano: '2024' },
        // {ano:'2025'},
        // {ano:'2026'},
        // {ano:'2027'},
        // {ano:'2028'},
        // {ano:'2029'},
        // {ano:'2030'},
    ])
    const [sistemas, setSistemas] = useState([])
    const [listaMes, setListaMes] = useState([])

    const [ano, setAno] = useState('')
    const [mes, setMes] = useState('')
    var metadata = {
        contentType: 'file/pdf'
    };
    const [arquivo, setArquivo] = useState('');

    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios')
    })

    useEffect(() => {
        servicos.usuarios.where('status', '==', 1).get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setSistemas(data)
        })
    }, [servicos.usuarios])

    function buscaContraMeses(item) {
        setAno(item.ano)
        servicos.usuarios.doc(usuario.id).collection('contracheques').doc(item.ano).collection('mes').get().then((d) => {
            const data = d.docs.map(doc => doc.data())
            setListaMes(data)
        })
    }

    function cadastraAvatar() {
        servicos.usuarios.doc(usuario.id).collection('contracheques').doc(ano).collection('mes').where('mes', '==', mes).where('status', '==', 'ATIVO').get().then((d) => {
            if (d.size > 0) {
                mensagemAlerta('Ja existe contracheque para este usuário para o período de ' + mes + ' de ' + ano)
            } else {
                mensagemLoading('Salvando o Contra Cheque de ' + usuario.nome)
                var uploadTask = firebase.storage().ref().child('contracheques/' + usuario.id + '/contracheque' + mes + ano + '.pdf').put(arquivo[0], metadata);
                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                            console.log('Passou')
                    }
                }, (error) => {
                    switch (error.code) {
                        case 'storage/unauthorized':
                            break;
                        case 'storage/canceled':
                            break;
                        case 'storage/unknown':
                            break;
                        default:
                            console.log('Passou')
                    }
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        servicos.usuarios.doc(usuario.id).collection('contracheques').doc(ano).collection('mes').add({
                            documento: downloadURL,
                            mes: mes,
                            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                            status: 'ATIVO'
                        }).then((docSnap) => {
                            servicos.usuarios.doc(usuario.id).collection('contracheques').doc(ano).collection('mes').doc(docSnap.id).update({
                                id: docSnap.id
                            }).then(() => {
                                Swal.close();
                                mensagemSucesso('Contra Cheque cadastrado com sucesso ')
                            })
                        })
                    });
                });
            }
        })

    }

    function cadastraContraCheque() {
        if (!mes) {
            mensagemAlerta('Favor escolha o Mes')
            return
        }
        if (!arquivo) {
            mensagemAlerta('Favor escolha o Arquivo')
            return
        }
        cadastraAvatar()
    }

    function removeContraCheque(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o Contra Cheque de  ' + usuario.nome + ' de ' + item.mes + ' de ' + ano + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Removendo imagem ...')
                firebase.storage().ref().child('contracheques/' + usuario.id + '/contracheque' + item.mes + ano + '.pdf').delete().then(() => {
                    servicos.usuarios.doc(usuario.id).collection('contracheques').doc(ano).collection('mes').doc(item.id).update({
                        documento: '',
                        status: 'REMOVIDO',
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                    }).then(() => {
                        mensagemSucesso('Contra Cheque removido com sucesso!')
                    })
                })
            }
        });
    }

    const handleAvatar = (event) => {
        event.persist();
        setArquivo(event.target.files)
    }
    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/contraCheques'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{usuario.id ? usuario.nome + ' ' + usuario.sobrenome : 'Carregando'}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div style={{ marginTop: 10 }} className="table-stats order-table ov-h">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th>Ano</th>
                                                    <th>Detalhe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {anos.sort((a, b) => {
                                                    if (parseInt(a.ano) < parseInt(b.ano)) {
                                                        return -1;
                                                    }
                                                    if (parseInt(a.ano) > parseInt(b.ano)) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((item) =>
                                                    <tr key={item.id} >
                                                        <td>Ano de {item.ano}</td>
                                                        <td>
                                                            <button onClick={() => buscaContraMeses(item)} data-toggle="modal" data-target="#exampleModal2" >
                                                                <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{usuario.id ? usuario.nome + ' ' + usuario.sobrenome : 'Carregando'}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="row m-0">
                                        <div className='col-md-3'>
                                            <label>Mes</label>
                                            <select name="select" id="select" class="form-control" onChange={handleMes} >
                                                <option value="">Escolha </option>
                                                <option value="JANEIRO" >Janeiro </option>
                                                <option value="FEVEREIRO" >Fevereiro </option>
                                                <option value="MARÇO" >Março </option>
                                                <option value="ABRIL">Abril </option>
                                                <option value="MAIO" >Maio </option>
                                                <option value="JUNHO" >Junho </option>
                                                <option value="JULHO" >Julho </option>
                                                <option value="AGOSTO">Agosto </option>
                                                <option value="SETEMBRO" >Setembro </option>
                                                <option value="OUTUBRO" >Outubro </option>
                                                <option value="NOVEMBRO" >Novembro </option>
                                                <option value="DEZEMBRO" >Dezembro </option>
                                            </select>
                                        </div>
                                        <div className='col-md-9'>
                                            <label>Arquivo (PDF)</label>
                                            <input onChange={handleAvatar} accept='.pdf' type="file" name="name" placeholder="Escolha a imagem " />
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <div style={{ marginTop: 10 }} className="table-stats order-table ov-h">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th>Mes</th>
                                                    <th>Detalhe</th>
                                                    <th>Remover</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listaMes.sort((a, b) => {
                                                    if (parseInt(a.ano) < parseInt(b.ano)) {
                                                        return -1;
                                                    }
                                                    if (parseInt(a.ano) > parseInt(b.ano)) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((item) =>
                                                    <tr key={item.id} >
                                                        <td>{item.mes}</td>
                                                        <td>
                                                            {item.documento ?
                                                                <a href={item.documento} target={'_blank'} >
                                                                    <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                </a>
                                                                :
                                                                'Documento Removido'
                                                            }

                                                        </td>
                                                        <td>
                                                        {item.documento ?
                                                               <button onClick={() => removeContraCheque(item)} >
                                                               <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                           </button>
                                                                :
                                                                'Não pode ser excluido'
                                                            }
                                                            
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {listaMes.length === 0 ?
                                            <p style={{ textAlign: 'center' }} >Sem Conta Cheques cadastrados</p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    <button type="button" onClick={() => cadastraContraCheque()} class="btn btn-success">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Colaboradores</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>CPF</th>
                                                            <th>Setor</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((item) =>
                                                            <tr key={item.id} >
                                                                <td>{item.nome} {item.sobrenome}</td>
                                                                <td>{item.cpf}</td>
                                                                <td>{item.setor}</td>
                                                                <td>
                                                                    <button onClick={() => setUsuario(item)} data-toggle="modal" data-target="#exampleModal" >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem sistemas cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
