import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Logística() {

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Logística Promocional</h4>
                                                    <p>Escolha abaixo os serviços</p>
                                                </div>
                                                <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                                    <div className="col-lg-4 col-md-6">
                                                        <Link to={{ pathname: '/filiaisLogisticaPromocional' }} >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-home"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Filiais</div>
                                                                                <div className="stat-heading">Centros de Distribuições</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <Link to={{ pathname: '/clientes' }} >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-users"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Clientes</div>
                                                                                <div className="stat-heading">Distribuidores e marcas</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <Link to={{ pathname: '/gaiolasLogisticaPromocional' }} >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-menu"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Gaiolas</div>
                                                                                <div className="stat-heading">Alocação de produtos</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <Link to={{ pathname: '/produtosLogisticaPromocional' }} >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-box2"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">SKU</div>
                                                                                <div className="stat-heading">Produtos de cada Cliente</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <Link to={{ pathname: '/saidasPromocional' }} >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-next-2"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Saídas</div>
                                                                                <div className="stat-heading">Saídas dos produtos</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
