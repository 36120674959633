import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Estoque(props) {
    const [saida] = useState(props.location.state ? props.location.state.saida : null)
    const [produto] = useState(props.location.state ? props.location.state.produto : null)
    const [gaiolas, setGaiolas] = useState([])
    const [servicos] = useState({
        saidas: firebase.firestore().collection('saidas'),
        gaiolas: firebase.firestore().collection('gaiolas'),
    })

    useEffect(() => {
        const list = []
        mensagemLoading('Carregando Gaiolas..')
        servicos.gaiolas.where('filial.id', '==', saida.filial.id).where('cliente.id', '==', saida.cliente.id).get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            data.forEach((g) => {
                servicos.gaiolas.doc(g.id).collection('produtos').where('codigoItem', '==', produto.codigoItem).get().then((snap) => {
                    if (snap.size > 0) {
                        list.push(
                            ...list,
                            {
                                filial: g.filial,
                                rua: g.rua,
                                gaiolaId: g.id,
                                gaiola: g.numero,
                                produto: snap.docs.map((d) => d.data())[0].id,
                                idProduto: snap.docs.map((d) => d.data())[0].idProduto,
                                qtd: parseInt(snap.docs.map((d) => d.data())[0].qtd)
                            }
                        )
                    }
                })
            })
            setTimeout(() => {
                Swal.close()
                setGaiolas(list)
            }, 5000);
        })

    }, [servicos.gaiolas])

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }

    function handleItemChanged(event, i) {
        gaiolas[i].remove = parseInt(event.target.value)
        if (gaiolas[i].qtd < event.target.value) {
            mensagemAlerta('Valor menor que ')
        }
    }

    function confirmaRetirada() {
        mensagemLoading('Confirmando retirada..')
        let qtd = 0
        const list = []
        gaiolas.forEach((d) => {
            if (d.remove) {
                qtd = parseInt(qtd) + d.remove
                list.push({
                    gaiola: d.gaiolaId,
                    produto: d.produto,
                    idProduto: d.idProduto,
                    qtd: parseInt(d.remove)
                })
            }
        })
        setTimeout(() => {
            Swal.close()
            if (qtd > produto.qtd) {
                mensagemAlerta('A retirada ultrapassa a quantidade maior de produtos')
                return
            }
            if (list.length === 0) {
                mensagemAlerta('Nenhuma retirada foi registrada para saída')
                return
            }
            mensagemSucesso('Tudo certo')
        }, 5000);
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Retiriada de Produtos - Restam: {produto.qtd} para retirar</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Gaiola</th>
                                                            <th>Rua</th>
                                                            <th>Filial</th>
                                                            <th>Qtd</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {gaiolas.sort((a, b) => {
                                                            if (a.numero < b.numero) {
                                                                return -1;
                                                            }
                                                            if (a.numero > b.numero) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((filial, index) =>
                                                            <tr key={filial.id} >
                                                                <td>{filial.gaiola}</td>
                                                                <td>{filial.rua.nome}</td>
                                                                <td>{filial.filial.nome}</td>
                                                                <td>{filial.qtd}</td>
                                                                <td style={{ width: '10%' }}>
                                                                    <div class="form-group">
                                                                        <input onChange={(text) => handleItemChanged(text, index)} type="text" id="company" class="form-control" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {gaiolas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem gaiolas registradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {gaiolas.length > 0 ?
                                <div >
                                    <button onClick={() => confirmaRetirada()} type="button" class="btn btn-info mb-1">
                                        Confirmar Retiradas
                                    </button>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
