import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment, { invalid, normalizeUnits } from 'moment'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

export default function CadastraCliente(props) {
    const [dados] = useState(props.location.state ? props.location.state.sistema : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        equipamentosTecnologia: firebase.firestore().collection('equipamentosTecnologia'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [filiais, setFiliais] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [historico, setHistorico] = useState([])
    const [admin, setAdmin] = useState({})

    const [nome, setNome] = useState(dados ? dados.nome : '')
    const [marca, setMarca] = useState(dados ? dados.marca : '')
    const [modelo, setModelo] = useState(dados ? dados.modelo : '')
    const [serie, setSerie] = useState(dados ? dados.serie : '')
    const [tipoEquipamento, setTipoEquipamento] = useState(dados ? dados.tipoEquipamento : '')
    const [estado, setEstado] = useState(dados ? dados.estado : '')
    const [problema, setProblema] = useState(dados ? dados.problema : '')
    const [filial, setFilial] = useState(dados && dados.filial ? dados.filial : {})
    const [usuario, setUsuario] = useState(dados && dados.usuario ? dados.usuario : {})
    const [conjugado, setConjugado] = useState(dados ? dados.conjugado : '')
    const [detalheHistorico, setDetalheHistorico] = useState({})

    // Saidas
    const [acao, setAcao] = useState('')
    const [filialSaida, setFilialSaida] = useState({})
    const [usuarioSaida, setUsuarioSaida] = useState({})
    const [solicitante, setSolicitante] = useState({})
    const [descricaoSaida, setDescricaoSaida] = useState('')

    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [status, setStatus] = useState(dados ? dados.status : '')

    const [loading, setLoading] = useState(false)

    var metadata = { contentType: 'image/jpeg' };
    const [upload, setUpload] = useState('');
    const [uploadComprovante, setUploadComprovante] = useState('');
    const [imagem, setImagem] = useState(dados ? dados.imagem : '');

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setAdmin(doc.data())
                })
            }
            servicos.filiais.get().then((doc) => {
                const data = doc.docs.map((d) => d.data())
                setFiliais(data)
            })
            servicos.usuarios.get().then((doc) => {
                const data = doc.docs.map((d) => d.data())
                setUsuarios(data)
            })
            if (dados) {
                servicos.equipamentosTecnologia.doc(dados.id).collection('historico').get().then((doc) => {
                    const data = doc.docs.map((d) => d.data())
                    setHistorico(data)
                })
            }
        })
    }, [servicos.usuarios])

    function cadastraImagem1(id) {
        mensagemLoading('Salvando a imagem...')
        var uploadTask = firebase.storage().ref().child('equipamentoTecnologia/' + id).put(imagem[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.equipamentosTecnologia.doc(id).update({
                    imagem: downloadURL
                }).then(() => {
                    Swal.close();
                    if (dados) {
                        mensagemSucesso('Equipamento atualizado com sucesso')
                    } else {
                        mensagemSucesso('Equipamento cadastrado com sucesso')
                    }

                })
            });
        });
    }

    function enviaComprovante(id) {
        if(!uploadComprovante){
            mensagemAlerta('Favor selecione o comprovante ')
            return
        }
        mensagemLoading('Enviando comprovante...')
        var uploadTask = firebase.storage().ref().child('equipamentoTecnologia/' + dados.id+'/comprovante/'+id).put(uploadComprovante[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.equipamentosTecnologia.doc(dados.id).collection('historico').doc(id).update({
                    comprovante: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Comprovante enviado com sucesso!')
                })
            });
        });
    }

    function cadastraSistema() {
        if (!imagem) {
            mensagemAlerta('Por selecione a imagem do equipamento')
            return
        }
        if (!nome) {
            mensagemAlerta('Por favor digite o nome do equipamento')
            return
        }
        if (!marca) {
            mensagemAlerta('Favor digite a marca do equipamento')
            return
        }
        if (!modelo) {
            mensagemAlerta('Por favor digite o modelo do equipamento')
            return
        }
        if (!serie) {
            mensagemAlerta('Por favor digite o N° de série do equipamento')
            return
        }
        if (!tipoEquipamento) {
            mensagemAlerta('Diga qual o tipo de equipamento ')
            return
        }
        if (!estado) {
            mensagemAlerta('Diga o estado do equipamento')
            return
        }
        if (!problema) {
            mensagemAlerta('O equipamento possui problema?')
            return
        }
        if (!filial.id) {
            mensagemAlerta('Escolha uma filial para o equipamento')
            return
        }
        if (!descricao) {
            mensagemAlerta('Descreva sobre o equipamento')
            return
        }
        if (!status) {
            mensagemAlerta('Diga em qual estatus está o sistema')
            return
        }
        if (dados) {
            mensagemLoading('Atualizando Equipamento...')
            servicos.equipamentosTecnologia.doc(dados.id).update({
                nome: nome,
                marca: marca,
                modelo: modelo,
                serie: serie,
                tipoEquipamento: tipoEquipamento,
                estado: estado,
                problema: problema,
                filial: filial,
                usuario: usuario,
                conjugado: conjugado,
                descricao: descricao,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD'),
            }).then((docRef) => {
                if (upload) {
                    cadastraImagem1(dados.id)
                } else {
                    mensagemSucesso('Equipamento atualizado com sucesso!')
                }
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o equipamento ' + erro)
            })
        } else {
            mensagemLoading('Cadastrando Sistema...')
            servicos.equipamentosTecnologia.add({
                nome: nome,
                marca: marca,
                modelo: modelo,
                serie: serie,
                tipoEquipamento: tipoEquipamento,
                estado: estado,
                problema: problema,
                filial: filial,
                usuario: usuario,
                conjugado: conjugado,
                descricao: descricao,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD'),
                dataAtualizacao: moment().format('YYYY-MM-DD'),
            }).then((docRef) => {
                servicos.equipamentosTecnologia.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    Swal.close();
                    cadastraImagem1(docRef.id)
                }).catch((err) => {
                    mensagemErro('Erro ao atualizar o equipamento ' + err)
                })
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o equipamento ' + erro)
            })
        }

    }

    function cadastraAcao() {
        if (!acao) {
            mensagemAlerta('Por selecione o tipo de Ação')
            return
        }
        if (!solicitante.id) {
            mensagemAlerta('Por favor selecione o Solicitante')
            return
        }
        if (acao === 'FILIAL') {
            if (!filialSaida.id) {
                mensagemAlerta('Favor selecione a filial de destino')
                return
            }
        }
        if (acao === 'USUARIO') {
            if (!usuarioSaida.id) {
                mensagemAlerta('Favor selecione o usuário de destino')
                return
            }
        }
        if (!descricaoSaida) {
            mensagemAlerta('Digite a descrição da ação')
            return
        }

        mensagemLoading('Cadastrando Ação...')
        servicos.equipamentosTecnologia.doc(dados.id).collection('historico').add({
            acao: acao,
            solicitante: {
                id: solicitante.id ? solicitante.id : null,
                nome: solicitante.id ? solicitante.nome : null,
                email: solicitante.id ? solicitante.email : null,
                cpf: solicitante.id ? solicitante.cpf : null,
            },
            filial: {
                id: filialSaida.id ? filialSaida.id : null,
                nome: filialSaida.id ? filialSaida.nome : null,
                avatar: filialSaida.id ? filialSaida.avatar : null,
            },
            usuario: {
                id: usuarioSaida.id ? usuarioSaida.id : null,
                nome: usuarioSaida.id ? usuarioSaida.nome : null,
                email: usuarioSaida.id ? usuarioSaida.email : null,
                cpf: usuarioSaida.id ? usuarioSaida.cpf : null,
            },
            descricao: descricaoSaida,
            admin: {
                id: admin.id,
                nome: admin.nome,
                email: admin.email
            },
            equipamento: {
                nome: nome,
                marca: marca,
                modelo: modelo,
                id: dados.id,
                tipoEquipamento: tipoEquipamento,
                avatar: imagem
            },
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
        }).then((docRef) => {
            servicos.equipamentosTecnologia.doc(dados.id).collection('historico').doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                mensagemSucesso('Ação cadastrada com sucesso! imprima o documento para assinatura das partes')
            })
        }).catch((erro) => {
            mensagemErro('Erro ao cadastrar a ação ' + erro)
        })

    }

    function detalhe(d) {
        setDetalheHistorico(d)
    }

    const handleAvatar = (event) => {
        event.persist();
        setImagem(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleMarca = (event) => {
        event.persist();
        setMarca(event.target.value);
    }
    const handleModelo = (event) => {
        event.persist();
        setModelo(event.target.value);
    }
    const handleSerie = (event) => {
        event.persist();
        setSerie(event.target.value);
    }
    const handleTipoEquipamento = (event) => {
        event.persist();
        setTipoEquipamento(event.target.value);
    }
    const handleEstado = (event) => {
        event.persist();
        setEstado(event.target.value);
    }
    const handleProblema = (event) => {
        event.persist();
        setProblema(event.target.value);
    }
    const handleFilial = (event) => {
        event.persist();
        if (event.target.value) {
            setFilial({
                filial: filiais[event.target.value].nome,
                id: filiais[event.target.value].id
            });
        }
    }
    const handleUsuario = (event) => {
        event.persist();
        if (event.target.value) {
            if (event.target.value === 'FILIAL') {
                setUsuario({
                    nome: 'DA FILIAL',
                    id: 'FILIAL'
                });
            } else {
                setUsuario({
                    nome: usuarios[event.target.value].nome + ' ' + usuarios[event.target.value].sobrenome,
                    id: usuarios[event.target.value].id
                });
            }
        }
    }
    const handleConjugado = (event) => {
        event.persist();
        setConjugado(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }


    const handleAcao = (event) => {
        event.persist();
        setAcao(event.target.value);
    }
    const handleSolicitante = (event) => {
        event.persist();
        setSolicitante(usuarios[event.target.value]);
    }
    const handleFilialSaida = (event) => {
        event.persist();
        setFilialSaida(filiais[event.target.value]);
    }
    const handleUsuarioSaida = (event) => {
        event.persist();
        setUsuarioSaida(usuarios[event.target.value]);
    }
    const handleDescricaoSaida = (event) => {
        event.persist();
        setDescricaoSaida(event.target.value);
    }

    const handleComprovante = (event) => {
        event.persist();
        setUploadComprovante(event.target.files)
    }



    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">{detalheHistorico.acao}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {detalheHistorico.id ?
                                <div class="modal-body">
                                    <div className='row col-md-12' style={{ marginTop: 20 }}>
                                        <div className='col-md-4'>
                                            <label>Solicitante</label>
                                            <p>{detalheHistorico ? detalheHistorico.solicitante.nome : ''}</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <label>CPF</label>
                                            <p>{detalheHistorico ? detalheHistorico.solicitante.cpf : ''}</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <label>Email</label>
                                            <p>{detalheHistorico ? detalheHistorico.solicitante.email : ''}</p>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: 20 }}>
                                            <label>Descrição do Serviço</label>
                                            <p>{detalheHistorico.descricao}</p>
                                        </div>
                                        <div className='col-md-6' style={{ marginTop: 20 }}>
                                            <label>Usuário</label>
                                            <p>{detalheHistorico.admin.nome}</p>
                                        </div>
                                        <div className='col-md-6' style={{ marginTop: 20 }}>
                                            <label>Data de Emissão</label>
                                            <p>{moment(detalheHistorico.dataCadastro).format('DD/MM/YYYY HH:mm')}</p>
                                        </div>
                                        {!detalheHistorico.comprovante ?
                                            <>
                                                <div className='col-md-10' style={{ marginTop: 20 }}>
                                                    <label>Documento de comprovação</label>
                                                    <input onChange={handleComprovante} type="file" name="name" placeholder="Escolha a imagem " />
                                                </div>
                                                <div className='col-md-1' style={{ marginTop: 40 }}>
                                                    <button onClick={()=> enviaComprovante(detalheHistorico.id)} type="button" class="btn btn-primary">Enviar</button>
                                                </div>
                                            </>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                :
                                null
                            }

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                {detalheHistorico.comprovante ?
                                    <a href={detalheHistorico.comprovante} type="button" class="btn btn-primary">Ver comprovante</a>
                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Equipamento</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        : imagem ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={imagem} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://png.pngtree.com/png-clipart/20190612/original/pngtree-enterprise-server-equipment-png-image_3266805.jpg' alt='carregando...' />
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Equipamento</label>
                                                    <p>Para mudar a foto do equipamento é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>
                                                    <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />

                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome do Equipamento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Marca</label>
                                                    <div class="form-group">
                                                        <input type="text" value={marca} id="company" class="form-control" onChange={handleMarca} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Modelo</label>
                                                    <div class="form-group">
                                                        <input type="text" value={modelo} id="company" class="form-control" onChange={handleModelo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>N° Série</label>
                                                    <div class="form-group">
                                                        <input type="text" value={serie} id="company" class="form-control" onChange={handleSerie} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipo Equipamento</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTipoEquipamento} >
                                                        <option value="">Escolha </option>
                                                        <option value="DESKTOP" selected={dados && dados.tipoEquipamento === "DESKTOP" ? "selected" : null}>Desktop </option>
                                                        <option value="NOTEBOOK" selected={dados && dados.tipoEquipamento === "NOTEBOOK" ? "selected" : null}>Notebook </option>
                                                        <option value="CELULAR" selected={dados && dados.tipoEquipamento === "CELULAR" ? "selected" : null}>Celular </option>
                                                        <option value="CARTAO" selected={dados && dados.tipoEquipamento === "CARTAO" ? "selected" : null}>Máquina de Cartão </option>
                                                        <option value="IMPRESSORA" selected={dados && dados.tipoEquipamento === "IMPRESSORA" ? "selected" : null}>Impressora </option>
                                                        <option value="SERVIDOR" selected={dados && dados.tipoEquipamento === "SERVIDOR" ? "selected" : null}>Servidor </option>
                                                        <option value="TV" selected={dados && dados.tipoEquipamento === "TV" ? "selected" : null}>Televisor </option>
                                                        <option value="MONITOR" selected={dados && dados.tipoEquipamento === "MONITOR" ? "selected" : null}>Monitor </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Estado</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleEstado} >
                                                        <option value="">Escolha </option>
                                                        <option value="NOVO" selected={dados && dados.estado === "NOVO" ? "selected" : null}>Novo </option>
                                                        <option value="USADO" selected={dados && dados.estado === "USADO" ? "selected" : null}>Usado </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Falha</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleProblema} >
                                                        <option value="">Escolha </option>
                                                        <option value="NENHUM" selected={dados && dados.problema === "NENHUM" ? "selected" : null}>Nenhum </option>
                                                        <option value="FISICO" selected={dados && dados.problema === "FISICO" ? "selected" : null}>Físico </option>
                                                        <option value="SISTEMA" selected={dados && dados.problema === "SISTEMA" ? "selected" : null}>Sistema </option>
                                                        <option value="AMBOS" selected={dados && dados.problema === "AMBOS" ? "selected" : null}>Ambos </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-3'>
                                                    <label>Filial</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFilial} >
                                                        <option value="">Escolha a filial </option>
                                                        {filiais.map((f, index) =>
                                                            <option value={index} selected={dados && dados.filial.id === f.id ? "selected" : null}>{f.nome} </option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Usuário Responsável</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleUsuario} >
                                                        <option value="">Escolha </option>
                                                        <option value="FILIAL" selected={dados && dados.usuario.id === "FILIAL" ? "selected" : null}>Da Filial </option>
                                                        {usuarios.map((f, index) =>
                                                            <option value={index} selected={dados && dados.usuario.id === f.id ? "selected" : null}>{f.nome} {f.sobrenome} </option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Conjugado</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleConjugado} >
                                                        <option value="">Escolha </option>
                                                        <option value="SIM" selected={dados && dados.conjugado === "SIM" ? "selected" : null}>Sim </option>
                                                        <option value="NAO" selected={dados && dados.conjugado === "NAO" ? "selected" : null}>Não </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-12'>
                                                    <label>Descrição do Equipamento</label>
                                                    <div class="form-group">
                                                        <textarea value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Status </label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha </option>
                                                        <option value="ATIVO" selected={dados && dados.status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                        <option value="DISPONIVEL" selected={dados && dados.status === "DISPONIVEL" ? "selected" : null}>Disponível </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {dados ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando Equipamento ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                            Atualizar Equipamento
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Equipamento ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                            Cadastrar Equipamento
                                                        </button>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                {dados ?
                                    <>
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="row m-0">
                                                        <h4 className="box-title">Ações nos equipamentos</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body-- row col-md-12" >
                                                    <div className='col-md-2'>
                                                        <label>Ações </label>
                                                        <select name="select" id="select" class="form-control" onChange={handleAcao} >
                                                            <option value="">Escolha </option>
                                                            <option value="MANUTENCAO" >Manutenção </option>
                                                            <option value="USUARIO" >Mudança de Usuário </option>
                                                            <option value="FILIAL" >Mudança de Filial </option>
                                                            <option value="VENDA" >Venda</option>
                                                            <option value="DESCARTE" >Descarte</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label>Solicitante</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleSolicitante} >
                                                            <option value="">Escolha </option>
                                                            {usuarios.map((f, index) =>
                                                                <option value={index} >{f.nome} {f.sobrenome} </option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    {acao === 'FILIAL' ?
                                                        <div className='col-md-3'>
                                                            <label>Filial</label>
                                                            <select name="select" id="select" class="form-control" onChange={handleFilialSaida} >
                                                                <option value="">Escolha a filial </option>
                                                                {filiais.map((f, index) =>
                                                                    <option value={index} >{f.nome} </option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {acao === 'USUARIO' ?
                                                        <div className='col-md-3'>
                                                            <label>Usuário Responsável</label>
                                                            <select name="select" id="select" class="form-control" onChange={handleUsuarioSaida} >
                                                                <option value="">Escolha </option>
                                                                <option value="FILIAL" selected={dados && dados.usuario.id === "FILIAL" ? "selected" : null}>Da Filial </option>
                                                                {usuarios.map((f, index) =>
                                                                    <option value={index} >{f.nome} {f.sobrenome} </option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    <div className='col-md-12' style={{ marginTop: 20 }}>
                                                        <label>Descrição da ação</label>
                                                        <div class="form-group">
                                                            <textarea value={descricaoSaida} id="company" class="form-control" onChange={handleDescricaoSaida} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="page-header col-md-3">
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Ação ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraAcao()}>
                                                            Cadastrar Ação
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="row m-0">
                                                        <h4 className="box-title">Histórico do Equipamento</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body--">
                                                    <div className="table-stats order-table ov-h">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th>Ação</th>
                                                                    <th>Solicitante</th>
                                                                    <th>Usuário</th>
                                                                    <th>Data/Hora</th>
                                                                    <th>Detalhe</th>
                                                                    <th>Documento</th>
                                                                    <th>Aviso</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {historico.sort((a, b) => {
                                                                    return moment(b.dataCadastro) - moment(a.dataCadastro);
                                                                }).map((sistema) =>
                                                                    <tr key={sistema.id} >
                                                                        <td>{sistema.acao}</td>
                                                                        <td>{sistema.solicitante.nome}</td>
                                                                        <td>{sistema.admin.nome}</td>
                                                                        <td>{moment(sistema.dataCadastro).format("DD/MM/YYYY - HH:mm")}</td>
                                                                        <td>
                                                                            <button onClick={() => detalhe(sistema)} data-toggle="modal" data-target="#exampleModal">
                                                                                <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={{ pathname: '/reciboEquipamentosTecnologia', state: { sistema: sistema } }} >
                                                                                <span className="badge badge-info"><i className='fa fa-file'></i></span>
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={{ pathname: '/avisoEquipamentosTecnologia', state: { sistema: sistema } }} >
                                                                                <span className="badge badge-info"><i className='fa fa-file'></i></span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        {historico.length === 0 ?
                                                            <p style={{ textAlign: 'center' }} >Sem hiistórico para o equipamento</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
