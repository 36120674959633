import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';
import { Link } from 'react-router-dom'

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ProdutoFilial(props) {
    const [filial] = useState(props.location.state.filial)
    const [id, setId] = useState('')
    const [numero, setNumero] = useState('')
    const [descricao, setDescricao] = useState('')
    const [observacao, setObservacao] = useState('')
    const [qtd, setQtd] = useState('')
    const [valor, setValor] = useState('')

    const [produtos, setProdutos] = useState([])
    const [produtosFile, setProdutosFile] = useState([])

    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        reembalados: firebase.firestore().collection('reembalados'),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [uploadAvatar, setUploadAvatar] = useState('');
    const [avaria, setAavaria] = useState('');
    const [uploadAvaria, setUploadAvaria] = useState('');
    const [progresso, setProgresso] = useState('')

    const [edit, setEdit] = useState(false)

    function editar(dados) {
        setEdit(true)
        setId(dados.id)
        setNumero(dados.codigoItem)
        setDescricao(dados.descricao)
        setQtd(dados.qtd)
        setValor(dados.valor)
        setObservacao(dados.observacao)
        setAvatar(dados.avatar)
        setAavaria(dados.avaria)
    }

    function limpar() {
        setEdit(false)
        setId('')
        setNumero('')
        setDescricao('')
        setQtd('')
        setValor('')
        setObservacao('')
        setAvatar('')
        setAavaria('')
        setUploadAvaria('')
        setUploadAvatar('')
    }

    function imagemInternet(id) {
        Swal.close();
        mensagemLoading('Cadastrando Imagem do Avatar...')
        var uploadTask = firebase.storage().ref().child('reembalados/avatar' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.reembalados.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    if (uploadAvaria) {
                        imagemAvaria(id);
                    } else {
                        mensagemSucesso('Produto atualizado com sucesso!')
                    }

                })
            });
        });
    }

    function imagemAvaria(id) {
        Swal.close();
        mensagemLoading('Cadastrando Imagem da Avaria...')
        var uploadTask = firebase.storage().ref().child('reembalados/avaria' + id).put(avaria[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.reembalados.doc(id).update({
                    avaria: downloadURL
                }).then(() => {
                    mensagemSucesso('Produto atualizado com sucesso')
                })
            });
        });
    }

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                servicos.reembalados.where('filial.id', '==', filial.id).get().then((snap) => {
                    const list = [];
                    const data = snap.docs.map(doc => doc.data())
                    setProdutos(data)
                    data.forEach((doc) => {
                        var valor = parseFloat(doc.valor * doc.qtd)
                        list.push({
                            codigoItem:doc.codigoItem,
                            descricao:doc.descricao,
                            qtd:doc.qtd,
                            valorUni:doc.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
                            valor:valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
                            observacao:doc.observacao
                        })
                    })
                    setProdutosFile(list);
                })
            }
        })
    }, [servicos.reembalados, servicos.usuarios])

    function cadastraProduto() {
        if (!edit && !uploadAvatar) {
            mensagemAlerta('Favor escolha a imagem da avatar')
            return
        }
        if (!edit && !uploadAvaria) {
            mensagemAlerta('Favor escolha a imagem a da Avaria')
            return
        }
        if (!numero) {
            mensagemAlerta('Favor digite o código de produto')
            return
        }
        if (!descricao) {
            mensagemAlerta('Favor digite a descrição do produto')
            return
        }
        if (!qtd) {
            mensagemAlerta('Favor digite a quantidade do produto')
            return
        }
        if (!valor) {
            mensagemAlerta('Favor digite o valor do produto')
            return
        }
        if (edit) {
            mensagemLoading('Atualizando produto...')
            servicos.reembalados.doc(id).update({
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email,
                    avatar: usuario.avatar
                },
                observacao: observacao,
                filial: {
                    id: filial.id,
                    nome: filial.nome,
                    responsavel: filial.responsavel.nome
                },
                codigoItem: numero,
                descricao: descricao,
                valor: parseFloat(valor),
                qtd: parseInt(qtd),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRef) => {
                if (uploadAvatar) {
                    imagemInternet(id)
                } else if (uploadAvaria) {
                    imagemAvaria(id)
                } else {
                    mensagemSucesso('Produto atualizado com sucesso')
                }
            }).catch((docRef) => {
                mensagemErro('Erro ao cadastrar a produto! Favor verifique com o administradors')
                Swal.close();
            })
        } else {
            mensagemLoading('Cadstrando produto...')
            servicos.reembalados.add({
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email,
                    avatar: usuario.avatar
                },
                observacao: observacao,
                filial: {
                    id: filial.id,
                    nome: filial.nome,
                    responsavel: filial.responsavel.nome
                },
                codigoItem: numero,
                descricao: descricao,
                valor: parseFloat(valor),
                qtd: parseInt(qtd),
                tipo: 'reembalados',
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRef) => {
                servicos.reembalados.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    servicos.reembalados.doc(docRef.id).collection('historico').add({
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        acao: 'Produto Cadastrado',
                        filial: {
                            id: filial.id,
                            nome: filial.nome
                        },
                        usuario: {
                            id: usuario.id,
                            nome: usuario.nome
                        }
                    }).then((dcId) => {
                        servicos.reembalados.doc(docRef.id).collection('historico').doc(dcId.id).update({
                            id: dcId.id
                        }).then(() => {
                            Swal.close();
                            imagemInternet(docRef.id)
                        }).catch(() => {
                            mensagemErro('Erro ao atualizar a produto no histórico! Favor verifique com o administrador')
                            Swal.close();
                        })
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar a produto no histórico! Favor verifique com o administrador')
                        Swal.close();
                    })
                }).catch(() => {
                    mensagemErro('Erro ao atualizar a produto! Favor verifique com o administrador')
                    Swal.close();
                })
            }).catch((docRef) => {
                mensagemErro('Erro ao cadastrar a produto! Favor verifique com o administradors')
                Swal.close();
            })
        }
    }

    function excluir(dados) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o produto ' + dados.descricao + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Aguarde.. Removendo produto')
                firebase.storage().ref().child('reembalados/avaria' + dados.id).delete().then(() => {
                    firebase.storage().ref().child('reembalados/avatar' + dados.id).delete().then(() => {
                        servicos.reembalados.doc(dados.id).collection('historico').onSnapshot((snap) => {
                            if (snap.size > 0) {
                                snap.forEach((doc) => {
                                    servicos.reembalados.doc(dados.id).collection('historico').doc(doc.data().id).delete();
                                })
                            } else {
                                servicos.reembalados.doc(dados.id).delete().then(() => {
                                    mensagemSucesso('Produto removido com sucesso!')
                                })
                            }
                        })
                    })
                })
            }
        });
    }

    function calculaValor() {
        const list = [];
        let valor = 0;
        produtos.forEach((doc) => {
            if (doc.qtd > 0) {
                let val = parseFloat(doc.valor * doc.qtd)
                valor = parseFloat(valor) + parseFloat(val)
            }
        })
        console.log(valor)
        mensagemAlerta("Valor total de " + parseFloat(valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }))
    }

    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleObservacao = (event) => {
        event.persist();
        setObservacao(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }
    const handleValor = (event) => {
        event.persist();
        setValor(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            limpar()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUploadAvatar(URL.createObjectURL(event.target.files[0]))
    }
    const handleAvaria = (event) => {
        event.persist();
        setAavaria(event.target.files)
        setUploadAvaria(URL.createObjectURL(event.target.files[0]))
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-8">
                                                    <h4 className="box-title">Produtos em estoque - {filial.nome}</h4>
                                                </div>
                                                <div className='col-md-2' >
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => calculaValor()} >
                                                        Calcular Valor
                                                    </button>
                                                </div>
                                                <div class="col-sm-2" >
                                                    <ExcelFile element={<button type="button" class="btn btn-success mb-1" >imprimir Excel</button>}>
                                                        <ExcelSheet data={produtosFile} name="Employees">
                                                            <ExcelColumn label="CODIGO" value="codigoItem" />
                                                            <ExcelColumn label="DESCRICAO" value="descricao" />
                                                            <ExcelColumn label="QTD" value="qtd" />
                                                            <ExcelColumn label="VALORUNI" value="valorUni" />
                                                            <ExcelColumn label="VALOR" value="valor" />
                                                            <ExcelColumn label="OBSERVACAO" value="observacao" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {uploadAvatar ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={uploadAvatar} alt='carregando...' />
                                                        : avatar ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={avatar} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://image.flaticon.com/icons/png/512/102/102348.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem do Avatar</label>
                                                    <p>Cadastrar aqui a imagem que será visualizada na lista</p>
                                                    <input accept=".png, .jpg, .jpeg" onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />

                                                </div>
                                                <div className='col-md-4'>
                                                    {uploadAvaria ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={uploadAvaria} alt='carregando...' />
                                                        : avaria ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={avaria} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://d26lpennugtm8s.cloudfront.net/stores/001/089/934/rte/caixinha%20pintadinha.png' alt='carregando...' />

                                                    }

                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem da Avaria</label>
                                                    <p>Cadastrar aqui a imagem que será visualizada para avaria</p>
                                                    <input accept=".png, .jpg, .jpeg" onChange={handleAvaria} type="file" name="name" placeholder="Escolha a imagem " />

                                                </div>
                                            </div>
                                            <div className="row m-0" style={{ marginTop: 30 }}>
                                                <div className='col-md-2'>
                                                    <label>Código</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Descrição do Produto</label>
                                                    <div class="form-group">
                                                        <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Qtd </label>
                                                    <div class="form-group">
                                                        <input type="text" value={qtd} id="company" class="form-control" onChange={handleQtd} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Valor Un.</label>
                                                    <div class="form-group">
                                                        <input type="text" value={valor} id="company" class="form-control" onChange={handleValor} />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label>Observações</label>
                                                    <div class="form-group">
                                                        <textarea type="text" value={observacao} id="company" class="form-control" onChange={handleObservacao} />
                                                    </div>
                                                </div>
                                                {edit ?
                                                    <>
                                                        <div className='col-md-2' style={{ marginTop: 30 }}>
                                                            <button type="button" class="btn btn-success mb-1" onClick={() => cadastraProduto()}>
                                                                Atualizar produto
                                                            </button>
                                                        </div>
                                                        <div className='col-md-2' style={{ marginTop: 30 }}>
                                                            <button type="button" class="btn btn-danger mb-1" onClick={() => limpar()}>
                                                                Cancelar
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='col-md-2' style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraProduto()}>
                                                            Cadastrar produto
                                                        </button>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Código</th>
                                                            <th>Descrição</th>
                                                            <th>Qtd</th>
                                                            <th>Valor Un</th>
                                                            <th>Valor Total</th>
                                                            <th>Detalhe</th>
                                                            <th>Editar</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {produtos.map((produto) =>
                                                            <tr key={produto.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={produto.avatar} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{produto.codigoItem}</td>
                                                                <td>{produto.descricao}</td>
                                                                <td>{produto.qtd}</td>
                                                                <td>{(produto.valor - (produto.valor * 0.25)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                <td>{(parseFloat((produto.valor - (produto.valor * 0.1))) * parseInt(produto.qtd)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheProdutosReembalados', state: { produto: produto } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => editar(produto)}>
                                                                        <span className="badge badge-warning"><i className='fa fa-edit'></i></span>
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => excluir(produto)}>
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {produtos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem produtos cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
