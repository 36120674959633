import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import axios from 'axios';
var XMLParser = require('react-xml-parser');

export default function Gaiolas() {

    const [produto, setProduto] = useState({})
    const [numero, setNumero] = useState('')
    const [descricao, setDescricao] = useState('')
    const [ncm, setNcm] = useState('')
    const [qtd, setQtd] = useState('')
    const [qtdAdicional, setQtdAdicional] = useState('')
    const [status, setStatus] = useState('')
    const [cliente, setCliente] = useState({})
    const [fornecedor, setFornecedor] = useState({})
    const [produtosNota, setProdutosNota] = useState([])
    const [produtosNotaTemp, setProdutosNotaTemp] = useState([])
    const [listaNotas, setListaNotas] = useState({})

    const [fornecedoes, setFornecedores] = useState([])
    const [clientes, setClientes] = useState([])
    const [produtos, setProdutos] = useState([])
    const [produtosFiltro, setProdutosFiltro] = useState([])

    const [filtro, setFiltro] = useState(true)
    const [clienteFiltro, setClienteFiltro] = useState('')

    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        produtos: firebase.firestore().collection('produtos'),
        notasFiscais: firebase.firestore().collection('notasFiscais'),
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    const [colunas, setColunas] = useState([])
    const [rows, setRows] = useState([])
    const [file, setFile] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                servicos.clientes.where('status', '==', 'ATIVO').onSnapshot((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setClientes(data)
                })
            }
        })
    }, [servicos.clientes, servicos.produtos, produtos, produtosFiltro])

    function buscaProduto() {
        if (!numero) {
            mensagemAlerta('O código do produto não pode ser vazio')
            return
        }
        mensagemLoading('Bsucando produtos..')
        servicos.produtos.where('codigoItem', '==', numero).get().then((d) => {
            if (d.size > 0) {
                Swal.close();
                setProduto(d.docs.map((p) => p.data())[0])
                setQtd(d.docs.map((p) => p.data())[0].qtd)
            } else {
                Swal.close()
                setProduto({})
                setQtd('')
                setQtdAdicional('')
                mensagemAlerta('Este produto não está cadastrado! Realize novo cadastro')
            }
        })
    }

    function cadastraProduto() {
        if (!cliente.id) {
            mensagemAlerta('Favor selecione o Cliente')
            return
        }
        if (!fornecedor.id) {
            mensagemAlerta('Favor selecione o Fornecedor')
            return
        }
        if (!descricao) {
            mensagemAlerta('Favor digite a descrição do produto')
            return
        }
        if (!qtd) {
            mensagemAlerta('Favor digite a quantidade')
            return
        }
        mensagemLoading('Cadstrando SKU...')
        servicos.produtos.where('codigoItem', '==', numero).get().then((snap) => {
            if (snap.size > 0) {
                mensagemAlerta('Produto já cadastrado com o mesmo Código')
            } else {
                servicos.produtos.add({
                    cliente: {
                        id: cliente.id,
                        nome: cliente.nome
                    },
                    fornecedor: {
                        id: fornecedor.id,
                        nome: fornecedor.nome
                    },
                    usuario: {
                        nome: usuario.nome,
                        id: usuario.id,
                        email: usuario.email,
                        avatar: usuario.avatar
                    },
                    ncm: ncm,
                    codigoItem: numero,
                    descricao: descricao,
                    qtdGaiolas: parseInt(qtd),
                    qtd: parseInt(qtd),
                    tipo: 'SKU',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                }).then((docRef) => {
                    servicos.produtos.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        Swal.close();
                        mensagemSucesso('SKU cadastrado com sucesso')
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar a SKU! Favor verifique com o administrador')
                        Swal.close();
                    })
                }).catch((docRef) => {
                    mensagemErro('Erro ao cadastrar a SKU! Favor verifique com o administradors')
                    Swal.close();
                })
            }
        })

    }

    function atualizaProduto() {
        if (!qtd) {
            mensagemAlerta('Favor digite a quantidade')
            return
        }
        if (!qtdAdicional) {
            mensagemAlerta('Favor digite a quantidade na Nota')
            return
        }
        mensagemLoading('Atualizando SKU...')
        servicos.produtos.doc(produto.id).update({
            qtd: parseInt(qtd) + parseInt(qtdAdicional),
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
        }).then(() => {
            Swal.close();
            mensagemSucesso('SKU atualizado com sucesso')
        }).catch((docRef) => {
            mensagemErro('Erro ao cadastrar a SKU! Favor verifique com o administradors')
            Swal.close();
        })

    }

    function cadastraProdutoXml(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja adicionar o produto ' + item.nome + ' ao estoque ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const list = []
                if (!cliente.id) {
                    mensagemAlerta('Favor selecione o Cliente')
                    return
                }
                if (!fornecedor.id) {
                    mensagemAlerta('Favor selecione o Fornecedor')
                    return
                }
                mensagemLoading('Cadstrando SKU...')
                servicos.produtos.add({
                    cliente: {
                        id: cliente.id,
                        nome: cliente.nome
                    },
                    fornecedor: {
                        id: fornecedor.id,
                        nome: fornecedor.nome
                    },
                    usuario: {
                        nome: usuario.nome,
                        id: usuario.id,
                        email: usuario.email,
                        avatar: usuario.avatar
                    },
                    ncm: item.ncm,
                    codigoItem: item.codigo,
                    descricao: item.nome,
                    qtd: parseInt(item.qtd),
                    qtdGaiolas: parseInt(item.qtd),
                    tipo: 'SKU',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                }).then((docRef) => {
                    servicos.produtos.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        servicos.produtos.doc(docRef.id).collection('notas').add({
                            nf: item.nf,
                            emissao: item.emissao,
                            natureza: item.natureza,
                            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                        }).then((docNota) => {
                            servicos.produtos.doc(docRef.id).collection('notas').doc(docNota.id).update({
                                id: docNota.id
                            }).then(() => {
                                servicos.notasFiscais.where('nf', '==', listaNotas.nf).get().then((n) => {
                                    if (n.size > 0) {
                                        setProdutosNota([])
                                        produtosNotaTemp.forEach((d, index) => {
                                            if (d.codigo === item.codigo) {
                                                produtosNotaTemp[index].cadastro = 0
                                            }
                                        })
                                        setProdutosNota(produtosNotaTemp)
                                        Swal.close();
                                        mensagemSucesso('SKU cadastrado com sucesso')
                                    } else {
                                        servicos.notasFiscais.add(listaNotas).then((snap) => {
                                            servicos.notasFiscais.doc(snap.id).update({
                                                id: snap.id
                                            }).then(() => {
                                                setProdutosNota([])
                                                produtosNotaTemp.forEach((d, index) => {
                                                    if (d.codigo === item.codigo) {
                                                        produtosNotaTemp[index].cadastro = 0
                                                    }
                                                })
                                                setProdutosNota(produtosNotaTemp)
                                                Swal.close();
                                                mensagemSucesso('SKU cadastrado com sucesso')
                                            })
                                        })
                                    }
                                })

                            })
                        })
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar a SKU! Favor verifique com o administrador')
                        Swal.close();
                    })
                }).catch((docRef) => {
                    mensagemErro('Erro ao cadastrar a SKU! Favor verifique com o administradors')
                    Swal.close();
                })
            }
        });

    }

    function atualizaProdutoXml(item) {

        Swal.fire({
            title: 'Aviso',
            text: 'Deseja atualizar o produto ' + item.nome + ' ao estoque ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Atualizando SKU...')
                servicos.produtos.doc(item.produtoId).update({
                    qtd: parseInt(item.qtdTotal),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                }).then(() => {
                    servicos.produtos.doc(item.produtoId).collection('notas').add({
                        nf: item.nf,
                        emissao: item.emissao,
                        natureza: item.natureza,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    }).then((docNota) => {
                        servicos.produtos.doc(item.produtoId).collection('notas').doc(docNota.id).update({
                            id: docNota.id
                        }).then(() => {
                            servicos.notasFiscais.where('nf', '==', listaNotas.nf).get().then((n) => {
                                if (n.size > 0) {
                                    setProdutosNota([])
                                    produtosNotaTemp.forEach((d, index) => {
                                        if (d.codigo === item.codigo) {
                                            produtosNotaTemp[index].cadastro = 0
                                        }
                                    })
                                    setProdutosNota(produtosNotaTemp)
                                    Swal.close();
                                    mensagemSucesso('SKU cadastrado com sucesso')
                                } else {
                                    servicos.notasFiscais.add(listaNotas).then((snap) => {
                                        servicos.notasFiscais.doc(snap.id).update({
                                            id: snap.id
                                        }).then(() => {
                                            setProdutosNota([])
                                            produtosNotaTemp.forEach((d, index) => {
                                                if (d.codigo === item.codigo) {
                                                    produtosNotaTemp[index].cadastro = 0
                                                }
                                            })
                                            setProdutosNota(produtosNotaTemp)
                                            Swal.close();
                                            mensagemSucesso('SKU cadastrado com sucesso')
                                        })
                                    })
                                }
                            })
                        })
                    })
                }).catch((docRef) => {
                    mensagemErro('Erro ao cadastrar a SKU! Favor verifique com o administradors')
                    Swal.close();
                })
            }
        });


    }

    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleNCM = (event) => {
        event.persist();
        setNcm(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }
    const handleQtdAdicional = (event) => {
        event.persist();
        setQtdAdicional(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleCliente = (event) => {
        event.persist();
        setFornecedores([])
        setFornecedor({})
        if (event.target.value) {
            mensagemLoading('Buscando Fornecedores...')
            setCliente(clientes[event.target.value])
            servicos.clientes.doc(clientes[event.target.value].id).collection('fornecedores').get().then((d) => {
                const data = d.docs.map(doc => doc.data())
                setFornecedores(data)
                Swal.close()
            })
        } else {
            setCliente({})
        }
    }
    const handleFornecedor = (event) => {
        event.persist();
        setFornecedor({})
        if (event.target.value) {
            setFornecedor(fornecedoes[event.target.value])
        } else {
            setFornecedor({})
        }
    }
    const handleBuscaProdutos = (event) => {
        setClienteFiltro('')
        setProdutos([])
        setProdutosFiltro([])
        event.persist();
        if (event.target.value) {
            setClienteFiltro(event.target.value)
        } else {
            mensagemAlerta('Favor escolha o cliente')
        }
    }

    const handleBuscaProdutosFiltro = (event) => {
        setProdutosFiltro([])
        setProdutos([])
        event.persist();
        if (event.target.value) {
            if (event.target.value === 'todos') {
                setFiltro(false)
                mensagemLoading('Buscando produtos')
                servicos.produtos
                    .where('tipo', '==', 'SKU')
                    .where('cliente.id', '==', clienteFiltro)
                    .get().then((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        setProdutos(data)
                    })
            } else {
                setFiltro(true)
                mensagemLoading('Aguarde... Carregando dados')
                servicos.gaiolas
                    .where('cliente.id', '==', clienteFiltro)
                    .get().then((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        const pArray = [];
                        data.forEach((g) => {
                            servicos.gaiolas.doc(g.id).collection('produtos').get().then((p) => {
                                const pData = p.docs.map(doc => doc.data())
                                pData.forEach((pd) => {
                                    const prod = {
                                        id: pd.id,
                                        codigoItem: pd.codigoItem,
                                        descricao: pd.descricao,
                                        qtd: pd.qtd,
                                        gaiola: pd.gaiola.numero,
                                        status: pd.status
                                    }
                                    pArray.push(prod)
                                    setProdutosFiltro(...produtosFiltro, pArray);
                                })
                            })
                        })

                    })
            }

        } else {
            mensagemAlerta('Favor escolha o filtro')
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    const fileHandler = (event) => {
        setProduto({})
        setQtd('')
        setQtdAdicional('')
        event.persist();
        mensagemLoading('Montando Documento')
        let files = event.target.files;
        const list = []
        for (let i = 0; i < files.length; i++) {
            axios.get(URL.createObjectURL(event.target.files[i]), {
                "Content-Type": "application/xml; charset=utf-8"
            }).then((response) => {
                var xml = new XMLParser().parseFromString(response.data);
                const natureza = xml.children[0].children[0].children[0].children.filter((d) => d.name === 'natOp')[0].value
                const emissao = xml.children[0].children[0].children[0].children.filter((d) => d.name === 'dhEmi')[0].value

                // Código da NFE
                const nf = xml.children[0].children[0].children[0].children.filter((d) => d.name === 'nNF')[0].value
                servicos.notasFiscais.where('nf', '==', nf).get().then((s) => {
                    if (s.size > 0) {
                        mensagemAlerta('Esta nota já está cadastrada no estoque')
                    } else {
                        const produtos = xml.children[0].children[0].children.forEach((d) => {
                            if (d.name === 'det') {
                                servicos.produtos.where('codigoItem', '==', d.children[0].children[0].value).get().then((s) => {
                                    if (s.size > 0) {
                                        list.push({
                                            nf: nf,
                                            emissao: emissao,
                                            natureza: natureza,
                                            cadastro: true,
                                            produtoId: s.docs.map((p) => p.data())[0].id,
                                            codigo: d.children[0].children.filter((d) => d.name === 'cProd')[0].value,
                                            nome: d.children[0].children.filter((d) => d.name === 'xProd')[0].value,
                                            ncm: d.children[0].children.filter((d) => d.name === 'NCM')[0].value,
                                            uni: d.children[0].children.filter((d) => d.name === 'uCom')[0].value,
                                            qtdTotal: parseInt(s.docs.map((p) => p.data())[0].qtd) + parseInt(d.children[0].children.filter((d) => d.name === 'qCom')[0].value),
                                            qtd: parseInt(d.children[0].children.filter((d) => d.name === 'qCom')[0].value),
                                            valorUnidade: parseFloat(d.children[0].children.filter((d) => d.name === 'vUnCom')[0].value),
                                            valorTotal: parseFloat(d.children[0].children.filter((d) => d.name === 'vProd')[0].value)
                                        })
                                    } else {
                                        list.push({
                                            nf: nf,
                                            emissao: emissao,
                                            natureza: natureza,
                                            cadastro: false,
                                            codigo: d.children[0].children.filter((d) => d.name === 'cProd')[0].value,
                                            nome: d.children[0].children.filter((d) => d.name === 'xProd')[0].value,
                                            ncm: d.children[0].children.filter((d) => d.name === 'NCM')[0].value,
                                            uni: d.children[0].children.filter((d) => d.name === 'uCom')[0].value,
                                            qtd: parseInt(d.children[0].children.filter((d) => d.name === 'qCom')[0].value),
                                            valorUnidade: parseFloat(d.children[0].children.filter((d) => d.name === 'vUnCom')[0].value),
                                            valorTotal: parseFloat(d.children[0].children.filter((d) => d.name === 'vProd')[0].value)
                                        })
                                    }
                                })
                            }
                        })
                        setTimeout(() => {
                            Swal.close()
                            setProdutosNota(list)
                            setProdutosNotaTemp(list)
                            setListaNotas({
                                nf: nf,
                                natureza: natureza,
                                emissao: emissao,
                                produtos: list
                            })
                        }, 1500);
                        console.log(produtos)
                    }
                })

            });
        }
    }

    function cancelaFile() {
        setColunas([])
        setRows([])
        setFile("")
    }

    function cadastraProdutosFile() {
        if (!cliente.id) {
            mensagemAlerta('Favor selecione o Cliente')
            return
        }
        if (!file) {
            mensagemAlerta('Favor selecione o arquivo Excel')
            return
        }
        mensagemLoading('Caastrando SKUs no cliente. Não cancele a operação! Pode demorar alguns minutos ')
        ExcelRenderer(file, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                const arquivo = {
                    cols: resp.cols,
                    rows: resp.rows
                };
                var count = arquivo.rows.length;
                arquivo.rows.forEach(element => {
                    // listRows.push({
                    //     ncm: element[2] ? element[2] : null,
                    //     descricao: element[1] ? element[1] : null,
                    //     codigoItem: element[0] ? element[0] : null
                    // })
                    if (element[0]) {
                        servicos.produtos.where('codigoItem', '==', element[0]).get().then((snap) => {
                            if (snap.size === 0) {
                                servicos.produtos.add({
                                    cliente: {
                                        id: cliente.id,
                                        nome: cliente.nome
                                    },
                                    usuario: {
                                        nome: usuario.nome,
                                        id: usuario.id,
                                        email: usuario.email,
                                        avatar: usuario.avatar
                                    },
                                    ncm: element[2] ? element[2] : null,
                                    codigoItem: element[0] ? element[0] : null,
                                    descricao: element[1] ? element[1] : null,
                                    status: status,
                                    tipo: 'SKU',
                                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                                }).then((docRef) => {
                                    servicos.produtos.doc(docRef.id).update({
                                        id: docRef.id
                                    }).then(() => {
                                        count = count - 1;
                                    }).catch(() => {
                                        mensagemErro('Erro ao atualizar a SKU! Favor verifique com o administrador')
                                        Swal.close();
                                    })
                                }).catch((docRef) => {
                                    mensagemErro('Erro ao cadastrar a SKU! Favor verifique com o administradors')
                                    Swal.close();
                                })
                            } else {
                                count = count - 1;
                            }
                            if (count === 0) {
                                mensagemSucesso('produtos cadastrados com sucesso')
                            }
                        })
                    }
                });


            }
        });
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Cadastro Manual de SKU</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0" style={{ marginTop: 30 }}>
                                                <div className='col-md-3'>
                                                    <label>Cód. item</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero.toLocaleUpperCase()} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1' style={{ marginTop: 30 }}>
                                                    <button onClick={() => buscaProduto(null)} type="button" class="btn btn-info mb-1">
                                                        <i className='fa fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cliente</label>
                                                    {produto.id ?
                                                        <p>{produto.cliente.nome}</p>
                                                        :
                                                        <select name="select" id="select" class="form-control" onChange={handleCliente} >
                                                            <option value="">Escolha </option>
                                                            {clientes.sort((a, b) => {
                                                                if (a.nome < b.nome) {
                                                                    return -1;
                                                                }
                                                                if (a.nome > b.nome) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            }).map((cliente, index) =>
                                                                <option value={index}>{cliente.nome}</option>
                                                            )}
                                                        </select>
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Fornecedores</label>
                                                    {produto.id ?
                                                        <p>{produto.fornecedor.nome}</p>
                                                        :
                                                        <select name="select" id="select" class="form-control" onChange={handleFornecedor} >
                                                            <option value="">Escolha </option>
                                                            {fornecedoes.sort((a, b) => {
                                                                if (a.nome < b.nome) {
                                                                    return -1;
                                                                }
                                                                if (a.nome > b.nome) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            }).map((fornecedor, index) =>
                                                                <option value={index}>{fornecedor.nome}</option>
                                                            )}
                                                        </select>
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Nome</label>
                                                    {produto.id ?
                                                        <p>{produto.descricao}</p>
                                                        :
                                                        <div class="form-group">
                                                            <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    }

                                                </div>
                                                <div className='col-md-3'>
                                                    <label>NCM</label>
                                                    {produto.id ?
                                                        <p>{produto.ncm}</p>
                                                        :
                                                        <div class="form-group">
                                                            <input type="text" value={ncm} id="company" class="form-control" onChange={handleNCM} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>QTD no estoque</label>
                                                    <div class="form-group">
                                                        {produto.id ?
                                                            <input type="text" value={qtd} disabled id="company" class="form-control" />
                                                            :
                                                            <input type="text" value={qtd} id="company" class="form-control" onChange={handleQtd} />
                                                        }

                                                    </div>
                                                </div>
                                                {produto.id ?
                                                    <div className='col-md-3'>
                                                        <label>Adicional da Nota</label>
                                                        <div class="form-group">
                                                            <input type="text" value={qtdAdicional} id="company" class="form-control" onChange={handleQtdAdicional} />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    {produto.id ?
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => atualizaProduto()}>
                                                            Atualizar SKU
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraProduto()}>
                                                            Cadastrar SKU
                                                        </button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Cadastrar SKU pelo XML</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0" >
                                                <div className='col-md-4'>
                                                    <label>Cliente</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleCliente} >
                                                        <option value="">Escolha </option>
                                                        {clientes.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((cliente, index) =>
                                                            <option value={index}>{cliente.nome}</option>
                                                        )}
                                                    </select>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Fornecedores</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFornecedor} >
                                                        <option value="">Escolha </option>
                                                        {fornecedoes.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((fornecedor, index) =>
                                                            <option value={index}>{fornecedor.nome}</option>
                                                        )}
                                                    </select>

                                                </div>
                                                <div className='col-md-4' style={{ marginTop: 20 }}>
                                                    <input type="file" accept=".xlsx, .xls, .xml" onChange={fileHandler} style={{ "padding": "10px" }} />
                                                </div>

                                                {file ?
                                                    <div className='col-md-2' style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraProdutosFile()}>
                                                            Cadastrar SKU
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {file ?
                                                    <div className='col-md-2' style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => cancelaFile()}>
                                                            Cancelar
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Nome</th>
                                                            <th>NCM</th>
                                                            <th>UNI</th>
                                                            <th>Qtd</th>
                                                            <th>Cadsatrar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {produtosNota.map((produto, index) =>
                                                            <tr key={produto.codigoItem} >
                                                                <td>{produto.codigo}</td>
                                                                <td>{produto.nome}</td>
                                                                <td>{produto.ncm}</td>
                                                                <td>{produto.uni}</td>
                                                                <td>{produto.qtd}</td>
                                                                <td>
                                                                    {produto.cadastro === true ?
                                                                        <button onClick={() => atualizaProdutoXml(produto)}>
                                                                            <span className="badge badge-success"><i className='fa fa-refresh'></i></span>
                                                                        </button>
                                                                        : produto.cadastro === false ?
                                                                            <button onClick={() => cadastraProdutoXml(produto)}>
                                                                                <span className="badge badge-info"><i className='fa fa-plus'></i></span>
                                                                            </button>
                                                                            :
                                                                            <button >
                                                                                <span className="badge badge-secondary"><i className='fa fa-check'></i></span>
                                                                            </button>
                                                                    }

                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">SKUs</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Cliente</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleBuscaProdutos} >
                                                        <option value="">Escolha </option>
                                                        {clientes.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((cliente) =>
                                                            <option value={cliente.id}>{cliente.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                {clienteFiltro ?
                                                    <div className='col-md-3'>
                                                        <label>Filtro de produto</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleBuscaProdutosFiltro} >
                                                            <option value="">Escolha </option>
                                                            <option value="todos">Todos os Produtos </option>
                                                            <option value="gaiola">Por gaiola </option>
                                                        </select>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                {filtro ?
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Código</th>
                                                                <th>Descrição</th>
                                                                <th>Gaiola</th>
                                                                <th>Qtd</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {produtosFiltro.map((produto) =>
                                                                <tr key={produto.id} >
                                                                    <td>{produto.codigoItem}</td>
                                                                    <td>{produto.descricao}</td>
                                                                    <td>{produto.gaiola}</td>
                                                                    <td>{produto.qtd}</td>
                                                                    <td>
                                                                        {produto.status === "ATIVO" ?
                                                                            <button>
                                                                                <span className="badge badge-success"><i className='fa fa-check'></i></span>
                                                                            </button>
                                                                            :
                                                                            <button>
                                                                                <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Código</th>
                                                                <th>Descrição</th>
                                                                <th>Cliente</th>
                                                                <th>Detalhe</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {produtos.map((produto) =>
                                                                <tr key={produto.id} >
                                                                    <td>{produto.codigoItem}</td>
                                                                    <td>{produto.descricao}</td>
                                                                    <td>{produto.cliente.nome}</td>
                                                                    <td>
                                                                        <button>
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }

                                                {produtos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem produtos cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
