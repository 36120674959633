import React, { useEffect, useState } from 'react';
import moment from 'moment'
import Swal from 'sweetalert2'
import { login, postFrete,postCotacao } from '../services/Brudam'
import { criaEmpresa, geraCotacaoNegocio, geraTask } from '../services/Agendor'
import { buscaEndereco } from '../services/Localidades'
import Sidebar from './componentes/Sidebar'
import firebase from 'firebase'
import InputMask from "react-input-mask";
import { cpf, cnpj } from 'cpf-cnpj-validator';

export default function CadastraCliente() {
    const [servicos] = useState({
        cotacao: firebase.firestore().collection('cotacao'),
        mail: firebase.firestore().collection('mail'),
    })
    const [produtos, setProdutos] = useState([])
    const [cotacao, setCotacao] = useState({})
    const [resultadoCotacao, setResultadoCotacao] = useState({})

    const [numeroCotacao, setNumeroCotacao] = useState('')

    // Destino
    const [cepOrigem, setCepOrigem] = useState('')
    const [cepDestino, setCepDestino] = useState('')
    const [dadosCepOrigem, setDadosCepOrigem] = useState({})
    const [dadosCepDestino, setDadosCepDestino] = useState({})

    // Dados pessoais
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [nomeCompleto, setNomeCompleto] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')

    // Nome Produto
    const [nome, setNome] = useState('')
    const [comprimento, setComprimento] = useState(0)
    const [largura, setLargura] = useState(0)
    const [altura, setAltura] = useState(0)
    const [qtd, setQtd] = useState(0)
    const [peso, setPeso] = useState(0)
    const [valor, setValor] = useState(0)

    const [servico, setServico] = useState('')
    const [tabela, setTabela] = useState('')
    const [index, setIndex] = useState('')

    // Dados da do usuario de Origem
    const [tipoDocumentoOrigem, setTipoDocumentoOrigem] = useState('')
    const [documentoOrigem, setDocumentoOrigem] = useState('')
    const [ieInsentoOrigem, setIeInsentoOrigem] = useState('')
    const [ieOrigem, setIeOrigem] = useState('')
    const [nomeOrigem, setNomeOrigem] = useState('')
    const [numeroOrigem, setNumeroOrigem] = useState('')
    // Dados do usuario do Destino
    const [tipoDocumentoDestino, setTipoDocumentoDestino] = useState('')
    const [documentoDestino, setDocumentoDestino] = useState('')
    const [ieInsentoDestino, setIeInsentoDestino] = useState('')
    const [ieDestino, setIeDestino] = useState('')
    const [nomeDestino, setNomeDestino] = useState('')
    const [numeroDestino, setNumeroDestino] = useState('')

    // Cotação
    const [idCotacao, setIdCotacao] = useState('')
    const [dadosEnvioCotacao, setDadosEnvioCotacao] = useState({})

    function enviaCotacao(data) {
        return (
            servicos.cotacao.get().then((q) => {
                servicos.cotacao.add({
                    nome: nomeCompleto,
                    whatsapp: whatsapp,
                    email: email,
                    origem: dadosCepOrigem,
                    destino: dadosCepDestino,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    mes: moment().format('MM'),
                    ano: moment().format('YYYY'),
                    entrega: data.dados.filter((d) => d.vPeso > 0)[0].nDias,
                    servico: servico,
                    advalore: parseFloat(data.dados[0].vAdv),
                    icms: parseFloat(data.dados[0].vICMS),
                    gris: parseFloat(data.dados[0].vGris),
                    pedagio: parseFloat(data.dados[0].vPedagio),
                    valorTotal: parseFloat(data.dados[0].vTotal),
                    valorFrete: (parseFloat(data.dados[0].vTotal) - parseFloat(data.dados[0].vAdv) - parseFloat(data.dados[0].vGris) + parseFloat(data.dados[0].vICMS) - parseFloat(data.dados[0].vPedagio)),
                    cotacao: 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY')
                }).then((snap) => {
                    servicos.cotacao.doc(snap.id).update({
                        id: snap.id
                    }).then(() => {
                        setIdCotacao(snap.id)
                        data.frete.produtos.forEach((d) => {
                            servicos.cotacao.doc(snap.id).collection('produtos').add(d)
                        })
                        // Enviar para o agendor
                        const dadosEmpresa = {
                            name: nomeCompleto,
                            cnpj: cnpjEmpresa,
                            ranking: "3",
                            contact: {
                                email: email,
                                whatsapp: whatsapp
                            }
                        }
                        Swal.close()
                        // criaEmpresa(dadosEmpresa).then((resEmpresa) => {

                        //     const dadosNegocio = {
                        //         title: 'Cotação ' + nomeCompleto,
                        //         dealStatusText: "ongoing",
                        //         description: "Cotação gerada e pode acompanhar em : \n\n https://administrador.entregaja.com.br/cotacao",
                        //         dealStage: 2918853,
                        //         value: parseFloat(data.dados[0].vTotal),
                        //         funnel: 714912,
                        //         ranking: 5,
                        //         customFields: {
                        //             link: "https://administrador.entregaja.com.br/cotacao"
                        //         }

                        //     }

                        //     geraCotacaoNegocio(resEmpresa.data.id, dadosNegocio).then((resNegocio) => {
                        //         const dadosTask = {
                        //             text: 'Cotação ' + nomeCompleto + "\n\nCotação gerada e pode acompanhar em : \n\n https://administrador.entregaja.com.br/cotacao",
                        //             type: "PROPOSTA",
                        //         }
                        //         geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                        //             Swal.close()
                        //         })
                        //     })
                        // })
                    })
                }).catch((er) => {
                    mensagemErro('Erro ao cadastrar cotação ' + er)
                })
            })
        )
    }

    function limpaDados() {
        setNome('')
        setEmail('')
        setWhatsapp('')
        setResultadoCotacao({})
        setCotacao({})
        setProdutos([])
        setNumeroCotacao('')
    }
    function editaDados() {
        setResultadoCotacao({})
        setCotacao({})
    }

    function adicionaProduto() {

        if (!nome) {
            mensagemAlerta('Informe o nome do produto')
            return
        }
        if (!comprimento) {
            mensagemAlerta('Informe o comprimento do volume')
            return
        }
        if (!largura) {
            mensagemAlerta('Informe a largura do volume')
            return
        }
        if (!altura) {
            mensagemAlerta('Informe a altura do volume')
            return
        }
        if (!qtd) {
            mensagemAlerta('Informe a quantidade de volumes')
            return
        }
        if (!peso) {
            mensagemAlerta('Informe o peso total do volume')
            return
        }
        const list = []
        const dados = {
            nome: nome,
            dAlt: parseFloat(altura / 100),
            dLar: parseFloat(largura / 100),
            dCom: parseFloat(comprimento / 100),
            pBru: parseFloat(peso),
            qVol: parseInt(qtd),
        }
        if (index !== '') {
            produtos[index].nome = nome
            produtos[index].dAlt = parseFloat(altura / 100)
            produtos[index].dLar = parseFloat(largura / 100)
            produtos[index].dCom = parseFloat(comprimento / 100)
            produtos[index].pBru = parseFloat(peso)
            produtos[index].qVol = parseFloat(qtd)

            cancelaAlteracao()
        } else {
            list.push(
                ...produtos,
                dados
            )
            setNome('')
            setComprimento(0)
            setLargura(0)
            setAltura(0)
            setQtd(0)
            setPeso(0)
            setProdutos(list)
        }

    }

    function limpaCotacao() {
        setCotacao({})
        setCepDestino('')
        setCepOrigem('')
        setDadosCepDestino({})
        setDadosCepOrigem({})
        setServico('')
        setProdutos([])
    }

    function removeProduto() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o produto ' + nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const list = []
                for (let i = 0; i < produtos.length; i++) {
                    const element = produtos[i];
                    if (i !== index) {
                        list.push(element)
                    }
                }
                cancelaAlteracao()
                setProdutos(list)
            }
        });

    }

    function editaProduto(item, ind) {
        setIndex(ind)
        setComprimento(item.dCom * 100)
        setLargura(item.dLar * 100)
        setAltura(item.dAlt * 100)
        setPeso(item.pBru)
        setQtd(item.qVol)
        setNome(item.nome)
    }

    function cancelaAlteracao() {
        setIndex('')
        setNome('')
        setComprimento(0)
        setLargura(0)
        setAltura(0)
        setQtd(0)
        setPeso(0)
    }

    function setaServico(serv, tab) {
        setServico(serv)
        setTabela(tab)
    }

    function enviaEmail() {
        if (!tipoDocumentoOrigem) {
            mensagemAlerta('Informar o Tipo de Documento do Remetente')
            return
        }
        if (tipoDocumentoOrigem === 'CPF') {
            if (!cpf.isValid(documentoOrigem.replace(/[^a-z0-9]/gi, ''))) {
                mensagemAlerta('CPF do remetente inválido')
                return
            }
        }
        if (tipoDocumentoOrigem === 'CNPJ') {
            if (!cnpj.isValid(documentoOrigem.replace(/[^a-z0-9]/gi, ''))) {
                mensagemAlerta('CNPJ do remetente inválido')
                return
            }
        }
        if (tipoDocumentoOrigem === 'CNPJ' && !ieInsentoOrigem) {
            mensagemAlerta('Informe a Inscrição Estadual (IE) ou Isento do Remetente')
            return
        }
        if (tipoDocumentoOrigem === 'CNPJ' && ieInsentoOrigem === 'NAO' && ieOrigem.length < 9) {
            mensagemAlerta('Inscrição Estadual do remetente inválida')
            return
        }
        if (tipoDocumentoOrigem === 'CPF' && !nomeOrigem) {
            mensagemAlerta('Informe o Nome Completo do Remetente')
            return
        }
        if (tipoDocumentoOrigem === 'CNPJ' && !nomeOrigem) {
            mensagemAlerta('Informe a Razão Social da Empresa do Remetente')
            return
        }
        if (!numeroOrigem) {
            mensagemAlerta('Informe o Nº do Endereço do Remetente')
            return
        }

        // Campos para Destino
        if (!tipoDocumentoDestino) {
            mensagemAlerta('Informar o Tipo de Documento do Destinatário')
            return
        }
        if (tipoDocumentoDestino === 'CPF') {
            if (!cpf.isValid(documentoDestino.replace(/[^a-z0-9]/gi, ''))) {
                mensagemAlerta('CPF do Destinatário inválido')
                return
            }
        }
        if (tipoDocumentoDestino === 'CNPJ') {
            if (!cnpj.isValid(documentoDestino.replace(/[^a-z0-9]/gi, ''))) {
                mensagemAlerta('CNPJ do Destinatário inválido')
                return
            }
        }
        if (tipoDocumentoDestino === 'CNPJ' && !ieInsentoDestino) {
            mensagemAlerta('Informe a Inscrição Estadual (IE) ou Isento do Destinatário')
            return
        }
        if (tipoDocumentoDestino === 'CNPJ' && ieInsentoDestino === 'NAO' && ieDestino.length < 9) {
            mensagemAlerta('Inscrição Estadual do Destinatário inválida')
            return
        }
        if (tipoDocumentoDestino === 'CPF' && !nomeDestino) {
            mensagemAlerta('Informe o Nome Completo do Destinatário')
            return
        }
        if (tipoDocumentoDestino === 'CNPJ' && !nomeDestino) {
            mensagemAlerta('Informe a Razão Social da Empresa do Destinatário')
            return
        }
        if (!numeroDestino) {
            mensagemAlerta('Informe o Nº do Endereço do Destinatário')
            return
        }

        servicos.cotacao.doc(idCotacao).update({
            destinoOrigem: {
                tipoDocumentoOrigem: tipoDocumentoOrigem,
                documentoOrigem: documentoOrigem,
                ieInsentoOrigem: ieInsentoOrigem,
                ie: ieOrigem,
                nome: nomeOrigem,
                numeroOrigem: numeroOrigem
            },
            destinoDestino: {
                tipoDocumentoDestino: tipoDocumentoDestino,
                documentoDestino: documentoDestino,
                ieInsentoDestino: ieInsentoDestino,
                ie: ieDestino,
                nome: nomeDestino,
                numeroDestino: numeroDestino
            },
        }).then(() => {
            mensagemLoading('Enviando sua solicitação...')
            dadosEnvioCotacao.solicit = nomeCompleto
            dadosEnvioCotacao.telSolicit = whatsapp.replace(/[^a-z0-9]/gi, '')
            dadosEnvioCotacao.rem = {
                nDoc:documentoOrigem.replace(/[^a-z0-9]/gi, ''),
                IE:ieOrigem,
                xNome:nomeOrigem,
                xFant:nomeOrigem,
                xLgr:dadosCepOrigem.logradouro,
                nro:numeroOrigem,
                xBairro:dadosCepOrigem.bairro,
                cMun:1,
                CEP:dadosCepOrigem.cep,
                cPais:1058,
            }
            dadosEnvioCotacao.dest = {
                nDoc:documentoDestino.replace(/[^a-z0-9]/gi, ''),
                IE:ieDestino,
                xNome:nomeDestino,
                xFant:nomeDestino,
                xLgr:dadosCepDestino.logradouro,
                nro:numeroDestino,
                xBairro:dadosCepDestino.bairro,
                cMun:3251,
                CEP:dadosCepDestino.cep,
                cPais:1058,
            }
            console.log(dadosEnvioCotacao)
            login().then((res) => {
                postCotacao(res.data.access_key, dadosEnvioCotacao).then((doc) => {
                    if (doc.message === 'OK') {
                        console.log(doc)
                    } else {
                        console.log('erro '+doc)
                    }
                })
            })
            // servicos.cotacao.doc(idCotacao).get().then((cot) => {
            //     const corpoTecnico = {
            //         to: "domilson.brabo@entregaja.com.br",
            //         from: 'comercial@entregaja.com.br',
            //         message: {
            //             subject: 'Nova Cotação Recebida - ' + cot.data().cotacao + ' - Portal de Cotação Online',
            //             html: '<html>' +
            //                 '<body>' +
            //                 '<h1><b>Resumo das informações: </b></h1>' +
            //                 '<h2><b>Solicitante : ' + cot.data().nome + ' </b></h2>' +
            //                 '<h2><b>Origem : ' + cot.data().origem.localidade + ' - ' + cot.data().origem.uf + '</b></h2>' +
            //                 '<h2><b>Destino : ' + cot.data().destino.localidade + ' - ' + cot.data().destino.uf + '</b></h2>' +
            //                 '<h2><b>Peso Taxado : 30kg </b></h2>' +
            //                 '<h2><b>Valor da Cotação : ' + parseFloat(cotacao.dados[0].vTotal).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) + ' </b></h2>' +
            //                 '<h4><b>Para dtalhes , clique no link abaixo<b/></h4>' +
            //                 '<h2><b>Detalhes da Cotação<b/></h2>' +
            //                 '<h4><b><a href="https://administrador.entregaja.com.br/detalheCotacao?id="' + cot.data().id + '>Ver Cotação</a><b/></h4>' +
            //                 '<p><b>Equipe Entrega Já<b/></p>' +
            //                 '</body>' +
            //                 '</html>'
            //         }
            //     }
            //     const corpoCliente = {
            //         to: cot.data().email,
            //         from: 'comercial@entregaja.com.br',
            //         message: {
            //             subject: 'Entrega Já - Cotação ' + cot.data().cotacao + ' Recebida',
            //             html: '<html>' +
            //                 '<body>' +
            //                 '<h1><b>Olá, ' + cot.data().nome + '!</b></h1>' +
            //                 '<h3><b>Acabamos de receber sua cotação de frete em nosso Portal de Cotação Online e ficamos muito felizes pela preferência e confiança.</b></h3>' +
            //                 '<h3><b>Em breve um de nossos executivos de vendas entrará em contato para concluir o seu atendimento.</b></h3>' +
            //                 '<h1><b>Resumo das informações: </b></h1>' +
            //                 '<h2><b>Código da Cotação : ' + cot.data().cotacao + ' </b></h2>' +
            //                 '<h2><b>Solicitante : ' + cot.data().nome + ' </b></h2>' +
            //                 '<h2><b>Origem : ' + cot.data().origem.localidade + ' - ' + cot.data().origem.uf + '</b></h2>' +
            //                 '<h2><b>Destino : ' + cot.data().destino.localidade + ' - ' + cot.data().destino.uf + '</b></h2>' +
            //                 '<h2><b>Quantidade de Volumes : ' + produtos.length + ' </b></h2>' +
            //                 '<h2><b>Valor da Cotação : ' + parseFloat(cotacao.dados[0].vTotal).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) + ' </b></h2>' +
            //                 '<h4><b>Para dtalhes , clique no link abaixo<b/></h4>' +
            //                 '<h2><b>Detalhe da Cotação<b/></h2>' +
            //                 '<h4><b><a href="https://administrador.entregaja.com.br/cotacao">Ver Cotação</a><b/></h4>' +
            //                 '<p><b>Equipe Entrega Já<b/></p>' +
            //                 '</body>' +
            //                 '</html>'
            //         }
            //     }
            //     servicos.mail.add(corpoTecnico).then(() => {
            //         servicos.mail.add(corpoCliente).then(() => {
            //             mensagemSucesso('Serviço solicitado com sucesso!')
            //         }).catch(() => {
            //             Swal.close();
            //         })
            //     }).catch(() => {
            //         Swal.close();
            //     })
            // })
        })
    }

    function calculaFrete() {

        let pesoBruto = 0
        let qtdVol = 0
        let pesoCubado = 0

        produtos.forEach((d) => {
            pesoBruto = parseFloat(pesoBruto) + parseFloat(d.pBru)
            qtdVol = parseInt(qtdVol) + parseInt(d.qVol)
            pesoCubado = parseFloat(pesoCubado) + ((parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom)) * 300) * parseInt(d.qVol)

        })
        if (!nomeCompleto) {
            mensagemAlerta('Informe seu nome')
            return
        }
        if (!email || !email.includes('@')) {
            mensagemAlerta('Informe um E-mail válido: exemplo@exemplo.com')
            return
        }
        if (!whatsapp || whatsapp.replace(/[^a-z0-9]/gi, '').length < 11) {
            mensagemAlerta('WhatsApp incompleto: (DDD) 9 XXXX-XXXX')
            return
        }
        if (!cepOrigem) {
            mensagemAlerta('Informe o CEP de Origem')
            return
        }
        if (!cepDestino) {
            mensagemAlerta('Informe o CEP de Destino')
            return
        }

        if (produtos.length === 0) {
            mensagemAlerta('Adicione um produto')
            return
        }
        if (!servico) {
            mensagemAlerta('Escolha o tipo de serviço')
            return
        }
        if (!valor) {
            mensagemAlerta('Informe o valor total da Nota Fiscal')
            return
        }

        const data = {
            nDocEmit: "27069620000133",
            nDocCli: "27069620000133",
            cOrigCalc: dadosCepOrigem.ibge,
            cDestCalc: dadosCepDestino.ibge,
            cServ: servico,
            pBru: parseFloat(pesoBruto),
            cTab: tabela,
            qVol: parseInt(qtdVol),
            vNF: parseFloat(valor),
            volumes: produtos
        }
        mensagemLoading('Buscando Cotação..')
        login().then((res) => {
            postFrete(res.data.access_key, data).then((doc) => {
                if (doc.message === 'OK') {
                    setDadosEnvioCotacao(data)
                    let val = 0
                    doc.data.forEach((d) => {
                        val = parseFloat(val) + parseFloat(d.vTotal)
                    })
                    if (val > 0) {
                        // Swal.close();
                        const data = {
                            dados: doc.data.filter((d) => d.vPeso > 0),
                            frete: {
                                produtos: produtos,
                                cepOrigem: dadosCepOrigem,
                                cepDestino: dadosCepDestino,
                                servico: servico
                            }
                        }
                        enviaCotacao(data).then(() => {
                            setCotacao({
                                dados: doc.data,
                                frete: {
                                    produtos: produtos,
                                    cepOrigem: dadosCepOrigem,
                                    cepDestino: dadosCepDestino,
                                    servico: servico
                                }
                            })
                        })
                        // setCotacao({
                        //     dados: doc.data,
                        //     frete: {
                        //         produtos: produtos,
                        //         cepOrigem: dadosCepOrigem,
                        //         cepDestino: dadosCepDestino,
                        //         servico: servico
                        //     }
                        // })
                    } else {
                        mensagemAlerta(doc.message)
                    }
                } else {
                    mensagemAlerta(doc.message)
                }
            })
        })
    }

    // Endereço
    const handleCepOrigem = (event) => {
        event.persist();
        setCepOrigem(event.target.value);
        if (event.target.value.length === 8) {
            setDadosCepOrigem({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    mensagemAlerta('Endereço não localizado')
                } else {
                    setDadosCepOrigem(dados)
                }
            })
        }
    }
    const handleCepDestino = (event) => {
        event.persist();
        setCepDestino(event.target.value);
        if (event.target.value.length === 8) {
            setDadosCepDestino({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    mensagemAlerta('Endereço não localizado')
                } else {
                    setDadosCepDestino(dados)
                }
            })
        }
    }



    // Dados Pessoais
    const handleCnpjEmpresa = (event) => {
        event.persist();
        setCnpjEmpresa(event.target.value);
    }
    const handleNomeCompleto = (event) => {
        event.persist();
        setNomeCompleto(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }

    // Dados do Produto
    const handleProduto = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleComprimento = (event) => {
        event.persist();
        setComprimento(event.target.value);
    }
    const handleLargura = (event) => {
        event.persist();
        setLargura(event.target.value);
    }
    const handleAltura = (event) => {
        event.persist();
        setAltura(event.target.value);
    }
    const handlePeso = (event) => {
        event.persist();
        setPeso(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }
    const handleValor = (event) => {
        event.persist();
        setValor(event.target.value);
    }
    const handleCotacao = (event) => {
        event.persist();
        if (event.target.value.length === 0) {
            setResultadoCotacao({})
        }
        setNumeroCotacao(event.target.value);
    }

    // Dados Origem e Destino
    const handleTipoDocumentoOrigem = (event) => {
        event.persist();
        setDocumentoOrigem('')
        setIeInsentoOrigem('')
        setIeOrigem('')
        setTipoDocumentoOrigem(event.target.value);
    }
    const handleDocumentoOrigem = (event) => {
        event.persist();
        setDocumentoOrigem(event.target.value);
    }
    const handleIeInsentpOrigem = (event) => {
        event.persist();
        setIeOrigem('')
        setIeInsentoOrigem(event.target.value);
    }
    const handleIeOrigem = (event) => {
        event.persist();
        setIeOrigem(event.target.value);
    }
    const handleNomeOrigem = (event) => {
        event.persist();
        setNomeOrigem(event.target.value);
    }
    const handleNumeroOrigem = (event) => {
        event.persist();
        setNumeroOrigem(event.target.value);
    }

    const handleTipoDocumentoDestino = (event) => {
        event.persist();
        setDocumentoDestino('')
        setIeInsentoDestino('')
        setIeDestino('')
        setTipoDocumentoDestino(event.target.value);
    }
    const handleDocumentoDestino = (event) => {
        event.persist();
        setDocumentoDestino(event.target.value);
    }
    const handleIeInsentpDestino = (event) => {
        event.persist();
        setIeDestino('')
        setIeInsentoDestino(event.target.value);
    }
    const handleIeDestino = (event) => {
        event.persist();
        setIeDestino(event.target.value);
    }
    const handleNomeDestino = (event) => {
        event.persist();
        setNomeDestino(event.target.value);
    }
    const handleNumeroDestino = (event) => {
        event.persist();
        setNumeroDestino(event.target.value);
    }



    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location.reload();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />

            <div id="right-panel" className="right-panel">
                <div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-labelledby="modalVideo" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalVideo">Vídeo Institucional</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe width={'100%'} height={315} src="https://www.youtube-nocookie.com/embed/Pu-T48lQ8as?si=OlK1jUg1QjPjhHI8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="modalExemplo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Composição do Frete</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {cotacao.dados ?
                                    <div className="table-stats order-table ov-h">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td>ICMS</td>
                                                    <td>{parseFloat(cotacao.dados[0].vICMS).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                </tr>
                                                <tr >
                                                    <td>GRIS</td>
                                                    <td>{parseFloat(cotacao.dados[0].vGris).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                </tr>
                                                <tr >
                                                    <td>Ad Valorem</td>
                                                    <td>{parseFloat(cotacao.dados[0].vAdv).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                </tr>
                                                <tr >
                                                    <td>Pedágio</td>
                                                    <td>{parseFloat(cotacao.dados[0].vPedagio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                </tr>
                                                <tr >
                                                    <td>Valor do Frete</td>
                                                    <td>{(parseFloat(cotacao.dados[0].vPeso)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                </tr>
                                                <tr style={{ marginTop: 20 }}>
                                                    <td><b>Valor Total</b></td>
                                                    <td><b>{(parseFloat(cotacao.dados[0].vTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    null
                                }

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="modalComercial" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Confirmar Informações</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className='col-md-12' style={{ marginBottom: 20 }}>
                                    <h5 class="modal-title" id="exampleModalLabel">Dados de Remetente</h5>
                                </div>
                                <div class="row m-0" >
                                    <div className='col-md-3' >
                                        <label>Tipo de Documento</label>
                                        <select name="select" id="select" class="form-control" onChange={handleTipoDocumentoOrigem} >
                                            <option value="">Selecione </option>
                                            <option value="CPF" >CPF </option>
                                            <option value="CNPJ" >CNPJ </option>
                                        </select>
                                    </div>
                                    <div className='col-md-4' >
                                        <label>{tipoDocumentoOrigem ? tipoDocumentoOrigem + ' *' : 'CPF / CNPJ *'}</label>
                                        <div class="form-group">
                                            {tipoDocumentoOrigem === 'CPF' ?

                                                <InputMask mask="999.999.999-99" placeholder='XXX.XXX.XXX-XX' onChange={handleDocumentoOrigem} value={documentoOrigem} name="username" type="text" className="form-control" />
                                                :
                                                <InputMask mask="99.999.999/9999-99" placeholder='XX.XXX.XXX/XXXX-XX' onChange={handleDocumentoOrigem} value={documentoOrigem} name="username" type="text" className="form-control" />
                                            }

                                        </div>
                                    </div>
                                    {tipoDocumentoOrigem === 'CNPJ' ?
                                        <>
                                            <div className='col-md-2' >
                                                <label>IE Insento?</label>
                                                <select name="select" id="select" class="form-control" onChange={handleIeInsentpOrigem} >
                                                    <option value="">Selecione </option>
                                                    <option value="SIM" >SIM </option>
                                                    <option value="NAO" >NÃO </option>
                                                </select>
                                            </div>
                                            <div className='col-md-3' >
                                                <label>Inscrição Estadual</label>
                                                <div class="form-group">
                                                    {ieInsentoOrigem === 'SIM' ?
                                                        <input disabled value={'Insento'} onChange={handleIeOrigem} type="text" id="company" class="form-control" />
                                                        : ieInsentoOrigem === 'NAO' ?
                                                            <input maxLength={9} value={ieOrigem} onChange={handleIeOrigem} type="text" id="company" class="form-control" />
                                                            :
                                                            <input disabled onChange={handleIeOrigem} type="text" id="company" class="form-control" />
                                                    }
                                                </div>
                                            </div>

                                        </>
                                        :
                                        null
                                    }

                                    <div className='col-md-5' >
                                        <label>{tipoDocumentoOrigem === 'CNPJ' ? 'Razão Social' : tipoDocumentoOrigem === 'CPF' ? 'Nome Completo' : 'Nome Completo / Razão Social'}</label>
                                        <div class="form-group">
                                            <input value={nomeOrigem} onChange={handleNomeOrigem} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                    {cotacao.dados ?
                                        <>
                                            <div className='col-md-3'>
                                                <label>CEP</label>
                                                <div class="form-group">
                                                    <input disabled value={cepOrigem} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Endereço</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepOrigem.logradouro} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-4' >
                                                <label>Bairro</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepOrigem.bairro} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-2' >
                                                <label>Nº</label>
                                                <div class="form-group">
                                                    <input onChange={handleNumeroOrigem} value={numeroOrigem} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-4' >
                                                <label>Cidade</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepOrigem.localidade} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-2' >
                                                <label>UF</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepOrigem.uf} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                            <div class="modal-body">
                                <div className='col-md-12' style={{ marginBottom: 20 }}>
                                    <h5 class="modal-title" id="exampleModalLabel">Dados do Destinatário</h5>
                                </div>
                                <div class="row m-0" >
                                    <div className='col-md-3' >
                                        <label>Tipo Documento</label>
                                        <select name="select" id="select" class="form-control" onChange={handleTipoDocumentoDestino} >
                                            <option value="">Selecione </option>
                                            <option value="CPF" >CPF </option>
                                            <option value="CNPJ" >CNPJ </option>
                                        </select>
                                    </div>
                                    <div className='col-md-4' >
                                        <label>{tipoDocumentoDestino ? tipoDocumentoDestino + ' *' : 'CPF / CNPJ *'}</label>
                                        <div class="form-group">
                                            {tipoDocumentoDestino === 'CPF' ?

                                                <InputMask mask="999.999.999-99" placeholder='XXX.XXX.XXX-XX' onChange={handleDocumentoDestino} value={documentoDestino} name="username" type="text" className="form-control" />
                                                :
                                                <InputMask mask="99.999.999/9999-99" placeholder='XX.XXX.XXX/XXXX-XX' onChange={handleDocumentoDestino} value={documentoDestino} name="username" type="text" className="form-control" />
                                            }

                                        </div>
                                    </div>
                                    {tipoDocumentoDestino === 'CNPJ' ?
                                        <>
                                            <div className='col-md-2' >
                                                <label>IE Insento?</label>
                                                <select name="select" id="select" class="form-control" onChange={handleIeInsentpDestino} >
                                                    <option value="">Selecione </option>
                                                    <option value="SIM" >SIM </option>
                                                    <option value="NAO" >NÃO </option>
                                                </select>
                                            </div>
                                            <div className='col-md-3' >
                                                <label>Inscrição Estadual</label>
                                                <div class="form-group">
                                                    {ieInsentoDestino === 'SIM' ?
                                                        <input disabled value={'Insento'} onChange={handleIeDestino} type="text" id="company" class="form-control" />
                                                        : ieInsentoDestino === 'NAO' ?
                                                            <input maxLength={9} value={ieDestino} onChange={handleIeDestino} type="text" id="company" class="form-control" />
                                                            :
                                                            <input disabled onChange={handleIeDestino} type="text" id="company" class="form-control" />
                                                    }
                                                </div>
                                            </div>

                                        </>
                                        :
                                        null
                                    }
                                    <div className='col-md-5' >
                                        <label>{tipoDocumentoDestino === 'CNPJ' ? 'Razão Social' : tipoDocumentoDestino === 'CPF' ? 'Nome Completo' : 'Nome Completo / Razão Social'}</label>
                                        <div class="form-group">
                                            <input value={nomeDestino} onChange={handleNomeDestino} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                    {cotacao.dados ?
                                        <>
                                            <div className='col-md-3' >
                                                <label>CEP</label>
                                                <div class="form-group">
                                                    <input disabled value={cepDestino} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-4' >
                                                <label>Endereço</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepDestino.logradouro} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-4' >
                                                <label>Bairro</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepDestino.bairro} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-2' >
                                                <label>Nº</label>
                                                <div class="form-group">
                                                    <input onChange={handleNumeroDestino} value={numeroDestino} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-4' >
                                                <label>Cidade</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepDestino.localidade} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-md-2' >
                                                <label>UF</label>
                                                <div class="form-group">
                                                    <input disabled value={dadosCepDestino.uf} type="text" id="company" class="form-control" />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button onClick={() => enviaEmail()} type="button" class="btn btn-success" data-dismiss="modal">Enviar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            {/* <div className="row">
                                <div className="col-xl-12">
                                    <div style={{ textAlign: 'center' }}>
                                        <Adsense
                                            className='blockHome1'
                                            client='ca-pub-9383816332258420'
                                            slot='8682041194'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                            responsive={true}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            {resultadoCotacao.dados ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Dados de Endereço e Solicitante</h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div class="col-md-6" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Endereço de Origem</h4>
                                                        <p><b>{resultadoCotacao.dados.origin.logradouro}, {resultadoCotacao.dados.origin.bairro} - {resultadoCotacao.dados.origin.cep}</b></p>
                                                        <p><b>{resultadoCotacao.dados.origin.localidade} - {resultadoCotacao.dados.origin.uf}</b></p>
                                                    </div>
                                                    <div class="col-md-6" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Endereço de Origem</h4>
                                                        <p><b>{resultadoCotacao.dados.destino.logradouro}, {resultadoCotacao.dados.destino.bairro} - {resultadoCotacao.dados.destino.cep}</b></p>
                                                        <p><b>{resultadoCotacao.dados.destino.localidade} - {resultadoCotacao.dados.destino.uf}</b></p>
                                                    </div>
                                                    <div class="col-md-4" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Nome do Solicitante</h4>
                                                        <p><b>{resultadoCotacao.dados.nome}</b></p>
                                                    </div>
                                                    <div class="col-md-4" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Email do Solicitante</h4>
                                                        <p><b>{resultadoCotacao.dados.email}</b></p>
                                                    </div>
                                                    <div class="col-md-4" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">WhatsApp do Solicitante</h4>
                                                        <p><b>{resultadoCotacao.dados.whatsapp}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Produtos a serem enviados - ({resultadoCotacao.produtos.length})</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {resultadoCotacao.produtos.map((p) =>
                                                <div className="col-lg-3 col-md-12">
                                                    <button style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>{p.nome}</b></h5>
                                                            <h5 style={{ color: '#fff', fontSize: 11 }}><b>{p.qVol} volumes</b></h5>
                                                            {/* <p style={{ marginTop: 20, color: '#fff' }}>Informaçoes do produto (Medidas, Peso e Valor)</p> */}
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Comprimento: {parseInt(p.dCom * 100)} cm</p>
                                                            <p style={{ color: '#fff' }}>Largura: {parseInt(p.dLar * 100)} cm</p>
                                                            <p style={{ color: '#fff' }}>Altura: {p.dAlt * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Peso: {p.pBru} kg</p>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div className='col-md-12' >
                                                        <h4 className="box-title">Resumo da Cotação</h4>
                                                    </div>
                                                    <div className='col-md-6' style={{ marginTop: 20 }} >
                                                        <label>Prazo Previsto de Entrega</label>
                                                        <h2>Em até {resultadoCotacao.dado ? resultadoCotacao.dados.entrega : 0} dias úteis</h2>

                                                    </div>
                                                    <div className='col-md-6' style={{ marginTop: 20 }} >
                                                        <label>Valor do Frete</label>
                                                        <h2 >{parseFloat(resultadoCotacao.dados.valorFrete).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: 20 }} >
                                                        <label>Observação</label>
                                                        <p>O valor final já está incluso encargos e outros valores na composição do Frete</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {window.innerWidth < 1000 ?
                                <div className="row">
                                    <div className='col-md-2'>
                                        <a href='https://api.whatsapp.com/send/?phone=551134750544&text=Olá gostaria de falar com o comercial sobre Cotação&type=phone_number&app_absent=0' style={{ width: '100%' }} type="button" class="btn btn-info mb-1">
                                            <i className="menu-icon fa fa-whatsapp"></i>  Comercial
                                        </a>
                                    </div>
                                    <div className='col-md-2'>
                                        <a href='https://entregaja.com.br/' style={{ width: '100%' }} type="button" class="btn btn-info mb-1">
                                            <i className="menu-icon fa fa-globe"></i>  Ir para o site
                                        </a>
                                    </div>
                                    <div className='col-md-2'>
                                        <button style={{ width: '100%' }} data-toggle="modal" data-target="#modalVideo" type="button" class="btn btn-info mb-1">
                                            <i className="menu-icon fa fa-video-camera"></i>  Nossa Empresa
                                        </button>
                                    </div>
                                </div>
                                :
                                null
                            }

                            {cotacao.dados && !resultadoCotacao.dados ?
                                <div className="row" style={{ marginTop: 20 }}>
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">{nomeCompleto}, resultado da sua Cotação</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : !resultadoCotacao.dados ?
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="row m-0">
                                                        <h4 className="box-title">Nova Cotação</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-md-4' >
                                        <button onClick={() => limpaDados()} type="button" class="btn btn-danger mb-1">
                                            Limpar Dados
                                        </button>
                                    </div>
                            }

                            {cotacao.dados && !resultadoCotacao.dados ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Dados de Endereço e Solicitante</h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div class="col-md-6" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Endereço de Origem</h4>
                                                        <p><b>{dadosCepOrigem.logradouro}, {dadosCepOrigem.bairro} - {dadosCepOrigem.cep}</b></p>
                                                        <p><b>{dadosCepOrigem.localidade} - {dadosCepOrigem.uf}</b></p>
                                                    </div>
                                                    <div class="col-md-6" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Endereço de Destino</h4>
                                                        <p><b>{dadosCepDestino.logradouro}, {dadosCepDestino.bairro} - {dadosCepDestino.cep}</b></p>
                                                        <p><b>{dadosCepDestino.localidade} - {dadosCepDestino.uf}</b></p>
                                                    </div>
                                                    <div class="col-md-4" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Nome do Solicitante</h4>
                                                        <p><b>{nomeCompleto}</b></p>
                                                    </div>
                                                    <div class="col-md-4" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Email do Solicitante</h4>
                                                        <p><b>{email}</b></p>
                                                    </div>
                                                    <div class="col-md-4" style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">WhatsApp do Solicitante</h4>
                                                        <p><b>{whatsapp}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Produtos a serem enviados - ({cotacao.frete.produtos.length})</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {cotacao.frete.produtos.map((p) =>
                                                <div className="col-lg-3 col-md-12">
                                                    <button style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>{p.nome}</b></h5>
                                                            <h5 style={{ color: '#fff', fontSize: 11 }}><b>{p.qVol} volumes</b></h5>
                                                            {/* <p style={{ marginTop: 20, color: '#fff' }}>Informaçoes do produto (Medidas, Peso e Valor)</p> */}
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Comprimento: {p.dCom * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Largura: {p.dLar * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Altura: {p.dAlt * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Peso: {p.pBru} kg</p>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div className='col-md-12' >
                                                        <h4 className="box-title">Resumo da Cotação</h4>
                                                    </div>
                                                    <div className='col-md-6' style={{ marginTop: 20 }} >
                                                        <label>Prazo Previsto de Entrega</label>
                                                        <h2>Em até {cotacao.dados ? cotacao.dados[0].nDias : 0} dias úteis</h2>
                                                    </div>
                                                    <div className='col-md-6' style={{ marginTop: 20 }} >
                                                        <label>Valor Total do Frete</label>
                                                        <h2 >{(parseFloat(cotacao.dados[0].vTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} <button data-toggle="modal" data-target="#modalExemplo" type="button" class="btn btn-info mb-1">
                                                            <i className='fa fa-info'></i>
                                                        </button></h2>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: 20 }} >
                                                        <label>Observação</label>
                                                        <p>O valor final  já inclui os encargos e outras taxas do transporte de mercadorias</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2' >
                                        <button onClick={() => editaDados()} type="button" class="btn btn-warning mb-1">
                                            <i className='fa fa-edit'></i> Editar Cotação
                                        </button>
                                    </div>
                                    <div className='col-md-2' >
                                        <button onClick={() => limpaCotacao()} type="button" class="btn btn-success mb-1">
                                            <i className='fa fa-plus'></i> Nova Cotação
                                        </button>
                                    </div>
                                    <div className='col-md-2' >
                                        {/* <button onClick={() => enviaCotacao()} type="button" class="btn btn-success mb-1">
                                            <i className='fa fa-whatsapp'></i>  Enviar para o comercial da Entrega Já
                                        </button> */}
                                        <button data-toggle="modal" data-target="#modalComercial" type="button" class="btn btn-success mb-1">
                                            <i className='fa fa-envelope'></i>  Enviar Solicitação de Serviço
                                        </button>
                                    </div>
                                </div>
                                :
                                null
                            }

                            {!cotacao.dados && !resultadoCotacao.dados ?
                                <div className="row" >
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Informaçõs Básicas</h4>
                                                </div>
                                                <p>Informações iniciais para um atendimento personalizado da sua necessidade</p>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12' ><div className='col-md-3'>
                                                    <label>CNPJ</label>
                                                    <div class="form-group">
                                                        <InputMask mask="99.999.999/9999-99" placeholder='XX.XXX.XXX/XXXX-XX' onChange={handleCnpjEmpresa} value={cnpjEmpresa} name="username" type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'>
                                                        <label>Nome Empresa</label>
                                                        <div class="form-group">
                                                            <input value={nomeCompleto} onChange={handleNomeCompleto} type="text" id="company" class="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <label>E-mail</label>
                                                        <div class="form-group">
                                                            <input value={email} onChange={handleEmail} type="text" id="company" class="form-control" placeholder='exemplo@exemplo.com' />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label>Whatsapp</label>
                                                        <div class="form-group">
                                                            {/* <input value={whatsapp} onChange={handleWhatsapp} type="text" id="company" class="form-control" /> */}
                                                            <InputMask mask="(99) 99999-9999" placeholder='(XX) X XXXX-XXXX' onChange={handleWhatsapp} value={whatsapp} name="username" type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label>CEP de Origem</label>
                                                        <div class="form-group">
                                                            <input maxLength={8} value={cepOrigem} onChange={handleCepOrigem} type="text" id="company" class="form-control" />
                                                        </div>
                                                        {dadosCepOrigem.bairro ?
                                                            <>
                                                                <p><b>{dadosCepOrigem.logradouro}, {dadosCepOrigem.bairro} - {dadosCepOrigem.cep}</b></p>
                                                                <p><b>{dadosCepOrigem.localidade} - {dadosCepOrigem.uf}</b></p>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label>CEP de Destino</label>
                                                        <div class="form-group">
                                                            <input maxLength={8} type="text" value={cepDestino} onChange={handleCepDestino} id="company" class="form-control" />
                                                        </div>
                                                        {dadosCepDestino.bairro ?
                                                            <>
                                                                <p><b>{dadosCepDestino.logradouro}, {dadosCepDestino.bairro} - {dadosCepDestino.cep}</b></p>
                                                                <p><b>{dadosCepDestino.localidade} - {dadosCepDestino.uf}</b></p>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Dados dos Volumes</h4>
                                                </div>
                                                <p>Informações de medidas de cada volume, peso, quantidade e descrição breve dos produtos a serem transportados.</p>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12' >
                                                    <div className='col-md-4' style={{ textAlign: 'center' }}>
                                                        <img style={{ width: 250, height: 200 }} src='https://static.significados.com.br/foto/comprimento-largura.jpg' />
                                                    </div>
                                                    <div className='col-md-8 row' >
                                                        <div className='col-md-4'>
                                                            <label >Comprimento (cm)</label>
                                                            <div class="form-group">
                                                                <input value={comprimento} onChange={handleComprimento} type="text" id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Largura (cm)</label>
                                                            <div class="form-group">
                                                                <input value={largura} onChange={handleLargura} type="text" id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Altura (cm)</label>
                                                            <div class="form-group">
                                                                <input type="text" value={altura} onChange={handleAltura} id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label>Peso (kg)</label>
                                                            <div class="form-group">
                                                                <input type="text" value={peso} onChange={handlePeso} id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <label>Qtd</label>
                                                            <div class="form-group">
                                                                <input type="text" value={qtd} onChange={handleQtd} id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <label>Nome do Produto</label>
                                                            <div class="form-group">
                                                                <input value={nome} onChange={handleProduto} type="text" id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index !== '' ?
                                                        <>
                                                            <div style={{ marginTop: 40, marginLeft: 20 }} >
                                                                <button onClick={() => adicionaProduto()} type="button" class="btn btn-info mb-1">
                                                                    Atualizar
                                                                </button>
                                                            </div>
                                                            <div style={{ marginTop: 40, marginLeft: 10 }} >
                                                                <button onClick={() => cancelaAlteracao()} type="button" class="btn btn-warning mb-1">
                                                                    Cancelar Alterações
                                                                </button>
                                                            </div>
                                                            <div style={{ marginTop: 40, marginLeft: 10 }} >
                                                                <button onClick={() => removeProduto()} type="button" class="btn btn-danger mb-1">
                                                                    Excluir
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className='col-md-2' style={{ marginTop: 40 }} >
                                                            <button onClick={() => adicionaProduto()} type="button" class="btn btn-info mb-1">
                                                                Adicionar
                                                            </button>
                                                        </div>
                                                    }
                                                    {/* <div className='col-md-2' style={{ marginTop: 40 }} >
                                                        <button onClick={() => adicionaProduto()} type="button" class="btn btn-info mb-1">
                                                            Adicionar
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-6">
                                                <div className="card">
                                                    <div className="card-body" >
                                                        <h5>Volumes adicionados ({produtos.length})</h5>
                                                        <p style={{ marginTop: 5 }}>Clique no volume para editar ou remover da lista</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {produtos.map((p, index) =>
                                                <div className="col-lg-3 col-md-12">
                                                    <button onClick={() => editaProduto(p, index)} style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>{p.nome}</b></h5>
                                                            <h5 style={{ color: '#fff', fontSize: 11 }}><b>{p.qVol} volumes</b></h5>
                                                            {/* <p style={{ marginTop: 20, color: '#fff' }}>Informaçoes do produto (Medidas, Peso e Valor)</p> */}
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Comprimento: {p.dCom * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Largura: {p.dLar * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Altura: {p.dAlt * 100} cm</p>
                                                            <p style={{ color: '#fff' }}>Peso: {p.pBru * p.qVol} kg</p>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-6">
                                                <div className="card">
                                                    <div className="card-body" >
                                                        <h5>Escolha abaixo o serviço desejado</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-3 col-md-6">
                                                {servico === '089' ?
                                                    <button style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>Rodo Expresso</b></h5>
                                                            <h5 style={{ color: '#fff' }}><b>Entrega</b></h5>
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Para aquela entrega que não pode esperar, prazos reduzidos e atendimento diferenciado para voce.</p>
                                                        </div>
                                                    </button>
                                                    :
                                                    <button onClick={() => setaServico('089', '089')} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5><b>Rodo Expresso</b></h5>
                                                            <h5><b>Entrega</b></h5>
                                                            <p style={{ marginTop: 20 }}>Para aquela entrega que não pode esperar, prazos reduzidos e atendimento diferenciado para voce.</p>
                                                        </div>
                                                    </button>
                                                }

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                {servico === '088' ?
                                                    <button style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>Rodo Expresso</b></h5>
                                                            <h5 style={{ color: '#fff' }}><b>Retirada</b></h5>
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Para entregas e embarques em uma de nossas bases! Garantimos o melhor atendimento</p>
                                                        </div>
                                                    </button>
                                                    :
                                                    <button onClick={() => setaServico('088', '089')} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5><b>Rodo Expresso</b></h5>
                                                            <h5><b>Retirada</b></h5>
                                                            <p style={{ marginTop: 20 }}>Para entregas e embarques em uma de nossas bases! Garantimos o melhor atendimento</p>
                                                        </div>
                                                    </button>
                                                }
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                {servico === '095' ?
                                                    <button style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>Rodo Economico</b></h5>
                                                            <h5 style={{ color: '#fff' }}><b>Entrega</b></h5>
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Para prazos flexiveis com uma tarifa promocional  para origem São Paulo com destino ao Maranhão e Pará</p>
                                                        </div>
                                                    </button>
                                                    :
                                                    <button onClick={() => setaServico('095', '092')} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5><b>Rodo Economico</b></h5>
                                                            <h5><b>Entrega</b></h5>
                                                            <p style={{ marginTop: 20 }}>Para prazos flexiveis com uma tarifa promocional  para origem São Paulo com destino ao Maranhão e Pará</p>
                                                        </div>
                                                    </button>
                                                }
                                            </div> */}
                                            <div className="col-lg-3 col-md-6">
                                                {servico === '080' ?
                                                    <button style={{ backgroundColor: '#eb8300' }} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5 style={{ color: '#fff' }}><b>Entrega Convencional</b></h5>
                                                            {/* <h5 style={{ color: '#fff' }}><b>Entrega</b></h5> */}
                                                            <p style={{ marginTop: 20, color: '#fff' }}>Serviço de entrega confiável e eficiente para satisfazer suas necessidades de transporte de mercadorias.</p>
                                                        </div>
                                                    </button>
                                                    :
                                                    <button onClick={() => setaServico('080', '132')} className="card">
                                                        <div className="card-body" style={{ textAlign: 'center' }}>
                                                            <h5><b>Entrega Convencional</b></h5>
                                                            {/* <h5><b>Entrega</b></h5> */}
                                                            <p style={{ marginTop: 20 }}>Serviço de entrega confiável e eficiente para satisfazer suas necessidades de transporte de mercadorias.</p>
                                                        </div>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <label>Valor total da Nota Fiscal</label>
                                            <div class="form-group">
                                                <input type="text" value={valor} onChange={handleValor} id="company" class="form-control" />
                                                {/* <CurrencyFormat prefix={'R$'}  value={valor} onChange={handleValor} id="company" class="form-control"  /> */}
                                            </div>
                                        </div>
                                        <div className='col-md-2' style={{ marginTop: 30 }}>
                                            <button onClick={() => calculaFrete()} type="button" class="btn btn-success mb-1">
                                                Gerar Cotação
                                            </button>
                                            {/* <button onClick={() => enviaEmail()} type="button" class="btn btn-success mb-1">
                                                Gerar Cotação
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
                {/* <Header /> */}
            </div>
        </div>

    );
}
