import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Autenticacao
import Login from './app/autenticacao/Login'
import NovoAcesso from './app/autenticacao/NovoAcesso'

// Administrador
import Administrador from './app/admin/Home'

//Aministrador - Sistema de Arquivos
import Documentacao from './app/admin/administracao/sistemas/Documentacao'
import CadastraDocumentacao from './app/admin/administracao/sistemas/Documentacao/Cadastro'
import DetalheDocumentacao from './app/admin/administracao/sistemas/Documentacao/Detalhe'

// Administrador - Serviços
import Agregados from './app/admin/servicos/agregados'
import Agregado from './app/admin/servicos/agregados/agregado'

// Administrador - Wikipedia
import Wikipedia from './app/admin/administracao/wikipedia'
import WikiCategorias from './app/admin/administracao/wikipedia/categorias'
import PublicacaoWiki from './app/admin/administracao/wikipedia/publicacao'

// Administrador - Sistemas
import Sistemas from './app/admin/administracao/sistemas'
import Arquivos from './app/admin/administracao/sistemas/Arquivos'

// Administrador - Setores
import Setores from './app/admin/administracao/setores'
import Setor from './app/admin/administracao/setores/setor'

// Administrador - Controladoria - Cupons
import Cupons from './app/admin/administracao/sistemas/Cupons'
import CadastraCupom from './app/admin/administracao/sistemas/Cupons/Cadastro'

import ContraCheques from './app/admin/administracao/sistemas/ContraCheques'
import CadastroContraCheques from './app/admin/administracao/sistemas/ContraCheques/Cadastro'


import PagamentosParceiros from './app/admin/administracao/sistemas/Pagamentos'
import DetalhePagamento from './app/admin/administracao/sistemas/Pagamentos/DetalhePagamento'

// Administrador - Comercial - Funil de Vendas
import Funil from './app/admin/administracao/sistemas/Funil'

// Administrador - Comercial - Eventos
import Leeds from './app/admin/administracao/sistemas/Leeds'
import PlanilhLeeds from './app/admin/administracao/sistemas/Leeds/Planilha'

//Aministrador - Torre de Controle - Ocorrências
import Ocorrencias from './app/admin/administracao/sistemas/Ocorrencias'
import CadastroOcorrencia from './app/admin/administracao/sistemas/Ocorrencias/cadastro'
import Ocorrencia from './app/admin/administracao/sistemas/Ocorrencias/detalhe'

//Aministrador - Torre de Controle - Notas Fiscais
import NotasFiscais from './app/admin/administracao/sistemas/NotasFiscais'

//Aministrador - Logística - Logística Promocional
import LogisticaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional'
import FiliaisLogisticaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Filiais'
import RuasLogisticaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Ruas'
import GaiolasLogisticaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Gaiolas'
import ProdutosGaiolasLogisticaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Gaiolas/CadastroProdutos'
import ProdutosLogisticaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Produtos'
import SaidasPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Saidas'
import CadastraSaidaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Saidas/cadastro'
import DetalheSaidaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Saidas/detalhe'
import EstoqueSaidaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Saidas/estoque'
import ContratoSaidaPromocional from './app/admin/administracao/sistemas/LogisticaPromocional/Saidas/contrato'

//Aministrador - Logística - Reembalados
import Reembalados from './app/admin/administracao/sistemas/Reembalados'
import ProdutosReembalados from './app/admin/administracao/sistemas/Reembalados/produtos'
import DetalheProdutosReembalados from './app/admin/administracao/sistemas/Reembalados/produtos/Detalhe'
import CatalogoReembalados from './app/admin/administracao/sistemas/Reembalados/produtos/Catalogo'
import DetalheCatalogoReembalados from './app/admin/administracao/sistemas/Reembalados/produtos/DetalheCatalogo'
import CatalogoExterno from './app/admin/administracao/sistemas/Reembalados/CatalogoExterno'
import DetalheCatalogoExterno from './app/admin/administracao/sistemas/Reembalados/DetalheCatalogoExterno'
import ProdutosExternos from './app/admin/administracao/sistemas/Reembalados/ProdutosExternos'
import DetalheProdutoExterno from './app/admin/administracao/sistemas/Reembalados/DetalheProdutosExternos'
import Contrato from './app/admin/administracao/sistemas/Reembalados/Contrato'

//Aministrador - Logística - Avarias
import Avarias from './app/admin/administracao/sistemas/Avarias'
import CadastroAvaria from './app/admin/administracao/sistemas/Avarias/Cadastro'
import DetalheAvaria from './app/admin/administracao/sistemas/Avarias/Detalhe'
import ContratoAvaria from './app/admin/administracao/sistemas/Avarias/Contrato'

//Aministrador - LABS - Sistemas Internos
import Estoque from './app/admin/administracao/sistemas/Estoque'
import CadastroEstoque from './app/admin/administracao/sistemas/Estoque/cadastro'

//Aministrador - LABS - Sistemas Internos
import SistemasExternos from './app/admin/administracao/sistemas/SistemasExternos'
import CadastroSistemasExterno from './app/admin/administracao/sistemas/SistemasExternos/cadastro'

//Aministrador - LABS - Sistemas Internos
import EquipamentosTecnologia from './app/admin/administracao/sistemas/EquipamentosTecnologia'
import CadastroEquipamentosTecnologia from './app/admin/administracao/sistemas/EquipamentosTecnologia/cadastro'
import ReciboEquipamentosTecnologia from './app/admin/administracao/sistemas/EquipamentosTecnologia/recibo'
import AvisoEquipamentosTecnologia from './app/admin/administracao/sistemas/EquipamentosTecnologia/aviso'

//Aministrador - Sistema de Chamados
import Chamados from './app/admin/administracao/sistemas/Chamados'
import CategoriasChamados from './app/admin/administracao/sistemas/Chamados/Categorias'
import NovoChamado from './app/admin/administracao/sistemas/Chamados/NovoChamado'
import DetalheChamado from './app/admin/administracao/sistemas/Chamados/Chamado'


// Administrador - Usuários
import Usuarios from './app/admin/administracao/usuarios'
import CadastraUsuario from './app/admin/administracao/usuarios/cadastroUsuario'
import Usuario from './app/admin/administracao/usuarios/usuario'
import Perfil from './app/admin/administracao/usuarios/perfil'

// Administrador - Filiais
import Filiais from './app/admin/administracao/filiais'
import CadastroFilial from './app/admin/administracao/filiais/CadastroFilial'

// Administrador - Clientes
import Clientes from './app/admin/administracao/clientes'
import CadastraCliente from './app/admin/administracao/clientes/cadastro'
import Promotores from './app/admin/administracao/clientes/promotores'
import CadastraPromotor from './app/admin/administracao/clientes/promotores/cadastro'
import Fornecedores from './app/admin/administracao/clientes/fornecedores'

// Administrador - Serviços
import Motoristas from './app/admin/administracao/motoristas'
import Motorista from './app/admin/administracao/motoristas/motorista'

// Usuário Comum
import Comum from './app/comum/Home'
import XML from './app/comum/Home/xml'

import Remessas from './app/admin/administracao/remessas'


// MOBILE
import Mobile from './app/comum/componentes/labs'

// Mobile - BLOG
import Blog from './app/mobile/blog'
import CadastraBlog from './app/mobile/blog/Cadastro'

// Mobile - Produtos Segurança
import ProdutosSeguranca from './app/mobile/produtos'
import ProdutosSegurancaCadastro from './app/mobile/produtos/Cadastro'

// PAGES
import BuscaMinuta from './app/page/BuscaMinuta'
import Cotacao from './app/page/Cotacao'
import CadastroFornecedor from './app/page/CadastroFornecedor'
import EnviaNFE from './app/page/EnviaNFE'


export default class Routes extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>

                    {/* Autenticação */}
                    <Route path="/" exact={true} component={Login} />
                    <Route path="/novoAcesso" exact={true} component={NovoAcesso} />

                    {/* Administrador */}
                    <Route path="/administrador" exact={true} component={Administrador} />

                    {/* Agregados */}
                    <Route path="/documentacao" exact={true} component={Documentacao} />
                    <Route path="/cadastraDocumentacao" exact={true} component={CadastraDocumentacao} />
                    <Route path="/detalheDocumentacao" exact={true} component={DetalheDocumentacao} />
                    
                    {/* Agregados */}
                    <Route path="/agregados" exact={true} component={Agregados} />
                    <Route path="/agregado" exact={true} component={Agregado} />

                    {/* Setores */}
                    <Route path="/setores" exact={true} component={Setores} />
                    <Route path="/setor" exact={true} component={Setor} />

                    {/* Sistemas */}
                    <Route path="/sistemas" exact={true} component={Sistemas} />
                    <Route path="/arquivosXML" exact={true} component={Arquivos} />

                    {/* Sistema CONNTROLADORIA - Cupons */}
                    <Route path="/cupons" exact={true} component={Cupons} />
                    <Route path="/cadastraCupom" exact={true} component={CadastraCupom} />
                    <Route path="/contraCheques" exact={true} component={ContraCheques} />
                    <Route path="/cadastrContraCheques" exact={true} component={CadastroContraCheques} />
                    <Route path="/pagamentos" exact={true} component={PagamentosParceiros} />
                    <Route path="/detalhePagamento" exact={true} component={DetalhePagamento} />

                    {/* Sistema TORRE DE CONTROLE - Notas fiscais */}
                    <Route path="/notasFiscais" exact={true} component={NotasFiscais} />
                    
                    {/* Sistema TORRE DE CONTROLE - Ocorrências */}
                    <Route path="/ocorrencias" exact={true} component={Ocorrencias} />
                    <Route path="/cadastroOcorrencia" exact={true} component={CadastroOcorrencia} />
                    <Route path="/ocorrencia" exact={true} component={Ocorrencia} />

                    {/* Sistema COMERCIAL - Funil de Vendas */}
                    <Route path="/funil" exact={true} component={Funil} />

                    {/* Sistema COMERCIAL - Eventos */}
                    <Route path="/leads" exact={true} component={Leeds} />
                    <Route path="/planilhaLeads" exact={true} component={PlanilhLeeds} />

                    {/* Sistema LOGISTICA - Logistica promocional */}
                    <Route path="/logisticaPromocional" exact={true} component={LogisticaPromocional} />
                    <Route path="/filiaisLogisticaPromocional" exact={true} component={FiliaisLogisticaPromocional} />
                    <Route path="/ruasLogisticaPromocional" exact={true} component={RuasLogisticaPromocional} />
                    <Route path="/produtosLogisticaPromocional" exact={true} component={ProdutosLogisticaPromocional} />
                    <Route path="/gaiolasLogisticaPromocional" exact={true} component={GaiolasLogisticaPromocional} />
                    <Route path="/produtosGaiolasLogisticaPromocional" exact={true} component={ProdutosGaiolasLogisticaPromocional} />
                    <Route path="/saidasPromocional" exact={true} component={SaidasPromocional} />
                    <Route path="/cadastraSaidaPromocional" exact={true} component={CadastraSaidaPromocional} />
                    <Route path="/detalheSaidaPromocional" exact={true} component={DetalheSaidaPromocional} />
                    <Route path="/estoqueSaidaPromocional" exact={true} component={EstoqueSaidaPromocional} />
                    <Route path="/contratoSaidaPromocional" exact={true} component={ContratoSaidaPromocional} />

                    {/* Sistema LOGISTICA - Reembalados */}
                    <Route path="/reembalados" exact={true} component={Reembalados} />
                    <Route path="/produtosReembalados" exact={true} component={ProdutosReembalados} />
                    <Route path="/detalheProdutosReembalados" exact={true} component={DetalheProdutosReembalados} />
                    <Route path="/catalogoReembalados" exact={true} component={CatalogoReembalados} />
                    <Route path="/detalheCatalogoReembalados" exact={true} component={DetalheCatalogoReembalados} />
                    <Route path="/catalogoExterno" exact={true} component={CatalogoExterno} />
                    <Route path="/detalheCatalogoExterno" exact={true} component={DetalheCatalogoExterno} />
                    <Route path="/produtosExterno" exact={true} component={ProdutosExternos} />
                    <Route path="/detalheProdutoExterno" exact={true} component={DetalheProdutoExterno} />
                    <Route path="/contrato" exact={true} component={Contrato} />

                    {/* Sistema LOGISTICA - Avarias */}
                    <Route path="/avarias" exact={true} component={Avarias} />
                    <Route path="/cadastroAvaria" exact={true} component={CadastroAvaria} />
                    <Route path="/detalheAvaria" exact={true} component={DetalheAvaria} />
                    <Route path="/contratoAvaria" exact={true} component={ContratoAvaria} />

                    {/* Sistema CONTROLADORIA - Estoque  */}
                    <Route path="/estoque" exact={true} component={Estoque} />
                    <Route path="/cadastroEstoque" exact={true} component={CadastroEstoque} />

                    {/* Sistema LABS - Sistemas Externos */}
                    <Route path="/sistemasExternos" exact={true} component={SistemasExternos} />
                    <Route path="/cadastroSistemasExterno" exact={true} component={CadastroSistemasExterno} />

                    {/* Sistema LABS - Equipamentos de Tecnologia */}
                    <Route path="/equipamentosTecnologia" exact={true} component={EquipamentosTecnologia} />
                    <Route path="/cadastroEquipamentosTecnologia" exact={true} component={CadastroEquipamentosTecnologia} />
                    <Route path="/reciboEquipamentosTecnologia" exact={true} component={ReciboEquipamentosTecnologia} />
                    <Route path="/avisoEquipamentosTecnologia" exact={true} component={AvisoEquipamentosTecnologia} />

                    {/* Sistema de Chamados */}
                    <Route path="/chamados" exact={true} component={Chamados} />
                    <Route path="/categoriasChamados" exact={true} component={CategoriasChamados} />
                    <Route path="/novoChamado" exact={true} component={NovoChamado} />
                    <Route path="/detalheChamado" exact={true} component={DetalheChamado} />

                    {/* Sistema Wikipedia */}
                    <Route path="/wikipedia" exact={true} component={Wikipedia} />
                    <Route path="/wikiCategorias" exact={true} component={WikiCategorias} />
                    <Route path="/publicacaoWiki" exact={true} component={PublicacaoWiki} />

                    {/* Usuários */}
                    <Route path="/usuarios" exact={true} component={Usuarios} />
                    <Route path="/cadastraUsuario" exact={true} component={CadastraUsuario} />
                    <Route path="/usuario" exact={true} component={Usuario} />
                    <Route path="/perfil" exact={true} component={Perfil} />

                    {/* Filiais */}
                    <Route path="/filiais" exact={true} component={Filiais} />
                    <Route path="/cadastroFilial" exact={true} component={CadastroFilial} />

                    {/* Clientes */}
                    <Route path="/clientes" exact={true} component={Clientes} />
                    <Route path="/cadastroCliente" exact={true} component={CadastraCliente} />
                    <Route path="/promotores" exact={true} component={Promotores} />
                    <Route path="/cadastraPromotor" exact={true} component={CadastraPromotor} />
                    <Route path="/fornecedores" exact={true} component={Fornecedores} />

                    {/* Motoristas */}
                    <Route path="/motoristas" exact={true} component={Motoristas} />
                    <Route path="/motorista" exact={true} component={Motorista} />

                    {/* Uuário Comum */}
                    <Route path="/comum" exact={true} component={Comum} />
                    <Route path="/xml" exact={true} component={XML} />

                    <Route path="/remessas" exact={true} component={Remessas} />

                     {/* MOBILE - Blog */}
                     <Route path="/mobile" exact={true} component={Mobile} />

                     <Route path="/blogApp" exact={true} component={Blog} />
                     <Route path="/cadastraBlog" exact={true} component={CadastraBlog} />

                     {/* MOBILE - Produtos de Segurança */}
                     <Route path="/produtosSeguranca" exact={true} component={ProdutosSeguranca} />
                     <Route path="/produtosSegurancaCadastro" exact={true} component={ProdutosSegurancaCadastro} />

                     {/* Pages */}
                     <Route path="/buscaMinuta" exact={true} component={BuscaMinuta} />
                     <Route path="/cotacao" exact={true} component={Cotacao} />
                     <Route path="/cadastroFornecedor" exact={true} component={CadastroFornecedor} />
                     <Route path="/envianfe" exact={true} component={EnviaNFE} />

                </BrowserRouter>
            </div>
        );
    }
}