import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Clientes() {

    const [clientes, setClientes] = useState([])
    const [servicos] = useState({
        clientes: firebase.firestore().collection('clientes')
    })

    useEffect(() => {
        servicos.clientes.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setClientes(data)
        })
    }, [servicos.clientes])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Clientes</h4>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Link to='/cadastroCliente'>
                                                        <button type="button" class="btn btn-success mb-1" >Novo Cliente</button>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Nome</th>
                                                            <th>Contato</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                            <th>Status</th>
                                                            <th>Promotores</th>
                                                            <th>Fornecedores</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clientes.map((cliente) =>
                                                            <tr key={cliente.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        {cliente.avatar ?
                                                                            <img class="rounded-circle" src={cliente.avatar} alt="" />
                                                                            :
                                                                            <img class="rounded-circle" src='https://www.pngitem.com/pimgs/m/315-3153392_transparent-cliente-png-png-download.png' alt="" />
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>{cliente.nome}</td>
                                                                <td>{cliente.contato}</td>
                                                                <td>{cliente.endereco.cidade}</td>
                                                                <td>{cliente.endereco.uf}</td>
                                                                {cliente.status === "INATIVO" ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/promotores', state: { cliente: cliente } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-users'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/fornecedores', state: { cliente: cliente } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-cubes'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/cadastroCliente', state: { cliente: cliente } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {clientes.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem clientes cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
