import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function DetalheOcorrencia(props) {
    const [dado] = useState(props.location.state.ocorrencia)
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        ocorrencias: firebase.firestore().collection('ocorrencias'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [usuario, setUsuario] = useState({})
    const [historicos, setHistorico] = useState([])


    const [descricao, setDescricao] = useState('')
    const [observacao, setObservacao] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [documento, setDocumento] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            console.log(dado)
            servicos.ocorrencias.doc(dado.id).collection('historico').get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setHistorico(data.sort((a, b) => {
                    return moment(b.dataCadastro) - moment(a.dataCadastro)
                }));
            })
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios, servicos.pendencia])

    function cadastraArquivo(id) {
        mensagemLoading('Salvando a imagem da Ocorrẽncia...')
        var uploadTask = firebase.storage().ref().child('ocorrencia/' + id).put(documento[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.ocorrencias.doc(dado.id).update({
                    documento: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Ocorrência finalizada com sucesso!')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem da Ocorrência...')
        var uploadTask = firebase.storage().ref().child('historicoOcorrencia/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.ocorrencias.doc(dado.id).collection('historico').doc(id).update({
                    documento: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Ocorrência cadastrada com sucesso!')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraOcorrencia() {
        if (!descricao) {
            mensagemAlerta('Digite o motivo da ocorrência')
            return
        }
        mensagemLoading('Aguarde.. Cadastrando nova atualização')
        servicos.ocorrencias.doc(dado.id).update({
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
        }).then((docRef) => {
            servicos.ocorrencias.doc(dado.id).collection('historico').add({
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome,
                    avatar: usuario.avatar,
                    email: usuario.email,
                    contato: usuario.contato
                },
                status: descricao,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm')
            }).then((docId) => {
                servicos.ocorrencias.doc(dado.id).collection('historico').doc(docId.id).update({
                    id: docId.id
                }).then(() => {
                    if (upload) {
                        cadastraAvatar(docId.id)
                    } else {
                        Swal.close();
                        mensagemSucesso('Ocorrência cadastrada com sucesso')
                    }
                }).catch(() => {
                    mensagemErro('Erro ao atualizar o Histórico')
                })
            }).catch(() => {
                mensagemErro('Erro ao cadastrar o Histórico')
            })
        }).catch(() => {
            mensagemErro('Erro ao cadastrar a Ocorrência')
        })
    }

    function finalizaOcorrencia() {
        if (!observacao) {
            mensagemAlerta('Digite a observação da finalização da ocorrência')
            return
        }
        mensagemLoading('Aguarde.. Finalizando ocorrência')
        servicos.ocorrencias.doc(dado.id).update({
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            status: 'FINALIZADO',
            observacao: observacao
        }).then(() => {
            servicos.ocorrencias.doc(dado.id).collection('historico').add({
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome,
                    avatar: usuario.avatar,
                    email: usuario.email,
                    contato: usuario.contato
                },
                status: 'FINALIZADO',
                dataCadastro: moment().format('YYYY-MM-DD HH:mm')
            }).then((docId) => {
                servicos.ocorrencias.doc(dado.id).collection('historico').doc(docId.id).update({
                    id: docId.id
                }).then(() => {
                    if (documento) {
                        cadastraArquivo(dado.id)
                    } else {
                        Swal.close();
                        mensagemSucesso('Ocorrência finalizada com sucesso')
                    }
                }).catch(() => {
                    mensagemErro('Erro ao atualizar o Histórico')
                })
            }).catch(() => {
                mensagemErro('Erro ao cadastrar o Histórico')
            })
        }).catch(() => {
            mensagemErro('Erro ao cadastrar a Ocorrência')
        })
    }

    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleObservacao = (event) => {
        event.persist();
        setObservacao(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleDocumento = (event) => {
        event.persist();
        setDocumento(event.target.files)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                {dado.status === "ABERTO" ?
                                    <>
                                        {dado.nivel > 30 ?
                                            <div className="col-xl-12">
                                                <div className="card" style={{ backgroundColor: "red" }}>
                                                    <div className="card-body">
                                                        <div class="row m-0">
                                                            <h3 style={{ color: "white" }}>Ocorrência Urgente</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-xl-12">
                                                <div className="card" style={{ backgroundColor: "#01A9DB" }}>
                                                    <div className="card-body">
                                                        <div class="row m-0">
                                                            <h3 style={{ color: "white" }}>Ocorrência Moderada</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className="col-xl-12">
                                        <div className="card" style={{ backgroundColor: "#31B404" }}>
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h3 style={{ color: "white" }}>Finalizada com sucesso</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Ocorrência - {dado.protocolo}</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-3'>
                                                    <label>Tipo da Ocorrência</label>
                                                    <p><b>{dado.ocorrencia}</b></p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Gravidade</label>
                                                    {dado.gravidade === 1 ?
                                                        <p><b>Sem Gravidade</b></p>
                                                        : dado.gravidade === 2 ?
                                                            <p><b>Puca Gravidade</b></p>
                                                            : dado.gravidade === 3 ?
                                                                <p><b>Grave</b></p>
                                                                : dado.gravidade === 4 ?
                                                                    <p><b>Muito Grave</b></p>
                                                                    :
                                                                    <p><b>Extremamente Grave</b></p>
                                                    }
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Urgência</label>
                                                    {dado.urgencia === 1 ?
                                                        <p><b>Sem Urgência</b></p>
                                                        : dado.urgencia === 2 ?
                                                            <p><b>Pouco Urgente</b></p>
                                                            : dado.urgencia === 3 ?
                                                                <p><b>Urgente</b></p>
                                                                : dado.urgencia === 4 ?
                                                                    <p><b>Muito Urgente</b></p>
                                                                    :
                                                                    <p><b>Extremamente Urgente</b></p>
                                                    }
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Tendência</label>
                                                    {dado.tendencia === 1 ?
                                                        <p><b>Sem tendência de piorar</b></p>
                                                        : dado.tendencia === 2 ?
                                                            <p><b>Piorar em longo prazo</b></p>
                                                            : dado.tendencia === 3 ?
                                                                <p><b>Piorar em médio prazo</b></p>
                                                                : dado.tendencia === 4 ?
                                                                    <p><b>Piorar em curto prazo</b></p>
                                                                    :
                                                                    <p><b>Agravar rápido</b></p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body" >
                                            <div class="row m-0">
                                                <h4 className="box-title">Detalhes do Favorecido</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Favorecido</label>
                                                    <p><b>{dado.favorecido.nome}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipo de Contato</label>
                                                    <p><b>Via {dado.favorecido.contato}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <p><b>{dado.favorecido.telefone}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Email</label>
                                                    {dado.favorecido.email ?
                                                        <p><b>{dado.favorecido.email}</b></p>
                                                        :
                                                        <p><b>Sem email</b></p>
                                                    }
                                                </div>
                                                <div className='col-md-6' style={{ marginTop: 20 }}>
                                                    <label>Cidade / UF</label>
                                                    <p><b>{dado.cidade} - {dado.uf}</b></p>
                                                </div>
                                                <div className='col-md-6' style={{ marginTop: 20 }}>
                                                    <label>Base</label>
                                                    <p><b>{dado.base.nome}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body" >
                                            <div class="row m-0">
                                                <h4 className="box-title">Motivo da Ocorrência</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-12'>
                                                    <p><b>{dado.descricao}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        {dado.status === "FINALIZADO" ?
                                            <>
                                                <div className="card-body" >
                                                    <div class="row m-0">
                                                        <h4 className="box-title">Observação da finalização</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body--" style={{ marginBottom: 20 }}>
                                                    <div className='row col-md-12' >
                                                        <div className='col-md-12'>
                                                            <p><b>{dado.observacao}</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            {dado.status === "ABERTO" ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Cadastrar nova atividade na ocorrência</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12' >
                                                    <div className='col-md-12'>
                                                        <label>Descrição</label>
                                                        <div class="form-group">
                                                            <textarea type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Arquivo ou Print - (PNG, JPEG, JPG)</label>
                                                        <div class="form-group">
                                                            <input type="file" accept=".png, .jpeg, .jpg" id="company" class="form-control" onChange={handleAvatar} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3" style={{ marginTop: 30 }}>
                                                        {loading ?
                                                            <button type="button" class="btn btn-default mb-1" >
                                                                Atualizando Ocorrência ...
                                                            </button>
                                                            :
                                                            <button type="button" class="btn btn-info mb-1" onClick={() => cadastraOcorrencia()}>
                                                                Atualizar Ocorrência
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {dado.status === "ABERTO" ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Finalizar Ocorrência</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12' >
                                                    <div className='col-md-12'>
                                                        <label>Observação</label>
                                                        <div class="form-group">
                                                            <textarea type="text" value={observacao} id="company" class="form-control" onChange={handleObservacao} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Arquivo ou Print - (PNG, JPEG, JPG)</label>
                                                        <div class="form-group">
                                                            <input type="file" accept=".png, .jpeg, .jpg" id="company" class="form-control" onChange={handleDocumento} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3" style={{ marginTop: 30 }}>
                                                        {loading ?
                                                            <button type="button" class="btn btn-default mb-1" >
                                                                Finalizando Ocorrência ...
                                                            </button>
                                                            :
                                                            <button type="button" class="btn btn-info mb-1" onClick={() => finalizaOcorrencia()}>
                                                                Finalizar Ocorrência
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Histórico</h4>
                                            </div>
                                        </div>
                                        {historicos.map((historico) =>
                                            <div className="card-body--">
                                                <div className='row col-md-12' >
                                                    <div className='col-md-1'>
                                                        <div class="round-img">
                                                            <img style={{ width: 50, height: 50 }} class="rounded-circle" src={historico.usuario.avatar ? historico.usuario.avatar : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png"} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-10'>
                                                        <label><b>{historico.usuario.nome} - Atualizado em {moment(historico.dataCadastro).format('DD/MM/YYYY HH:mm')}</b></label>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: 20 }}>
                                                        <label><b>Observações</b></label>
                                                        <p><b>{historico.status}</b></p>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: 20 }}>
                                                        <label><b>Arquivo / Print</b></label>
                                                        {historico.documento ?
                                                            <p>
                                                                <a href={historico.documento} target="_blank" class="btn btn-info mb-1">
                                                                    Abrir arquivo
                                                                </a>
                                                            </p>
                                                            :
                                                            <p><b>Nao pssui arquivo</b></p>
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
