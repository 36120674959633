import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco, carregaEstados, carregaCidade } from '../../../../services/Localidades'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Usuario(props) {
    const [dado] = useState(props.location.state.usuario)
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        pendencia: firebase.firestore().collection('pendencia'),
    })
    const [usuario, setUsuario] = useState({})

    const [ufs, setUfs] = useState([])
    const [cidades, setCidades] = useState([])

    const [nome, setNome] = useState(dado.nome)
    const [sobrenome, setSobrenome] = useState(dado.sobrenome)
    const [contato, setContato] = useState(dado.contato)
    const [cpf, setCpf] = useState(dado.cpf)
    const [cep, setCep] = useState(dado.endereco ? dado.endereco.cep : '')
    const [endereco, setEndereco] = useState(dado.endereco ? dado.endereco.endereco : '')
    const [bairro, setBairro] = useState(dado.endereco ? dado.endereco.bairro : '')
    const [numero, setNumero] = useState(dado.endereco ? dado.endereco.numero : '')
    const [complemento, setComplemento] = useState(dado.endereco ? dado.endereco.complemento : '')
    const [perfil, setPerfil] = useState(dado.usuario)
    const [setor, setSetor] = useState(dado.setor)
    const [cargo, setCargo] = useState(dado.cargo)
    const [uf, setUf] = useState(dado.endereco ? dado.endereco.uf : '')
    const [cidade, setCidade] = useState(dado.endereco ? dado.endereco.cidade : '')
    const [email, setEmail] = useState(dado.email)

    // Sistemas
    const [reembalados, setReembalados] = useState(dado.reembalados?dado.reembalados:0)
    const [promocional, setPromocional] = useState(dado.promocional?dado.promocional:0)
    const [ocorrencia, setOcorrencia] = useState(dado.ocorrencia?dado.ocorrencia:0)
    const [cupons, setCupons] = useState(dado.cupons?dado.cupons:0)
    const [avarias, setAvarias] = useState(dado.avarias?dado.avarias:0)
    const [notas, setNotas] = useState(dado.notas?dado.notas:0)
    const [blog, setBlog] = useState(dado.blog?dado.blog:0)
    const [produtos, setProdutos] = useState(dado.produtos?dado.produtos:0)
    const [agregados, setAgregados] = useState(dado.agregados?dado.agregados:0)
    const [usuarios, setUsuarios] = useState(dado.usuarios?dado.usuarios:0)
    const [funil, setFunil] = useState(dado.funil?dado.funil:0)

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState(dado.avatar ? dado.avatar : '');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)
    const [verificaCpf, setVerificaCpf] = useState(false)
    const [loadingCpf, setLoadingCpf] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            console.log(dado.usuario)
            carregaEstados().then((res) => {
                setUfs(res);
            })
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios, servicos.pendencia])


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem do Avatar...')
        var uploadTask = firebase.storage().ref().child('usuarios/avatar' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Usuário atualizado com sucesso! ')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraUsuario() {
        if (nome === '') {
            mensagemAlerta('Favor digitar o nome ')
            setLoading(false)
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar o sobrenome ')
            setLoading(false)
        } else if (contato === '' || contato.length < 11) {
            mensagemAlerta('Contato vazio ou incompleto')
            setLoading(false)
        } else if (verificaCpf === 1) {
            mensagemAlerta('CPF já existe no sistema')
            setLoading(false)
        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
            setLoading(false)
        } else if (uf === '') {
            mensagemAlerta('Favor escolha o Estado')
            setLoading(false)
        } else if (cidade === '') {
            mensagemAlerta('Favor escolha a Cidade')
            setLoading(false)
        } else if (numero === '') {
            mensagemAlerta('Favor digite o número')
            setLoading(false)
        } else if (perfil === '') {
            mensagemAlerta('Favor escolha o Perfil')
            setLoading(false)
        } else {
            mensagemLoading('Aguarde.. Cadastrando informações')
            servicos.usuarios.doc(dado.id).update({
                nome: nome,
                sobrenome: sobrenome,
                contato: contato,
                cpf: cpf,
                endereco: {
                    endereco: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: complemento,
                    uf: uf,
                    cidade: cidade,
                    cep: cep
                },
                reembalados:parseInt(reembalados),
                promocional:parseInt(promocional),
                ocorrencia:parseInt(ocorrencia),
                cupons:parseInt(cupons),
                avarias:parseInt(avarias),
                notas:parseInt(notas),
                blog:parseInt(blog),
                produtos:parseInt(produtos),
                agregados:parseInt(agregados),
                usuarios:parseInt(usuarios),
                funil:parseInt(funil),
                admin: {
                    id: usuario.id,
                    nome: usuario.nome
                },
                usuario: perfil,
                cargo: cargo ? cargo : null,
                setor: setor,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
            }).then(() => {
                if (upload) {
                    cadastraAvatar(dado.id)
                } else {
                    mensagemSucesso('Usuário atualizado com sucesso!')
                }
            }).catch(() => {
                mensagemErro('Erro ao atualizar o usuário')
            })
        }
    }

    function removeUsuario() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja desativar o usuário ' + dado.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Aguarde... Removendo usuário')
                servicos.usuarios.doc(dado.id).update({
                    status:'INATIVO'
                }).then(() => {
                    mensagemSucesso('Usuário desativado com sucesso!')
                })
            }
        });
    }


    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            mensagemLoading('Verificando CPF...')
            servicos.usuarios.where('cpf', '==', event.target.value).onSnapshot((snap) => {
                if (snap.size > 0) {
                    Swal.close()
                    setLoadingCpf(false)
                    setVerificaCpf(1)
                } else {
                    Swal.close();
                    setLoadingCpf(false)
                    setVerificaCpf(0)
                }
            })
        } else {
            setVerificaCpf(false)
        }
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaEndereco(event.target.value).then((doc) => {
                if (doc.erro === true) {
                    mensagemAlerta('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handlePerfil = (event) => {
        event.persist();
        setPerfil(event.target.value);
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handleCargo = (event) => {
        event.persist();
        setCargo(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    const handleUf = (event) => {
        mensagemLoading('Buscando cidades...')
        event.persist();
        setUf(event.target.value);
        if (event.target.value) {
            setUf(ufs[event.target.value])
            carregaCidade(ufs[event.target.value].sigla).then((res) => {
                Swal.close();
                setCidades(res);
            })
        }
    }
    const handleCidade = (event) => {
        event.persist();
        if (event.target.value) {
            setCidade(cidades[event.target.value]);
        }
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep() {
        setLoadingCep(true)
        if (cep.length < 8) {
            setLoadingCep(false)
            mensagemAlerta('Favor digite o CEP para realizar a busca')
        } else {
            buscaEndereco(cep).then((doc) => {
                if (doc.erro === true) {
                    setLoadingCep(false)
                    mensagemAlerta('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setLoadingCep(false)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function sucessoCpf(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">{dado.nome} {dado.sobrenome}</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        : avatar ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={avatar} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Usuário</label>
                                                    <p>Para mudar a foto do usuário é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>

                                                    {loadingImage ?
                                                        <div class="progress-box progress-1" style={{ marginTop: 10 }}>
                                                            <div class="por-txt">Carregando... ({progresso})</div>
                                                            <div class="progress mb-2" >
                                                                <div class="progress-bar bg-flat-color-1" role="progressbar" style={{ width: progresso }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                    }

                                                </div>
                                            </div>

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Sobrenome</label>
                                                    <div class="form-group">
                                                        <input type="text" value={sobrenome} id="company" class="form-control" onChange={handleSobrenome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>CPF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cpf} id="company" class="form-control" onChange={handleCpf} />
                                                    </div>
                                                    {loadingCpf ?
                                                        <p style={{ color: "blue", fontSize: 10 }}>Aguarde.. Verifcando CPF no sistema </p>
                                                        :
                                                        null
                                                    }
                                                    {verificaCpf === 1 ?
                                                        <p style={{ color: "red", fontSize: 10 }}>CPF já cadastrado </p>
                                                        : verificaCpf === 0 ?
                                                            <p style={{ color: "green", fontSize: 10 }}>CPF disponível </p>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-2'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input disabled type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input disabled type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Setor</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleSetor} >
                                                        <option value="">Escolha o setor </option>
                                                        <option value="DIRETORIA" selected={dado.setor === "DIRETORIA" ? "selected" : null}>Diretoria </option>
                                                        <option value="CONTROLADORIA" selected={dado.setor === "CONTROLADORIA" ? "selected" : null}>Controladoria </option>
                                                        <option value="CONTROLE" selected={dado.setor === "CONTROLE" ? "selected" : null}>Torre de Controle </option>
                                                        <option value="LOGISTICA" selected={dado.setor === "LOGISTICA" ? "selected" : null}>Logística </option>
                                                        <option value="LABS" selected={dado.setor === "LABS" ? "selected" : null}>Labs </option>
                                                        <option value="MOTORISTA" selected={dado.setor === "MOTORISTA" ? "selected" : null}>Motoristas </option>
                                                    </select>
                                                </div>
                                                {setor && setor !== 'MOTORISTA' ?
                                                    <div className='col-md-4'>
                                                        <label>Cargo</label>
                                                        <div class="form-group">
                                                            <input type="text" value={cargo} id="company" class="form-control" onChange={handleCargo} />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className='col-md-3'>
                                                    <label>Administrador do Sistema?</label>
                                                    <select name="select" id="select" class="form-control" onChange={handlePerfil} >
                                                        <option value="">Escolha o setor </option>
                                                        <option value="MASTER" selected={dado.usuario === "MASTER" ? "selected" : null}>Sim </option>
                                                        <option value="COMUM" selected={dado.usuario === "COMUM" ? "selected" : null}>Não </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handlePerfil} >
                                                        <option value="">Escolha  </option>
                                                        <option value="MASTER">Sim </option>
                                                        <option value="COMUM">Não </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Email</label>
                                                    <div class="form-group">
                                                        <input type="text" disabled value={email} id="company" class="form-control" onChange={handleEmail} />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Permissões de Sistemas</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-2'>
                                                    {reembalados === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setReembalados(1)}>
                                                           Sist. Reembalados
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setReembalados(0)}>
                                                           Sist. Reembalados
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {promocional === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setPromocional(1)}>
                                                            Log. Promocional
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setPromocional(0)}>
                                                            Log. Promocional
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {ocorrencia === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setOcorrencia(1)}>
                                                           Sist. Ocorrências
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setOcorrencia(0)}>
                                                           Sist. Ocorrências
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {cupons === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setCupons(1)}>
                                                           Sistema Cupons
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setCupons(0)}>
                                                           Sistema Cupons
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {avarias === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setAvarias(1)}>
                                                           Sistema Avarias
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setAvarias(0)}>
                                                           Sistema Avarias
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {notas === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setNotas(1)}>
                                                           Notas Fiscais
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setNotas(0)}>
                                                           Notas Fiscais
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {blog === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setBlog(1)}>
                                                           Sistema de Blog
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setBlog(0)}>
                                                           Sistema de Blog
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {produtos === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setProdutos(1)}>
                                                           Sistema Produtos
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setProdutos(0)}>
                                                           Sistema Produtos
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {agregados === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setAgregados(1)}>
                                                          Sist. Agregados
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setAgregados(0)}>
                                                          Sist. Agregados
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {funil === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setFunil(1)}>
                                                          Sist. Funil Venda
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setFunil(0)}>
                                                          Sist. Funil Venda
                                                        </button>
                                                    }
                                                </div>
                                                <div className='col-md-2'>
                                                    {usuarios === 0 ?
                                                        <button type="button" class="btn btn-danger mb-1" onClick={() => setUsuarios(1)}>
                                                          Sistema Usuários
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => setUsuarios(0)}>
                                                          Sistema Usuários
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Atualizando Usuário ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraUsuario()}>
                                                        Atualizar Usuário
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
