import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Avarias() {

    const [avarias, setAvarias] = useState([])
    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        avarias: firebase.firestore().collection('avarias'),
        usuarios: firebase.firestore().collection('usuarios'),
        filiais: firebase.firestore().collection('filiais'),
    })

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data())
                if (doc.data().setor === 'LOGISTICA') {
                    servicos.filiais.where('responsavel.id', '==', doc.data().id).get().then((snap) => {
                        if (snap.size > 0) {
                            servicos.avarias.where('responsavel.id', '==', doc.data().id).onSnapshot((snap) => {
                                const data = snap.docs.map(doc => doc.data())
                                setAvarias(data)
                            })
                        }
                    })
                } else if (doc.data().setor === 'RH') {
                    servicos.avarias.where('status', '==', 'FINANCEIRO').onSnapshot((snap) => {
                        const data = snap.docs.map(doc => doc.data())
                        setAvarias(data)
                    })
                } else  {
                    servicos.avarias.where('status', '!=', 'ABERTO').onSnapshot((snap) => {
                        const data = snap.docs.map(doc => doc.data())
                        setAvarias(data)
                    })
                }
            })
        })
    }, [servicos.avarias])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-3">
                                                    <i className="pe-7s-ribbon"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{avarias.filter((d) => d.status === "ABERTO").length}</div>
                                                        <div className="stat-heading">Abertos</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-6">
                                                    <i className="pe-7s-clock"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{avarias.filter((d) => d.status === "ANALISE" || d.status === "FINANCEIRO").length}</div>
                                                        <div className="stat-heading">Análise</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-1">
                                                    <i className="pe-7s-check"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{avarias.filter((d) => d.status === "FINALIZADO").length}</div>
                                                        <div className="stat-heading">Fechados</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-4">
                                                    <i className="pe-7s-close-circle"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">{avarias.filter((d) => d.status === "CANCELADO").length}</div>
                                                        <div className="stat-heading">Cancelados</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Avarias</h4>
                                                </div>
                                                {usuario.usuario !== "MASTER" ?
                                                    <div className="col-sm-2">
                                                        <Link to={{ pathname: '/cadastroAvaria' }} >
                                                            <button style={{ marginLeft: 10 }} type="button" class="btn btn-success mb-1">
                                                                Nova Avaria
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Imagem</th>
                                                            <th>Motorista</th>
                                                            <th>Responsávell</th>
                                                            <th>Veículo</th>
                                                            <th>Alocação</th>
                                                            <th>Serviço</th>
                                                            <th>Status</th>
                                                            <th>Contrato</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {avarias.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((usuario) =>
                                                            <tr key={usuario.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={usuario.imagem1} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{usuario.nome}</td>
                                                                <td>{usuario.responsavel.nome}</td>
                                                                <td>{usuario.placa}</td>
                                                                <td>{usuario.alocacao}</td>
                                                                <td>{usuario.servico}</td>
                                                                <td>
                                                                    {usuario.status === 'ABERTO' ?
                                                                        <button  >
                                                                            <span className="badge badge-info">ABERTO</span>
                                                                        </button>
                                                                        : usuario.status === 'ANALISE' ?
                                                                            <button  >
                                                                                <span className="badge badge-warning">EM ANÁLISE</span>
                                                                            </button>
                                                                            : usuario.status === 'FINANCEIRO' ?
                                                                                <button  >
                                                                                    <span className="badge badge-warning">NO FINANCEIRO</span>
                                                                                </button>
                                                                                : usuario.status === 'FINALIZADO' ?
                                                                                    <button  >
                                                                                        <span className="badge badge-success">FINALIZADO</span>
                                                                                    </button>
                                                                                    :
                                                                                    <button  >
                                                                                        <span className="badge badge-danger">CANCELADO</span>
                                                                                    </button>

                                                                    }
                                                                </td>
                                                                <td>
                                                                    {usuario.contratoAssinatura ?
                                                                        <a href={usuario.contratoAssinatura} target='_blank'>
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </a>
                                                                        :
                                                                        <Link to={{ pathname: '/contratoAvaria', state: { avaria: usuario } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheAvaria', state: { avaria: usuario } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {avarias.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem avarias cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
