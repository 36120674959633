import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Sistemas() {

    const [sistemas, setSistemas] = useState([])
    const [sistemasTemp, setSistemasTemp] = useState([])
    const [excel, setExcel] = useState([])
    const [servicos] = useState({
        equipamentosTecnologia: firebase.firestore().collection('equipamentosTecnologia')
    })

    useEffect(() => {
        servicos.equipamentosTecnologia.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setSistemas(data)
            setSistemasTemp(data)
            const list = []
                data.forEach((d) => {
                    const dataEx = {
                        imagem: d.imagem ? d.imagem : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png",
                        equipamento: d.nome,
                        filial: d.filial ? d.filial.filial : 'Sem filial',
                        usuario: d.usuario ? d.usuario.nome : 'Sem usuário',
                        tipo: d.tipoEquipamento,
                        status: d.status
                    }
                    list.push(dataEx)
                })
                setExcel(list)
        })
    }, [servicos.equipamentosTecnologia])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando ocorrências')
            servicos.equipamentosTecnologia.where('status', '==', event.target.value).get().then((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())
                setSistemas(data)
                setSistemasTemp(data)
                const list = []
                data.forEach((d) => {
                    const dataEx = {
                        imagem: d.imagem ? d.imagem : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png",
                        equipamento: d.nome,
                        filial: d.filial ? d.filial.filial : 'Sem filial',
                        usuario: d.usuario ? d.usuario.nome : 'Sem usuário',
                        tipo: d.tipoEquipamento,
                        status: d.status
                    }
                    list.push(dataEx)
                })
                setExcel(list)
            })
        } else {
            setSistemas(sistemasTemp);
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-9">
                                                    <h4 className="box-title">Equipamentos de Tecnologia</h4>
                                                </div>
                                                <div class="col-sm-2" >
                                                    <ExcelFile element={<button type="button" class="btn btn-success mb-1" >imprimir Excel</button>}>
                                                        <ExcelSheet data={excel} name="Employees">
                                                            <ExcelColumn label="IMAGEM" value="imagem" />
                                                            <ExcelColumn label="EQUIPAMENTO" value="equipamento" />
                                                            <ExcelColumn label="FILIAL" value="filial" />
                                                            <ExcelColumn label="USUÁRIO" value="usuario" />
                                                            <ExcelColumn label="TIPO" value="tipo" />
                                                            <ExcelColumn label="STATUS" value="status" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastroEquipamentosTecnologia' }} >
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Nova Equipamento
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="ATIVO">Ativos </option>
                                                        <option value="INATIVO">Inativos </option>
                                                        <option value="ANALISE">Em Análise </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Imagem</th>
                                                            <th>Equipamento</th>
                                                            <th>Filial</th>
                                                            <th>Usuário</th>
                                                            <th>Tipo</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((sistema) =>
                                                            <tr key={sistema.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={sistema.imagem ? sistema.imagem : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png"} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{sistema.nome}</td>
                                                                <td>{sistema.filial ? sistema.filial.filial : "Sem Filial"}</td>
                                                                <td>{sistema.usuario ? sistema.usuario.nome : "Sem Usuário"}</td>
                                                                <td>{sistema.tipoEquipamento}</td>
                                                                {sistema.status === "ATIVO" ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-success">ATIVO</span>
                                                                        </button>
                                                                    </td>
                                                                    : sistema.status === "INATIVO" ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-danger">INATIVO</span>
                                                                            </button>
                                                                        </td>
                                                                        : sistema.status === "DISPONIVEL" ?
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-info">Disponível</span>
                                                                                </button>
                                                                            </td>
                                                                            :
                                                                            null

                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/cadastroEquipamentosTecnologia', state: { sistema: sistema } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem sistemas cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
