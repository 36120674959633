import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Gaiolas() {

    const [gaiolas, setGaiolas] = useState([])
    const [numero, setNumero] = useState('')
    const [cliente, setCliente] = useState({})
    const [filial, setFilial] = useState({})
    const [rua, setRua] = useState({})

    const [clientes, setClientes] = useState([])
    const [filiais, setFiliais] = useState([])
    const [ruas, setRuas] = useState([])

    const [nome, setNome] = useState('')
    const [servicos] = useState({
        gaiolas: firebase.firestore().collection('gaiolas'),
        produtos: firebase.firestore().collection('produtos').where('tipo', '==', 'SKU'),
        filiais: firebase.firestore().collection('filiais'),
        clientes: firebase.firestore().collection('clientes').where('status', '==', 'ATIVO'),
    })

    useEffect(() => {
        servicos.gaiolas.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setGaiolas(data)
        })
        servicos.clientes.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setClientes(data)
        })
        servicos.filiais.where('status', '==', 'ATIVO').onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setFiliais(data)
        })
    }, [servicos.gaiolas])

    function cadastraGaiola() {
        if (!numero) {
            mensagemAlerta('Favor digite o número da Gaiola')
            return
        }
        if (!filial.id) {
            mensagemAlerta('Favor selecione a filial')
            return
        }
        if (!rua.id) {
            mensagemAlerta('Favor selecione a rua')
            return
        }
        if (!cliente.id) {
            mensagemAlerta('Favor selecione o Cliente alocado a galioa')
            return
        }
        mensagemLoading('Cadastrando Gaiola...')
        servicos.gaiolas.where('numero', '==', numero).get().then((snap) => {
            if (snap.size > 0) {
                mensagemAlerta('Gaiola de número ' + numero + ' já cadastrada!')
            } else {
                servicos.gaiolas.add({
                    numero: numero,
                    filial: {
                        id: filial.id,
                        nome: filial.nome
                    },
                    cliente: {
                        id: cliente.id,
                        nome: cliente.nome
                    },
                    rua: {
                        id: rua.id,
                        nome: rua.nome
                    },
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                }).then((docRef) => {
                    servicos.gaiolas.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        Swal.close();
                        mensagemSucesso('Gaiola cadastrada com sucesso')
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar a gaiola! Favor verifique com o administrador')
                        Swal.close();
                    })
                }).catch((docRef) => {
                    mensagemErro('Erro ao cadastrar a Gaiola! Favor verifique com o administradors')
                    Swal.close();
                })
            }
        })

    }

    function removeGaiola(gaiola) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover a Gaiola ' + gaiola.numero + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Verificando produtos na Gaiola...')
                servicos.produtos.where('gaiola.id', '==', gaiola.id).get().then((doc) => {
                    if (doc.size > 0) {
                        mensagemAlerta('A gaiola não pode ser removida, pois existem produtos vinculadas a ela')
                    } else {
                        mensagemLoading('Removendo Gaiola...')
                        servicos.gaiolas.doc(gaiola.id).delete().then(() => {
                            mensagemSucesso('Gaiola removida com sucesso')
                        }).catch(() => {
                            mensagemErro('Erro ao remover a Gaiola! Contate o administrador')
                        })
                    }
                })
            }
        });
    }

    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleFilial = (event) => {
        event.persist();
        if (event.target.value) {
            setFilial(filiais[event.target.value])
            servicos.filiais.doc(filiais[event.target.value].id).collection('ruas').onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setRuas(data)
            })
        } else {
            setFilial({})
        }
    }
    const handleRua = (event) => {
        event.persist();
        if (event.target.value) {
            setRua(ruas[event.target.value])
        } else {
            setRua({})
        }
    }
    const handleCliente = (event) => {
        event.persist();
        if (event.target.value) {
            setCliente(clientes[event.target.value])
        } else {
            setCliente({})
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Gaiolas</h4>
                                                </div>
                                            </div>
                                            <div className="row m-0" style={{ marginTop: 30 }}>
                                                <div className='col-md-1'>
                                                    <label>Número</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Filial da Gaiola</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFilial} >
                                                        <option value="">Escolha </option>
                                                        {filiais.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((filial, index) =>
                                                            <option value={index}>{filial.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                {filial.id ?
                                                    <div className='col-md-2'>
                                                        <label>Rua</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleRua} >
                                                            <option value="">Escolha </option>
                                                            {ruas.sort((a, b) => {
                                                                if (a.nome < b.nome) {
                                                                    return -1;
                                                                }
                                                                if (a.nome > b.nome) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            }).map((rua, index) =>
                                                                <option value={index}>{rua.nome}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className='col-md-4'>
                                                    <label>Cliente Alocado</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleCliente} >
                                                        <option value="">Escolha </option>
                                                        {clientes.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((cliente, index) =>
                                                            <option value={index}>{cliente.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraGaiola()}>
                                                        Cadastrar Gaiola
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>N°</th>
                                                            <th>Filial</th>
                                                            <th>Rua</th>
                                                            <th>Cliente</th>
                                                            <th>Produtos</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {gaiolas.map((gaiola) =>
                                                            <tr key={gaiola.id} >
                                                                <td>{gaiola.numero}</td>
                                                                <td>{gaiola.filial.nome}</td>
                                                                <td>{gaiola.rua.nome}</td>
                                                                <td>{gaiola.cliente.nome}</td>
                                                                <td>
                                                                    {/* produtosGaiolasLogisticaPromocional */}
                                                                    <Link to={{ pathname: '/produtosGaiolasLogisticaPromocional', state: { gaiola: gaiola } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => removeGaiola(gaiola)} >
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {gaiolas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem gaiolas cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
