import React, { useEffect, useState } from 'react';
import Header from '../../../admin/componentes/Header'
import Sidebar from '../../../admin/componentes/Sidebar'
import firebase from 'firebase'
import moment, { min } from 'moment'
import Swal from 'sweetalert2'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function CadastraCliente(props) {
    const [dados] = useState(props.location.state ? props.location.state.blog : null)
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        blog: firebase.firestore().collection('blog'),
        notificacoes: firebase.firestore().collection('notificacoes'),
    })

    const [titulo, setTitulo] = useState(dados ? dados.titulo : '')
    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [chamada, setChamada] = useState(dados ? dados.chamada : '')
    const [solucao, setSolucao] = useState(dados ? dados.solucao : '')
    const [conteudo, setConteudo] = useState(dados ? dados.conteudo : '')
    const [status, setStatus] = useState(dados ? dados.status : '')
    const [tipo, setTipo] = useState(dados ? dados.tipo : '')
    const [referencia, setReferencia] = useState(dados ? dados.referencia : '')
    const [link, setLink] = useState(dados ? dados.link : '')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState(dados ? dados.avatar : '');
    const [upload, setUpload] = useState('');
    const [miniatura, setMiniatura] = useState(dados ? dados.miniatura : '');
    const [uploadMiniatura, setUploadMiniatura] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem do Avatar...')
        var uploadTask = firebase.storage().ref().child('blog/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.blog.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    if (uploadMiniatura) {
                        cadastraMiniatura(id)
                    } else {
                        Swal.close();
                        if (dados) {
                            mensagemSucesso('Blog atualizado com sucesso! ')
                        } else {
                            mensagemSucesso('Blog cadastrado com sucesso!')
                        }
                    }
                })
            });
        });
    }

    function cadastraMiniatura(id) {
        mensagemLoading('Salvando a imagem da Miniatura...')
        var uploadTask = firebase.storage().ref().child('blog/miniatura' + id).put(miniatura[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.blog.doc(id).update({
                    miniatura: downloadURL
                }).then(() => {
                    Swal.close();
                    if (dados) {
                        mensagemSucesso('Blog atualizado com sucesso! ')
                    } else {
                        mensagemSucesso('Blog cadastrado com sucesso!')
                    }
                })
            });
        });
    }

    function cadastraCliente() {
        if (uploadMiniatura === '' && !miniatura) {
            mensagemAlerta('Favor escolha a imagem Miniatura')
            setLoading(false)
        } else if (upload === '' && !avatar) {
            mensagemAlerta('Favor escolha a imagem da Capa')
            setLoading(false)
        } else if (titulo === '') {
            mensagemAlerta('Favor digitar o título')
            setLoading(false)
        } else if (descricao === '') {
            mensagemAlerta('Favor digitar a Descrição ')
            setLoading(false)
        } else if (conteudo === '') {
            mensagemAlerta('Favor digite o conteúdo')
            setLoading(false)
        } else if (status === '') {
            mensagemAlerta('Favor escolha o status da publicação')
            setLoading(false)
        } else if (tipo === '') {
            mensagemAlerta('Favor escolha o tipo de publicação')
            setLoading(false)
        } else if (referencia === '') {
            mensagemAlerta('Favor digite a referência')
            setLoading(false)
        } else {
            if (dados) {
                mensagemLoading('Aguarde.. Atualizando informações')
                servicos.blog.doc(dados.id).update({
                    titulo: titulo,
                    slug: titulo.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''),
                    descricao: descricao,
                    conteudo: conteudo,
                    status: status,
                    chamada:chamada,
                    solucao: solucao,
                    referencia: referencia,
                    link: link,
                    tipo: tipo,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then((docRef) => {
                    Swal.close()
                    if (upload) {
                        cadastraAvatar(dados.id)
                    } else if (uploadMiniatura) {
                        cadastraMiniatura(dados.id)
                    } else {
                        mensagemSucesso('Blog atualizado com sucesso!')
                    }
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar o blog')
                })
            } else {
                mensagemLoading('Aguarde.. Cadastrando informações')
                servicos.blog.add({
                    titulo: titulo,
                    slug: titulo.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''),
                    descricao: descricao,
                    conteudo: conteudo,
                    status: status,
                    solucao: solucao,
                    chamada:chamada,
                    referencia: referencia,
                    link: link,
                    tipo: tipo,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then((docRef) => {
                    servicos.blog.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        Swal.close()
                        cadastraAvatar(docRef.id)
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar o blog')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar o blog')
                })
            }
        }
    }


    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleConteudo = (event) => {
        setConteudo(event)
    }
    const handleChamada = (event) => {
        event.persist();
        setChamada(event.target.value);
    }
    const handleSolucao = (event) => {
        setSolucao(event)
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }
    const handleLink = (event) => {
        event.persist();
        setLink(event.target.value);
    }

    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleMiniatura = (event) => {
        event.persist();
        setMiniatura(event.target.files)
        setUploadMiniatura(URL.createObjectURL(event.target.files[0]))
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagem da Miniatura</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-12'>
                                                    {uploadMiniatura ?
                                                        <img class="card-img-top" style={{ height: 200, width: 250 }} src={uploadMiniatura} alt='carregando...' />
                                                        : dados && miniatura ?
                                                            <img class="card-img-top" style={{ height: 200, width: 250 }} src={miniatura} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 200, width: 250 }} src='images/MiniaturaEmpty.png' alt='carregando...' />
                                                    }
                                                    <input style={{ marginTop: 10, width: '60%', marginBottom: 10 }} id="selectedFile" onChange={handleMiniatura} type="file" name="name" placeholder="Escolha a imagem " />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagem da Capa</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-12 ' >
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 200, width: 600 }} src={upload} alt='carregando...' />

                                                        : dados ?
                                                            <img class="card-img-top" style={{ height: 200, width: 600 }} src={avatar} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 200, width: 600 }} src='images/CapaEmpty.png' alt='carregando...' />
                                                    }
                                                    <input style={{ marginTop: 10, width: '25%', marginBottom: 10, marginLeft: 10 }} onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                {dados ?
                                                    <h4 className="box-title">{dados.titulo}</h4>
                                                    :
                                                    <h4 className="box-title">Nova Publicação</h4>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-body--">

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-6'>
                                                    <label>Título</label>
                                                    <div class="form-group">
                                                        <input type="text" value={titulo} id="company" class="form-control" onChange={handleTitulo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: 20 }}>
                                                    <label>Conteúdo</label>
                                                    <SunEditor
                                                        lang="pt_br"
                                                        placeholder="informações"
                                                        onChange={handleConteudo}
                                                        setContents={conteudo}
                                                    />
                                                </div>
                                            <div className='col-md-12' style={{ marginTop: 20 }}>
                                                    <label>Chamada da Solução</label>
                                                    <div class="form-group">
                                                        <input type="text" value={chamada} id="company" class="form-control" onChange={handleChamada} />
                                                    </div>
                                                </div>
                                            <div className='col-md-12' style={{ marginTop: 20 }}>
                                                    <label>Solução</label>
                                                    <SunEditor
                                                        lang="pt_br"
                                                        placeholder="informações"
                                                        onChange={handleSolucao}
                                                        setContents={solucao}
                                                    />
                                                </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-2'>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha  </option>
                                                        <option value="ATIVO" selected={dados && dados.status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipo</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTipo} >
                                                        <option value="">Escolha </option>
                                                        <option value="ENTREGA JA" selected={dados && dados.tipo === "ENTREGA JA" ? "selected" : null}>Entrega Já </option>
                                                        <option value="EXTERNO" selected={dados && dados.tipo === "EXTERNO" ? "selected" : null}>Referência Externa </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Referência</label>
                                                    <div class="form-group">
                                                        <input type="text" value={referencia} id="company" class="form-control" onChange={handleReferencia} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Link</label>
                                                    <div class="form-group">
                                                        <input type="text" value={link} id="company" class="form-control" onChange={handleLink} />
                                                    </div>
                                                </div>
                                            </div>
                                            {dados ?
                                                <div class="page-header col-md-12 row" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando Blog ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Atualizar Blog
                                                        </button>
                                                    }
                                                    <button style={{ marginLeft: 10 }} type="button" class="btn btn-info mb-1" >
                                                        <a target={'_blank'} href={'https://entregaja.com.br/post?d=' + dados.slug} style={{ color: 'white' }}>
                                                            Ver Publicação Teste
                                                        </a>
                                                    </button>
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Blog ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Cadastrar Blog
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
