import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import XMLData from '../../services/magazine.xml';
import axios from 'axios';
var XMLParser = require('react-xml-parser');

export default function Home() {
    const [destino, setDestino] = useState({})
    const [lista, setLista] = useState([])

    useEffect(() => {
        axios.get(XMLData, {
            "Content-Type": "application/xml; charset=utf-8"
        }).then((response) => {
            const lista1 = []
            var xml = new XMLParser().parseFromString(response.data);
            console.log(xml.children[0].children[0].children)
            // setDestino({
            //     cpf: xml.children[0].children[0].children[2].children[0].value,
            //     nome: xml.children[0].children[0].children[2].children[1].value,
            //     endereco: xml.children[0].children[0].children[2].children[2].children,
            //     email: xml.children[0].children[0].children[2].children[4].value
            // })
            // console.log(xml.children[0].children[0].children[2].children)
            const list = xml.children[0].children[0].children
            list.forEach((element) => {
                if (element.name === "det") {
                    lista1.push({
                        codigo: element.children[0].children[0].value,
                        gtin: element.children[0].children[1].value,
                        produto: element.children[0].children[2].value,
                        ncm: element.children[0].children[3].value,
                        cest: element.children[0].children[4].value,
                        escala: element.children[0].children[5].value,
                        cfop: element.children[0].children[6].value,
                        qtdComp: element.children[0].children[7].value,
                        unComp: element.children[0].children[8].value,
                        prod: element.children[0].children[9].value,
                        ceantrib: element.children[0].children[10].value,
                        utrib: element.children[0].children[11].value,
                        qTrib: element.children[0].children[12].value,
                        vUnTrib: element.children[0].children[13].value,
                        indTot: element.children[0].children[14].value,
                        // ped: element.children[0].children[15].value,
                        // itemPed: element.children[0].children[16].value,
                    })
                }
            });
            setLista(lista1)
        });
    }, [])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h2 className="box-title">Destino</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-12'>
                                                    <h4><b>{destino.nome}</b></h4>
                                                </div>
                                                {/* <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                    <div className='col-md-6' >
                                                        <label>Endereço</label>
                                                        <h4><b>{destino.endereco[0].value}</b></h4>
                                                    </div>
                                                    <div className='col-md-4' >
                                                        <label>Bairro</label>
                                                        <h4><b>{destino.endereco[2].value}</b></h4>
                                                    </div>
                                                    <div className='col-md-2' >
                                                        <label>N°</label>
                                                        <h4><b>{destino.endereco[1].value}</b></h4>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                    <div className='col-md-2' >
                                                        <label>Cep</label>
                                                        <h4><b>{destino.endereco[6].value}</b></h4>
                                                    </div>
                                                    <div className='col-md-4' >
                                                        <label>Cidade</label>
                                                        <h4><b>{destino.endereco[4].value}</b></h4>
                                                    </div>
                                                    <div className='col-md-2' >
                                                        <label>UF</label>
                                                        <h4><b>{destino.endereco[5].value}</b></h4>
                                                    </div>
                                                </div> */}
                                                {/* <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                        <div className='col-md-4' >
                                                            <label>Veículo</label>
                                                            <h4><b>{usuario.veiculo}</b></h4>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label>Cidade</label>
                                                            <h4><b>{usuario.cidade}</b></h4>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label>Protocolo</label>
                                                            <h4><b>{usuario.protocolo}</b></h4>
                                                        </div>
                                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h4 className="box-title">Produtos</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Cod</th>
                                                            <th>GTIN</th>
                                                            <th>Produto</th>
                                                            <th>NCM</th>
                                                            <th>CEST</th>
                                                            <th>ESCALA</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lista.map((list) =>
                                                            <tr key={list.codigo} >
                                                                <td>{list.codigo}</td>
                                                                <td>{list.gtin}</td>
                                                                <td>{list.produto}</td>
                                                                <td>{list.ncm}</td>
                                                                <td>{list.cest}</td>
                                                                <td>{list.escala}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
