import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

export default function Labs() {

    return (
        <div className="content">
            <div className="animated fadeIn">
                <div className="orders">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row m-0">
                                        <div className="col-sm-4">
                                            <h4 className="box-title">Aplicativo Entrega Já Log</h4>
                                        </div>
                                        <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                            <div className="col-lg-4 col-md-6">
                                                <Link to={{ pathname: '/blogApp' }} >
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="stat-widget-five">
                                                                <div className="stat-icon dib flat-color-3">
                                                                    <i className="pe-7s-news-paper"></i>
                                                                </div>
                                                                <div className="stat-content">
                                                                    <div className="text-left dib">
                                                                        <div className="stat-text">Blog</div>
                                                                        <div className="stat-heading">Post de Conteúdos</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <Link to={{ pathname: '/produtosSeguranca' }} >
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="stat-widget-five">
                                                                <div className="stat-icon dib flat-color-3">
                                                                    <i className="pe-7s-box2"></i>
                                                                </div>
                                                                <div className="stat-content">
                                                                    <div className="text-left dib">
                                                                        <div className="stat-text">Produtos</div>
                                                                        <div className="stat-heading">Produtos Segurança</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
