import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import axios from 'axios';
var XMLParser = require('react-xml-parser');

export default function CadastraSaida(props) {
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios'),
        gaiolas: firebase.firestore().collection('gaiolas'),
        produtos: firebase.firestore().collection('produtos').where('tipo', '==', 'SKU'),
        filiais: firebase.firestore().collection('filiais'),
        clientes: firebase.firestore().collection('clientes'),
        saidas: firebase.firestore().collection('saidas'),
    })
    const [numPedido, setNumPedido] = useState('')

    // Clientes
    const [clientes, setClientes] = useState([])
    const [cliente, setCliente] = useState({})

    // Promomtores
    const [promotor, setPromotor] = useState({})

    // Bases
    const [filiais, setFiliais] = useState([])
    const [filial, setFilial] = useState({})

    // Gaiolas
    const [gaiolas, setGaiolas] = useState([])
    const [qtd, setQtd] = useState('')

    // Produtos
    const [listaProdutos, setListaProdutos] = useState([])
    const [listaProdutosTemp, setListaProdutosTemp] = useState([])

    const [usuario, setUsuario] = useState({})

    // Modal
    const [produtoModal, setProdutoModal] = useState({})
    const [gaiolasModal, setGaiolasModal] = useState([])

    // Documento
    const [nfe, setNfe] = useState('')

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
            servicos.clientes.where('status', '==', 'ATIVO').get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setClientes(data)
            })
            servicos.filiais.where('status', '==', 'ATIVO').get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFiliais(data)
            })
            servicos.saidas.get().then((snap) => {
                setNumPedido(snap.size)
            })
        })
    }, [servicos.clientes, listaProdutos])



    const handleCliente = (event) => {
        event.persist();
        setCliente({})
        setPromotor({})
        setListaProdutos([])
        if (event.target.value) {
            setCliente(clientes[event.target.value])
            servicos.gaiolas.where('filial.id', '==', filial.id).where('cliente.id', '==', clientes[event.target.value].id).get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setGaiolas(data)
            })
        }

    }


    const fileHandler = (event) => {
        setQtd('')
        setPromotor({})
        setListaProdutos([])
        event.persist();
        mensagemLoading('Montando Documento')
        let files = event.target.files;
        const list = []
        for (let i = 0; i < files.length; i++) {
            axios.get(URL.createObjectURL(event.target.files[i]), {
                "Content-Type": "application/xml; charset=utf-8"
            }).then((response) => {
                var xml = new XMLParser().parseFromString(response.data);
                const listProdutos = []
                const nfe = xml.children[0].children[0].attributes.Id
                const dataSaida = xml.children[0].children[0].children[0].children.filter((d) => d.name === "dhSaiEnt")[0] ? xml.children[0].children[0].children[0].children.filter((d) => d.name === "dhSaiEnt")[0].value : null
                setNfe(nfe)
                
                // Destino
                const cpf = xml.children[0].children[0].children.filter((d) => d.name === "dest")[0].children[0].value
                servicos.clientes.doc(cliente.id).collection('promotores').where('cpf', '==', cpf).get().then((snap) => {
                    if (snap.size > 0) {
                        setPromotor(snap.docs.map((d) => d.data())[0])
                        xml.children[0].children[0].children.filter((d) => d.name === "det").forEach(element => {
                            const dados = {
                                codigoItem: element.children[0].children.filter((d) => d.name === "cProd")[0].value,
                                produto: element.children[0].children.filter((d) => d.name === "cProd") ? element.children[0].children.filter((d) => d.name === "xProd")[0].value : 'Sem informações',
                                qtd: element.children[0].children.filter((d) => d.name === "cProd") ? parseInt(element.children[0].children.filter((d) => d.name === "qCom")[0].value) : 0,
                                valorUnidade: element.children[0].children.filter((d) => d.name === "cProd") ? parseFloat(element.children[0].children.filter((d) => d.name === "vUnTrib")[0].value) : 0,
                                valorTotal: element.children[0].children.filter((d) => d.name === "cProd") ? parseFloat(element.children[0].children.filter((d) => d.name === "vProd")[0].value) : 0,
                                check: parseInt(2)
                            }
                            gaiolas.forEach((g) => {
                                servicos.gaiolas.doc(g.id).collection('produtos').where('codigoItem', '==', element.children[0].children.filter((d) => d.name === "cProd")[0].value).get().then((snap) => {
                                    if (snap.size > 0) {
                                        dados.check = parseInt(1)
                                        return
                                    }
                                })
                            })
                            setTimeout(() => {
                                listProdutos.push(
                                    ...listaProdutos,
                                    dados
                                )
                            }, 5000);


                        });
                        setTimeout(() => {
                            Swal.close()
                            setListaProdutos(listProdutos)
                            setListaProdutosTemp(listaProdutosTemp)
                        }, 20000);
                    } else {
                        mensagemAlerta('Nenhum promotor encontrado! Primeiro cadastre um promotor para seguir com a importação da saída')
                        return
                    }
                })
            });
        }
    }

    const handleFilial = (event) => {
        event.persist();
        setFilial({})
        if (event.target.value) {
            setFilial(filiais[event.target.value])
        }
    }

    function detalheProduto(item, index) {
        setProdutoModal({
            ...item,
            index: index
        })
        setGaiolasModal([])
        mensagemLoading('Carregando Gaiolas...')
        const list = []
        gaiolas.forEach((g) => {
            servicos.gaiolas.doc(g.id).collection('produtos').where('codigoItem', '==', item.codigoItem).get().then((snap) => {
                if (snap.size > 0) {
                    console.log('Existe')
                    list.push(
                        ...list,
                        {
                            gaiolaId: g.id,
                            gaiola: g.numero,
                            qtd: parseInt(snap.docs.map((d) => d.data())[0].qtd)
                        }
                    )
                } else {
                    console.log('Não existe')
                }
            })
        })
        setTimeout(() => {
            Swal.close()
            setGaiolasModal(list)
        }, 5000);
    }

    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value)
    }

    function atualizaLinha(item, index) {
        const list = []
        if (!qtd) {
            mensagemAlerta('Por favor digite a quantidade que sairá do estoque')
            return
        }
        gaiolasModal[index].qtdSaida = parseInt(qtd)
        listaProdutos[produtoModal.index].gaiolas = parseInt(qtd)
        listaProdutos[produtoModal.index].check = parseInt(2)
        listaProdutos.forEach((d) => {
            list.push(d)
        })
        setTimeout(() => {
            setListaProdutos([])
            setListaProdutos(list)
        }, 200);
    }

    function cadastraSaida() {
        if (!filial.id) {
            mensagemAlerta('Favor escolha a base')
            return
        }
        if (!cliente.id) {
            mensagemAlerta('Favor escolha o cliente')
            return
        }
        if (!promotor.id) {
            mensagemAlerta('Favor escolha o promotor')
            return
        }
        if (listaProdutos.length === 0) {
            mensagemAlerta('Favor escolha adicione um produto')
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja gerar a saída ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                // listaProdutos.forEach((d) => {
                //     if (d.check === 3) {
                //         mensagemAlerta('Existem inconsistencias nos dados da nota com o estoque atual!')
                //         return
                //     }
                // })
                mensagemLoading('Aguarde.. Gerando Saída')
                servicos.saidas.get().then((snap) => {
                    servicos.saidas.add({
                        filial: filial,
                        cliente: cliente,
                        destino: promotor,
                        usuario: {
                            id: usuario.id,
                            nome: usuario.nome,
                            email: usuario.email,
                            avatar: usuario.avatar
                        },
                        mes: moment().format('MM'),
                        ano: moment().format('YYYY'),
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                        pedido: parseInt(snap.size + 1),
                        status: 'GERADO'
                    }).then((docRef) => {
                        servicos.saidas.doc(docRef.id).update({
                            id: docRef.id
                        }).then(() => {
                            listaProdutos.forEach((doc) => {
                                servicos.saidas.doc(docRef.id).collection('produtos').add(doc).then((dR) => {
                                    servicos.saidas.doc(docRef.id).collection('produtos').doc(dR.id).update({
                                        id: dR.id
                                    })
                                })
                            })
                            setTimeout(() => {
                                mensagemSucesso('Saída gerada com sucesso!')
                            }, 10000);
                        }).catch(() => {
                            mensagemErro('Erro ao atualizar')
                        })
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar')
                    })
                })

            }
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados da Base</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' style={{ marginBottom: 20 }}>
                                                <div className='col-md-4' >
                                                    <label>Base</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFilial} >
                                                        <option value="">Selecione </option>
                                                        {filiais.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((c, index) =>
                                                            <option value={index}>{c.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            {filial.id ?
                                                <div className='row col-md-12' style={{ marginBottom: 20, marginTop: 20 }}>
                                                    <div className='col-md-4' >
                                                        <label>Nome</label>
                                                        <p><b>{filial.nome}</b></p>
                                                    </div>
                                                    <div className='col-md-2' >
                                                        <label>CNPJ</label>
                                                        <p><b>{filial.cnpj}</b></p>
                                                    </div>
                                                    <div className='col-md-4' >
                                                        <label>Resonsável</label>
                                                        <p><b>{filial.responsavel.nome}</b></p>
                                                    </div>
                                                    <div className='col-md-2' >
                                                        <label>Contato</label>
                                                        <p><b>{filial.contato}</b></p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados do Cliente</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' style={{ marginBottom: 20 }}>
                                                <div className='col-md-6' >
                                                    <label>Cliente</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleCliente} >
                                                        <option value="">Selecione </option>
                                                        {clientes.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((c, index) =>
                                                            <option value={index}>{c.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-4' style={{ marginTop: 20 }}>
                                                    <input type="file" accept=".xlsx, .xls, .xml" onChange={fileHandler} style={{ "padding": "10px" }} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {cliente && cliente.id && promotor && promotor.id ?
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Dados do Destino</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                {promotor.id ?
                                                    <div className='row col-md-12' style={{ marginBottom: 20, marginTop: 20 }}>
                                                        <div className='col-md-6' >
                                                            <label>Nome</label>
                                                            <p><b>{promotor.nome}</b></p>
                                                        </div>
                                                        <div className='col-md-3' >
                                                            <label>CPF</label>
                                                            <p><b>{promotor.cpf}</b></p>
                                                        </div>
                                                        <div className='col-md-3' >
                                                            <label>Contato</label>
                                                            <p><b>{promotor.contato}</b></p>
                                                        </div>
                                                        <div className='col-md-3' >
                                                            <label>Endereço</label>
                                                            <p><b>{promotor.endereco.endereco}</b></p>
                                                        </div>
                                                        <div className='col-md-3' >
                                                            <label>Bairro/N°</label>
                                                            <p><b>{promotor.endereco.bairro} - {promotor.endereco.numero}</b></p>
                                                        </div>
                                                        <div className='col-md-3' >
                                                            <label>Cidade</label>
                                                            <p><b>{promotor.endereco.cidade}</b></p>
                                                        </div>
                                                        <div className='col-md-2' >
                                                            <label>UF</label>
                                                            <p><b>{promotor.endereco.uf}</b></p>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {cliente && cliente.id && promotor && promotor.id ?
                                    <>
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="row m-0">
                                                        <div className='col-sm-10'>
                                                            <h4 className="box-title">Dados do Produto em gaiolas</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body--">
                                                    <div className="table-stats order-table ov-h">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th>Código</th>
                                                                    <th>Produto</th>
                                                                    <th>Qtd</th>
                                                                    <th>Valor Uni</th>
                                                                    <th>Valor Total</th>
                                                                    <th>Detalhe</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {listaProdutos.map((pr, index) =>
                                                                    <tr key={pr.codigoItem} >
                                                                        <td>{pr.codigoItem}</td>
                                                                        <td>{pr.produto}</td>
                                                                        <td>{pr.qtd}</td>
                                                                        <td>{pr.valorUnidade.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                        <td>{(parseFloat(pr.valorTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                        <td>
                                                                            {pr.check === 1 ?
                                                                                <button >
                                                                                    <span className="badge badge-success"><i className='fa fa-check'></i></span>
                                                                                </button>
                                                                                :
                                                                                <button >
                                                                                    <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                                </button>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                )}

                                                            </tbody>
                                                        </table>
                                                        {listaProdutos.length === 0 ?
                                                            <p style={{ textAlign: 'center' }} >Sem produtos cadastrados</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-2' style={{ marginTop: 30 }}>
                                                <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSaida()}>
                                                    Gerar Saída
                                                </button>
                                            </div>
                                        </div>
                                    </>

                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
