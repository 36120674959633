import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import firebase from 'firebase'

export default function Sidebar() {

    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [filial, setFilial] = useState({})

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    servicos.filiais.where('responsavel.id', '==', user.uid).get().then((snap) => {
                        if (snap.size > 0) {
                            const data = snap.docs.map((d) => d.data())[0]
                            setFilial(data);
                        }
                    })
                })
            }
        })
    }, [servicos.usuarios])
    return (
        <aside id="left-panel" className="left-panel">
            <nav className="navbar navbar-expand-sm navbar-default">
                {usuario.usuario === "MASTER" ?
                    <div id="main-menu" className="main-menu collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li>
                                <Link to="/administrador"><i className="menu-icon fa fa-dashboard"></i>Dashboard </Link>
                            </li>
                            <li className="menu-title">Serviços</li>
                            <li>
                                <Link to="/wikipedia"><i className="menu-icon fa fa-wikipedia-w"></i>Wiki  </Link>
                            </li>
                            <li>
                                <Link to="/sistemas"><i className="menu-icon fa fa-desktop"></i>Sistemas  </Link>
                            </li>
                            <li>
                                <Link to="/documentacao"><i className="menu-icon fa fa-file"></i>Documentação</Link>
                            </li>
                            <li className="menu-title">Administração</li>
                            <li>
                                <Link to="/setores"><i className="menu-icon fa fa-briefcase"></i>Setores </Link>
                            </li>
                            <li>
                                <Link to="/usuarios"><i className="menu-icon fa fa-users"></i>Usuários </Link>
                            </li>
                            <li>
                                <Link to="/agregados"><i className="menu-icon fa fa-truck"></i>Agregados  </Link>
                            </li>
                            <li>
                                <Link to="/filiais"><i className="menu-icon fa fa-home"></i>Filiais </Link>
                            </li>
                            <li>
                                <Link to="/motoristas"><i className="menu-icon fa fa-truck"></i>Motoristas </Link>
                            </li>
                        </ul>
                    </div>
                    :
                    <div id="main-menu" className="main-menu collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li>
                                <Link to="/comum"><i className="menu-icon fa fa-dashboard"></i>Dashboard </Link>
                            </li>
                            <li className="menu-title">Serviços</li>
                            <li>
                                <Link to="/wikipedia"><i className="menu-icon fa fa-wikipedia-w"></i>Wiki </Link>
                            </li>
                            <li>
                                <Link to="/leads"><i className="menu-icon fa fa-users"></i>Leads </Link>
                            </li>
                            <li>
                                <Link to="/chamados"><i className="menu-icon fa fa-desktop"></i>Chamados </Link>
                            </li>
                            {usuario.reembalados && usuario.reembalados === 1 ?
                                <li>
                                    <Link to="/reembalados"><i className="menu-icon fa fa-shopping-cart"></i>Reembalados  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.promocional && usuario.promocional === 1 ?
                                <li>
                                    <Link to="/logisticaPromocional"><i className="menu-icon fa fa-archive"></i>Reembalados  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.ocorrencia && usuario.ocorrencia === 1 ?
                                <li>
                                    <Link to="/ocorrencias"><i className="menu-icon fa fa-exclamation-triangle"></i>Ocorrências  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.cupons && usuario.cupons === 1 ?
                                <li>
                                    <Link to="/cupons"><i className="menu-icon fa fa-file-text-o"></i>Cupons  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.avarias && usuario.avarias === 1 ?
                                <li>
                                    <Link to="/avarias"><i className="menu-icon fa fa-cubes"></i>Avarias  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.notas && usuario.notas === 1 ?
                                <li>
                                    <Link to="/notasFiscais"><i className="menu-icon fa fa-files-o"></i>Notas Fiscais  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.blog && usuario.blog === 1 ?
                                <li>
                                    <Link to="/blogApp"><i className="menu-icon fa fa-newspaper-o"></i>Blog  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.produtos && usuario.produtos === 1 ?
                                <li>
                                    <Link to="/produtosSeguranca"><i className="menu-icon fa fa-shield"></i>Produtos Seguros  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.agregados && usuario.agregados === 1 ?
                                <li>
                                    <Link to="/agregados"><i className="menu-icon fa fa-truck"></i>Agregados  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.usuarios && usuario.usuarios === 1 ?
                                <li>
                                    <Link to="/usuarios"><i className="menu-icon fa fa-users"></i>Usuários  </Link>
                                </li>
                                :
                                null
                            }
                            {usuario.funil && usuario.funil === 1 ?
                                <li>
                                    <Link to="/funil"><i className="menu-icon fa fa-filter"></i>Funil  </Link>
                                </li>
                                :
                                null
                            }
                        </ul>
                    </div>
                }
            </nav>
        </aside>
    );
}
