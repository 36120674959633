import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco } from '../../../../../services/Localidades'
import moment from 'moment'
import 'suneditor/dist/css/suneditor.min.css';
import Swal from 'sweetalert2'

export default function CadastraPromotor(props) {
    const [dados] = useState(props.location.state ? props.location.state.promotor : null)
    const [dadosCliente] = useState(props.location.state ? props.location.state.cliente : null)
    const [servicos] = useState({
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})

    const [nome, setNome] = useState(dados ? dados.nome : '')
    const [cnpj, setCnpj] = useState(dados ? dados.cnpj : '')
    const [contato, setContato] = useState(dados ? dados.contato : '')
    const [cep, setCep] = useState(dados ? dados.endereco.cep : '')
    const [endereco, setEndereco] = useState(dados ? dados.endereco.endereco : '')
    const [bairro, setBairro] = useState(dados ? dados.endereco.bairro : '')
    const [numero, setNumero] = useState(dados ? dados.endereco.numero : '')
    const [complemento, setComplemento] = useState(dados ? dados.endereco.complemento : '')
    const [uf, setUf] = useState(dados ? dados.endereco.uf : '')
    const [cidade, setCidade] = useState(dados ? dados.endereco.cidade : '')
    const [tipo, setTipo] = useState(dados ? dados.tipo : '')
    const [status, setStatus] = useState(dados ? dados.status : '')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState(dados ? dados.avatar : '');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.clientes])

    function cadastraAvatar(id) {
        Swal.close();
        mensagemLoading('Cadastrando Imagem...')
        var uploadTask = firebase.storage().ref().child('promotores/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.clientes.doc(dadosCliente.id).collection('promotores').doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    mensagemSucesso('Promotor cadastrado com sucesso! Você será redirecionado a lista de promotores')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraCliente() {
        if (nome === '') {
            mensagemAlerta('Favor digitar o nome do Cliente')
        } else if (cnpj === '') {
            mensagemAlerta('Favor digitar o CNPJ')

        } else if (contato === '') {
            mensagemAlerta('Favor digitar o Contato com DDD + 9')

        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')

        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')

        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')

        } else if (status === '') {
            mensagemAlerta('Favor escolha o status')

        } else {
            if (dados) {
                mensagemLoading('Atualizando Cliente...')
                servicos.clientes.doc(dados.cliente.id).collection('promotores').doc(dados.id).update({
                    nome: nome,
                    contato: contato,
                    cpf: cnpj,
                    endereco: {
                        endereco: endereco,
                        bairro: bairro,
                        numero: numero,
                        complemento: complemento,
                        uf: uf,
                        cidade: cidade,
                        cep: cep
                    },
                    status: status,
                    dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
                }).then(() => {
                    if (upload) {
                        cadastraAvatar(dados.id)
                    } else {
                        mensagemSucesso('Promotor atualizado com sucesso!')
                    }
                }).catch(() => {
                    mensagemAlerta('Erro ao cadastrar o Promotor')
                })
            } else {
                mensagemLoading('Cadastrando Promotor...')
                servicos.clientes.doc(dadosCliente.id).collection('promotores').add({
                    nome: nome,
                    contato: contato,
                    cpf: cnpj,
                    endereco: {
                        endereco: endereco,
                        bairro: bairro,
                        numero: numero,
                        complemento: complemento,
                        uf: uf,
                        cidade: cidade,
                        cep: cep
                    },
                    cliente: {
                        id: dadosCliente.id,
                        nome: dadosCliente.nome
                    },
                    status: status,
                    dataCadastro: moment().format('DD/MM/YYYY HH:mm'),
                    dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
                }).then((docRef) => {
                    servicos.clientes.doc(dadosCliente.id).collection('promotores').doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        if (upload) {
                            cadastraAvatar(docRef.id)
                        } else {
                            mensagemSucesso('Promotor cadastrado com sucesso!')
                        }

                    }).catch(() => {
                        mensagemAlerta('Erro ao atualizar o cliente')
                    })
                }).catch(() => {
                    mensagemAlerta('Erro ao cadastrar o Promotor')
                })
            }

        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleCnpj = (event) => {
        event.persist();
        setCnpj(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep() {
        setLoadingCep(true)
        if (cep.length < 8) {
            setLoadingCep(false)
            alert('Favor digite o CEP para realizar a busca')
        } else {
            buscaEndereco(cep).then((doc) => {
                if (doc.erro === true) {
                    setLoadingCep(false)
                    alert('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                    setLoadingCep(false)
                }
            })
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Promotor</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        : avatar ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={avatar} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://www.pngitem.com/pimgs/m/315-3153392_transparent-cliente-png-png-download.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Promotor</label>
                                                    <p>Para mudar a foto do promotor é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>

                                                    {loadingImage ?
                                                        <div class="progress-box progress-1" style={{ marginTop: 10 }}>
                                                            <div class="por-txt">Carregando... ({progresso})</div>
                                                            <div class="progress mb-2" >
                                                                <div class="progress-bar bg-flat-color-1" role="progressbar" style={{ width: progresso }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                    }

                                                </div>
                                            </div>

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-6'>
                                                    <label>Nome do Prommotor</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>CNPJ</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cnpj} id="company" class="form-control" onChange={handleCnpj} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-2'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="number" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    {loadingCep ?
                                                        <button type="button" class="btn btn-info mb-1" >Buscando...</button>
                                                        :
                                                        <button type="button" onClick={() => buscaCep()} class="btn btn-success mb-1" >Buscar CEP</button>
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="number" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3' style={{ marginTop: 20 }}>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Selecione </option>
                                                        <option value="ATIVO" selected={status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {dados ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando Promotor ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Atualizar Promotor
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Promotor ...
                                                    </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Cadastra Promotor
                                                </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
