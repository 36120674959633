import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { verificaAutenticado } from '../services/Firebase'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { decode } from 'js-base64'
import moment from 'moment'

export default function Login() {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const [senhaAtual, setSenhaAtual] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');

    const [inputSenha, setInputSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usuarioNovo, setUsuarioNovo] = useState(false);

    const [usuario, setUsuario] = useState({});

    useEffect(() => {
        if (!usuarioNovo) {
            verificaAutenticado('login')
        }
    }, [usuarioNovo])

    function verificaEmail() {
        setUsuario({})
        mensagemLoading('Buscando Email...')
        setInputSenha(false)
        if (email === '') {
            mensagemAlerta('Email não pode ser vazio')
        } else {
            firebase.firestore().collection('usuarios')
                .where('email', '==', email)
                .get().then((snap) => {
                    if (snap.size > 0) {
                        Swal.close()
                        firebase.firestore().collection('usuarios')
                            .where('email', '==', email)
                            .where('status', '==', 0)
                            .get().then((snap) => {
                                if (snap.size > 0) {
                                    setUsuarioNovo(true)
                                    setUsuario(snap.docs.map((d) => d.data())[0])
                                } else {
                                    setInputSenha(true)
                                }
                            })
                    } else {
                        Swal.close()
                        mensagemAlerta('Email não consta em nosso sistema')
                    }
                })
        }
    }

    function realizaLogin() {
        if (email === '') {
            mensagemAlerta('Email não pode ser vazio')
        } else if (senha === '') {
            mensagemAlerta('Senha vazia')
        } else {
            mensagemLoading('Realizando login ...')
            firebase.auth().signInWithEmailAndPassword(email, senha).then((res) => {
                firebase.firestore().collection('usuarios').doc(res.user.uid).get().then((snap) => {
                    if (snap.data().usuario !== 'MASTER' && snap.data().usuario !== 'COMUM') {
                        Swal.close();
                        mensagemAlerta('Usuário não autorizado para este ambiente.')
                        firebase.auth().signOut();
                    } else if (snap.data().usuario === 'MASTER') {
                        window.location = '/administrador';
                    } else {
                        window.location = '/comum';
                    }
                })
            }).catch((error) => {
                var errorCode = error.code;
                if (error) {
                    if (errorCode === 'auth/invalid-email') {
                        Swal.close()
                        mensagemAlerta('Email incompleto ou não é válido')
                    } else if (errorCode === 'auth/user-not-found') {
                        Swal.close()
                        mensagemAlerta('Email não cadastrado no Entrega Já! Cadastre-se ou verifique novamente')
                    } else if (errorCode === 'auth/wrong-password') {
                        Swal.close()
                        mensagemAlerta('Senha Incorreta')
                    }
                }
            });
        }

    }

    function cadastraUsuario() {
        if (email === '') {
            mensagemAlerta('Email não pode ser vazio')
        } else if (email + '' + senhaAtual !== decode(usuario.pass)) {
            mensagemAlerta('Senha atual inválida')
        } else if (novaSenha === '') {
            mensagemAlerta('Por favor digite a nova senha')
        } else if (confirmaSenha === '') {
            mensagemAlerta('Por favor digite connfirme a nova senha')
        } else if (novaSenha !== confirmaSenha) {
            mensagemAlerta('A nova senha não confere com o campo de confirmação de senha')
        } else {
            firebase.auth().createUserWithEmailAndPassword(email, confirmaSenha)
                .then(() => {
                    firebase.firestore().collection('usuarios').doc(usuario.id).delete().then(() => {
                        mensagemLoading("Aguarde.. Configurando seu Perfil")
                        var usuarioId = firebase.auth().currentUser;
                        firebase.firestore().collection('usuarios').doc(usuarioId.uid).set({
                            id: usuarioId.uid,
                            avatar: usuario.avatar ? usuario.avatar : null,
                            avatarId: usuario.avatar ? usuario.id : null,
                            nome: usuario.nome,
                            sobrenome: usuario.sobrenome,
                            contato: usuario.contato,
                            cpf: usuario.cpf,
                            endereco: usuario.endereco,
                            status: 'ATIVO',
                            email: usuario.email,
                            usuario: usuario.usuario,
                            cargo: usuario.cargo,
                            setor: usuario.setor,
                            admin: usuario.admin,
                            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                            acesso:moment().format('YYYY-MM-DD HH:mm')
                        }).then(() => {
                            if (usuario.usuario === 'MASTER') {
                                window.location = '/administrador';
                            } else if (usuario.usuario === 'COMUM') {
                                window.location = '/comum';
                            }
                        }).catch((erro) => {
                            mensagemErro("Erro ao cadastrar Usuario")
                        })
                    })

                }).catch((erro) => {
                    console.log(erro);
                    var errorCode = erro.code;
                    if (errorCode == "auth/invalid-email") {
                        mensagemAlerta("O formato do Email é inválido!")
                    } else if (errorCode == "auth/email-already-in-use") {
                        mensagemAlerta("O email já está em uso pelo Entrega Já")
                    } else if (errorCode == "auth/weak-password") {
                        mensagemAlerta("Escolha uma senha mais forte!")
                    }
                })
        }
    }

    const handleInputEmail = (event) => {
        event.persist();
        setUsuario({})
        setUsuarioNovo(false)
        setInputSenha(false)
        setEmail(event.target.value);
    }
    const handleInputSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleSenhaAtual = (event) => {
        event.persist();
        setSenhaAtual(event.target.value);
    }
    const handleNovaSenha = (event) => {
        event.persist();
        setNovaSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="login100-more" >
                    <img src='/images/background.png' alt="" />
                </div>
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <span className="login100-form-title p-b-19">Login</span>
                    <span className="login90-form-title" >Acesso ao administrador interno do Entrega Já</span>

                    <div className="wrap-input100 validate-input" data-validate="Name is required" style={{ marginTop: 20 }} >
                        <input className="input100" value={email} autoCapitalize="none" onChange={handleInputEmail} type="text" name="name" placeholder="Email" />
                        <span className="focus-input100"></span>
                    </div>
                    {inputSenha ?
                        <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input className="input100" value={senha} autoCapitalize="none" onChange={handleInputSenha} type="password" name="email" placeholder="Senha" />
                            <span className="focus-input100"></span>
                        </div>
                        :
                        null
                    }
                    {usuarioNovo ?
                        <>
                            <p>Bem vindo, <b>{usuario.nome}</b>. Confirme os dados abaixo para finalizar a configuração da sua conta</p>
                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                <input className="input100" value={senhaAtual} autoCapitalize="none" onChange={handleSenhaAtual} type="password" name="email" placeholder="Senha Atual" />
                                <span className="focus-input100"></span>
                            </div>
                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                <input className="input100" value={novaSenha} autoCapitalize="none" onChange={handleNovaSenha} type="password" name="email" placeholder="Nova Senha" />
                                <span className="focus-input100"></span>
                            </div>
                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                <input className="input100" value={confirmaSenha} autoCapitalize="none" onChange={handleConfirmaSenha} type="password" name="email" placeholder="Confirma Nova senha" />
                                <span className="focus-input100"></span>
                            </div>
                        </>

                        :
                        null
                    }
                    <div className="container-login100-form-btn">
                        <div className="wrap-login100-form-btn">
                            <div className="login100-form-bgbtn"></div>
                            {loading ?
                                <button className="login100-form-btn" style={{ backgroundColor: 'red' }} >Aguarde...</button>
                                :
                                <>
                                    {email && inputSenha ?
                                        <button className="login100-form-btn" style={{ backgroundColor: 'red' }} onClick={() => realizaLogin()}>Login</button>
                                        : usuarioNovo ?
                                            <button className="login100-form-btn" style={{ backgroundColor: 'red' }} onClick={() => cadastraUsuario()}>Cadastrar Senha</button>
                                            :
                                            <button className="login100-form-btn" style={{ backgroundColor: 'red' }} onClick={() => verificaEmail()}>Verifica Email</button>

                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className='row col-md-12' style={{ marginTop: 100 }}>
                        <img src='/images/entrega.png' alt="" />
                    </div>
                </div>
            </div>
        </div>

    );
}
