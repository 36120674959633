import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'


export default function DetalheProduto(props) {
    const [dado] = useState(props.location.state.catalogo);
    const [qtd, setQtd] = useState(0)
    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [contato, setContato] = useState('')
    const [funcao, setFuncao] = useState('')
    const [pagamento, setPagamento] = useState('')
    const [parcelamento, setParcelamento] = useState('')

    const [servicos] = useState({
        auth: firebase.auth(),
        reembalados: firebase.firestore().collection('reembalados'),
        vendas: firebase.firestore().collection('vendas'),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    function cadastraProduto() {
        if (!nome) {
            mensagemAlerta('Favor digitar o nome do Cliente')
        } else if (!contato) {
            mensagemAlerta('Favor digitar o Contato')
        } else if (!cpf) {
            mensagemAlerta('Favor digitar o CPF')
        } else if (!funcao) {
            mensagemAlerta('Favor digitar a função')
        } else if (!pagamento) {
            mensagemAlerta('Escolha a forma de pagamento')
        } else if (!qtd || qtd > dado.qtd) {
            mensagemAlerta('Quantidade vazia ou maior que a quantidade geral do estoque')
        } else {
            if (pagamento !== 'vista') {
                if (!parcelamento) {
                    mensagemAlerta('Escolha em quantas parcelas deseja pagar o produto')
                } else {
                    mensagemLoading('Aguarde enviando solicitação...')
                    servicos.vendas.add({
                        cliente: {
                            nome: nome,
                            contato: contato,
                            cpf: cpf,
                            funcao: funcao,
                        },
                        pagamento: pagamento,
                        parcelamento: parcelamento,
                        filial: dado.filial,
                        qtd:parseInt(qtd),
                        produto: {
                            id: dado.id,
                            descricao: dado.descricao,
                        },
                        valor: dado.valor,
                        status: 'ABERTO',
                        tipo: 'REEMBALADO',
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                    }).then((docRef) => {
                        servicos.vendas.doc(docRef.id).update({
                            id: docRef.id
                        }).then(() => {
                            Swal.close();
                            mensagemSucesso('Solicitação de compra enviado com sucesso para administração. Aguarde o retorno de nossos analistas.')
                        }).catch(() => {
                            Swal.close();
                            mensagemSucesso('Erro ao atualizar o produto')
                        })
                    }).catch(() => {
                        mensagemSucesso('Erro ao cadastrar o produto')
                    })
                }
            } else {
                mensagemLoading('Aguarde enviando solicitação...')
                servicos.vendas.add({
                    cliente: {
                        nome: nome,
                        contato: contato,
                        cpf: cpf,
                        funcao: funcao,
                    },
                    pagamento: pagamento,
                    parcelamento: 0,
                    filial: dado.filial,
                    qtd:parseInt(qtd),
                    produto: {
                        id: dado.id,
                        descricao: dado.descricao,
                        avatar: dado.avatar
                    },
                    valor: dado.valor,
                    status: 'ABERTO'
                }).then((docRef) => {
                    servicos.vendas.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        Swal.close();
                        mensagemSucesso('Solicitação de compra enviado com sucesso para administração. Aguarde o retorno de nossos analistas.')
                    }).catch(() => {
                        Swal.close();
                        mensagemSucesso('Erro ao atualizar o produto')
                    })
                }).catch(() => {
                    mensagemSucesso('Erro ao cadastrar o produto')
                })
            }
        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleFuncao = (event) => {
        event.persist();
        setFuncao(event.target.value);
    }
    const handlePagamento = (event) => {
        event.persist();
        setPagamento(event.target.value);
    }
    const handleParcelamento = (event) => {
        event.persist();
        setParcelamento(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/reembalados'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div class="row m-0">
                                            <div className="col-xl-12">
                                                <div style={{ marginTop: 20 }}>
                                                    <h1 >{dado.descricao}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginTop: 20 }}>
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={dado.avatar} alt='carregando...' />
                                                </div>
                                                <div className='col-md-8'>
                                                    <div class="row m-0">
                                                        <div className="col-xl-3">
                                                            <div style={{ marginTop: 20 }}>
                                                                <h4 className="box-title">Cód Produto</h4>
                                                            </div>
                                                            <div >
                                                                <h4 >{dado.codigoItem}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <div style={{ marginTop: 20 }}>
                                                                <h4 className="box-title">Qtd</h4>
                                                            </div>
                                                            <div >
                                                                <h4 >{dado.qtd}</h4>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="row m-0">
                                                        <div className="col-xl-6">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Valor Unitário</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{dado.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Valor 10% desconto</h4>
                                                            </div>
                                                            <div >
                                                                <h2 >{(dado.valor - (dado.valor * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row m-0">
                                                        <div className="col-xl-12">
                                                            <div style={{ marginTop: 40 }}>
                                                                <h4 className="box-title">Observações</h4>
                                                            </div>
                                                            <div >
                                                                {dado.observacao ?
                                                                    <h2 >{dado.observacao}</h2>
                                                                    :
                                                                    <h2 >Sem observações</h2>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagem da Avaria e envio de solicitação</h4>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={dado.avaria} alt='carregando...' />
                                                </div>
                                                <div className="row col-md-8">
                                                    <div className='col-md-8'>
                                                        <label>Nome </label>
                                                        <div class="form-group">
                                                            <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Contato</label>
                                                        <div class="form-group">
                                                            <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>CPF </label>
                                                        <div class="form-group">
                                                            <input type="text" value={cpf} id="company" class="form-control" onChange={handleCpf} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Função</label>
                                                        <div class="form-group">
                                                            <input type="text" value={funcao} id="company" class="form-control" onChange={handleFuncao} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Forma Pagamento</label>
                                                        <select name="select" id="select" class="form-control" onChange={handlePagamento}>
                                                            <option value="">Escolha  </option>
                                                            <option value="vista">A vista  </option>
                                                            <option value="cartao">Cartão </option>
                                                            <option value="funcionario">Funcionário da Casa </option>
                                                        </select>
                                                    </div>
                                                    {pagamento && pagamento !== 'vista' ?
                                                        <div className='col-md-4'>
                                                            <label>Parcelamento</label>
                                                            <select name="select" id="select" class="form-control" onChange={handleParcelamento}>
                                                                <option value="">Escolha  </option>
                                                                <option value="1">1x  </option>
                                                                <option value="2">2x </option>
                                                                <option value="3">3x </option>
                                                                <option value="4">4x </option>
                                                                <option value="5">5x </option>
                                                                <option value="6">6x </option>
                                                                <option value="7">7x </option>
                                                                <option value="8">8x </option>
                                                                <option value="9">9x </option>
                                                                <option value="10">10x </option>
                                                            </select>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className='col-md-2'>
                                                        <label>Qtd</label>
                                                        <div class="form-group">
                                                            <input type="number" value={qtd} id="company" class="form-control" onChange={handleQtd} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2' style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraProduto()}>
                                                            Enviar Solicitação
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
