import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Ocorrencias() {

    const [ocorrencias, setOcorrencias] = useState([])
    const [ocorrenciasTemp, setOcorrenciasTemp] = useState([])
    const [servicos] = useState({
        ocorrencias: firebase.firestore().collection('ocorrencias')
    })

    useEffect(() => {
        servicos.ocorrencias.where('status', '==', 'ABERTO').onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            console.log(snap);
            setOcorrencias(data)
            setOcorrenciasTemp(data)
        })
    }, [servicos.ocorrencias])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando ocorrências')
            servicos.ocorrencias.where('status', '==', event.target.value).onSnapshot((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())
                setOcorrencias(data)
                setOcorrenciasTemp(data)
            })
        } else {
            setOcorrencias(ocorrenciasTemp);
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-3">
                                                    <i className="pe-7s-clock"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">Abertas</div>
                                                        <div className="stat-heading">{ocorrencias.length} Ocorrências</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-4">
                                                    <i className="pe-7s-attention"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">Urgentes</div>
                                                        <div className="stat-heading">{ocorrencias.filter((d) => d.nivel > 30).length} Ocorrências</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-3">
                                                    <i className="pe-7s-info"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">Moderadas</div>
                                                        <div className="stat-heading">{ocorrencias.filter((d) => d.nivel < 30).length} Ocorrências</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Ocorrências</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastroOcorrencia' }} >
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Nova Ocorrência
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="ABERTO">Abertas </option>
                                                        <option value="FINALIZADO">Finalizadas </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Agente</th>
                                                            <th>Protocolo</th>
                                                            <th>Ocorrência</th>
                                                            <th>Favorecido</th>
                                                            <th>Atualizado</th>
                                                            <th>Nível</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ocorrencias.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((ocorrencia) =>
                                                            <tr key={ocorrencia.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={ocorrencia.usuario.avatar ? ocorrencia.usuario.avatar : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png"} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{ocorrencia.protocolo}</td>
                                                                <td>{ocorrencia.ocorrencia}</td>
                                                                <td>{ocorrencia.favorecido.nome.substr(0, 20)}...</td>
                                                                <td>{moment(ocorrencia.dataAtualizacao).format('DD/MM/YYYY HH:mm')}</td>
                                                                {ocorrencia.nivel > 30 ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-danger">URGENTE</span>
                                                                        </button>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-warning">MODERADA</span>
                                                                        </button>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/ocorrencia', state: { ocorrencia: ocorrencia } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {ocorrencias.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem ocorrências cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
