import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import firebase from 'firebase'
import { logout, verificaAutenticado } from '../../services/Firebase'

export default function Header() {
    const [servico] = useState({
        usuarios: firebase.firestore().collection("usuarios")
    })
    const [usuario, setUsuario] = useState({});

    useEffect(() => {
        verificaAutenticado('header')
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servico.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
            }
        })
    }, []);

    function sair() {
        logout();
    }

    return (
        <header id="header" className="header">
            <div className="top-left">
                <div className="navbar-header">
                    <Link className="navbar-brand" to="/">{usuario.nome}</Link>
                    <a href="#" id="menuToggle" className="menutoggle"><i className="fa fa-bars"></i></a>
                </div>
            </div>
            <div className="top-right">
                <div className="header-menu">

                    <div className="user-area dropdown float-right">
                        <a href="#" className="dropdown-toggle active" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {usuario.avatar ?
                                <img className="user-avatar rounded-circle" src={usuario.avatar} alt="" />
                                :
                                <img className="user-avatar rounded-circle" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQML-WJr0Ark5Nx7yrXh8r_tBjdzbmolEBfVQ&usqp=CAU" alt="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQML-WJr0Ark5Nx7yrXh8r_tBjdzbmolEBfVQ&usqp=CAU" />
                            }
                        </a>

                        <div className="user-menu dropdown-menu">
                            <Link className="nav-link" to="javascript:void(0)" onClick={sair} ><i className="fa fa-user"></i>Meus Dados</Link>
                            <Link className="nav-link" to="javascript:void(0)" onClick={sair} ><i className="fa fa-power-off"></i>Sair</Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
