import React, { useEffect, useState } from 'react';
import Sidebar from './componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function ProdutosExterno() {
    const [catalogo, setCatlogo] = useState([])
    const [servicos] = useState({
        reembalados: firebase.firestore().collection('reembalados'),
    })

    useEffect(() => {
        servicos.reembalados.where('qtd', '>', 0).get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setCatlogo(data)
        })
    }, [])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Catálogo</h4>
                                                </div>
                                                <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                                    {catalogo.map((catalogo) =>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                                                                        <img class="card-img-top" style={{ height: 150, width: 150 }} src={catalogo.avatar} alt='carregando...' />
                                                                    </div>
                                                                    <div style={{ flexDirection: "column", alignItems: "center", textAlign: "center", marginTop: 20 }}>
                                                                        <h4 ><b>{catalogo.descricao.substr(0,19)}...</b></h4>
                                                                    </div>
                                                                    <Link to={{ pathname: '/detalheProdutoExterno', state: { produto: catalogo } }} type="button" class="btn btn-info mb-1" style={{ marginTop: 20 }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i> Ver mais detalhe do produto</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
