import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Filiais() {

    const [filiais, setFiliais] = useState([])
    const [servicos] = useState({
        filiais: firebase.firestore().collection('filiais')
    })

    useEffect(() => {
        servicos.filiais.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setFiliais(data)
        })
    }, [servicos.filiais])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Filiais</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastroFilial' }} >
                                                        <button style={{ marginLeft: 10 }} type="button" class="btn btn-success mb-1">
                                                            Nova Filial
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Nome</th>
                                                            <th>Contato</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                            <th>Responsável</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filiais.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((filial) =>
                                                            <tr key={filial.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={filial.avatar ? filial.avatar : "https://conquiste.com.br/img/industria1.png"} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{filial.nome}</td>
                                                                <td>{filial.contato}</td>
                                                                <td>{filial.endereco ? filial.endereco.cidade : null}</td>
                                                                <td>{filial.endereco ? filial.endereco.uf : null}</td>
                                                                <td>{filial.responsavel.nome}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/cadastroFilial', state: { filial: filial } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {filiais.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem filiais cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
