import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Documentos(props) {
    const [dados, setDados] = useState(props.location.state ? props.location.state.produto : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        documentacao: firebase.firestore().collection('documentacao'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [id, setId] = useState('')
    const [nome, setNome] = useState(dados ? dados.nome : '')
    const [setor, setSetor] = useState(dados ? dados.setor : '')
    const [senha, setSenha] = useState(dados ? dados.senha : '')
    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [status, setStatus] = useState(dados ? dados.status : '')

    const [nomeArquivo, setNomeArquivo] = useState('')
    const [tipoArquivo, setTipoArquivo] = useState('')
    var metadata = { contentType: 'image/jpeg' };
    const [upload, setUpload] = useState('');
    const [arquivo, setArquivo] = useState(dados ? dados.imagem : '');
    const [arquivos, setArquivos] = useState([])


    const [usuario, setUsuario] = useState({})
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                if (dados) {
                    servicos.documentacao.doc(dados.id).collection('documentos').onSnapshot((res) => {
                        const data = res.docs.map((d) => d.data())
                        setArquivos(data)
                    })
                }
            }
        })
    }, [servicos.usuarios])

    function cadastraDocumento(idDocumento, id) {
        mensagemLoading('Salvando a Documento...')
        var uploadTask = firebase.storage().ref().child('documentacao/' + idDocumento + '/' + nomeArquivo + '.' + tipoArquivo).put(arquivo[0], { contentType: arquivo[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.documentacao.doc(idDocumento).collection('documentos').doc(id).update({
                    arquivo: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Documento cadastrado com sucesso')
                })
            });
        });
    }

    function cadastraArquivo() {
        if (!nomeArquivo) {
            mensagemAlerta('Por favor digite o nome do arquivo')
            return
        }
        if (!tipoArquivo) {
            mensagemAlerta('Por favor escolha o tipo de arquivo')
            return
        }
        if (!arquivo) {
            mensagemAlerta('Por favor escolha o arquivo')
            return
        }
        mensagemLoading('Cadastrando Arquivo...')
        if (id) {
            servicos.documentacao.doc(dados.id).collection('documentos').add({
                nome: nomeArquivo,
                tipoArquivo: tipoArquivo,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRes) => {
                servicos.documentacao.doc(dados.id).collection('documentos').doc(docRes.id).update({
                    id: docRes.id
                }).then(() => {
                    cadastraDocumento(id, docRes.id)
                })
            })
        } else {
            servicos.documentacao.doc(dados.id).collection('documentos').add({
                nome: nomeArquivo,
                tipoArquivo: tipoArquivo,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRes) => {
                servicos.documentacao.doc(dados.id).collection('documentos').doc(docRes.id).update({
                    id: docRes.id
                }).then(() => {
                    cadastraDocumento(dados.id, docRes.id)
                })
            })
        }
    }

    function cadastraSistema() {
        if (!nome) {
            mensagemAlerta('Por favor digite o nome do grupo de Documentos')
            return
        }
        if (!setor) {
            mensagemAlerta('Escolha o setor que pertence os documentos')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite uma senha para os documentos')
            return
        }
        if (!descricao) {
            mensagemAlerta('Digite a desecrição para os documentos')
            return
        }
        if (!status) {
            mensagemAlerta('Diga em qual estatus está o grupo de documentação')
            return
        }
        if (dados) {
            mensagemLoading('Atualizando Documentação...')
            servicos.documentacao.doc(dados.id).update({
                nome: nome,
                setor: setor,
                senha: senha,
                descricao: descricao,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRef) => {
                Swal.close();

                mensagemSucesso('Grupo de documento atualizado com sucesso')
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o documento ' + erro)
            })
        } else {
            mensagemLoading('Cadastrando Documentação...')
            servicos.documentacao.add({
                nome: nome,
                setor: setor,
                senha: senha,
                descricao: descricao,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                usuario: {
                    nome: usuario.nome,
                    email: usuario.email,
                    id: usuario.id
                }
            }).then((docRef) => {
                servicos.documentacao.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    Swal.close();
                    setId(docRef.id)
                    mensagemSucesso('Grupo de documento cadastrado com sucesso! Agora cadastre os documentos que fazem parte do grupo')
                }).catch((err) => {
                    mensagemErro('Erro ao atualizar o documento ' + err)
                })
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o documento ' + erro)
            })
        }

    }

    function removeImagem(img) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remoover o arquivo ' + img.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Removendo imagem ...')
                firebase.storage().ref().child('documentacao/' + dados.id + '/' + img.nome + '.' + img.tipoArquivo).delete().then(() => {
                    servicos.documentacao.doc(dados.id).collection('documentos').doc(img.id).delete().then(() => {
                        mensagemSucesso('Arquivo removido com sucesso!')
                    })
                })
            }
        });
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Arquivos

    const handleNomeArquivo = (event) => {
        event.persist();
        setNomeArquivo(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        setTipoArquivo(event.target.value);
    }
    const handleArquivo = (event) => {
        event.persist();
        setArquivo(event.target.files)
        console.log(event.target.files[0])
        setUpload(URL.createObjectURL(event.target.files[0]))
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados do Documento</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 10 }}>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-6'>
                                                    <label>Nome do Grupo de documentos</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Setor</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleSetor} >
                                                        <option value="">Escolha o setor </option>
                                                        <option value="DIRETORIA" selected={dados && dados.setor === "DIRETORIA" ? "selected" : null}>Diretoria </option>
                                                        <option value="CONTROLADORIA" selected={dados && dados.setor === "CONTROLADORIA" ? "selected" : null}>Controladoria </option>
                                                        <option value="CONTROLE" selected={dados && dados.setor === "CONTROLE" ? "selected" : null}>Torre de Controle </option>
                                                        <option value="LOGISTICA" selected={dados && dados.setor === "LOGISTICA" ? "selected" : null}>Logística </option>
                                                        <option value="LABS" selected={dados && dados.setor === "LABS" ? "selected" : null}>Labs </option>
                                                        <option value="MOTORISTA" selected={dados && dados.setor === "MOTORISTA" ? "selected" : null}>Motoristas </option>
                                                        <option value="MARKETING" selected={dados && dados.setor === "MARKETING" ? "selected" : null}>Marketing </option>
                                                        <option value="COMERCIAL" selected={dados && dados.setor === "COMERCIAL" ? "selected" : null}>Comercial </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Senha do documento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={senha} id="company" class="form-control" onChange={handleSenha} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-12'>
                                                    <label>Descrição do Documento</label>
                                                    <div class="form-group">
                                                        <textarea value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Status </label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha </option>
                                                        <option value="ATIVO" selected={dados && dados.status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        {dados ?
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Atualizando Arquivos ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                        Atualizar Arquivos
                                                    </button>
                                                }
                                            </div>
                                            :
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Cadastrando Arquivos ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                        Cadastrar Arquivos
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {id || dados.id ?
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Lista de Documentos</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                    <div className='col-md-5'>
                                                        <label>Nome</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nomeArquivo} id="company" class="form-control" onChange={handleNomeArquivo} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>Tipo</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleTipo} >
                                                            <option value="">Escolha o arquivo </option>
                                                            <option value="xlsx" >Excel </option>
                                                            <option value="pdf" >PDF </option>
                                                            <option value="docx" >Word </option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <label>Selecione o arquivo</label>
                                                        <input onChange={handleArquivo} accept={'.' + tipoArquivo} type="file" name="name" placeholder="Escolha a imagem " />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraArquivo()}>
                                                            Adicionar
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body--">
                                                    <div className="table-stats order-table ov-h">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th>Nome</th>
                                                                    <th>Tipo</th>
                                                                    <th>Detalhe</th>
                                                                    <th>Deletar</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {arquivos.sort((a, b) => {
                                                                    return b.nivel - a.nivel;
                                                                }).map((produto) =>
                                                                    <tr key={produto.id} >
                                                                        <td>{produto.nome}</td>
                                                                        <td>{produto.tipoArquivo}</td>
                                                                        <td>
                                                                            <a href={produto.arquivo} >
                                                                                <span className="badge badge-info">
                                                                                    {produto.tipoArquivo === 'xlsx' ?
                                                                                        <i className='fa fa-file-excel-o'></i>
                                                                                        : produto.tipoArquivo === 'pdf' ?
                                                                                            <i className='fa fa-file-pdf-o'></i>
                                                                                            :
                                                                                            <i className='fa fa-file-word-o'></i>
                                                                                    }

                                                                                </span>
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <button onClick={() => removeImagem(produto)} >
                                                                                <span className="badge badge-danger">
                                                                                    <i className='fa fa-trash'></i>
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        {arquivos.length === 0 ?
                                                            <p style={{ textAlign: 'center' }} >Sem arquivos cadastrados</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
