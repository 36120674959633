import React, { useEffect, useState } from 'react';
import Sidebar from '../../../componentes/Sidebar'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2';
import validator from 'cpf-cnpj-validator';

export default function DetalhePagamento(props) {
    const [dado] = useState(props.location.state.pagamento);
    const [historico, setHistorico] = useState([])
    const [observacao, setObservacao] = useState('')

    const [servicos] = useState({
        auth: firebase.auth(),
        pagamento: firebase.firestore().collection('pagamentos'),
        mail: firebase.firestore().collection('mail'),
        historicos: firebase.firestore().collection('pagamentos').doc(dado.id).collection('historico'),
    })

    useEffect(() => {
        servicos.historicos.get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setHistorico(data)
        })
    }, [])

    const handleObservacao = (event) => {
        event.persist();
        setObservacao(event.target.value);
    }

    function validaPagamento() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja validar a Nota Fiscal do Parceiro ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Validando Nota Fiscal ...')
                servicos.historicos.add({
                    acao: 'Validação da Nota',
                    status: 'VALIDADO',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                }).then((docRef) => {
                    servicos.historicos.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        servicos.pagamento.doc(dado.id).update({
                            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                            status: 'VALIDADO',
                        }).then(() => {
                            const corpo = {
                                to: dado.email,
                                from: 'controladoria@entregaja.com.br',
                                message: {
                                    subject: 'Validação da Nota Fiscal',
                                    html: '<html>' +
                                        '<body>' +
                                        '<h1><b>' + dado.nome + ', </b></h1>' +
                                        '<h2><b>Sua Nota foi validada com sucesso!</b></h2>' +
                                        '<h3>A partir de agora, o nosso financeiro realizará o pagamento direto em sua conta.</h3>' +
                                        '<h3>Para mais detalhes entre em contato</h3>' +
                                        '<h4><b>Detalhe do Contato<b/></h4>' +
                                        '<h4>Whatsapp: (98)98412-7047</h4>' +
                                        '<h4>Email: controladoria@entregaja.com.br</h4>' +
                                        '<p><b>Equipe Entrega Ja<b/></p>' +
                                        '</body>' +
                                        '</html>'
                                }
                            }
                            servicos.mail.add(corpo).then(() => {
                                mensagemSucesso('Nota validada com sucesso!')
                            })

                        })
                    })
                }).catch(() => {
                    mensagemErro('Não foi possível aceitar o cadastro')
                })
            }
        });
    }

    function naoAtivaPagamento() {
        if (!observacao) {
            mensagemAlerta('No ato da não validação da Nota Fiscal, é importante enviar a observação ao parceiro.')
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja invalidar a Nota Fiscal do Parceiro ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Validando Nota Fiscal ...')
                servicos.historicos.add({
                    acao: 'Nota Fiscal não validada',
                    status: 'CANCELADO',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                }).then((docRef) => {
                    servicos.historicos.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        servicos.pagamento.doc(dado.id).update({
                            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                            status: 'CANCELADO',
                        }).then(() => {
                            const corpo = {
                                to: dado.email,
                                from: 'controladoria@entregaja.com.br',
                                message: {
                                    subject: 'Validação da Nota Fiscal',
                                    html: '<html>' +
                                        '<body>' +
                                        '<h1><b>' + dado.nome + ', </b></h1>' +
                                        '<h2><b>Sua Nota nào foi validada!</b></h2>' +
                                        '<h3>Verifique abaixo o motivo da não validação</h3>' +
                                        '<h3>' + observacao + '</h3>' +
                                        '<h3>Para mais detalhes entre em contato</h3>' +
                                        '<h4><b>Detalhe do Contato<b/></h4>' +
                                        '<h4>Whatsapp: (98)98412-7047</h4>' +
                                        '<h4>Email: controladoria@entregaja.com.br</h4>' +
                                        '<p><b>Equipe Entrega Ja<b/></p>' +
                                        '</body>' +
                                        '</html>'
                                }
                            }
                            servicos.mail.add(corpo).then(() => {
                                mensagemSucesso('Nota atualizada com sucesso!')
                            })
                        })
                    })
                }).catch(() => {
                    mensagemErro('Não foi possível aceitar o cadastro')
                })
            }
        });
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados Pessoais</h4>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className="col-xl-6">
                                                    <p className="box-title">Nome</p>
                                                    <p >{dado.nome}</p>
                                                </div>
                                                <div className="col-xl-3">
                                                    <p className="box-title">Tipo </p>
                                                    <p>{dado.tipoDocumento}</p>
                                                </div>
                                                <div className="col-xl-3">
                                                    <p className="box-title">Documento </p>
                                                    <p>{dado.documento}</p>
                                                </div>
                                                <div className="col-xl-4" style={{ marginTop: 20 }}>
                                                    <p className="box-title">Email </p>
                                                    <p>{dado.email}</p>
                                                </div>
                                                <div className="col-xl-2" style={{ marginTop: 20 }}>
                                                    <p className="box-title">Status </p>
                                                    <p>{dado.status}</p>
                                                </div>
                                                <div className="col-xl-2" style={{ marginTop: 20 }}>
                                                    <p className="box-title">Enviado </p>
                                                    <p>{moment(dado.dataCadastro).format('DD/MM/YYYY - HH:mm')}</p>
                                                </div>
                                                <div className="col-xl-2" style={{ marginTop: 20 }}>
                                                    <p className="box-title">Atualizado </p>
                                                    <p>{moment(dado.dataAtualizacao).format('DD/MM/YYYY - HH:mm')}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados do documento</h4>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div className="col-xl-2">
                                                <p className="box-title">Período</p>
                                                {dado.mes === '01' ?
                                                    dado.periodo + 'º / Janeiro'
                                                    : dado.mes === '02' ?
                                                        dado.periodo + 'º / Fevereiro'
                                                        : dado.mes === '03' ?
                                                            dado.periodo + 'º / Março'
                                                            : dado.mes === '04' ?
                                                                dado.periodo + 'º / Abril'
                                                                : dado.mes === '05' ?
                                                                    dado.periodo + 'º / Maio'
                                                                    : dado.mes === '06' ?
                                                                        dado.periodo + 'º / Junho'
                                                                        : dado.mes === '07' ?
                                                                            dado.periodo + 'º / Julho'
                                                                            : dado.mes === '08' ?
                                                                                dado.periodo + 'º / Agosto'
                                                                                : dado.mes === '09' ?
                                                                                    dado.periodo + 'º / Setembro'
                                                                                    : dado.mes === '10' ?
                                                                                        dado.periodo + 'º / Outubro'
                                                                                        : dado.mes === '11' ?
                                                                                            dado.periodo + 'º / Novembro'
                                                                                            : dado.mes === '12' ?
                                                                                                dado.periodo + 'º / Dezembro'
                                                                                                : null
                                                }
                                            </div>
                                            <div className="col-xl-3">
                                                <p className="box-title">Arquivo </p>
                                                <p style={{ color: 'blue' }}>
                                                    <a href={dado.notafiscal} target='_blank'>
                                                        Detalhe do Arquivo
                                                    </a>
                                                </p>
                                            </div>
                                            <div className="col-xl-12" style={{ marginTop: 20 }}>
                                                <label className="box-title">Observação </label>
                                                <textarea value={observacao} onChange={handleObservacao} type="text" id="company" class="form-control" />
                                            </div>
                                            <div className="col-sm-2" style={{ marginTop: 20 }}>
                                                <button onClick={() => validaPagamento()} type="button" class="btn btn-success mb-1">
                                                    Validar
                                                </button>
                                            </div>
                                            <div className="col-sm-2" style={{ marginTop: 20 }}>
                                                <button onClick={() => naoAtivaPagamento()} type="button" class="btn btn-danger mb-1">
                                                    Não Validar
                                                </button>
                                            </div>

                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Histórico</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Ação</th>
                                                            <th>Data</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {historico.sort((a, b) => {
                                                            return moment(b.dataCadastro) - moment(a.dataCadastro);
                                                        }).map((historico) =>
                                                            <tr key={historico.id} >
                                                                <td>{historico.acao}</td>
                                                                <td>{moment(historico.dataCadastro).format('DD/MM/YYYY HH:mm')}</td>
                                                                <td>{historico.status}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
