import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Documentos(props) {
    const [dados, setDados] = useState(props.location.state ? props.location.state.produto : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        documentacao: firebase.firestore().collection('documentacao'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [id, setId] = useState('')
    const [nome, setNome] = useState(dados ? dados.nome : '')
    const [setor, setSetor] = useState(dados ? dados.setor : '')
    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')

    const [senha, setSenha] = useState('')
    const [verificaSenha, setVerificaSenha] = useState(false)
    const [arquivos, setArquivos] = useState([])


    const [usuario, setUsuario] = useState({})
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                if (dados) {
                    servicos.documentacao.doc(dados.id).collection('documentos').onSnapshot((res) => {
                        const data = res.docs.map((d) => d.data())
                        setArquivos(data)
                    })
                }
            }
        })
    }, [servicos.usuarios])
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }

    function desbloquear() {
        if (!senha) {
            mensagemAlerta('Digite a senha de desbloqueio')
            return
        }
        if (senha != dados.senha) {
            mensagemAlerta('Senha não autorizada')
            return
        }
        setVerificaSenha(true)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                {senha === dados.senha && verificaSenha ?
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Dados do Documento</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--" style={{ marginBottom: 10 }}>
                                                <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                    <div className='col-md-6'>
                                                        <label>Nome do Grupo de documentos</label>
                                                        <p>{nome}</p>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>Setor</label>
                                                        <p>{setor}</p>
                                                    </div>
                                                </div>
                                                <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                    <div className='col-md-12'>
                                                        <label>Descrição do Documento</label>
                                                        <p>{descricao}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Lista de Documentos</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className="card-body--">
                                                    <div className="table-stats order-table ov-h">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th>Nome</th>
                                                                    <th>Tipo</th>
                                                                    <th>Detalhe</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {arquivos.sort((a, b) => {
                                                                    return b.nivel - a.nivel;
                                                                }).map((produto) =>
                                                                    <tr key={produto.id} >
                                                                        <td>{produto.nome}</td>
                                                                        <td>{produto.tipoArquivo}</td>
                                                                        <td>
                                                                            <a href={produto.arquivo} >
                                                                                <span className="badge badge-info">
                                                                                    {produto.tipoArquivo === 'xlsx' ?
                                                                                        <i className='fa fa-file-excel-o'></i>
                                                                                        : produto.tipoArquivo === 'pdf' ?
                                                                                            <i className='fa fa-file-pdf-o'></i>
                                                                                            :
                                                                                            <i className='fa fa-file-word-o'></i>
                                                                                    }

                                                                                </span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        {arquivos.length === 0 ?
                                                            <p style={{ textAlign: 'center' }} >Sem arquivos cadastrados</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Dados do Documento</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--" style={{ marginBottom: 10 }}>
                                                <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                    <div className='col-md-6'>
                                                        <label>Digite a Senha de Acesso</label>
                                                        <div class="form-group">
                                                            <input type="text" value={senha} id="company" class="form-control" onChange={handleSenha} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <button onClick={()=> desbloquear()} style={{ marginTop: 30 }} type="button" class="btn btn-success mb-1" >
                                                            Desbloquear
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
