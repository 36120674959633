import moment from 'moment';
import React, { useState } from 'react';

export default function Contrato(props) {
    const [dado] = useState(props.location.state ? props.location.state.avaria : null);
    return (
        <div>
            <div id="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h2 className="box-title" style={{ marginTop: 20 }}>Comprovaante de Avaria</h2>
                                                <h5 className="box-title">Contrato para comprovação de avaria</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ padding: 20 }}>
                                <div className="col-xl-12">
                                    <div className="card-body--" style={{ marginBottom: 20 }}>
                                        <div className='row col-md-12' >
                                            <div className='col-md-2'>
                                                <label>Conferente</label>
                                                <p><b>{dado.responsavel.nome}</b></p>
                                            </div>
                                            <div className='col-md-2'>
                                                <label>Motorista</label>
                                                <p><b>{dado.nome}</b></p>
                                            </div>
                                            <div className='col-md-2'>
                                                <label>CPF</label>
                                                <p><b>{dado.cpf}</b></p>
                                            </div>
                                            <div className='col-md-2'>
                                                <label>Tipo</label>
                                                <p><b>{dado.alocacao}</b></p>
                                            </div>
                                            {dado.tipoCliente === 'interno' ?
                                                <div className='col-md-2'>
                                                    <label>Alocação</label>
                                                    <p><b>Entrega Já</b></p>
                                                </div>
                                                :
                                                <div className='col-md-2'>
                                                    <label>Cliente</label>
                                                    <p><b>{dado.cliente.nome}</b></p>
                                                </div>
                                            }
                                            <div className='col-md-2'>
                                                <label>Placa</label>
                                                <p><b>{dado.placa}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body--" style={{ marginBottom: 20, marginTop: 20 }}>
                                        <div className='row col-md-12' >
                                            <div className='col-md-2'>
                                                <label>Nota Fiscal</label>
                                                <p><b>{dado.notaFiscal}</b></p>
                                            </div>
                                            {dado.pedido ?
                                                <div className='col-md-2'>
                                                    <label>N° Pedido</label>
                                                    <p><b>{dado.pedido}</b></p>
                                                </div>
                                                :
                                                null
                                            }
                                            <div className='col-md-2'>
                                                <label>Data Recebimento</label>
                                                <p><b>{moment(dado.dataCadastro).format('DD/MM/YYYY - HH:mm')}</b></p>
                                            </div>
                                            <div className='col-md-2'>
                                                <label>Serviço</label>
                                                <p><b>{dado.servico}</b></p>
                                            </div>
                                            <div className='col-md-3'>
                                                <label>Filial</label>
                                                <p><b>{dado.filial.nome}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" >
                                        <div class="row m-0">
                                            <h4 className="box-title">Descrição</h4>
                                        </div>
                                    </div>
                                    <div className="card-body--" style={{ marginBottom: 20, padding: 10 }}>
                                        <div className='row col-md-12' >
                                            <div className='col-md-12'>
                                                <p><b>{dado.descricao}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-3" style={{ marginTop: 30, textAlign: "center" }} >
                                                <img class="card-img-top" style={{ height: 200, width: 200 }} src={dado.imagem1} alt='carregando...' />
                                                <h4 className="box-title" style={{ marginTop: 30 }}><b>Foto 1</b></h4>
                                            </div>
                                            <div className="col-sm-3" style={{ marginTop: 30, textAlign: "center" }} >
                                                <img class="card-img-top" style={{ height: 200, width: 200 }} src={dado.imagem2} alt='carregando...' />
                                                <h4 className="box-title" style={{ marginTop: 30 }}><b>Foto 2</b></h4>
                                            </div>
                                            {dado.imagem3 ?
                                                <div className="col-sm-3" style={{ marginTop: 30, textAlign: "center" }} >
                                                    <img class="card-img-top" style={{ height: 200, width: 200 }} src={dado.imagem3} alt='carregando...' />
                                                    <h4 className="box-title" style={{ marginTop: 30 }}><b>Foto 3</b></h4>
                                                </div>
                                                :
                                                null
                                            }
                                            {dado.imagem4 ?
                                                <div className="col-sm-3" style={{ marginTop: 30, textAlign: "center" }} >
                                                    <img class="card-img-top" style={{ height: 200, width: 200 }} src={dado.imagem4} alt='carregando...' />
                                                    <h4 className="box-title" style={{ marginTop: 30 }}><b>Foto 4</b></h4>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>_______________________________________________</b></h4>
                                            </div>
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>{dado.nome}</b></h4>
                                                <h5 className="box-title" >{dado.cpf}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};