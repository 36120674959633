import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Chamados() {

    const [chamados, setChamados] = useState([])
    const [chamadosTemp, setChamadosTemp] = useState([])
    const [admin, setAdmin] = useState(false)
    const [servicos] = useState({
        auth: firebase.auth(),
        usuario: firebase.firestore().collection('usuarios'),
        chamados: firebase.firestore().collection('chamados')
    })
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        // (window.adsbygoogle = window.adsbygoogle || []).push({})
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuario.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    if (doc.data().usuario === 'MASTER') {
                        setAdmin(true)
                        servicos.chamados.where('status', '==', 'ABERTO').onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setChamados(data)
                        })
                    } else {
                        servicos.chamados
                            .where('departamento', '==', doc.data().setor)
                            .onSnapshot((snap) => {
                                const data = snap.docs.map(doc => doc.data())
                                setChamados(data)
                            })
                    }

                })
            }
        })

    }, [servicos.chamados])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            if (event.target.value === 'TODOS') {
                mensagemLoading('Buscando Chamados Abertos')
                servicos.chamados
                    .where('departamento', '==', usuario.setor)
                    .onSnapshot((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        setChamados(data)
                    })
            } else if (event.target.value === 'ABERTO') {
                mensagemLoading('Buscando Chamados Abertos')
                servicos.chamados
                    .where('status', '==', 'ABERTO')
                    .where('departamento', '==', usuario.setor)
                    .onSnapshot((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        setChamados(data)
                    })
            } else if (event.target.value === 'FINALIZADO') {
                mensagemLoading('Buscando Chamados Finalizados')
                servicos.chamados
                    .where('status', '==', 'FINALIZADO')
                    .where('departamento', '==', usuario.setor)
                    .onSnapshot((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        setChamados(data)
                    })
            } else if (event.target.value === 'USUARIOABERTO') {
                mensagemLoading('Buscando Meus Chamados')
                servicos.chamados
                    .where('usuario.id', '==', usuario.id)
                    .onSnapshot((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        setChamados(data)
                    })
            } else if (event.target.value === 'USUARIOATENDIMENTO') {
                mensagemLoading('Buscando Meus Atendimentos')
                servicos.chamados
                    .where('atendente.id', '==', usuario.id)
                    .onSnapshot((snap) => {
                        Swal.close();
                        const data = snap.docs.map(doc => doc.data())
                        setChamados(data)
                    })
            }

        } else {
            setChamados(chamadosTemp);
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    {/* <BlocoRodape /> */}
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9383816332258420"
                        crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                        style={{display:'block',width: '100%', height: 90}}
                        data-ad-client="ca-pub-9383816332258420"
                        data-ad-slot="8682041194"
                        data-ad-format="auto"
                        data-adtest="on"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({ });
                    </script>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-3">
                                                    <i className="pe-7s-bell"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">Abertos</div>
                                                        <div className="stat-heading">{chamados.filter((d) => d.status === 'ABERTO').length} Chamados</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-6">
                                                    <i className="pe-7s-clock"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">Em Atendimento</div>
                                                        <div className="stat-heading">{chamados.filter((d) => d.status === 'ATENDIMENTO').length} Chamados</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="stat-widget-five">
                                                <div className="stat-icon dib flat-color-5">
                                                    <i className="pe-7s-check"></i>
                                                </div>
                                                <div className="stat-content">
                                                    <div className="text-left dib">
                                                        <div className="stat-text">Fechados</div>
                                                        <div className="stat-heading">{chamados.filter((d) => d.status === 'FINALIZADOS').length} Chamados</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        {admin ?
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-8">
                                                        <h4 className="box-title">Chamados</h4>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Link to={{ pathname: '/categoriasChamados' }} >
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Categorias
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Link to={{ pathname: '/novoChamado' }} >
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Novo Chamado
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-10">
                                                        <h4 className="box-title">Chamados</h4>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Link to={{ pathname: '/novoChamado' }} >
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Novo Chamado
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="TODOS">Todos </option>
                                                        <option value="ABERTO">Todos Abertos </option>
                                                        <option value="FINALIZADO">Todos Finalizados </option>
                                                        <option value="USUARIOABERTO">Meus Chamados Abertos </option>
                                                        <option value="USUARIOATENDIMENTO">Meus Atendimentos </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {admin ?
                                            <div className="card-body--">
                                                <div className="table-stats order-table ov-h">
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Protocolo</th>
                                                                <th>Assunto</th>
                                                                <th>Departamento</th>
                                                                <th>Categoria</th>
                                                                <th>Prioridade</th>
                                                                <th>Status</th>
                                                                <th>Detalhe</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {chamados.sort((a, b) => {
                                                                return moment(b.dataAtualizacao) - moment(a.dataAtualizacao)
                                                            }).map((chamado) =>
                                                                <tr key={chamado.id} >
                                                                    <td>#{chamado.protocolo}</td>
                                                                    <td>{chamado.assunto}</td>
                                                                    <td>{chamado.departamento}</td>
                                                                    <td>{chamado.categoria}</td>
                                                                    {chamado.prioridade === 'BAIXA' ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-info">Baixa</span>
                                                                            </button>
                                                                        </td>
                                                                        : chamado.prioridade === 'NORMAL' ?
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-success">Normal</span>
                                                                                </button>
                                                                            </td>
                                                                            : chamado.prioridade === 'ALTA' ?
                                                                                <td>
                                                                                    <button >
                                                                                        <span className="badge badge-warning">Alta</span>
                                                                                    </button>
                                                                                </td>
                                                                                :
                                                                                <td>
                                                                                    <button >
                                                                                        <span className="badge badge-danger">Urgente</span>
                                                                                    </button>
                                                                                </td>
                                                                    }
                                                                    {chamado.status === 'ABERTO' ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-info">Aberto</span>
                                                                            </button>
                                                                        </td>
                                                                        : chamado.status === 'ATENDIMENTO' ?
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-warning">Atendimento</span>
                                                                                </button>
                                                                            </td>
                                                                            :
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-success">Fechado</span>
                                                                                </button>
                                                                            </td>
                                                                    }
                                                                    <td>
                                                                        <Link to={{ pathname: '/detalheChamado', state: { chamado: chamado } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {chamados.length === 0 ?
                                                        <p style={{ textAlign: 'center' }} >Sem chamados cadastrados</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="card-body--">
                                                <div className="table-stats order-table ov-h">
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Protocolo</th>
                                                                <th>Assunto</th>
                                                                <th>Cliente</th>
                                                                <th>Categoria</th>
                                                                <th>Atualização</th>
                                                                <th>Prioridade</th>
                                                                <th>Status</th>
                                                                <th>Detalhe</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {chamados.sort((a, b) => {
                                                                return moment(b.dataAtualizacao) - moment(a.dataAtualizacao)
                                                            }).map((chamado) =>
                                                                <tr key={chamado.id} >
                                                                    <td>#{chamado.protocolo}</td>
                                                                    <td>{chamado.assunto}</td>
                                                                    <td>{chamado.usuario.nome}</td>
                                                                    <td>{chamado.categoria}</td>
                                                                    <td>{moment(chamado.dataAtualizacao).format('DD/MM/YYYY HH:mm')}</td>
                                                                    {chamado.prioridade === 'BAIXA' ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-info">Baixa</span>
                                                                            </button>
                                                                        </td>
                                                                        : chamado.prioridade === 'NORMAL' ?
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-success">Normal</span>
                                                                                </button>
                                                                            </td>
                                                                            : chamado.prioridade === 'ALTA' ?
                                                                                <td>
                                                                                    <button >
                                                                                        <span className="badge badge-warning">Alta</span>
                                                                                    </button>
                                                                                </td>
                                                                                :
                                                                                <td>
                                                                                    <button >
                                                                                        <span className="badge badge-danger">Urgente</span>
                                                                                    </button>
                                                                                </td>
                                                                    }
                                                                    {chamado.status === 'ABERTO' ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-info">Aberto</span>
                                                                            </button>
                                                                        </td>
                                                                        : chamado.status === 'ATENDIMENTO' ?
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-warning">Atendimento</span>
                                                                                </button>
                                                                            </td>
                                                                            :
                                                                            <td>
                                                                                <button >
                                                                                    <span className="badge badge-success">Fechado</span>
                                                                                </button>
                                                                            </td>
                                                                    }
                                                                    <td>
                                                                        <Link to={{ pathname: '/detalheChamado', state: { chamado: chamado } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {chamados.length === 0 ?
                                                        <p style={{ textAlign: 'center' }} >Sem chamados cadastrados</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
