import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Home() {

    const [usuarios, setUsuarios] = useState([])
    const [usuariosTemp, setUsuariosTemp] = useState([])
    const [last, setLast] = useState('')
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios').orderBy('nome').limit(10)
    })

    useEffect(() => {
        servicos.usuarios.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setLast(snap.docs[snap.docs.length - 1])

            setUsuarios(data)
            setUsuariosTemp(data)
        })
    }, [servicos.usuarios])

    function carrega() {
        if (last) {
            servicos.usuarios.startAfter(last).get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setLast(snap.docs[snap.docs.length - 1])
                const list = usuarios
                data.forEach((d) => {
                    list.push(d)
                })
                setUsuarios([])
                setUsuariosTemp([])
                setUsuarios(list)
                setUsuariosTemp(list)
            })
        }

    }

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            if (event.target.value === '0') {
                setUsuarios(usuariosTemp.filter((d) => !d.status));
            } else if (event.target.value === 'MASTER') {
                setUsuarios(usuariosTemp.filter((d) => d.usuario === 'MASTER'));
            } else if (event.target.value === 'COMUM') {
                setUsuarios(usuariosTemp.filter((d) => d.usuario === 'COMUM'));
            } else {
                setUsuarios(usuariosTemp);
            }
        } else {
            setUsuarios(usuariosTemp);
        }
    }

    function removeUsuario(user) {
        if (user.usuario === 'MASTER') {
            mensagemAlerta('Não é possível remover um usuário Master')
        } else {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja remover o usuário ' + user.nome + ' ? Ao remover o usuário, ele não terá permissão e acesso ao sistema ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Removendo Usuário ...')
                    firebase.storage().ref().child('usuarios/avatar' + user.id).delete().then(() => {
                        servicos.usuarios.doc(user.id).delete().then(() => {
                            mensagemSucesso('Usuário removido com sucesso!')
                        })
                    }).catch(() => {
                        servicos.usuarios.doc(user.id).delete().then(() => {
                            mensagemSucesso('Usuário removido com sucesso!')
                        })
                    })
                }
            });
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-1">
                                                <i className="pe-7s-id"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{usuarios.filter((d) => d.usuario === "MASTER").length}</div>
                                                    <div className="stat-heading">Administrador do Sistema</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{usuarios.filter((d) => d.usuario === "COMUM").length}</div>
                                                    <div className="stat-heading">Usuário Comum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Usuários do Sistema</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastraUsuario' }} >
                                                        <button style={{ marginLeft: 10 }} type="button" class="btn btn-success mb-1">
                                                            Novo Usuário
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-4'>
                                                    <label>Tipo de Usuário</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="MASTER">Administrador do Sistema </option>
                                                        <option value="COMUM">Usuário Comum </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Nome</th>
                                                            <th>Email</th>
                                                            <th>Setor</th>
                                                            <th>Perfil</th>
                                                            <th>Acesso</th>
                                                            <th>Detalhe</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {usuarios.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((usuario) =>
                                                            <tr key={usuario.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={usuario.avatar ? usuario.avatar : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png"} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{usuario.nome}</td>
                                                                <td>{usuario.email}</td>
                                                                <td>{usuario.setor}</td>
                                                                <td>{usuario.usuario}</td>
                                                                <td>{usuario.acesso ? moment(usuario.acesso).format('DD/MM/YYYY HH:mm') : '--|--'}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/usuario', state: { usuario: usuario } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => removeUsuario(usuario)} >
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {usuarios.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem usuários cadastrados</p>
                                                    :
                                                    null
                                                }
                                                {last ?
                                                    <div className="col-sm-2">
                                                        <button style={{ marginLeft: 10 }} onClick={() => carrega()} type="button" class="btn btn-info mb-1">
                                                            Carrega Mais
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
