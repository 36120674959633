import React, { useEffect, useState } from 'react';
import Header from '../../admin/componentes/Header'
import Sidebar from '../../admin/componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function Logística() {

    const [blogs, setBlogs] = useState([])
    const [servicos] = useState({
        blog: firebase.firestore().collection('blog')
    })

    useEffect(() => {
        servicos.blog.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setBlogs(data)
        })
    }, [servicos.blog])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Blog</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastraBlog' }} >
                                                        <button style={{ marginLeft: 10 }} type="button" class="btn btn-success mb-1">
                                                            Novo Post
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Titulo</th>
                                                            <th>Descrição</th>
                                                            <th>Cadastro</th>
                                                            <th>Atualização</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {blogs.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((blog) =>
                                                            <tr key={blog.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={blog.miniatura ? blog.miniatura:blog.avatar? blog.avatar : "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png"} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{blog.titulo}</td>
                                                                <td>{blog.descricao}</td>
                                                                <td>{moment(blog.dataCadastro).format("DD/MM/YYYY - HH:mm")}</td>
                                                                <td>{moment(blog.dataAtualizacao).format("DD/MM/YYYY - HH:mm")}</td>
                                                                {blog.status === "INATIVO" ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/cadastraBlog', state: { blog: blog } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {blogs.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem conteudo cadastrado</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
