import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment';
import Swal from 'sweetalert2'

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Cupons() {

    const [cupons, setCupons] = useState([])
    const [listaCupons, setListaCupons] = useState([])
    const [servicos] = useState({
        cupons: firebase.firestore().collection('cupons')
    })
    const [data, setData] = useState('')
    useEffect(() => {
        const list = [];
        servicos.cupons.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setCupons(data)
            data.forEach((doc) => {
                list.push({
                    cupom: doc.cupom,
                    usuario: doc.usuario.nome,
                    cpf: doc.usuario.cpf,
                    data: moment(doc.dataFull).format('DD/MM/YYYY HH:mm'),
                })
            })
            setListaCupons(list)
        })
    }, [servicos.cupons])

    const handleData = (event) => {
        event.persist();
        setListaCupons([])
        setData(event.target.value)
        if (event.target.value) {
            const list = [];
            mensagemLoading('Carregando Cupons..')
            servicos.cupons
                .where('dataCadastro', '==', moment(event.target.value).format('YYYY-MM-DD'))
                .onSnapshot((snap) => {
                    Swal.close()
                    const data = snap.docs.map(doc => doc.data())
                    setCupons(data)
                    data.forEach((doc) => {
                        list.push({
                            cupom: doc.cupom,
                            usuario: doc.usuario.nome,
                            cpf: doc.usuario.cpf,
                            data: moment(doc.dataFull).format('DD/MM/YYYY HH:mm'),
                        })
                    })
                    setListaCupons(list)
                })
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-8">
                                                    <h4 className="box-title">Lista de Cupons </h4>
                                                </div>
                                                <div class="col-sm-2" >
                                                    <ExcelFile element={<button type="button" class="btn btn-success mb-1" >imprimir Excel</button>}>
                                                        <ExcelSheet data={listaCupons} name="Employees">
                                                            <ExcelColumn label="CUPOM" value="cupom" />
                                                            <ExcelColumn label="USUARIO" value="usuario" />
                                                            <ExcelColumn label="CPF" value="cpf" />
                                                            <ExcelColumn label="DATA / HORA" value="data" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastraCupom' }} >
                                                        <button style={{ marginLeft: 10 }} type="button" class="btn btn-success mb-1">
                                                            Novo Cupom
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-3">
                                                    <label>Filtro por data</label>
                                                    <div class="form-group">
                                                        <input type="date" value={data} id="company" class="form-control" onChange={handleData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Cupom</th>
                                                            <th>Motorista</th>
                                                            <th>CPF</th>
                                                            <th>Data / Hora</th>
                                                            <th>Imagem</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cupons.sort((a, b) => {
                                                            return moment(b.dataFull) - moment(a.dataFull)
                                                        }).map((cupom) =>
                                                            <tr key={cupom.id} >
                                                                <td>{cupom.cupom}</td>
                                                                <td>{cupom.usuario.nome}</td>
                                                                <td>{cupom.usuario.cpf}</td>
                                                                <td>{moment(cupom.dataFull).format('DD/MM/YYYY HH:mm')}</td>
                                                                <td>
                                                                    <a href={cupom.arquivo} target="_blank" >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {cupons.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem Cupons cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
