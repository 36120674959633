import moment from 'moment';
import React, { useState } from 'react';

export default function Contrato(props) {
    const [dado] = useState(props.location.state ? props.location.state.venda : null);
    const [catalogo] = useState(props.location.state ? props.location.state.catalogo : null);
    return (
        <div>
            <div id="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h2 className="box-title" style={{ marginTop: 20 }}>Solicitação de Compra</h2>
                                                <h5 className="box-title">Contrato de compra de produtos</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>Dados do Cliente</b></h4>
                                            </div>
                                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>CPF</th>
                                                            <th>Contato</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{dado.cliente.nome}</td>
                                                            <td>{dado.cliente.cpf}</td>
                                                            <td>{dado.cliente.contato}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>Dados de Retirada</b></h4>
                                            </div>
                                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Filial</th>
                                                            <th>Responsável</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{dado.filial.nome}</td>
                                                            <td>{dado.filial.responsavel}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>Dados do Produto</b></h4>
                                                <h4 className="box-title" style={{ marginTop: 30 }}><b></b></h4>
                                            </div>
                                            <div className="col-sm-12">
                                                <table className="table" style={{ marginTop: 20 }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Descrição</th>
                                                            <th>Quantidade</th>
                                                            <th>Valor</th>
                                                            <th>Forma de Pagamento</th>
                                                            <th>Parcelado?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{dado.produto.descricao}</td>
                                                            <td>{dado.qtd}</td>
                                                            {dado.parcelamento ?
                                                                <td>{(dado.valor * dado.qtd).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                :
                                                                <td>{((dado.valor * dado.qtd) - ((dado.valor * dado.qtd) * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                            }
                                                            <td>{dado.pagamento === "funcionario" ? "Desconto no salário" : dado.pagamento}</td>
                                                            <td>{dado.parcelamento ? dado.parcelamento + "x" : "Sem parcela"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-12" >
                                    <div className="card-body">
                                        <div className="row m-0">
                                            {/* <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20,textAlign:"center" }}>Comprovante de compra do produto <b>{dado.produto.descricao}</b> no valor de <b>{(dado.valor - (dado.valor * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b> produto em <b>{dado.parcelamento}x</b>. O produto será comprado pelo(a) cliente <b>{dado.cliente.nome}</b> e vendido pelo(a) agente <b>{dado.filial.responsavel}</b>, responsável pela filial <b>{dado.filial.nome}</b></h4>
                                                <h4 className="box-title" style={{ marginTop: 30 }}><b>Imagens do produto</b></h4>
                                            </div> */}
                                            <div className="col-sm-6" style={{ marginTop: 30, textAlign: "center" }} >
                                                <img class="card-img-top" style={{ height: 200, width: 200 }} src={catalogo.avatar} alt='carregando...' />
                                                <h4 className="box-title" style={{ marginTop: 30 }}><b>Foto do produto</b></h4>
                                            </div>
                                            <div className="col-sm-6" style={{ marginTop: 30, textAlign: "center" }}>
                                                <img class="card-img-top" style={{ height: 200, width: 200 }} src={catalogo.avaria} alt='carregando...' />
                                                <h4 className="box-title" style={{ marginTop: 30 }}><b>Foto da avaria</b></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>_______________________________________________</b></h4>
                                            </div>
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>{dado.cliente.nome}</b></h4>
                                                <h5 className="box-title" >{dado.cliente.cpf}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};