import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';
import { Link } from 'react-router-dom'
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Eventos() {

    const [sistemas, setSistemas] = useState([])
    const [sistemasTemp, setSistemasTemp] = useState([])
    const [servicos] = useState({
        leeds: firebase.firestore().collection('leads')
    })
    const [excel, setExcel] = useState([])

    useEffect(() => {
        const list = [];
        servicos.leeds.where('data', '==', moment().format('YYYY-MM-DD')).onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            data.forEach((d)=>{
                list.push(
                    [
                        { value: d.nome, style: { font: { sz: "11", } } },
                        { value: d.contato, style: { font: { sz: "11", } } },
                        { value: d.email, style: { font: { sz: "11", } } },
                        { value: d.empresa, style: { font: { sz: "11", } } },
                        { value: d.area, style: { font: { sz: "11", } } },
                    ]
                )
            })
            setExcel([{
                columns: [
                    { title: "NOME", width: { wch: 20 } },//pixels width 
                    { title: "CONTATO", width: { wch: 15 } },//char width 
                    { title: "EMAIL", width: { wch: 30 } },//char width 
                    { title: "EMPRESA", width: { wch: 30 } },
                    { title: "ATUAÇÃO", width: { wch: 20 } },
                ],
                data: list
            }])
            setSistemas(data)
            setSistemasTemp(data)
        })
    }, [servicos.leeds])

    const handleFiltro = (event) => {
        event.persist();
        setExcel([])
        if (event.target.value) {
            const list = [];
            if (event.target.value === 'todos') {
                mensagemLoading('Buscando Leads')
                servicos.leeds.onSnapshot((snap) => {
                    Swal.close();
                    const data = snap.docs.map(doc => doc.data())
                    data.forEach((d)=>{
                        list.push(
                            [
                                { value: d.nome, style: { font: { sz: "11", } } },
                                { value: d.contato, style: { font: { sz: "11", } } },
                                { value: d.email, style: { font: { sz: "11", } } },
                                { value: d.empresa, style: { font: { sz: "11", } } },
                                { value: d.area, style: { font: { sz: "11", } } },
                            ]
                        )
                    })
                    setExcel([{
                        columns: [
                            { title: "NOME", width: { wch: 20 } },//pixels width 
                            { title: "CONTATO", width: { wch: 15 } },//char width 
                            { title: "EMAIL", width: { wch: 30 } },//char width 
                            { title: "EMPRESA", width: { wch: 30 } },
                            { title: "ATUAÇÃO", width: { wch: 20 } },
                        ],
                        data: list
                    }])
                    setSistemas(data)
                    setSistemasTemp(data)
                })
            } else {
                mensagemLoading('Buscando Leads')
                servicos.leeds.where('data', '==', event.target.value).onSnapshot((snap) => {
                    Swal.close();
                    const data = snap.docs.map(doc => doc.data())
                    data.forEach((d)=>{
                        list.push(
                            [
                                { value: d.nome, style: { font: { sz: "11", } } },
                                { value: d.contato, style: { font: { sz: "11", } } },
                                { value: d.email, style: { font: { sz: "11", } } },
                                { value: d.empresa, style: { font: { sz: "11", } } },
                                { value: d.area, style: { font: { sz: "11", } } },
                            ]
                        )
                    })
                    setExcel([{
                        columns: [
                            { title: "NOME", width: { wch: 20 } },//pixels width 
                            { title: "CONTATO", width: { wch: 15 } },//char width 
                            { title: "EMAIL", width: { wch: 30 } },//char width 
                            { title: "EMPRESA", width: { wch: 30 } },
                            { title: "ATUAÇÃO", width: { wch: 20 } },
                        ],
                        data: list
                    }])
                    setSistemas(data)
                    setSistemasTemp(data)
                })
            }
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Leads</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/planilhaLeads' }}>
                                                        <button class="btn btn-success mb-1" >Nova Planilha</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-2'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="todos">Todos  </option>
                                                        <option value="2022-09-21">Dia 21 </option>
                                                        <option value="2022-09-22">Dia 22 </option>
                                                        <option value="2022-09-23">Dia 23 </option>
                                                        <option value="2022-09-24">Dia 24 </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-12'>
                                                    <h3 style={{ marginTop: 20 }}>Hoje, dia {moment().format('DD/MM/YYYY')}</h3>
                                                </div>
                                                <div className='col-md-10'>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="col-sm-2" >
                                                        <ExcelFile element={<button type="button" class="btn btn-success mb-1" >Download Excel</button>}>
                                                            <ExcelSheet dataSet={excel} name="Employees">
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                    {/* <Link to={{ pathname: '/cadastraCompetidor', state: { evento: evento } }}>
                                                        <button type="button" class="btn btn-success mb-1" >Download Excel</button>
                                                    </Link> */}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Sel</th>
                                                            <th>Nome</th>
                                                            <th>Empresa</th>
                                                            <th>Atuação</th>
                                                            <th>Email</th>
                                                            <th>Contato</th>
                                                            <th>Novidades</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((sistema) =>
                                                            <tr key={sistema.id} >
                                                                <td>
                                                                    <input type="checkbox" ></input>
                                                                </td>
                                                                <td>{sistema.nome}</td>
                                                                <td>{sistema.empresa}</td>
                                                                <td>{sistema.area}</td>
                                                                <td>{sistema.email}</td>
                                                                <td>{sistema.contato}</td>
                                                                {sistema.news === 1 ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-success"><i className='fa fa-check'></i></span>
                                                                        </button>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                        </button>
                                                                    </td>

                                                                }
                                                                {/* <td>
                                                                    <Link to={{ pathname: '/cadastroEquipamentosTecnologia', state: { sistema: sistema } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td> */}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem Leads cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
