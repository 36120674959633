import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import * as JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver';
var JSZip = require("jszip");

export default function Motorista(props) {
    const [usuario] = useState(props ? props.location.state.agregado : null)
    const [listaVeiculos, setVeiculos] = useState([])
    const [listaEnderecos, setEnderecos] = useState([])
    const [listaImagens, setImagens] = useState([])
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario").doc(usuario.email),
        storage: firebase.storage().ref("formulario/" + usuario.email)
    })

    useEffect(() => {

        servico.storage.listAll().then((res) => {
            const list = [];
            res.items.forEach((doc) => {
                var file = doc.toString().replace("gs://entrega-ja-a942a.appspot.com/formulario/"+usuario.email+"/", "")
                firebase.storage().ref("formulario/" + usuario.email+"/"+file).getDownloadURL().then((res)=>{
                    list.push({
                        nome:file,
                        url:res
                    })
                })
            })
            setImagens(list);
        })
    }, [])

    async function download() {
        mensagemLoading("Realizando Download...")
        var zip = new JSZip();
        var count = 0;
        console.log(listaImagens.length);
        listaImagens.forEach((url, index) => {
            var filename = url.nome;
            JSZipUtils.getBinaryContent(url.url, (err, data) => {
                if (err) {
                    throw err;
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count == listaImagens.length) {
                    zip.generateAsync({ type: "blob" }).then(function (blob) { // 1) generate the zip file
                        saveAs(blob, "" + usuario.nome);                          // 2) trigger the download
                        Swal.close();
                    }, function (err) {
                        console.log(err)
                    });
                }
            });
        });
    }
    function modificaUsuario() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja aceitar cadastro ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Salvando alterações ...')
                servico.formulario.update({
                    status: parseInt(2)
                }).then(() => {
                    mensagemSucesso('Cadastro aceito com sucesso!')
                }).catch(() => {
                    mensagemErro('Não foi possível aceitar o cadastro')
                })
            }
        });

    }

    function cancelar() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja cancelar este cadastro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Cancelando cadastro')
                firebase.storage().ref('formulario/' + usuario.email + '/antt').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/certificado').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/cnh').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/docVeiculo').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/docBancario').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/docEndereco').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/fotoVeiculoFrente').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/fotoVeiculoFundo').delete();
                firebase.storage().ref('formulario/' + usuario.email + '/fotoVeiculoBau').delete().then(() => {
                    servico.formulario.delete().then(() => {
                        mensagemSucesso("Cadastrado cancelado com sucesso!")
                    })
                })

            }
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = "/motoristas"
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados Pessoais</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {usuario.avatar ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={usuario.avatar} alt='carregando...' />
                                                        :
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://cargox.com.br/wp-content/uploads/2019/05/GettyImages-537453510.jpg' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-8'>
                                                    <div className='col-md-12'>
                                                        <h3><b>{usuario.nome}</b></h3>
                                                    </div>
                                                    <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                        <div className='col-md-3' >
                                                            <label>Contato</label>
                                                            <h4><b>{usuario.ddd} {usuario.celular}</b></h4>
                                                        </div>
                                                        <div className='col-md-6' >
                                                            <label>Email</label>
                                                            <h4><b>{usuario.email}</b></h4>
                                                        </div>
                                                        <div className='col-md-3' >
                                                            <label>Contrato</label>
                                                            <h4><b>{usuario.dia} dias</b></h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                        <div className='col-md-4' >
                                                            <label>Veículo</label>
                                                            <h4><b>{usuario.veiculo}</b></h4>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label>Cidade</label>
                                                            <h4><b>{usuario.cidade}</b></h4>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label>Protocolo</label>
                                                            <h4><b>{usuario.protocolo}</b></h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 row' style={{ marginTop: 20 }}>
                                                        <div className='col-md-4' >
                                                            <label>Tipo de Pix</label>
                                                            <h4><b>{usuario.tipoPix}</b></h4>
                                                        </div>
                                                        <div className='col-md-6' >
                                                            <label>PIX</label>
                                                            <h4><b>{usuario.pix}</b></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Documentação</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12'>
                                                {usuario.antt ?
                                                    <a href={usuario.antt} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Cartão ANTT
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-danger mb-1">
                                                        Cartão ANTT
                                                    </button>
                                                }
                                                {usuario.certificado ?
                                                    <a href={usuario.certificado} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Certificado do Cronotacógrafo
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-danger mb-1">
                                                        Certificado do Cronotacógrafo
                                                    </button>
                                                }
                                                {usuario.cnh ?
                                                    <a href={usuario.cnh} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Carteira Nacional de Habilitação
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-danger mb-1">
                                                        Carteira Nacional de Habilitação
                                                    </button>
                                                }
                                                {usuario.docVeiculo ?
                                                    <a href={usuario.docVeiculo} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Documento do Veículo
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-danger mb-1">
                                                        Documento do Veículo
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Documentação</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12'>
                                                {usuario.docVeiculoProprietario ?
                                                    <a href={usuario.docVeiculoProprietario} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Doc. Proprietário do Veículo
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-danger mb-1">
                                                        Doc. Proprietário do Veículo
                                                    </button>
                                                }
                                                {usuario.bancario ?
                                                    <a href={usuario.bancario} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Documentação Bancária
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-danger mb-1">
                                                        Documentação Bancária
                                                    </button>
                                                }
                                                {usuario.docEndereco ?
                                                    <a href={usuario.docEndereco} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Comprovante de Endereço
                                                    </a>
                                                    :
                                                    <button target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Comprovante de Endereço
                                                    </button>
                                                }
                                                {usuario.cnpj ?
                                                    <a href={usuario.cnpj} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Cartão CNPJ
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Cartão CNPJ
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Fotos do Veículo</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12'>
                                                {usuario.fotoVeiculoFrente ?
                                                    <a href={usuario.fotoVeiculoFrente} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Frente
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Frente
                                                    </button>
                                                }
                                            </div>
                                            <div className='col-md-12'>
                                                {usuario.fotoVeiculoFundo ?
                                                    <a href={usuario.fotoVeiculoFrente} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Fundo
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Fundo
                                                    </button>
                                                }
                                            </div>
                                            <div className='col-md-12'>
                                                {usuario.fotoVeiculoBau ?
                                                    <a href={usuario.fotoVeiculoFrente} target="_blank" style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Baú
                                                    </a>
                                                    :
                                                    <button style={{ width: '100%' }} class="btn btn-info mb-1">
                                                        Baú
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-success mb-1" onClick={() => modificaUsuario()}>
                        Aceitar Cadastro
                    </button>
                    <button style={{ marginLeft: 10 }} type="button" class="btn btn-danger mb-1" onClick={() => cancelar()}>
                        Cancelar Cadastrado
                    </button>
                    <button style={{ marginLeft: 10 }} type="button" class="btn btn-info mb-1" onClick={() => download()}>
                        Download de todos os Documentos
                    </button>
                </div>
                <Header />
            </div>
        </div>

    );
}
