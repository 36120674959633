import React, { useEffect, useState } from 'react';
import Header from '../../../admin/componentes/Header'
import Sidebar from '../../../admin/componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function CadastraCliente(props) {
    const [dados] = useState(props.location.state ? props.location.state.blog : null)
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        blog: firebase.firestore().collection('produtosSeguranca'),
        notificacoes: firebase.firestore().collection('notificacoes'),
    })

    const [titulo, setTitulo] = useState(dados ? dados.produto : '')
    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [conteudo, setConteudo] = useState(dados ? dados.conteudo : '')
    const [status, setStatus] = useState(dados ? dados.status : '')
    const [nivel, setNivel] = useState(dados ? dados.nivel : '')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState(dados ? dados.avatar : '');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem do Produto...')
        var uploadTask = firebase.storage().ref().child('produtosSeguranca/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.blog.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    if (dados) {
                        mensagemSucesso('Produto atualizado com sucesso! ')
                    } else {
                        mensagemSucesso('Produto cadastrado com sucesso!')
                    }
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraCliente() {
        if (upload === '' && !avatar) {
            mensagemAlerta('Favor escolha a imagem da publicação')
            setLoading(false)
        } else if (titulo === '') {
            mensagemAlerta('Favor digitar o nome do Produto')
            setLoading(false)
        } else if (descricao === '') {
            mensagemAlerta('Favor digitar a Descrição ')
            setLoading(false)
        } else if (conteudo === '') {
            mensagemAlerta('Favor digite o conteúdo')
            setLoading(false)
        } else if (status === '') {
            mensagemAlerta('Favor escolha o status da publicação')
            setLoading(false)
        } else if (nivel === '') {
            mensagemAlerta('Favor escolha o nível do perigo do produto')
            setLoading(false)
        } else {
            if (dados) {
                mensagemLoading('Aguarde.. Atualizando informações')
                servicos.blog.doc(dados.id).update({
                    produto: titulo,
                    descricao: descricao,
                    conteudo: conteudo,
                    status: status,
                    nivel: nivel,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then((docRef) => {
                    Swal.close()
                    if (upload) {
                        cadastraAvatar(dados.id)
                    } else {
                        mensagemSucesso('Produto atualizado com sucesso!')
                    }
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar o produto')
                })
            } else {
                mensagemLoading('Aguarde.. Cadastrando informações')
                servicos.blog.add({
                    produto: titulo,
                    descricao: descricao,
                    conteudo: conteudo,
                    status: status,
                    nivel: nivel,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then((docRef) => {
                    servicos.blog.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        Swal.close()
                        if (upload) {
                            cadastraAvatar(docRef.id)
                        } else {
                            // servicos.notificacoes.doc(docRef.id).set({
                            //     id: docRef.id,
                            //     titulo: titulo,
                            //     descricao: descricao,
                            //     avatar: null,
                            //     topico: 'blog'
                            // }).then(() => {
                            //     mensagemSucesso('Blog cadastrado com sucesso!')
                            // }).catch(() => {
                            //     mensagemErro('Erro ao cadastrar notificacção')
                            // })
                            mensagemSucesso('Produto cadastrado com sucesso!')
                        }
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar o produto')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar o produto')
                })
            }
        }
    }


    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleConteudo = (event) => {
        setConteudo(event)
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleNivel = (event) => {
        event.persist();
        setNivel(event.target.value);
    }

    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                {dados ?
                                                    <h4 className="box-title">{dados.titulo}</h4>
                                                    :
                                                    <h4 className="box-title">Nova Publicação</h4>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        : dados ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={avatar} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://www.pkmpneus.com.br//assets/images/imagem-exemplo.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem da Publicação</label>
                                                    <p>Para mudar a foto da publicação é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>

                                                    {loadingImage ?
                                                        <div class="progress-box progress-1" style={{ marginTop: 10 }}>
                                                            <div class="por-txt">Carregando... ({progresso})</div>
                                                            <div class="progress mb-2" >
                                                                <div class="progress-bar bg-flat-color-1" role="progressbar" style={{ width: progresso }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                    }

                                                </div>
                                            </div>

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-6'>
                                                    <label>Produto</label>
                                                    <div class="form-group">
                                                        <input type="text" value={titulo} id="company" class="form-control" onChange={handleTitulo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-12'>
                                                    <label>Conteúdo</label>
                                                    <SunEditor
                                                        lang="pt_br"
                                                        placeholder="informações"
                                                        onChange={handleConteudo}
                                                        setContents={conteudo}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-2'>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha  </option>
                                                        <option value="ATIVO" selected={dados && dados.status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Nível</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleNivel} >
                                                        <option value="">Escolha </option>
                                                        <option value="ALTO" selected={dados && dados.nivel === "ALTO" ? "selected" : null}>Alto </option>
                                                        <option value="MEDIO" selected={dados && dados.nivel === "MEDIO" ? "selected" : null}>Médio </option>
                                                        <option value="BAIRO" selected={dados && dados.nivel === "BAIXO" ? "selected" : null}>Baixo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {dados ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando Produto ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Atualizar Produto
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Produto ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Cadastrar Produto
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
