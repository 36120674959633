import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function CadastraPublicacao(props) {
    const [categoria] = useState(props.location.state ? props.location.state.categoria : '')
    const [dados] = useState(props.location.state ? props.location.state.post : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        wikipedia: firebase.firestore().collection('wikipedia').doc(categoria.id).collection('publicacoes'),
    })
    const [optionsSun] = useState({
        mode: "classic",
        rtl: false,
        katex: "window.katex",
        videoFileInput: false,
        tabDisable: false,
        buttonList: [
            [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "blockquote",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "lineHeight",
                "table",
                "link",
                "image",
                "video",
                "audio",
                "math",
                "imageGallery",
                "showBlocks",
                "codeView",
                "preview",
                "print",
                "save",
                "template"
            ]
        ],
    })
    const [conteudo, setConteudo] = useState(dados ? dados.conteudo : "")
    const [usuario, setUsuario] = useState({})

    const [titulo, setTitulo] = useState(dados ? dados.titulo : '')
    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [status, setStatus] = useState(dados ? dados.status : '')

    const [loading, setLoading] = useState(false)

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios,])

    function cadastraPublicacao() {
        if (!titulo) {
            mensagemAlerta("Favor digite o título")
            return
        }
        if (!descricao) {
            mensagemAlerta("Favor digite a descrição")
            return
        }
        if (!status) {
            mensagemAlerta("Favor digite o Status")
            return
        }
        if (dados) {
            mensagemLoading('Atualizando publicação...')
            servicos.wikipedia.doc(dados.id).update({
                titulo: titulo,
                descricao: descricao,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                conteudo: conteudo,
            }).then((snap) => {
                Swal.close();
                mensagemSucesso('Publicação atualizaca com sucesso!')
            }).catch((err) => {
                mensagemErro('Erro ao cadastrar a publicação ' + err)
            })
        } else {
            mensagemLoading('Cadastrando Publicação...')
            servicos.wikipedia.add({
                titulo: titulo,
                descricao: descricao,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                conteudo: conteudo,
                setor: usuario.setor,
                categoria: {
                    id: categoria.id,
                    nome: categoria.nome
                },
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome
                }
            }).then((snap) => {
                servicos.wikipedia.doc(snap.id).update({
                    id: snap.id,
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Publicação cadastrada com sucesso!')
                }).catch((erro) => {
                    mensagemErro('Erro ao atualizar a puclicação ' + erro)
                })
            }).catch((err) => {
                mensagemErro('Erro ao cadastrar a publicação ' + err)
            })
        }

    }

    function cadastraModelo() {
        if(!avatar){
            mensagemAlerta('Selecione o modelo')
            return
        }
        mensagemLoading('Salvando a imagem do Modelo...')
        var uploadTask = firebase.storage().ref().child('wikipedi/' + dados.id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.wikipedia.doc(dados.id).update({
                    modelo: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Modelo cadastrado com sucesso ')
                })
            });
        });
    }


    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleConteudo = (event) => {
        setConteudo(event);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Nova Publicação</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Título</label>
                                                    <div class="form-group">
                                                        <input type="text" value={titulo} id="company" class="form-control" onChange={handleTitulo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-8'>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-12' >
                                                    <label>Conteúdo</label>
                                                    <SunEditor
                                                        setOptions={optionsSun}
                                                        lang="pt_br"
                                                        placeholder="Conoteúdo"
                                                        onChange={handleConteudo}
                                                        setContents={conteudo}
                                                    />
                                                </div>
                                                <div className="col-sm-2" style={{ marginTop: 20 }}>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha </option>
                                                        <option value="ATIVO" selected={dados && dados.status === 'ATIVO' ? 'selected' : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === 'INATIVO' ? 'selected' : null}>Inativo </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Cadastrando Publicação ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraPublicacao()}>
                                                        Cadastrar Publicação
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dados ?
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Modelo</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='row col-md-12' >
                                                    <div className='col-md-8'>
                                                        <label>Imagem do Modelo Bizagi</label>
                                                        <div class="form-group">
                                                            <input onChange={handleAvatar} type="file" id="company" class="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4' style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraModelo()}>
                                                            Cadastrar Imagem
                                                        </button>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: 20 }}>
                                                        {dados.modelo ?
                                                            <img class="card-img-top" style={{ height: '100%', width: '100%' }} src={dados.modelo} alt='carregando...' />
                                                            :
                                                            <p>Sem modelo cadastrado. Cadastre um novo com o botão acimia</p>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
