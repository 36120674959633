import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Sistemas() {

    const [sistemas, setSistemas] = useState([])
    const [sistemasTemp, setSistemasTemp] = useState([])
    const [servicos] = useState({
        estoque: firebase.firestore().collection('estoque')
    })

    useEffect(() => {
        servicos.estoque.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setSistemas(data)
            setSistemasTemp(data)
        })
    }, [servicos.estoque])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando ocorrências')
            servicos.estoque.where('status', '==', event.target.value).get().then((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())
                setSistemas(data)
                setSistemasTemp(data)
            })
        } else {
            setSistemas(sistemasTemp);
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Estoque</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastroEstoque' }} >
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Novo Produto
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="ATIVO">Ativos </option>
                                                        <option value="INATIVO">Inativos </option>
                                                        <option value="ANALISE">Em Análise </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Produto</th>
                                                            <th>QTD</th>
                                                            <th>Descrição</th>
                                                            <th>Setor</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((produto) =>
                                                            <tr key={produto.id} >
                                                                <td>{produto.nome}</td>
                                                                <td>{produto.qtd}</td>
                                                                <td>{produto.descricao.substr(0,50)}</td>
                                                                <td>{produto.setor}</td>
                                                                {produto.status === "ATIVO" ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-success">ATIVO</span>
                                                                        </button>
                                                                    </td>
                                                                    : produto.status === "INATIVO" ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-danger">INATIVO</span>
                                                                            </button>
                                                                        </td>
                                                                        :
                                                                        null

                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/cadastroEstoque', state: { produto: produto } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem sistemas cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
