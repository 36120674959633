import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Estoque(props) {
    const [dados] = useState(props.location.state ? props.location.state.produto : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        estoque: firebase.firestore().collection('estoque'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [filiais, setFiliais] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [usuario, setUsuario] = useState({})
    const [admin, setAdmin] = useState({})
    const [historico, setHistorico] = useState([])
    const [nome, setNome] = useState(dados ? dados.nome : '')
    const [qtd, setQtd] = useState(dados ? dados.qtd : 0)
    const [filial, setFilial] = useState(dados && dados.filial ? dados.filial : {})
    const [setor, setSetor] = useState(dados ? dados.setor : '')
    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [status, setStatus] = useState(dados ? dados.status : '')

    const [tipoSaida, setTipoSaida] = useState('')
    const [qtdSaida, setQtdSaida] = useState(0)
    const [agregado, setAgregado] = useState('')
    const [setorSaida, setSetorSaida] = useState('')
    const [filialSaida, setFilialSaida] = useState('')

    const [loading, setLoading] = useState(false)

    var metadata = { contentType: 'image/jpeg' };
    const [upload, setUpload] = useState('');
    const [imagem, setImagem] = useState(dados ? dados.imagem : '');

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setAdmin({
                        nome: doc.data().nome,
                        id: doc.data().id
                    })
                })
            }
            servicos.filiais.get().then((doc) => {
                const data = doc.docs.map((d) => d.data())
                setFiliais(data)
            })
            servicos.usuarios.get().then((doc) => {
                const data = doc.docs.map((d) => d.data())
                setUsuarios(data)
            })
            if (dados) {
                servicos.estoque.doc(dados.id).collection('saidas').get().then((doc) => {
                    const data = doc.docs.map((d) => d.data())
                    setHistorico(data)
                })
            }
        })
    }, [servicos.usuarios])

    function cadastraImagem1(id) {
        mensagemLoading('Salvando a imagem...')
        var uploadTask = firebase.storage().ref().child('estoque/' + id).put(imagem[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.estoque.doc(id).update({
                    imagem: downloadURL
                }).then(() => {
                    Swal.close();
                    if (dados) {
                        mensagemSucesso('Produto atualizado com sucesso')
                    } else {
                        mensagemSucesso('Produto cadastrado com sucesso')
                    }

                })
            });
        });
    }

    function cadastraSistema() {
        if (!imagem) {
            mensagemAlerta('Por selecione a imagem do produto')
            return
        }
        if (!nome) {
            mensagemAlerta('Por favor digite o nome do produto')
            return
        }
        if (!qtd) {
            mensagemAlerta('Digite a quantidade do produto')
            return
        }
        if (!filial.id) {
            mensagemAlerta('Favor escolha a filial')
            return
        }
        if (!setor) {
            mensagemAlerta('Escolha o setor que pertence o produto')
            return
        }
        if (!descricao) {
            mensagemAlerta('Descreva sobre o equipamento')
            return
        }
        if (!status) {
            mensagemAlerta('Diga em qual estatus está o sistema')
            return
        }
        if (dados) {
            mensagemLoading('Atualizando Equipamento...')
            servicos.estoque.doc(dados.id).update({
                nome: nome,
                qtd: parseInt(qtd),
                filial: filial,
                setor: setor,
                descricao: descricao,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD'),
            }).then((docRef) => {
                if (upload) {
                    cadastraImagem1(dados.id)
                } else {
                    mensagemSucesso('Equipamento atualizado com sucesso!')
                }
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o equipamento ' + erro)
            })
        } else {
            mensagemLoading('Cadastrando Sistema...')
            servicos.estoque.add({
                nome: nome,
                qtd: parseInt(qtd),
                filial: filial,
                setor: setor,
                descricao: descricao,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD'),
                dataAtualizacao: moment().format('YYYY-MM-DD'),
            }).then((docRef) => {
                servicos.estoque.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    Swal.close();
                    cadastraImagem1(docRef.id)
                }).catch((err) => {
                    mensagemErro('Erro ao atualizar o equipamento ' + err)
                })
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o equipamento ' + erro)
            })
        }

    }

    function cadastraSaida() {
        if (!tipoSaida) {
            mensagemAlerta('Por favor selecione o tipo de saída')
            return
        }
        if (tipoSaida === 'COLABORADOR') {
            if (!usuario.id) {
                mensagemAlerta('Por favor selecione o Colaborador')
                return
            }
        }
        if (tipoSaida === 'AGREGADO') {
            if (!agregado) {
                mensagemAlerta('Por favor digite o nome do Agregado')
                return
            }
        }
        if (tipoSaida === 'SETOR') {
            if (!setorSaida) {
                mensagemAlerta('Por escolha o setor para saida')
                return
            }
        }
        if (tipoSaida === 'FILIAL') {
            if (!filialSaida.id) {
                mensagemAlerta('Por escolha a filial de saída')
                return
            }
        }
        if (!qtdSaida) {
            mensagemAlerta('Digite a quantidade que sairá o produto')
            return
        }
        if (parseInt(qtdSaida) > parseInt(qtd)) {
            mensagemAlerta('Estoque insuficiente para saída')
            return
        }
        mensagemLoading('Cadastrando Saida...')
        servicos.estoque.doc(dados.id).collection('saidas').add({
            tipoSaida: tipoSaida,
            qtd: parseInt(qtdSaida),
            filial: filialSaida,
            setor: setor,
            agregado: agregado,
            colaborador: usuario,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            admin:admin,
            usuario: usuario
        }).then((docRef) => {
            servicos.estoque.doc(dados.id).collection('saidas').doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                Swal.close();
                let qtdTotal = parseInt(qtd) - parseInt(qtdSaida)
                servicos.estoque.doc(dados.id).update({
                    qtd: parseInt(qtdTotal)
                }).then(() => {
                    mensagemSucesso('Saida cadastrada com sucesso!')
                })
            }).catch((err) => {
                mensagemErro('Erro ao atualizar o equipamento ' + err)
            })
        }).catch((erro) => {
            mensagemErro('Erro ao cadastrar o equipamento ' + erro)
        })

    }

    const handleAvatar = (event) => {
        event.persist();
        setImagem(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }
    const handleFilial = (event) => {
        event.persist();
        if (event.target.value) {
            setFilial({
                filial: filiais[event.target.value].nome,
                id: filiais[event.target.value].id
            });
        }
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Saida
    const handleTipoSaida = (event) => {
        setUsuario({})
        setAgregado('')
        setSetorSaida('')
        setFilialSaida({})
        event.persist();
        setTipoSaida(event.target.value);
    }
    const handleUsuario = (event) => {
        event.persist();
        setUsuario({
            nome: usuarios[event.target.value].nome + ' ' + usuarios[event.target.value].sobrenome,
            id: usuarios[event.target.value].id
        });
    }
    const handleAgregado = (event) => {
        event.persist();
        setAgregado(event.target.value);
    }
    const handleSetorSaida = (event) => {
        event.persist();
        setSetorSaida(event.target.value);
    }
    const handleFilialSaida = (event) => {
        event.persist();
        setFilialSaida({
            filial: filiais[event.target.value].nome,
            id: filiais[event.target.value].id
        });
    }
    const handleQtdSaida = (event) => {
        event.persist();
        setQtdSaida(event.target.value);
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Produto</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        : imagem ?
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={imagem} alt='carregando...' />
                                                            :
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://png.pngtree.com/png-clipart/20190612/original/pngtree-enterprise-server-equipment-png-image_3266805.jpg' alt='carregando...' />
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Produto</label>
                                                    <p>Para mudar a foto do produto é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>
                                                    <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />

                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome do Produto</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>QTD</label>
                                                    <div class="form-group">
                                                        <input type="text" value={qtd} id="company" class="form-control" onChange={handleQtd} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Filial</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFilial} >
                                                        <option value="">Escolha a filial </option>
                                                        {filiais.map((f, index) =>
                                                            <option value={index} selected={dados && dados.filial.id === f.id ? "selected" : null}>{f.nome} </option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Setor</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleSetor} >
                                                        <option value="">Escolha o setor </option>
                                                        <option value="DIRETORIA" selected={dados && dados.setor === "DIRETORIA" ? "selected" : null}>Diretoria </option>
                                                        <option value="CONTROLADORIA" selected={dados && dados.setor === "CONTROLADORIA" ? "selected" : null}>Controladoria </option>
                                                        <option value="CONTROLE" selected={dados && dados.setor === "CONTROLE" ? "selected" : null}>Torre de Controle </option>
                                                        <option value="LOGISTICA" selected={dados && dados.setor === "LOGISTICA" ? "selected" : null}>Logística </option>
                                                        <option value="LABS" selected={dados && dados.setor === "LABS" ? "selected" : null}>Labs </option>
                                                        <option value="MOTORISTA" selected={dados && dados.setor === "MOTORISTA" ? "selected" : null}>Motoristas </option>
                                                        <option value="MARKETING" selected={dados && dados.setor === "MARKETING" ? "selected" : null}>Marketing </option>
                                                        <option value="COMERCIAL" selected={dados && dados.setor === "COMERCIAL" ? "selected" : null}>Comercial </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-12'>
                                                    <label>Descrição do Produto</label>
                                                    <div class="form-group">
                                                        <textarea value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Status </label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha </option>
                                                        <option value="ATIVO" selected={dados && dados.status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {dados ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando Produto ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                            Atualizar Produto
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Produto ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                            Cadastrar Produto
                                                        </button>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                {dados ?
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Histórico de Saídas</h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div className='col-md-2'>
                                                        <label>Tipo de Saída</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleTipoSaida} >
                                                            <option value="">Escolha </option>
                                                            <option value="COLABORADOR" >Funcionário </option>
                                                            <option value="AGREGADO" >Agregado </option>
                                                            <option value="SETOR" >Setor </option>
                                                            <option value="FILIAL" >Filial </option>
                                                        </select>
                                                    </div>
                                                    {tipoSaida === 'COLABORADOR' ?
                                                        <div className='col-md-4'>
                                                            <label>Funcionário</label>
                                                            <select name="select" id="select" class="form-control" onChange={handleUsuario} >
                                                                <option value="">Escolha </option>
                                                                {usuarios.map((f, index) =>
                                                                    <option value={index} >{f.nome} {f.sobrenome} </option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        : tipoSaida === 'AGREGADO' ?
                                                            <div className='col-md-4'>
                                                                <label>Agregado</label>
                                                                <div class="form-group">
                                                                    <input type="text" value={agregado} id="company" class="form-control" onChange={handleAgregado} />
                                                                </div>
                                                            </div>
                                                            : tipoSaida === 'SETOR' ?
                                                                <div className='col-md-4'>
                                                                    <label>Setor</label>
                                                                    <select name="select" id="select" class="form-control" onChange={handleSetorSaida} >
                                                                        <option value="">Escolha </option>
                                                                        <option value="">Escolha o setor </option>
                                                                        <option value="DIRETORIA" >Diretoria </option>
                                                                        <option value="CONTROLADORIA" >Controladoria </option>
                                                                        <option value="CONTROLE" >Torre de Controle </option>
                                                                        <option value="LOGISTICA" >Logística </option>
                                                                        <option value="LABS" >Labs </option>
                                                                        <option value="MOTORISTA" >Motoristas </option>
                                                                        <option value="MARKETING" >Marketing </option>
                                                                        <option value="COMERCIAL" >Comercial </option>
                                                                    </select>
                                                                </div>
                                                                :
                                                                tipoSaida === 'FILIAL' ?
                                                                    <div className='col-md-4'>
                                                                        <label>Filial</label>
                                                                        <select name="select" id="select" class="form-control" onChange={handleFilialSaida} >
                                                                            <option value="">Escolha </option>
                                                                            {filiais.map((f, index) =>
                                                                                <option value={index} >{f.nome} </option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    null
                                                    }

                                                    <div className='col-md-1'>
                                                        <label>QTD</label>
                                                        <div class="form-group">
                                                            <input type="text" value={qtdSaida} id="company" class="form-control" onChange={handleQtdSaida} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2' style={{ marginTop: 30 }}>
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSaida()}>
                                                            Realizar Saida
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className="table-stats order-table ov-h">
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Tipo</th>
                                                                <th>Admin</th>
                                                                <th>Favorecido</th>
                                                                <th>QTD</th>
                                                                <th>Data/Hora</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {historico.sort((a, b) => {
                                                                return b.nivel - a.nivel;
                                                            }).map((sistema) =>
                                                                <tr key={sistema.id} >
                                                                    <td>{sistema.tipoSaida}</td>
                                                                    <td>{sistema.admin.nome}</td>
                                                                    {sistema.tipoSaida === 'COLABORADOR' ?
                                                                        <td>{sistema.colaborador.nome}</td>
                                                                        : sistema.tipoSaida === 'AGREGADO' ?
                                                                            <td>{sistema.agregado}</td>
                                                                            : sistema.tipoSaida === 'SETOR' ?
                                                                                <td>{sistema.setor}</td>
                                                                                :
                                                                                <td>{sistema.filial.nome}</td>
                                                                    }
                                                                    <td>{sistema.qtd}</td>
                                                                    <td>{moment(sistema.dataCadastro).format('DD/MM/YYYY - HH:mm')}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {historico.length === 0 ?
                                                        <p style={{ textAlign: 'center' }} >Sem hiistórico para o produto</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
