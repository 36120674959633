import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment';

export default function Wikipedia(props) {
    const [setor] = useState(props.location.state ? props.location.state.setor : '')
    const [wiki, setWiki] = useState([])
    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        wikipedia: firebase.firestore().collection('wikipedia'),
    })

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((data) => {
                    setUsuario(data.data())
                    if (data.data().usuario !== 'MASTER') {
                        servicos.wikipedia.doc(setor.id).collection('publicacoes').onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setWiki(data)
                        })
                    } else {
                        servicos.wikipedia.where('setor', '==', setor).onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setWiki(data)
                        })
                    }
                })
            }
        })
    }, [servicos.usuarios])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Wikipédia - {setor.id ? setor.nome : setor}</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    {setor.id ?
                                                        <Link to={{ pathname: '/publicacaoWiki', state: { categoria: setor } }} style={{ marginLeft: 10 }} type="button" class="btn btn-success mb-1">
                                                            Novo Post
                                                        </Link>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Título</th>
                                                            <th>Descricao</th>
                                                            <th>Usuário</th>
                                                            <th>Atualização</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {wiki.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((post) =>
                                                            <tr key={post.id} >
                                                                <td>{post.titulo}</td>
                                                                <td>{post.descricao}</td>
                                                                <td>{post.usuario ? post.usuario.nome : null}</td>
                                                                <td>{moment(post.dataAtualizacao).format('DD/MM/YYYY - HH:mm')}</td>
                                                                <td>
                                                                    {post.status === 'ATIVO' ?
                                                                        <button  >
                                                                            <span className="badge badge-success"><i className='fa fa-check'></i></span>
                                                                        </button>
                                                                        :
                                                                        <button  >
                                                                            <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                        </button>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/publicacaoWiki', state: { categoria: setor, post: post } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <button  >
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {wiki.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem publicações cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
