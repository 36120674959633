import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

import BlocoRodape from '../../../../../services/Ads/BlocoRodape'

export default function DetalheChamado(props) {
    const [dado] = useState(props.location.state ? props.location.state.chamado : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        chamados: firebase.firestore().collection('chamados'),
        categorias: firebase.firestore().collection('categoriaChamados'),
    })
    const [usuario, setUsuario] = useState({})
    const [historico, setHistorico] = useState([])
    const [descricao, setDescricao] = useState('')
    const [solucao, setSolucao] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
            if (dado) {
                servicos.chamados.doc(dado.id).collection('historico').onSnapshot((snap) => {
                    const data = snap.docs.map((d) => d.data())
                    setHistorico(data.sort((a, b) => {
                        return moment(b.dataCadastro) - moment(a.dataCadastro)
                    }))
                })
            }
        })
    }, [servicos.usuarios, servicos.pendencia])


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a anexo...')
        var uploadTask = firebase.storage().ref().child('chamado/' + dado.id + '/historico/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.chamados.doc(dado.id).collection('historico').doc(id).update({
                    documento: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Resposta cadastrada com sucesso!')
                })
            });
        });
    }

    function atendeChamado(c) {
        if (dado.id) {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja atender a este chamado ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Atualizando Chamado')
                    servicos.chamados.doc(dado.id).collection('historico').add({
                        acao: usuario.nome + ' se tornou atendente deste chamado',
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        usuario: {
                            id: usuario.id,
                            nome: usuario.nome,
                            email: usuario.email,
                            avatar: usuario.avatar ? usuario.avatar : 'https://cdn-icons-png.flaticon.com/512/81/81117.png'
                        }
                    }).then((docRef) => {
                        servicos.chamados.doc(dado.id).collection('historico').doc(docRef.id).update({
                            id: docRef.id
                        }).then(() => {
                            servicos.chamados.doc(dado.id).update({
                                status: 'ATENDIMENTO',
                                atendente: {
                                    id: usuario.id,
                                    nome: usuario.nome,
                                },
                                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                            }).then(() => {
                                mensagemSucesso('Chamado atualizado e agora voce é o atendente')
                            })
                        })
                    }).catch(() => {

                    })
                }
            });
        } else {
            window.history.back()
        }

    }

    function cadastraResposta() {
        if (!descricao) {
            mensagemAlerta('Favor digite a resposta ao chamado')
            return
        }
        mensagemLoading('Cadastrando Resposta')
        servicos.chamados.doc(dado.id).collection('historico').add({
            acao: descricao,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            usuario: {
                id: usuario.id,
                nome: usuario.nome,
                email: usuario.email,
                avatar: usuario.avatar ? usuario.avatar : 'https://cdn-icons-png.flaticon.com/512/81/81117.png'
            }
        }).then((docRef) => {
            servicos.chamados.doc(dado.id).collection('historico').doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                servicos.chamados.doc(dado.id).update({
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then(() => {
                    if (avatar) {
                        cadastraAvatar(docRef.id)
                    } else {
                        mensagemSucesso('Resposta cadastrada com sucesso')
                    }

                })
            })
        }).catch(() => {

        })

    }

    function finalizaChamado() {
        if (!solucao) {
            mensagemAlerta('Favor digite a solução do chamado')
            return
        }
        mensagemLoading('Finalizando Chamado')
        servicos.chamados.doc(dado.id).collection('historico').add({
            acao: solucao,
            finalizado: true,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            usuario: {
                id: usuario.id,
                nome: usuario.nome,
                email: usuario.email,
                avatar: usuario.avatar ? usuario.avatar : 'https://cdn-icons-png.flaticon.com/512/81/81117.png'
            }
        }).then((docRef) => {
            servicos.chamados.doc(dado.id).collection('historico').doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                servicos.chamados.doc(dado.id).update({
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    status: 'FINALIZADO',
                    dia: moment().format('DD'),
                    mes: moment().format('MM'),
                    ano: moment().format('YYYY')
                }).then(() => {
                    if (avatar) {
                        cadastraAvatar(docRef.id)
                    } else {
                        mensagemSucesso('Chamado finalizado com sucesso')
                    }

                })
            })
        }).catch(() => {

        })

    }

    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleSolucao = (event) => {
        event.persist();
        setSolucao(event.target.value);
    }

    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/chamados';
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog " role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Responder Chamado</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12' >
                                        <div className='col-md-12'>
                                            <label>Resposta</label>
                                            <div class="form-group">
                                                <textarea type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <label>Arquivo ou Print</label>
                                            <div class="form-group">
                                                <input type="file" id="company" class="form-control" onChange={handleAvatar} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    <button onClick={() => cadastraResposta()} type="button" class="btn btn-primary">Responder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="modalFinalizacao" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog " role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Finalizar Chamado</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12' >
                                        <div className='col-md-12'>
                                            <label>Solução</label>
                                            <div class="form-group">
                                                <textarea type="text" value={solucao} id="company" class="form-control" onChange={handleSolucao} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Arquivo ou Print</label>
                                            <div class="form-group">
                                                <input type="file" id="company" class="form-control" onChange={handleAvatar} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    <button onClick={() => finalizaChamado()} type="button" class="btn btn-primary">Finalizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="modalTransferencia" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog " role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Transferir Chamado</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12' >
                                        <div className='col-md-6'>
                                            <label>Transferir Para</label>
                                            <select name="select" id="select" class="form-control"  >
                                                <option value="">Escolha o departamento</option>
                                                <option value="CONTROLADORIA">Controladoria / Financeiro </option>
                                                <option value="CONTROLE">Torre de Controle / Monitoramento </option>
                                                <option value="LOGISTICA">Logística / Operação </option>
                                                <option value="LABS">Labs / Tecnologia </option>
                                                <option value="MARKETING">Marketing </option>
                                                <option value="COMERCIAL">Comercial </option>
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Transferir Para</label>
                                            <select name="select" id="select" class="form-control"  >
                                                <option value="">Escolha o nível</option>
                                                <option value="1">Sem Gravidade </option>
                                                <option value="2">Pouco Grave </option>
                                                <option value="3">Grave </option>
                                                <option value="4">Muito Grave </option>
                                                <option value="5">Extremamente Grave </option>
                                            </select>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: 20 }}>
                                            <label>Motivo</label>
                                            <div class="form-group">
                                                <textarea type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    <button onClick={() => cadastraResposta()} type="button" class="btn btn-primary">Responder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlocoRodape />
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Chamado N #{dado.protocolo}: {dado.assunto} - {dado.departamento} - {dado.categoria}</h2>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Solicitante</label>
                                                    <p>{dado.usuario.nome}</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Categoria</label>
                                                    <p>{dado.categoria}</p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Data Solicitação</label>
                                                    <p>{moment(dado.dataCadastro).format('DD/MM/YYYY HH:mm')}</p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Prioridade</label>
                                                    <p>{dado.prioridade}</p>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 20 }}>
                                                    <label>Objetivo do Chamado</label>
                                                    <p>{dado.descricao}</p>
                                                </div>
                                                {!dado.atendente ?
                                                    <>
                                                        <div className='col-md-12' style={{ marginTop: 20 }}>
                                                            <label>Para responder a este chamado, voce precisa tornar atendente. Após atendender voce se tornará dono primário do chamado</label>
                                                        </div>
                                                        <div className='col-md-12' style={{ marginTop: 20 }}>
                                                            <button type="button" class="btn btn-success mb-1" onClick={() => atendeChamado()}>
                                                                Atender chamado
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {dado.atendente ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <div className="col-sm-10">
                                                        <h4 className="box-title">Histórico de Respostas ({historico.length})</h4>
                                                    </div>
                                                    {dado.status !== 'FINALIZADO' ?
                                                        <div className="col-sm-2">
                                                            <button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-success mb-1">
                                                                Responder
                                                            </button>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {historico.map((h) =>
                                        <>
                                            <div className="col-xl-12">
                                                <div className="card">
                                                    <div className="card-body--">
                                                        <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                            <div className='col-md-1'>
                                                                <div class="round-img">
                                                                    <img class="rounded-circle" style={{ height: 50, width: 50 }} src={h.usuario.avatar} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-11'>
                                                                {dado.atendente.id === usuario.id ?
                                                                    <h4 className="box-title">{h.usuario.nome} - {dado.departamento} - (Atendente)</h4>
                                                                    :
                                                                    <h4 className="box-title">{h.usuario.nome} - (Cliente)</h4>
                                                                }
                                                                <p>{h.usuario.email}</p>
                                                            </div>

                                                            <div className='col-md-12' style={{ marginTop: 20, marginBottom: 10 }}>
                                                                <p >{h.acao}</p>
                                                            </div>
                                                            <div className='col-md-10' style={{ marginTop: 20, marginBottom: 10, }}>
                                                                {h.documento ?
                                                                    <a href={h.documento} target='_blank'>
                                                                        <p style={{ fontSize: 12 }}>Ver Anexo <i className='fa fa-paperclip'></i></p>
                                                                    </a>
                                                                    : h.finalizado ?
                                                                        <p style={{ fontSize: 12, color: 'green' }}>Chamado Finalizado <i className='fa fa-check'></i></p>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className='col-md-2' style={{ marginTop: 20, marginBottom: 10, textAlign: 'right' }}>
                                                                <p style={{ fontSize: 10 }}>{moment(h.dataCadastro).format('DD/MM/YYYY HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {dado.status !== 'FINALIZADO' ?
                                        <div className="col-xl-12">
                                            <div class="row m-0">
                                                <div className="col-sm-2">
                                                    <button type="button" data-toggle="modal" data-target="#modalFinalizacao" class="btn btn-success mb-1">
                                                        Finalizar Chamado
                                                    </button>
                                                </div>
                                                <div className="col-sm-2" style={{ marginLeft: 10 }}>
                                                    <button type="button" data-toggle="modal" data-target="#modalTransferencia" class="btn btn-info mb-1">
                                                        Transferir Chamado
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
