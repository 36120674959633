import React, { useEffect, useState } from 'react';
import moment from 'moment'
import Swal from 'sweetalert2'
import { login, getEmpresa } from '../services/Brudam'
import Sidebar from './componentes/Sidebar'
import firebase from 'firebase'
import InputMask from 'react-input-mask'
import { cnpj, cpf } from 'cpf-cnpj-validator';

export default function EnviaNFE() {
    const [servicos] = useState({
        pagamentos: firebase.firestore().collection('pagamentos'),
        mail: firebase.firestore().collection('mail')
    })

    function documentoNFE(id, name, arquivo) {
        Swal.close();
        mensagemLoading('Enviando Nota Fiscal ao Financeiro...')
        var uploadTask = firebase.storage().ref().child('pagamentos/' + id + '/' + name).put(arquivo[0], arquivo[0].type);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.pagamentos.doc(id).update({
                    notafiscal: downloadURL,
                    nomeArquivo: name
                }).then(() => {
                    const corpo = {
                        to: email,
                        from: 'controladoria@entregaja.com.br',
                        message: {
                            subject: 'Envio de Nota Fiscal',
                            html: '<html>' +
                                '<body>' +
                                '<h1><b>' + nome + ', </b></h1>' +
                                '<h2><b>Nota fiscal enviada com sucesso!</b></h2>' +
                                '<h3>A equipe do nosso financeiro já recebeu sua Nota Fiscal! Agora iremos avaliar sua solicitação. Você receberá notificação por este email em caso de aprovação ou alguma divergência.</h3>' +
                                '<h3>Para mais detalhes entre em contato</h3>' +
                                '<h4><b>Detalhe do Contato<b/></h4>' +
                                '<h4>Whatsapp: (98)98412-7047</h4>' +
                                '<h4>Email: controladoria@entregaja.com.br</h4>' +
                                '<p><b>Equipe Entrega Ja<b/></p>' +
                                '</body>' +
                                '</html>'
                        }
                    }
                    servicos.mail.add(corpo).then(() => {
                        mensagemSucesso('Nota fiscal enviada com sucesso! Aguarde a análise de nosso financeiro')
                    })

                })
            });
        });
    }

    function buscaCadastro() {
        if (!tipoDocumento) {
            mensagemAlerta('Selecione se você é PJ ou PF')
            return
        }
        if (tipoDocumento === 'PF' && !cpf.isValid(documento.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CPF incorreto! Favor verique novamente')
            return
        }
        if (tipoDocumento === 'PJ' && !cnpj.isValid(documento.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CNPJ incorreto! Favor verique novamente')
            return
        }
        mensagemLoading('Buscando cadastro...')
        login().then((res) => {
            getEmpresa(res.data.access_key, documento.replace(/[^a-z0-9]/gi, '')).then((dado) => {
                Swal.close()
                if (dado.status === 0) {
                    mensagemAlerta('Nenhum cadastro para este documento! Entre em contato com a Administração')
                } else {
                    setNome(dado.data[0].razao)
                    setEmail(dado.data[0].email)
                    setCadastro(true)
                }
            })
        })
    }


    function cadastraNotaFiscal() {
        if (!tipoDocumento) {
            mensagemAlerta('Selecione se você é PJ ou PF')
            return
        }
        if (tipoDocumento === 'PF' && !cpf.isValid(documento.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CPF incorreto! Favor verique novamente')
            return
        }
        if (tipoDocumento === 'PJ' && !cnpj.isValid(documento.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CNPJ incorreto! Favor verique novamente')
            return
        }
        if (!nome) {
            mensagemAlerta('Digite seu nome completo')
            return
        }
        if (!email) {
            mensagemAlerta('Digite o email para receber as notificações sobre sua nota')
            return
        }
        if (!mes) {
            mensagemAlerta('Escolha o mês')
            return
        }
        if (!periodo) {
            mensagemAlerta('Escolha o período')
            return
        }
        if (!nfe) {
            mensagemAlerta('Escolha o arquivo da Nota Fiscal')
            return
        }

        mensagemLoading('Cadastrando seus dados...')
        servicos.pagamentos.add({
            documento: documento,
            mes: mes,
            email: email,
            ano: moment().format('YYYY'),
            periodo: parseInt(periodo),
            nome: nome,
            tipoDocumento: tipoDocumento,
            status: 'CADASTRADO',
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
        }).then((docRef) => {
            servicos.pagamentos.doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                servicos.pagamentos.doc(docRef.id).collection('historico').add({
                    acao: 'Enviado para análise',
                    status: 'Enviado',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                }).then((docHis) => {
                    servicos.pagamentos.doc(docRef.id).collection('historico').doc(docHis.id).update({
                        id: docHis.id
                    }).then(() => {
                        var name = documento.replace(/[^a-z0-9]/gi, '') + '-' + periodo + '' + mes + moment().format('YYYY')
                        if (nfe[0].type.includes('image')) {
                            documentoNFE(docRef.id, name + '.' + nfe[0].type.replace('image/', ''), nfe)
                        } else {
                            documentoNFE(docRef.id, name + '.' + nfe[0].type.replace('application/', ''), nfe)
                        }
                    })
                })


            }).catch(() => {
                mensagemErro('Erro ao atualizar a Nota Fiscal')
            })
        }).catch(() => {
            mensagemErro('Erro ao cadastrar o Nota Fiscal')
        })

    }


    // Dados
    const [cadastro, setCadastro] = useState(false)
    const [tipoDocumento, setTipoDocumento] = useState('')
    const [documento, setDocumento] = useState('')
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [nfe, setNFE] = useState('')
    const [mes, setMes] = useState('')
    const [periodo, setPeriodo] = useState('')

    const handleTipoDocumento = (event) => {
        event.persist();
        setTipoDocumento(event.target.value);
    }
    const handleDocumento = (event) => {
        event.persist();
        setDocumento(event.target.value);
    }
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handlePeriodo = (event) => {
        event.persist();
        setPeriodo(event.target.value);
    }
    const handleNFE = (event) => {
        event.persist();
        setNFE(event.target.files)
    }



    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location.reload()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />

            <div id="right-panel" className="right-panel">

                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Envio de Nota Fiscal para pagamento</h2>
                                            </div>
                                            <p>Seja bem vindo, parceiro! Este canal, você enviará sua Nota Fiscal para nosso financeiro, para análise e pagamento.</p>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-3'>
                                                    <label>PJ / PF</label>
                                                    <div class="form-group">
                                                        <select name="select" id="select" class="form-control" onChange={handleTipoDocumento} >
                                                            <option value="">Selecione </option>
                                                            <option value="PJ">Conta PJ </option>
                                                            <option value="PF">Conta PF </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {tipoDocumento && tipoDocumento === 'PJ' ?
                                                    <div className='col-md-3'>
                                                        <label>CNPJ</label>
                                                        <div class="form-group">
                                                            <InputMask value={documento} onChange={handleDocumento} class="form-control" mask="99.999.999/9999-99" maskChar=" " />
                                                        </div>
                                                    </div>
                                                    : tipoDocumento && tipoDocumento === 'PF' ?
                                                        <div className='col-md-3'>
                                                            <label>CPF</label>
                                                            <div class="form-group">
                                                                <InputMask value={documento} onChange={handleDocumento} class="form-control" mask="999.999.999-99" maskChar=" " />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-md-3'>
                                                            <label>PF/PJ</label>
                                                            <div class="form-group">
                                                                <InputMask disabled value={documento} onChange={handleDocumento} class="form-control" mask="999.999.999-99" maskChar=" " />
                                                            </div>
                                                        </div>
                                                }
                                                {cadastro ?
                                                    <>
                                                        <div className='col-md-6'>
                                                            <label>Nome Completo</label>
                                                            <div class="form-group">
                                                                <input disabled value={nome} onChange={handleNome} type="text" id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <label>Email para notificações</label>
                                                            <div class="form-group">
                                                                <input value={email} onChange={handleEmail} type="text" id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <label>Mês</label>
                                                            <div class="form-group">
                                                                <select name="select" id="select" class="form-control" onChange={handleMes} >
                                                                    <option value="">Selecione </option>
                                                                    <option value="01">Janeiro </option>
                                                                    <option value="02">Fevereiro </option>
                                                                    <option value="03">Março </option>
                                                                    <option value="04">Abril </option>
                                                                    <option value="05">Maio </option>
                                                                    <option value="06">Junho </option>
                                                                    <option value="07">Julho </option>
                                                                    <option value="08">Agosto </option>
                                                                    <option value="09">Setembro </option>
                                                                    <option value="10">Outubro </option>
                                                                    <option value="11">Novembro </option>
                                                                    <option value="12">Dezembro </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <label>Período</label>
                                                            <div class="form-group">
                                                                <select name="select" id="select" class="form-control" onChange={handlePeriodo} >
                                                                    <option value="">Selecione </option>
                                                                    <option value="01">1º quinzena </option>
                                                                    <option value="02">2º quinzena </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <label>Nota Fiscal - (PDF, PNG, JPEG, JPG)</label>
                                                            <div class="form-group">
                                                                <input accept='.pdf, .jpg, .jpeg, .png' onChange={handleNFE} type="file" id="company" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12' style={{ marginTop: 20 }}>
                                                            <button onClick={() => cadastraNotaFiscal()} type="button" class="btn btn-success mb-1">
                                                                Enviar minha Nota Fiscal
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='col-md-4' style={{ marginTop: 30 }}>
                                                        <button onClick={() => buscaCadastro()} type="button" class="btn btn-success mb-1">
                                                            Buscar
                                                        </button>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                {/* <Header /> */}
            </div>
        </div>

    );
}
