import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment, { invalid } from 'moment';
import Swal from 'sweetalert2'

export default function Home() {

    const [prospeccao, setProspeccao] = useState([])
    const [negociacao, setNegociacao] = useState([])
    const [proposta, setProposta] = useState([])
    const [fechado, setFechado] = useState([])
    const [funil, setFunil] = useState([])
    const [historico, setHistorico] = useState([])
    const [cliente, setCliente] = useState({})
    const [usuario, setUsuario] = useState({})

    // Formuláraio Cadastro
    const [empresa, setEmpresa] = useState('')
    const [representante, setRepresentante] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [mensagem, setMensagem] = useState('')
    // Formulário historico
    const [descricao, setDescricao] = useState('')
    const [data, setData] = useState('')

    const [servicos] = useState({
        auth: firebase.auth(),
        funil: firebase.firestore().collection('funil'),
        usuarios: firebase.firestore().collection('usuarios'),
    })


    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((usuario) => {
                    setUsuario(usuario.data())
                })
            }
            servicos.funil.onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFunil(data)
                setProspeccao(data.filter((d) => d.status === 0))
                setNegociacao(data.filter((d) => d.status === 1))
                setProposta(data.filter((d) => d.status === 2))
                setFechado(data.filter((d) => d.status === 3))
            })
        })

    }, [servicos.funil])

    function handleOnDragEnd(result) {
        console.log(result)
        if (result.destination) {
            if (result.destination.droppableId === "characters") {
                servicos.funil.doc(result.draggableId).update({
                    status: parseInt(0)
                })
            } else if (result.destination.droppableId === "characters2") {
                servicos.funil.doc(result.draggableId).update({
                    status: parseInt(1)
                })
            } else if (result.destination.droppableId === "characters3") {
                servicos.funil.doc(result.draggableId).update({
                    status: parseInt(2)
                })
            } else if (result.destination.droppableId === "characters4") {
                servicos.funil.doc(result.draggableId).update({
                    status: parseInt(3)
                })
            }
        }
    }

    function detalheCliente(index) {
        setCliente(funil[index])
        console.log(funil[index])
        servicos.funil.doc(funil[index].id).collection("historico").get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setHistorico(data)
        })
    }

    function cadastraFunil() {
        if (!empresa) {
            mensagemAlerta("Favor digite o nome da Empresa")
            return
        }
        if (!representante) {
            mensagemAlerta("Favor digite o nome do Representante")
            return
        }
        if (!email) {
            mensagemAlerta("Favor digite o Email")
            return
        }
        if (!whatsapp && whatsapp.length < 11) {
            mensagemAlerta("Favor digite o WhatsApp")
            return
        }
        if (!mensagem) {
            mensagemAlerta("Favor digite a observação deste cliente")
            return
        }
        mensagemLoading("Adicionando o cliente ao Funil")
        servicos.funil.add({
            empresa: empresa,
            representante: representante,
            email: email,
            whatsapp: whatsapp,
            mensagem: mensagem,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            meio: 'site',
            status: parseInt(0),
            usuario: {
                id: usuario.id,
                nome: usuario.nome,
                email: usuario.email
            }
        }).then((snap) => {
            servicos.funil.doc(snap.id).update({
                id: snap.id
            }).then(() => {
                setEmpresa("")
                setRepresentante("")
                setEmail("")
                setWhatsapp("")
                setMensagem("")
                mensagemSucesso("Cliente adicionado com sucesso ao Funil")
            })
        })
    }

    function cadastraAcao() {
        if (!descricao) {
            mensagemAlerta("Favor digite o objetivo da ação")
            return
        }
        if (!data) {
            mensagemAlerta("Escolha uma data para retorno")
            return
        }

        mensagemLoading("Cadastrando ação ao cliente")
        servicos.funil.doc(cliente.id).collection("historico").add({
            descricao: descricao,
            dataCadastro: data,
            usuario: {
                id: usuario.id,
                nome: usuario.nome,
                email: usuario.email
            }
        }).then((snap) => {
            servicos.funil.doc(cliente.id).collection("historico").doc(snap.id).update({
                id: snap.id
            }).then(() => {
                servicos.funil.doc(cliente.id).update({
                    retorno: data
                }).then(() => {
                    setDescricao("")
                    setData("")
                    setEmpresa("")
                    setRepresentante("")
                    setEmail("")
                    setWhatsapp("")
                    setMensagem("")
                    mensagemSucesso("Cliente adicionado com sucesso ao Funil")
                })
            })
        })
    }

    function limpaForm() {
        setEmpresa("")
        setRepresentante("")
        setEmail("")
        setWhatsapp("")
        setMensagem("")
    }

    // Form
    const handleEmpresa = (event) => {
        event.persist();
        setEmpresa(event.target.value);
    }
    const handleRepresentante = (event) => {
        event.persist();
        setRepresentante(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }
    const handleMensagem = (event) => {
        event.persist();
        setMensagem(event.target.value);
    }
    const handleData = (event) => {
        event.persist();
        setData(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }


    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div class="modal fade" id="detalhe" tabindex="-1" role="dialog" aria-labelledby="TituloModalLongoExemplo" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            {cliente.id ?
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="TituloModalLongoExemplo">{cliente.representante}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body row">
                                        <div className='col-md-6'>
                                            <label>Nome da Empresa</label>
                                            <p>{cliente.empresa}</p>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Nome do Representante</label>
                                            <p>{cliente.representante}</p>
                                        </div>
                                        <div className='col-md-5' style={{ marginTop: 20 }}>
                                            <label>Email</label>
                                            <p>{cliente.email}</p>
                                        </div>
                                        <div className='col-md-3' style={{ marginTop: 20 }}>
                                            <label>Whatsapp</label>
                                            <p>{cliente.whatsapp}</p>
                                        </div>
                                        <div className='col-md-3' style={{ marginTop: 20 }}>
                                            <label>Tel Fixo</label>
                                            <p>{cliente.whatsapp}</p>
                                        </div>
                                        <div className='col-md-4' style={{ marginTop: 20 }}>
                                            <label>Data</label>
                                            <p>{moment(cliente.dataCadastro).format('DD/MM/YYYY - HH:mm')}</p>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: 20 }}>
                                            <label>Objetivo</label>
                                            <p>{cliente.mensagem}</p>
                                        </div>
                                    </div>
                                    <div class="modal-body row">
                                        <div className='col-md-12' >
                                            <h5 class="modal-title" >Histórico</h5>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: 20 }} >
                                            <label>Descrição</label>
                                            <div class="form-group">
                                                <textarea value={descricao} onChange={handleDescricao} type="text" id="company" class="form-control" />
                                            </div>
                                        </div>
                                        <div className='col-md-8' style={{ marginTop: 20 }} >
                                            <input type="date" value={data} onChange={handleData} id="company" class="form-control" />
                                        </div>
                                        <div className='col-md-4' style={{ marginTop: 20 }} >
                                            <button type="button" onClick={() => cadastraAcao()} class="btn btn-success">Adicionar ação</button>
                                        </div>
                                        {/* {historico.length > 0 ?
                                            <>
                                                {historico.map((h) => {
                                                    <div className='col-md-12' style={{ marginTop: 20 }} >
                                                        <p style={{ fontSize: 10 }}><b>{moment(h.dataCadastro).format('DD/MM/YYYY - HH:mm')}</b></p>
                                                        <p style={{ marginTop: 5 }}>{h.descricao}</p>
                                                    </div>
                                                })}
                                            </>
                                            :
                                            <div className='col-md-12' style={{ marginTop: 20 }} >
                                                <p >Sem histórico para este cliente</p>
                                            </div>
                                        } */}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div class="modal fade" id="cadastro" tabindex="-1" role="dialog" aria-labelledby="TituloModalLongoExemplo" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="TituloModalLongoExemplo">Novo Cliente</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body row">
                                    <div className='col-md-12'>
                                        <label>Nome da Empresa</label>
                                        <div class="form-group">
                                            <input value={empresa} onChange={handleEmpresa} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <label>Nome do Representante</label>
                                        <div class="form-group">
                                            <input value={representante} onChange={handleRepresentante} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <label>Email</label>
                                        <div class="form-group">
                                            <input value={email} onChange={handleEmail} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <label>Whatsapp</label>
                                        <div class="form-group">
                                            <input value={whatsapp} onChange={handleWhatsapp} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <label>Observação</label>
                                        <div class="form-group">
                                            <textarea value={mensagem} onChange={handleMensagem} type="text" id="company" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" onClick={() => limpaForm()} class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    <button type="button" onClick={() => cadastraFunil()} class="btn btn-success">Cadastrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-6">
                                                <i className="pe-7s-rocket"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{prospeccao.length}</div>
                                                    <div className="stat-heading">Prospecção</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{negociacao.length}</div>
                                                    <div className="stat-heading">Negociação</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-mail-open-file"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{proposta.length}</div>
                                                    <div className="stat-heading">Proposta</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-1">
                                                <i className="pe-7s-hammer"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{fechado.length}</div>
                                                    <div className="stat-heading">Fechados</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-global"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{funil.filter((d) => d.meio === "site").length}</div>
                                                    <div className="stat-heading">Pelo Site</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-phone"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{funil.filter((d) => d.meio === "app").length}</div>
                                                    <div className="stat-heading">Pelo App</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-headphones"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{funil.filter((d) => d.meio === "voip").length}</div>
                                                    <div className="stat-heading">Pelo VOIP</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <div className="orders">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-4">
                                                        <h4 className="box-title">Funil de Vendas</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Agenda para hoje, {moment().format("DD/MM/YYYY")}</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <button data-toggle="modal" data-target="#cadastro" type="button" class="btn btn-success mb-1">
                                                        Novo Cliente
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3" >
                                        <Droppable droppableId="characters">
                                            {(provided) => (
                                                <div className="characters card" {...provided.droppableProps} ref={provided.innerRef}  style={{ backgroundColor: "#4682B4" }}>
                                                    <div className="card-body">
                                                        <div className="row m-0">
                                                            <div className="col-sm-12">
                                                                <h4 className="box-title" style={{ color: "#fff" }}>Prospecção</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <>

                                                    </>
                                                    {prospeccao.map(({ id, email, representante, whatsapp, meio, empresa }, index) => {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                    <div data-toggle="modal" data-target="#detalhe" onClick={() => detalheCliente(index)} key={id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="card-body card " >
                                                                        <div className="row m-0" >
                                                                            <div className="col-sm-12" >
                                                                                <p><i className="menu-icon fa fa-user"></i> <b>{representante}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-home"></i> <b>{empresa}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-envelope"></i> {email}</p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone"></i> {whatsapp}</p>
                                                                                {meio === "site" ?
                                                                                    <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-globe"></i> Pelo Site</p>
                                                                                    : meio === "app" ?
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-mobile"></i> Pelo App</p>
                                                                                        :
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone-square"></i> Pelo VOIP</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                    <div className="col-xl-3" >
                                        <Droppable droppableId="characters2">
                                            {(provided) => (
                                                <div className="characters2 card" {...provided.droppableProps} ref={provided.innerRef}  style={{ backgroundColor: "#4682B4" }}>
                                                    <div className="card-body">
                                                        <div className="row m-0">
                                                            <div className="col-sm-12">
                                                                <h4 className="box-title" style={{ color: "#fff" }}>Negociação</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <>

                                                    </>
                                                    {negociacao.map(({ id, email, representante, whatsapp, meio, empresa }, index) => {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                    <div data-toggle="modal" data-target="#detalhe" key={id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="card-body card " >
                                                                        <div className="row m-0" >
                                                                            <div className="col-sm-12" >
                                                                                <p><i className="menu-icon fa fa-user"></i> <b>{representante}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-home"></i> <b>{empresa}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-envelope"></i> {email}</p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone"></i> {whatsapp}</p>
                                                                                {meio === "site" ?
                                                                                    <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-globe"></i> Pelo Site</p>
                                                                                    : meio === "app" ?
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-mobile"></i> Pelo App</p>
                                                                                        :
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone-square"></i> Pelo VOIP</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                    <div className="col-xl-3">
                                        <Droppable droppableId="characters3">
                                            {(provided) => (
                                                <div className="characters3 card" {...provided.droppableProps} ref={provided.innerRef}  style={{ backgroundColor: "#4682B4" }}>
                                                    <div className="card-body">
                                                        <div className="row m-0">
                                                            <div className="col-sm-12">
                                                                <h4 className="box-title" style={{ color: "#fff" }}>Proposta</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <>

                                                    </>
                                                    {proposta.map(({ id, email, representante, whatsapp, meio, empresa }, index) => {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                    <div data-toggle="modal" data-target="#detalhe" key={id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="card-body card " >
                                                                        <div className="row m-0" >
                                                                            <div className="col-sm-12" >
                                                                                <p ><i className="menu-icon fa fa-user"></i> <b>{representante}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-home"></i> <b>{empresa}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-envelope"></i> {email}</p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone"></i> {whatsapp}</p>
                                                                                {meio === "site" ?
                                                                                    <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-globe"></i> Pelo Site</p>
                                                                                    : meio === "app" ?
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-mobile"></i> Pelo App</p>
                                                                                        :
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone-square"></i> Pelo VOIP</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                    <div className="col-xl-3">
                                        <Droppable droppableId="characters4">
                                            {(provided) => (
                                                <div className="characters4 card" {...provided.droppableProps} ref={provided.innerRef}  style={{ backgroundColor: "#4682B4" }}>
                                                    <div className="card-body">
                                                        <div className="row m-0">
                                                            <div className="col-sm-12">
                                                                <h4 className="box-title" style={{ color: "#fff" }}>Fechado</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <>

                                                    </>
                                                    {fechado.map(({ id, email, representante, whatsapp, meio, empresa }, index) => {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                    <div data-toggle="modal" data-target="#detalhe" key={id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="card-body card " >
                                                                        <div className="row m-0" >
                                                                            <div className="col-sm-12" >
                                                                                <p><i className="menu-icon fa fa-user"></i> <b>{representante}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-home"></i> <b>{empresa}</b></p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-envelope"></i> {email}</p>
                                                                                <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone"></i> {whatsapp}</p>
                                                                                {meio === "site" ?
                                                                                    <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-globe"></i> Pelo Site</p>
                                                                                    : meio === "app" ?
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-mobile"></i> Pelo App</p>
                                                                                        :
                                                                                        <p style={{ fontSize: 10 }}><i className="menu-icon fa fa-phone-square"></i> Pelo VOIP</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                </div>
                            </div>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>

    );
}
