import firebase from 'firebase'
import moment from 'moment'

export const verificaAutenticado = (contexto) => {

    firebase.auth().onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
            if (contexto === 'login') {
                firebase.firestore().collection('usuarios').doc(firebaseUser.uid).get().then((snap) => {
                    if (snap.data().usuario === 'MASTER') {
                        firebase.firestore().collection('usuarios').doc(snap.data().id).update({
                            acesso: moment().format('YYYY-MM-DD HH:mm')
                        })
                        window.location = '/administrador';
                    } else if (snap.data().usuario === 'COMUM') {
                        firebase.firestore().collection('usuarios').doc(snap.data().id).update({
                            acesso: moment().format('YYYY-MM-DD HH:mm')
                        })
                        window.location = '/comum';
                    }
                })
            } else {
                firebase.firestore().collection('usuarios').doc(firebaseUser.uid).update({
                    acesso: moment().format('YYYY-MM-DD HH:mm')
                })
            }
        } else {
            if (contexto !== 'login') {
                window.location = '/';
            }
        }
    });
}

export const logout = () => {
    firebase.auth().signOut().then(() => {
        localStorage.setItem('usuarioLogado', '');
        window.location = '/'
    })
}