import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';

export default function CadastroProdutos(props) {
    const [dados] = useState(props.location.state.gaiola)
    const [numero, setNumero] = useState('')
    const [descricao, setDescricao] = useState('')
    const [ncm, setNcm] = useState('')
    const [qtd, setQtd] = useState('')

    const [id, setId] = useState('')
    const [qtdProduto, setQtdProduto] = useState('')

    const [historicos, setHistoricos] = useState([])
    const [produtos, setProdutos] = useState([])
    const [produto, setProduto] = useState({})

    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        gaiolas: firebase.firestore().collection('gaiolas').doc(dados.id).collection('produtos'),
        produtos: firebase.firestore().collection('produtos'),
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                servicos.gaiolas.onSnapshot((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setProdutos(data.sort((a, b) => {
                        return moment(b.dataAtualizacao) - moment(a.dataAtualizacao)
                    }))
                })
            }
        })
    }, [])

    function buscaProduto() {
        if (!numero) {
            mensagemAlerta('O código do produto não pode ser vazio')
            return
        }
        mensagemLoading('Bsucando produtos..')
        servicos.gaiolas.where('codigoItem','==',numero).get().then((p)=>{
            if(p.size > 0){
                Swal.close();
                servicos.produtos.doc(p.docs.map((p) => p.data())[0].idProduto).get().then((prod)=>{
                    setProduto(prod.data())
                    setQtd(p.docs.map((p) => p.data())[0].qtd)
                    setQtdProduto(p.docs.map((p) => p.data())[0].qtd)
                    setId(p.docs.map((p) => p.data())[0].id)
                })
            }else{
                servicos.produtos.where('codigoItem', '==', numero).get().then((d) => {
                    if (d.size > 0) {
                        Swal.close();
                        setProduto(d.docs.map((p) => p.data())[0])
                        setQtd(d.docs.map((p) => p.data())[0].qtdGaiolas)
                    } else {
                        Swal.close()
                        setProduto({})
                        setQtd('')
                        mensagemAlerta('Este produto não está cadastrado! Realize novo cadastro')
                    }
                })
            }
        })
    }

    function cadastraProduto() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja alocar o produto ' + produto.descricao + ' na gaiola ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                if (!qtd) {
                    mensagemAlerta('Por favor digite a quantidade do produto que será alocado a gaiola')
                    return
                }
                if (parseInt(produto.qtdGaiolas) < parseInt(qtd)) {
                    mensagemAlerta('A quantidade de produto não pode ser maior que a quantidade disponível para alocar')
                    return
                }
                mensagemLoading('Alocando produto a gaiola')
                servicos.gaiolas.add({
                    codigoItem: produto.codigoItem,
                    descricao: produto.descricao,
                    fornecedor: produto.fornecedor,
                    ncm: produto.ncm,
                    idProduto: produto.id,
                    usuario: {
                        id: usuario.id,
                        nome: usuario.nome,
                        email: usuario.email
                    },
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    qtd: parseInt(qtd)
                }).then((docRef) => {
                    servicos.gaiolas.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        servicos.gaiolas.doc(docRef.id).collection('historico').add({
                            usuario: {
                                id: usuario.id,
                                nome: usuario.nome,
                                email: usuario.email
                            },
                            titulo: 'Cadastrou o produto na Gaiola para '+qtd,
                            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        }).then((hId) => {
                            servicos.gaiolas.doc(docRef.id).collection('historico').doc(hId.id).update({
                                id: hId.id
                            }).then(() => {
                                servicos.produtos.doc(produto.id).update({
                                    qtdGaiolas: parseInt(produto.qtdGaiolas) - parseInt(qtd)
                                }).then(() => {
                                    Swal.close()
                                    mensagemSucesso('Produto cadastrado a gaiola')
                                })
                            })
                        })
                    })
                })
            }
        });
    }

    function atualizaProduto() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja atualizar o produto ' + produto.descricao + ' na gaiola ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                if (!qtd) {
                    mensagemAlerta('Por favor digite a quantidade do produto que será alocado a gaiola')
                    return
                }
                if (parseInt(produto.qtdGaiolas) < parseInt(qtd)) {
                    mensagemAlerta('A quantidade de produto não pode ser maior que a quantidade disponível para alocar')
                    return
                }
                mensagemLoading('Atualizando produto a gaiola')
                servicos.gaiolas.doc(id).update({
                    usuario: {
                        id: usuario.id,
                        nome: usuario.nome,
                        email: usuario.email
                    },
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    qtd: parseInt(qtd)
                }).then(() => {
                    servicos.gaiolas.doc(id).collection('historico').add({
                        usuario: {
                            id: usuario.id,
                            nome: usuario.nome,
                            email: usuario.email
                        },
                        titulo: 'Atualizou a quantidade do produto na Gaiola para '+qtd,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    }).then((hId) => {
                        servicos.gaiolas.doc(id).collection('historico').doc(hId.id).update({
                            id: hId.id
                        }).then(() => {
                            if(parseInt(qtdProduto) > parseInt(qtd)){
                                servicos.produtos.doc(produto.id).update({
                                    qtdGaiolas: parseInt(produto.qtdGaiolas) + parseInt(parseInt(qtdProduto) - parseInt(qtd))
                                }).then(() => {
                                    Swal.close()
                                    mensagemSucesso('Produto atualizado a gaiola')
                                    descartar()
                                })
                            }else if(qtdProduto){
                                servicos.produtos.doc(produto.id).update({
                                    qtdGaiolas: parseInt(produto.qtdGaiolas) - parseInt(parseInt(qtdProduto) - parseInt(qtd))
                                }).then(() => {
                                    Swal.close()
                                    mensagemSucesso('Produto atualizado a gaiola')
                                    descartar()
                                })
                            }else{
                                servicos.produtos.doc(produto.id).update({
                                    qtdGaiolas: parseInt(produto.qtdGaiolas) - parseInt(qtd)
                                }).then(() => {
                                    Swal.close()
                                    mensagemSucesso('Produto atualizado a gaiola')
                                    descartar()
                                })
                            }
                            
                        })
                    })
                })
            }
        });
    }

    function buscaHistoricos(item) {
        servicos.gaiolas.doc(item.id).collection('historico').get().then((p) => {
            const data = p.docs.map((d) => d.data())
            setHistoricos(data.sort((a, b) => {
                return moment(b.dataCadastro) - moment(a.dataCadastro)
            }))
        })
    }

    function detalheProduto(item) {
        servicos.produtos.doc(item.idProduto).get().then((s) => {
            setProduto(s.data())
        })
        setId(item.id)
        setQtd(parseInt(item.qtd))
        setQtdProduto(parseInt(item.qtd))
    }

    function descartar() {
        setNumero('')
        setProduto({})
        setId('')
        setQtd('')
        setQtdProduto('')
    }


    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location.reload()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            mensagemLoading('Buscando produtos...')
            console.log(event.target.value.substr(37, 100))
            servicos.produtos.where('codigoItem', '==', event.target.value).get().then((res) => {
                if (res.size > 0) {
                    Swal.close();
                    setProduto(res.docs.map((d) => d.data())[0])
                    setNcm(res.docs.map((d) => d.data())[0].ncm)
                    setDescricao(res.docs.map((d) => d.data())[0].descricao)
                } else {
                    mensagemAlerta('Produto não cadastrado! Favor cadastre manualmente')
                    setNcm('')
                    setDescricao('')
                    setProduto({})
                    setNumero('')
                }
            })
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Histórico de Atualizações</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="table-stats order-table ov-h">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th>Usuário</th>
                                                    <th>Ação</th>
                                                    <th>Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {historicos.map((historico) =>
                                                    <tr key={historico.id} >
                                                        <td>{historico.usuario.nome}</td>
                                                        <td>{historico.titulo}</td>
                                                        <td>{moment(historico.dataCadastro).format('DD/MM/YYYY HH:mm')}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Gaiola G{dados.numero}</h4>
                                                    <p>Cliente {dados.cliente.nome}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0" style={{ marginTop: 30 }}>
                                                <div className='col-md-3'>
                                                    <label>Cód. item</label>
                                                    <div class="form-group">
                                                        <input type="text" value={numero} onKeyDown={handleKeyDown} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1' style={{ marginTop: 30 }}>
                                                    <button onClick={() => buscaProduto(null)} type="button" class="btn btn-info mb-1">
                                                        <i className='fa fa-search'></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {produto.id ?
                                                <div className="row m-0" style={{ marginTop: 40 }}>
                                                    <div className='col-md-5'>
                                                        <label>Nome</label>
                                                        <p>{produto.descricao}</p>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>NCM</label>
                                                        <p>{produto.ncm}</p>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>Estoque</label>
                                                        <p>{produto.qtd} produtos</p>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label>Disponível para Alocar</label>
                                                        <p>{produto.qtdGaiolas} produtos</p>
                                                    </div>
                                                    <div className='col-md-2' style={{ marginTop: 20 }}>
                                                        <label>Alocar</label>
                                                        <div class="form-group">
                                                            <input type="text" value={qtd} id="company" class="form-control" onChange={handleQtd} />
                                                        </div>
                                                    </div>
                                                    {id ?
                                                        <>
                                                            <div className='col-md-2' style={{ marginTop: 50 }}>
                                                                <button type="button" class="btn btn-success mb-1" onClick={() => atualizaProduto()}>
                                                                    Atualizar SKU
                                                                </button>
                                                            </div>
                                                            <div className='col-md-2' style={{ marginTop: 50 }}>
                                                                <button type="button" class="btn btn-danger mb-1" onClick={() => descartar()}>
                                                                    Descartar
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className='col-md-2' style={{ marginTop: 50 }}>
                                                            <button type="button" class="btn btn-success mb-1" onClick={() => cadastraProduto()}>
                                                                Cadastrar SKU
                                                            </button>
                                                        </div>
                                                    }

                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Descrição</th>
                                                            <th>Fornecedor</th>
                                                            <th>QTD</th>
                                                            <th>Historico</th>
                                                            <th>Editar</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {produtos.map((produto) =>
                                                            <tr key={produto.id} >
                                                                <td>{produto.codigoItem}</td>
                                                                <td>{produto.descricao}</td>
                                                                <td>{produto.fornecedor.nome}</td>
                                                                <td>{produto.qtd}</td>
                                                                <td>
                                                                    <button onClick={() => buscaHistoricos(produto)} data-toggle="modal" data-target="#exampleModal">
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => detalheProduto(produto)}>
                                                                        <span className="badge badge-warning"><i className='fa fa-edit'></i></span>
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button>
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {produtos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem produtos cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
