import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function DetalheOcorrencia(props) {
    const [dado] = useState(props.location.state.avaria)
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        avarias: firebase.firestore().collection('avarias').doc(dado.id),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [usuario, setUsuario] = useState({})
    const [historicos, setHistorico] = useState([])


    const [descricaoAnalista, setDescricaoAnalista] = useState('')
    const [valorNota, setValorNota] = useState(0)
    const [valorAvaria, setValorAvaria] = useState(0)
    const [observacao, setObservacao] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [comprovanteAssinatura, setCommprovanteAssinatura] = useState('');
    const [comprovanteFfinalizacao, setCommprovanteFinializacao] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data())
            })
        })
    }, [servicos.avarias])

    function cadastraComprovanteAssinatura() {
        mensagemLoading('Salvando comprovante de assinatura...')
        var uploadTask = firebase.storage().ref().child('avarias/' + dado.id + 'contratoAssinatura' + dado.id).put(comprovanteAssinatura[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.avarias.update({
                    contratoAssinatura: downloadURL,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    dataEnvioAnalise: moment().format('YYYY-MM-DD HH:mm'),
                    status: 'ANALISE'
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Comprovante enviado e ocorrência enviada para Analise com sucesso!')
                    setLoadingImage(false)
                })
            });
        });
    }

    function cadastraComprovanteFinalizacao() {
        mensagemLoading('Salvando comprovante de finalização...')
        var uploadTask = firebase.storage().ref().child('avarias/' + dado.id + 'comprovanteFinalizacao' + dado.id).put(comprovanteAssinatura[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.avarias.update({
                    comprovanteFinalizacao: downloadURL,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    dataFinalizacao: moment().format('YYYY-MM-DD HH:mm'),
                    status: 'FINALIZADO'
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Ocorrência finalizada com sucesso!')
                    setLoadingImage(false)
                })
            });
        });
    }

    function enviaComprovanteAssinatura() {
        if (!comprovanteAssinatura) {
            mensagemAlerta('Favor selecione o arquivo scaneado da assinatura')
        } else {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja enviar a ocorrência para análise? ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Enviando comprovante...')
                    cadastraComprovanteAssinatura();
                }
            });
        }
    }

    function enviaComprovanteFinalizacao() {
        if (!comprovanteAssinatura) {
            mensagemAlerta('Favor selecione o arquivo scaneado da assinatura')
        } else {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja enviar a ocorrência para análise? ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Enviando comprovante...')
                    cadastraComprovanteAssinatura();
                }
            });
        }
    }

    function enviaFinanceiro() {
        if (!descricaoAnalista) {
            mensagemAlerta('Por favor digite a análise feita.')
        } else if (!valorNota || valorNota === 0) {
            mensagemAlerta('Por favor digite o valor da Nota')
        } else if (!valorAvaria || valorAvaria === 0) {
            mensagemAlerta('Por favor digite o valor da Avaria')
        } else if (parseFloat(valorAvaria) > parseFloat(valorNota)) {
            mensagemAlerta('O valor da avaria não popde ser maior que o valor da Nota')
        } else {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja enviar a análise para o setor de RH?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Enviando ao RH')
                    servicos.avarias.update({
                        valorNota: parseFloat(valorNota),
                        valorAvaria: parseFloat(valorAvaria),
                        descricaoAnalista: descricaoAnalista,
                        status: 'FINANCEIRO',
                        analista:{
                            nome:usuario.nome,
                            id:usuario.id,
                        }
                    }).then(() => {
                        Swal.close();
                        mensagemSucesso('Ocorrência enviada para o RH com sucesso!')
                        setLoadingImage(false)
                    })
                }
            });
        }
    }


    const handleComprovanteAssinatura = (event) => {
        event.persist();
        setCommprovanteAssinatura(event.target.files)
    }
    const handleComprovanteFinalizacao = (event) => {
        event.persist();
        setCommprovanteFinializacao(event.target.files)
    }
    const handleDescricaoAnalista = (event) => {
        event.persist();
        setDescricaoAnalista(event.target.value);
    }
    const handleValorNota = (event) => {
        event.persist();
        setValorNota(event.target.value);
    }
    const handleValorAvaria = (event) => {
        event.persist();
        setValorAvaria(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                {dado.status === "ABERTO" ?
                                    <div className="col-xl-12">
                                        <div className="card" style={{ backgroundColor: "#4682B4" }}>
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h3 style={{ color: "white" }}>Ocorrência de Avaria Aberta</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : dado.status === "ANALISE" ?
                                        <div className="col-xl-12">
                                            <div className="card" style={{ backgroundColor: "#D2691E" }}>
                                                <div className="card-body">
                                                    <div class="row m-0">
                                                        <h3 style={{ color: "white" }}>Ocorrência de Avaria em Analise</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : dado.status === "FINANCEIRO" ?
                                            <div className="col-xl-12">
                                                <div className="card" style={{ backgroundColor: "#D2691E" }}>
                                                    <div className="card-body">
                                                        <div class="row m-0">
                                                            <h3 style={{ color: "white" }}>Ocorrência de Avaria no setor Financeiro</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : dado.status === "FINALIZADO" ?
                                                <div className="col-xl-12">
                                                    <div className="card" style={{ backgroundColor: "#006400" }}>
                                                        <div className="card-body">
                                                            <div class="row m-0">
                                                                <h3 style={{ color: "white" }}>Ocorrência da Avaria finalizada com sucesso</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-xl-12">
                                                    <div className="card" style={{ backgroundColor: "#B22222" }}>
                                                        <div className="card-body">
                                                            <div class="row m-0">
                                                                <h3 style={{ color: "white" }}>Ocorrência de Avaria cancelada</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                }

                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Dados dos Envolvidos</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-2'>
                                                    <label>Conferente</label>
                                                    <p><b>{dado.responsavel.nome}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Motorista</label>
                                                    <p><b>{dado.nome}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>CPF</label>
                                                    <p><b>{dado.cpf}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipo</label>
                                                    <p><b>{dado.alocacao}</b></p>
                                                </div>
                                                {dado.tipoCliente === 'interno' ?
                                                    <div className='col-md-2'>
                                                        <label>Alocação</label>
                                                        <p><b>Entrega Já</b></p>
                                                    </div>
                                                    :
                                                    <div className='col-md-2'>
                                                        <label>Cliente</label>
                                                        <p><b>{dado.cliente.nome}</b></p>
                                                    </div>
                                                }
                                                <div className='col-md-2'>
                                                    <label>Placa</label>
                                                    <p><b>{dado.placa}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body" >
                                            <div class="row m-0">
                                                <h4 className="box-title">Detalhes da Ocorrência</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-2'>
                                                    <label>Nota Fiscal</label>
                                                    <p><b>{dado.notaFiscal}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>N° Pedido</label>
                                                    <p><b>Via {dado.pedido}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Data Recebimento</label>
                                                    <p><b>{moment(dado.dataCadastro).format('DD/MM/YYYY - HH:mm')}</b></p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Serviço</label>
                                                    <p><b>{dado.servico}</b></p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Filial</label>
                                                    <p><b>{dado.filial.nome}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body" >
                                            <div class="row m-0">
                                                <h4 className="box-title">Motivo da Ocorrência</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>
                                            <div className='row col-md-12' >
                                                <div className='col-md-12'>
                                                    <p><b>{dado.descricao}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Descrição do Analista - {dado.analista.nome}</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>

                                            {dado.descricaoAnalista ?
                                                <div className='row col-md-12' >
                                                    <div className='col-md-12' >
                                                        <p>{dado.descricaoAnalista}</p>
                                                    </div>
                                                    <div className='col-md-2' style={{marginTop:20}} >
                                                        <label>Valor da Nota</label>
                                                        <p>{dado.valorNota.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                    <div className='col-md-2' style={{marginTop:20}} >
                                                        <label>Valor da Avaria</label>
                                                        <p>{dado.valorAvaria.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className='row col-md-12' >
                                                        <div className='col-md-12'>
                                                            <label>Descrição</label>
                                                            <div class="form-group">
                                                                <textarea type="text" value={descricaoAnalista} id="company" class="form-control" onChange={handleDescricaoAnalista} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <label>Valor da Nota</label>
                                                            <div class="form-group">
                                                                <input type="text" value={valorNota} id="company" class="form-control" onChange={handleValorNota} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <label>Valor da Avaria</label>
                                                            <div class="form-group">
                                                                <input type="text" value={valorAvaria} id="company" class="form-control" onChange={handleValorAvaria} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2' style={{ marginTop: 30 }} onClick={() => enviaFinanceiro()}>
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Enviar ao RH
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='row col-md-12' >
                                                        <div className='col-md-3'>
                                                            <label>Imagem do Comprovante</label>
                                                            <div class="form-group">
                                                                <input type="file" id="company" class="form-control" onChange={handleComprovanteFinalizacao} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3' style={{ marginTop: 30 }} onClick={() => enviaComprovanteFinalizacao()}>
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Finalizar Ocorrência
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Descrição do Analista de RH</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>

                                            {dado.descricaoRH ?
                                                <div className='row col-md-12' >
                                                    <div className='col-md-12' >
                                                        <p>{dado.descricaoAnalista}</p>
                                                    </div>
                                                    <div className='col-md-2' style={{marginTop:20}} >
                                                        <label>Valor da Nota</label>
                                                        <p>{dado.valorNota.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                    <div className='col-md-2' style={{marginTop:20}} >
                                                        <label>Valor da Avaria</label>
                                                        <p>{dado.valorAvaria.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className='row col-md-12' >
                                                        <div className='col-md-12'>
                                                            <label>Descrição</label>
                                                            <div class="form-group">
                                                                <textarea type="text" value={descricaoAnalista} id="company" class="form-control" onChange={handleDescricaoAnalista} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row col-md-12' >
                                                        <div className='col-md-3'>
                                                            <label>Imagem do Comprovante</label>
                                                            <div class="form-group">
                                                                <input type="file" id="company" class="form-control" onChange={handleComprovanteFinalizacao} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3' style={{ marginTop: 30 }} onClick={() => enviaComprovanteFinalizacao()}>
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Finalizar Ocorrência
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Comprovante de Assinatura do Motorista</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--" style={{ marginBottom: 20 }}>

                                            {dado.contratoAssinatura ?
                                                <div className='row col-md-12' >
                                                    <div className='col-md-3'>
                                                        <label>Visualize o contrato ao lado</label>
                                                    </div>
                                                    <div className='col-md-3' >
                                                        <a href={dado.contratoAssinatura} target='_blank' type="button" class="btn btn-success mb-1" >
                                                            Ver contrato
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <div className='row col-md-12' >
                                                    <div className='col-md-3'>
                                                        <label>Imagem do Comprovante</label>
                                                        <div class="form-group">
                                                            <input type="file" id="company" class="form-control" onChange={handleComprovanteAssinatura} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3' style={{ marginTop: 30 }} onClick={() => enviaComprovanteAssinatura()}>
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Enviar e Finalizar ocorrência
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
