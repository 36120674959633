import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'

export default function Home() {

    const [mudancas, setMudancas] = useState([])
    const [servicos] = useState({
        mudancas: firebase.firestore().collection('mudancas').where('status', '==', 0),
        storage:firebase.storage().ref().child('entregalar/967A4989_naook.jpg')
    })

    useEffect(() => {
        
        // servicos.storage.getDownloadURL().then((d)=>{
        //     console.log(d)
        //     console.log(firebase.storage().refFromURL(d).name)
        // })
        servicos.mudancas.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setMudancas(data)
        })
    }, [servicos.mudancas])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            {/* <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-1">
                                                <i className="pe-7s-id"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{professores.length}</div>
                                                    <div className="stat-heading">Clientes</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{alunos.length}</div>
                                                    <div className="stat-heading">Motoristas</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-display2"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{aulas.length}</div>
                                                    <div className="stat-heading">Filiais</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-note2"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{matriculas.length}</div>
                                                    <div className="stat-heading">Serviços</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h4 className="box-title">Dashboard</h4>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Cliente</th>
                                                            <th>Origem</th>
                                                            <th>Destino</th>
                                                            <th>Data Mudança</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {mudancas.map((servico) => {
                                                            servico.status === 0 ?
                                                            <button title="Processo em Análise">
                                                                <span className="badge badge-warning"><i className='fa fa-clock-o'></i></span>
                                                            </button>
                                                            : servico.status === 1 ?
                                                                <button title="Processo em andamento">
                                                                    <span className="badge badge-info"><i className='fa fa-user-md'></i></span>
                                                                </button>
                                                                : servico.status === 2 ?
                                                                    <button title="Processo finalizado">
                                                                        <span className="badge badge-success"><i className='fa fa-check'></i></span>
                                                                    </button>
                                                                    :
                                                                    <button title="Processo Cancelado">
                                                                        <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                    </button>

                                                        }
                                                        )}

                                                    </tbody>
                                                </table>
                                                {mudancas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem mudanças solicitadas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
