import React, { useState, useEffect } from 'react';
import { verificaAutenticado } from '../services/Firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import { login, getMinuta } from '../services/Brudam'

export default function BuscaMinuta() {
    const queryParams = new URLSearchParams(window.location.search);
    const [minuta, setMminuta] = useState('')
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const [lista, setLista] = useState([]);
    const [corpoMinuta, setCorpoMinuta] = useState({});


    function BuscaMinuta() {
        if (!minuta) {
            mensagemAlerta('Favor digitar a minuta com 6 dígitos')
            return
        }
        setLoading(true)
        login().then((res) => {
            setErro('')
            getMinuta(res.data.access_key, minuta).then((doc) => {
                if (doc.message === 'OK') {
                    if (doc.data[0].dados) {
                        setCorpoMinuta(doc.data[0])
                        setLista(doc.data[0].dados)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        setErro(doc.data[0].message)
                    }
                } else {
                    setLoading(false)
                    setErro('Erro na consulta da Minuta! Verifique mais tarde')
                }
            })
        })
    }

    const handleMinuta = (event) => {
        setLista([])
        event.persist();
        setMminuta(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }


    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="login100-more" >
                    <img src='/images/background.png' alt="" />
                </div>
                {loading ?
                    <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50" style={{ textAlign: "center" }}>
                        <span className="login100-form-title p-b-19" style={{ textAlign: "center" }}>Aguarde...</span>
                        <span className="login90-form-title" >Buscando sua encomenda. Isso pode levar alguns minutos</span>
                        <div className='row col-md-12' style={{ textAlign: "center" }}>
                            <img src='/images/loading.gif' alt="" />
                        </div>
                    </div>
                    :
                    <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                        <span className="login100-form-title p-b-19">Minuta n° {minuta}</span>
                        <p><b>{corpoMinuta.prev_entrega ? 'Previsão de entrega dia ' + corpoMinuta.prev_entrega : 'Aguardando previsão'}</b></p><br />
                        {erro ?
                            <>
                                <span className="login90-form-title" >{erro}</span>
                                <div style={{ marginTop: 100, alignItems: 'center' }}>
                                    <button className="login100-form-btn" style={{ backgroundColor: 'red', borderRadius: 8, textAlign: 'center' }} onClick={() => BuscaMinuta()}>Buscar minha encomenda</button>
                                    <img style={{ marginTop: 20 }} src='/images/entrega.png' alt="" />
                                </div>
                            </>
                            :
                            <>
                                <span className="login90-form-title" style={{ marginTop: 40 }}>Verifique abaixo sua Encomenda</span>
                                <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                    <input className="input100" value={minuta} autoCapitalize="none" onChange={handleMinuta} type="text" name="email" placeholder="Digite a Minuta" />
                                    <span className="focus-input100"></span>
                                </div>
                                {lista.length > 0 ?
                                    <>
                                        {lista.map((item) =>
                                            <div className="row " style={{ marginTop: 20 }}>
                                                <div className="col-md-1">
                                                    <i className="fa fa-truck" />
                                                </div>
                                                <div className="col-md-8">
                                                    <h5><b>{item.descricao}</b></h5>
                                                    <p><b>Em {moment(item.data).format('DD/MM/YYYY - HH:mm')}</b></p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                    :
                                    null
                                }
                                <div style={{ marginTop: 100, alignItems: 'center' }}>
                                    <button className="login100-form-btn" style={{ backgroundColor: 'red', borderRadius: 8, textAlign: 'center' }} onClick={() => BuscaMinuta()}>Buscar minha encomenda</button>
                                    <img style={{ marginTop: 20 }} src='/images/entrega.png' alt="" />
                                </div>


                            </>
                        }

                    </div>
                }

            </div>
        </div>

    );
}
