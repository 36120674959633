import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

export default function Wikipedia() {

    const [wiki, setWiki] = useState([])
    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        wikipedia: firebase.firestore().collection('wikipedia'),
    })
    const [id, setId] = useState('')
    const [nome, setNome] = useState('')
    const [descricaoo, setDescricao] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((data) => {
                    setUsuario(data.data())
                    if (data.data().usuario !== 'MASTER') {
                        servicos.wikipedia.where('setor', '==', data.data().setor).onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setWiki(data)
                        })
                    }
                })
            }
        })
    }, [servicos.usuarios])

    function cadastraCategoria() {
        if (!nome) {
            mensagemAlerta('Favor digite o nome da categoria')
            return
        }
        if (!descricaoo) {
            mensagemAlerta('Favor digite uma descrição para categoria')
            return
        }
        if (!status) {
            mensagemAlerta('Escolha o status da categoria')
            return
        }
        if (id) {
            mensagemLoading('Atualizando categoria...')
            servicos.wikipedia.doc(id).update({
                nome: nome,
                descricao: descricaoo,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                setor: usuario.setor,
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome
                }
            }).then((snap) => {
                Swal.close()
                mensagemSucesso('Categoria cadastrada com sucesso!')
                limpaSelecao();
            }).catch((err) => {
                mensagemErro('Erro ao cadastrar categoria ' + err)
            })
        } else {
            mensagemLoading('Cadastrando categoria...')
            servicos.wikipedia.add({
                nome: nome,
                descricao: descricaoo,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                setor: usuario.setor,
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome
                }
            }).then((snap) => {
                servicos.wikipedia.doc(snap.id).update({
                    id: snap.id,
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Categoria cadastrada com sucesso!')
                    limpaSelecao();
                }).catch((erro) => {
                    mensagemErro('Erro ao atualizar categoria ' + erro)
                })
            }).catch((err) => {
                mensagemErro('Erro ao cadastrar categoria ' + err)
            })
        }
    }

    function detalhe(item) {
        setId(item.id)
        setNome(item.nome)
        setDescricao(item.descricao)
        setStatus(item.status)
    }

    function limpaSelecao() {
        setId('')
        setNome('')
        setDescricao('')
        setStatus('')
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            {usuario && usuario.usuario === "MASTER" ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-12">
                                                        <h4 className="box-title">Wikipedia</h4>
                                                    </div>
                                                    <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: '/wikiCategorias', state: { setor: 'CONTROLADORIA' } }} className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-ribbon"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Controladoria</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: '/wikiCategorias', state: { setor: 'CONTROLE' } }} className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-ribbon"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Torre de Controle</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: '/wikiCategorias', state: { setor: 'LOGISTICA' } }} className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-ribbon"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Logística</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: '/wikiCategorias', state: { setor: 'COMERCIAL' } }} className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-ribbon"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Comercial</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: '/wikiCategorias', state: { setor: 'LABS' } }} className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-ribbon"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Labs</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <Link to={{ pathname: '/wikiCategorias', state: { setor: 'MARKETING' } }} className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className="pe-7s-ribbon"></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">Marketing</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                            {usuario.id && usuario.usuario !== "MASTER" ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-10">
                                                        <h4 className="box-title">Categorias {usuario && usuario.setor}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-3">
                                                        <label>Categoria</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <label>Descrição</label>
                                                        <div class="form-group">
                                                            <input type="text" value={descricaoo} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <label>Status</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                            <option value="">Escolha </option>
                                                            <option value="ATIVO" selected={id && status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                            <option value="INATIVO" selected={id && status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        {id ?
                                                            <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCategoria()}>
                                                                Atualizar Categoria
                                                            </button>
                                                            :
                                                            <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCategoria()}>
                                                                Cadastrar Categoria
                                                            </button>
                                                        }
                                                    </div>
                                                    <div className="col-sm-2">
                                                        {id ?
                                                            <button type="button" class="btn btn-danger mb-1" onClick={() => limpaSelecao()}>
                                                                Descartar
                                                            </button>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className="table-stats order-table ov-h">
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Categoria</th>
                                                                <th>Descrição</th>
                                                                <th>Usuário</th>
                                                                <th>Data Cadastro</th>
                                                                <th>Status</th>
                                                                <th>Detalhe</th>
                                                                <th>Editar </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {wiki.sort((a, b) => {
                                                                if (a.nome < b.nome) {
                                                                    return -1;
                                                                }
                                                                if (a.nome > b.nome) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            }).map((usuario) =>
                                                                <tr key={usuario.id} >
                                                                    <td>{usuario.nome}</td>
                                                                    <td>{usuario.descricao.length > 50 ? usuario.descricao.substr(0, 50) + '...' : usuario.descricao}</td>
                                                                    <td>{usuario.usuario ? usuario.usuario.nome : null}</td>
                                                                    <td>{moment(usuario.dataCadastro).format('DD/MM/YYYY - HH:mm')}</td>
                                                                    <td>
                                                                        {usuario.status === 'ATIVO' ?
                                                                            <button  >
                                                                                <span className="badge badge-success"><i className='fa fa-check'></i></span>
                                                                            </button>
                                                                            :
                                                                            <button  >
                                                                                <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                            </button>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <Link to={{ pathname: '/wikiCategorias', state: { setor: usuario } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <button onClick={() => detalhe(usuario)}>
                                                                            <span className="badge badge-warning"><i className='fa fa-edit'></i></span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {wiki.length === 0 ?
                                                        <p style={{ textAlign: 'center' }} >Sem usuários cadastrados</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
