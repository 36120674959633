import React, { useEffect, useState } from 'react';
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment';
import Swal from 'sweetalert2'
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

export default function Arquivos(props) {
    const [dado] = useState(props.location.state ? props.location.state.semana : '')
    const [arquivos, setArquivos] = useState([])
    const [arquivosTemp, setArquivosTemp] = useState([])
    const [erro, setErro] = useState(false)
    const [file, setFile] = useState('')
    const [nome, setNome] = useState('')
    const [servicos] = useState({
        arquivos: firebase.firestore().collection('semanas').doc(dado.id).collection('arquivos')
    })

    var metadata = { contentType: 'text/xml' };

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        const listRows = [];
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                const arquivo = {
                    cols: resp.cols,
                    rows: resp.rows
                };
                var count = arquivo.rows.length;
                // arquivo.rows[0]
                arquivo.rows.forEach(element => {
                    listRows.push({
                        minuta: element[0],
                        cliente: element[81],
                        codigo: element[135]
                    })
                });
                // setArquivos(listRows)
                setArquivosTemp(listRows)
            }
        });
    }

    const handleArquivos = (event) => {
        event.persist();
        setFile(event.target.value)
        setErro(false)
        if (event.target.value.length === 15) {
            setNome('')
            if (arquivos.length === 0) {
                const list = [];
                const lista = {
                    minuta: arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].minuta,
                    cliente: arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].cliente,
                    codigo: arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].codigo ? arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].codigo : null,
                    data: moment().format('YYYY-MM-DD HH:mm')
                }
                list.push(lista)
                setArquivos(list)
                setFile('')
                setNome(arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].cliente)
            } else {
                if (arquivos.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8))).length > 0) {
                    setErro(true);
                    setFile('')
                    setNome(arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].cliente)
                } else {
                    const list = {
                        minuta: arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].minuta,
                        cliente: arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].cliente,
                        codigo: arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].codigo ? arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].codigo : null,
                        data: moment().format('YYYY-MM-DD HH:mm')
                    }
                    const lista = [
                        ...arquivos,
                        list
                    ]
                    setArquivos(lista)
                    setFile('')
                    setNome(arquivosTemp.filter((d) => d.codigo && d.codigo.includes(event.target.value.substr(3, 8)))[0].cliente)
                }
            }
        }
    }

    function novaLista() {
        Swal.fire({
            title: 'Aviso',
            text: 'A lista de notas cadastradas será excluída, deseja limpar assim mesmo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                setErro(false)
                setArquivos([])
                setFile('')
                Swal.close();
            }
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }

    return (
        <div>
            {/* <Sidebar /> */}
            <div id="right-panel" >
                {/* <Header /> */}
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Notas de remessas para verificação</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <label>Selecione os arquivos (.xml) para serem enviados</label>
                                                </div>
                                                <div className="col-sm-12">
                                                    <input type="file" accept=".xlsx, .xls" onChange={fileHandler} style={{ "padding": "10px" }} />
                                                </div>
                                                <div className="col-sm-12">
                                                    <p><b>Planilha com {arquivosTemp.length} registros</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <label>Pesquisar</label>
                                                    <input type="text" class="form-control" value={file} onChange={handleArquivos} />
                                                </div>
                                                {arquivos.length > 0 ?
                                                    <div className="col-sm-2">
                                                        <button onClick={() => novaLista()} type="button" class="btn btn-info mb-1" style={{ marginTop: 30 }} >
                                                            Nova Lista
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {erro ?
                                                    <div className="col-sm-12 " style={{ marginTop: 20 }}>
                                                        <p style={{ color: "red" }}>Nota de {nome} já cadastrada na lista</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {nome && !erro ?
                                                    <div className="col-sm-12 " style={{ marginTop: 20 }}>
                                                        <p style={{ color: "green" }}>Cliente {nome} cadastrado</p>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>

                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4>{arquivos.length} Notas na lista</h4>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-body--">

                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Minuta</th>
                                                            <th>Cliente</th>
                                                            <th>Codigo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {arquivos.sort((a, b) => {
                                                            return moment(b.data) - moment(a.data)
                                                        }).map((arquivo, index) =>
                                                            <tr key={index} >
                                                                <td>{arquivo.minuta}</td>
                                                                <td>{arquivo.cliente}</td>
                                                                <td>{arquivo.codigo}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {arquivos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem arquivos cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
