import React from 'react';
import { Link } from 'react-router-dom'

export default function Sidebar() {

    return (
        <>
            <aside id="contato" className="left-panel">
                <nav className="navbar navbar-expand-sm navbar-default">
                    <div id="main-menu" className="main-menu collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li className="menu-title">Entrega Já</li>
                            <li>
                                <Link to="#" >Ecommerce de produtos avariados para comercialização com preços mais baratos</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </aside>
        </>
    );
}
