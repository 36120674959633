import React, { useEffect, useState } from 'react';
import Header from '../../../../../componentes/Header'
import Sidebar from '../../../../../componentes/Sidebar'
import moment from 'moment'
import firebase from 'firebase'

export default function DetalheProduto(props) {
    const [dado] = useState(props.location.state.produto);
    const [historico, setHistorico] = useState([])

    const [servicos] = useState({
        auth: firebase.auth(),
        reembalados: firebase.firestore().collection('reembalados'),
        usuarios: firebase.firestore().collection('usuarios'),
    })

    useEffect(() => {
        servicos.reembalados.doc(dado.id).collection('historico').onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setHistorico(data)
        })
    }, [])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className="col-xl-12">
                                                    <h4 className="box-title">Produto</h4>
                                                </div>
                                                <div className="col-xl-12">
                                                    <h3 >{dado.descricao}</h3>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Cód Produto</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.codigoItem}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Data Cadastro</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{moment(dado.dataCadastro).format("DD/MM/YYYY - HH:mm")}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Última Atuaalização</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{moment(dado.dataAtualizacao).format("DD/MM/YYYY - HH:mm")}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Qtd</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.qtd}</h4>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Filial</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.filial.nome}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4 className="box-title">Responsável</h4>
                                                    </div>
                                                    <div >
                                                        <h4 >{dado.filial.responsavel}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-12">
                                                    <div style={{ marginTop: 20 }}>

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-12">
                                                    <div style={{ marginTop: 30 }}>
                                                        <h4 className="box-title">Observações</h4>
                                                    </div>
                                                    <div >
                                                        {dado.observacao ?
                                                            <h2 >{dado.observacao}</h2>
                                                            :
                                                            <h2 >Sem observações</h2>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div className="col-xl-4">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Valor Unitário</h4>
                                                    </div>
                                                    <div >
                                                        <h2 >{dado.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Valor Total</h4>
                                                    </div>
                                                    <div >
                                                        <h2 >{(dado.valor * dado.qtd).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div style={{ marginTop: 40 }}>
                                                        <h4 className="box-title">Valor Total 25%</h4>
                                                    </div>
                                                    <div >
                                                        <h2 >{((dado.valor - (dado.valor * 0.25)) * dado.qtd).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Imagens</h4>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={dado.avatar} alt='carregando...' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem do Avatar</label>
                                                    <p>Cadastrar aqui a imagem que será visualizada na lista</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <img class="card-img-top" style={{ height: 300, width: 300 }} src={dado.avaria} alt='carregando...' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Imagem da Avaria</label>
                                                    <p>Cadastrar aqui a imagem que será visualizada para avaria</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Histórico</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Ação</th>
                                                            <th>Filial</th>
                                                            <th>Responsável</th>
                                                            <th>Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {historico.map((historico) =>
                                                            <tr key={historico.id} >
                                                                <td>{historico.acao}</td>
                                                                <td>{historico.filial.nome}</td>
                                                                <td>{historico.usuario.nome}</td>
                                                                <td>{moment(historico.dataCadastro).format('DD/MM/YYYY - HH:mm')}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {historico.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem ações executadas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
