import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

export default function Prommotores(props) {
    const [dados] = useState(props.location.state ? props.location.state.cliente : null)
    const [clientes, setClientes] = useState([])
    const [servicos] = useState({
        fornecedores: firebase.firestore().collection('clientes').doc(dados.id).collection('fornecedores')
    })

    const [nome, setNome] = useState('')
    const [descricao, setDescricao] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        servicos.fornecedores.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setClientes(data)
        })
    }, [servicos.fornecedores])

    function cadastraFornecedor() {
        if (nome === '') {
            mensagemAlerta('Favor digitar o nome do Fornecedor')
        } else if (descricao === '') {
            mensagemAlerta('Favor digite a descrição do fornecedor')
        } else if (status === '') {
            mensagemAlerta('Favor escolha o status')
        } else {
            mensagemLoading('Cadastrando Fornecedor...')
            servicos.fornecedores.add({
                nome: nome,
                descricao: descricao,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
            }).then((docRef) => {
                servicos.fornecedores.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    mensagemSucesso('Fornecedor cadastrado com sucesso!')
                }).catch(() => {
                    mensagemErro('Erro ao atualizar o fornecedor')
                })
            }).catch(() => {
                mensagemErro('Erro ao cadastrar o Fornecedor')
            })

        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-12">
                                                    <h4 className="box-title">Fornecedores </h4>
                                                    <p>{dados.nome}</p>
                                                </div>
                                                <div className='col-md-3' style={{ marginTop: 20 }}>
                                                    <label>Nome do Fornecedor</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5' style={{ marginTop: 20 }}>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 20 }}>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Selecione </option>
                                                        <option value="ATIVO" >Ativo </option>
                                                        <option value="INATIVO" >Inativo </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-2" style={{ marginTop: 50 }}>
                                                    <button onClick={() => cadastraFornecedor()} type="button" class="btn btn-success mb-1" >Cadastrar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Descrição</th>
                                                            <th>Status</th>
                                                            <th>Editar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clientes.map((cliente) =>
                                                            <tr key={cliente.id} >
                                                                <td>{cliente.nome}</td>
                                                                <td>{cliente.descricao}</td>
                                                                {cliente.status === "INATIVO" ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <button>
                                                                        <span className="badge badge-warning"><i className='fa fa-edit'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {clientes.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem fornecedores cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
