import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function CadastraCliente(props) {
    const [dados] = useState(props.location.state ? props.location.state.sistema : '')
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        sistemasExternos: firebase.firestore().collection('sistemasExternos'),
    })
    const [usuario, setUsuario] = useState({})

    const [nome, setNome] = useState(dados ? dados.nome : '')
    const [setor, setSetor] = useState(dados ? dados.setor : '')

    const [tipoPagamento, setTipoPagamento] = useState(dados ? dados.tipoPagamento : '')
    const [periodo, setPeriodo] = useState(dados ? dados.periodo : '')
    const [diaAquisicao, setDiaAquisicao] = useState(dados ? dados.diaAquisicao : '')
    const [valorAquisicao, setValorAquisicao] = useState(dados ? dados.valorAquisicao : 0)
    const [dataPagamento, setDataPagamento] = useState(dados ? dados.dataPagamento : '')
    const [valorPagamento, setValorPagamento] = useState(dados ? dados.valorPagamento : 0)
    const [formaPagamento, setFormaPagamento] = useState(dados ? dados.formaPagamento : '')

    const [descricao, setDescricao] = useState(dados ? dados.descricao : '')
    const [responsavel, setResponsavel] = useState(dados ? dados.responsavel : '')
    const [email, setEmail] = useState(dados ? dados.email : '')
    const [contato, setContato] = useState(dados ? dados.contato : '')
    const [status, setStatus] = useState(dados ? dados.status : '')

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.usuarios])

    function cadastraSistema() {
        if (!nome) {
            mensagemAlerta('Por favor digite o nome do sistema')
            return
        }
        if (!setor) {
            mensagemAlerta('Por favor escolha o setor do sistema')
            return
        }
        if (!tipoPagamento) {
            mensagemAlerta('Por favor escolha o tipo de pagamento')
            return
        }
        if (!periodo) {
            mensagemAlerta('Por favor digite o período contratado')
            return
        }
        if (!dataPagamento) {
            mensagemAlerta('Por favor digite a data de pagamento ' + periodo)
            return
        }
        if (!valorPagamento) {
            mensagemAlerta('Por favor digite o valor pago no período ' + periodo)
            return
        }
        if (!formaPagamento) {
            mensagemAlerta('Por favor digite como o pagamento é realizado')
            return
        }
        if (!descricao) {
            mensagemAlerta('Diga qual o objetivo do sistema')
            return
        }
        if (!responsavel) {
            mensagemAlerta('Diga quem é o responsável legal de contato ao sistema')
            return
        }
        if (!email) {
            mensagemAlerta('Diga o email do responsável')
            return
        }
        if (!contato) {
            mensagemAlerta('Diga o contato do responsável')
            return
        }
        if (!status) {
            mensagemAlerta('Diga em qual estatus está o sistema')
            return
        }
        if (dados) {
            mensagemLoading('Atualizando Sistema...')
            servicos.sistemasExternos.doc(dados.id).update({
                nome: nome,
                setor: setor,
                tipoPagamento: tipoPagamento,
                periodo: periodo,
                diaAquisicao: diaAquisicao,
                valorAquisicao: parseFloat(valorAquisicao),
                dataPagamento: dataPagamento,
                valorPagamento: parseFloat(valorPagamento),
                formaPagamento: formaPagamento,
                descricao: descricao,
                responsavel: responsavel,
                email: email,
                contato: contato,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD'),
            }).then((docRef) => {
                mensagemSucesso('Sistema atualizado com sucesso!')
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o sistema ' + erro)
            })
        } else {
            mensagemLoading('Cadastrando Sistema...')
            servicos.sistemasExternos.add({
                nome: nome,
                setor: setor,
                tipoPagamento: tipoPagamento,
                periodo: periodo,
                diaAquisicao: diaAquisicao,
                valorAquisicao: parseFloat(valorAquisicao),
                dataPagamento: dataPagamento,
                valorPagamento: parseFloat(valorPagamento),
                formaPagamento: formaPagamento,
                descricao: descricao,
                responsavel: responsavel,
                email: email,
                contato: contato,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD'),
                dataAtualizacao: moment().format('YYYY-MM-DD'),
            }).then((docRef) => {
                servicos.sistemasExternos.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Sistema cadastrado com sucesso!')
                }).catch((err) => {
                    mensagemErro('Erro ao atualizar o sistema ' + err)
                })
            }).catch((erro) => {
                mensagemErro('Erro ao cadastrar o sistema ' + erro)
            })
        }

    }


    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handlePagamento = (event) => {
        event.persist();
        setTipoPagamento(event.target.value);
    }
    const handlePeriodo = (event) => {
        event.persist();
        setPeriodo(event.target.value);
    }
    const handleDiaAquisicao = (event) => {
        event.persist();
        setDiaAquisicao(event.target.value);
    }
    const handleValorAquisicao = (event) => {
        event.persist();
        setValorAquisicao(event.target.value);
    }
    const handleDataPagamento = (event) => {
        event.persist();
        setDataPagamento(event.target.value);
    }
    const handleValorPagamento = (event) => {
        event.persist();
        setValorPagamento(event.target.value);
    }
    const handleFormaPagamento = (event) => {
        event.persist();
        setFormaPagamento(event.target.value);
    }

    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleResponsavel = (event) => {
        event.persist();
        setResponsavel(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Sistema</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-5'>
                                                    <label>Nome do Sistema</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Setor</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleSetor} >
                                                        <option value="">Escolha o setor </option>
                                                        <option value="DIRETORIA" selected={dados && dados.setor === "DIRETORIA" ? "selected" : null}>Diretoria </option>
                                                        <option value="CONTROLADORIA" selected={dados && dados.setor === "CONTROLADORIA" ? "selected" : null}>Controladoria </option>
                                                        <option value="CONTROLE" selected={dados && dados.setor === "CONTROLE" ? "selected" : null}>Torre de Controle </option>
                                                        <option value="LOGISTICA" selected={dados && dados.setor === "LOGISTICA" ? "selected" : null}>Logística </option>
                                                        <option value="LABS" selected={dados && dados.setor === "LABS" ? "selected" : null}>Labs </option>
                                                        <option value="MOTORISTA" selected={dados && dados.setor === "MOTORISTA" ? "selected" : null}>Motoristas </option>
                                                        <option value="MARKETING" selected={dados && dados.setor === "MARKETING" ? "selected" : null}>Marketing </option>
                                                        <option value="COMERCIAL" selected={dados && dados.setor === "COMERCIAL" ? "selected" : null}>Comercial </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipo Pagamento</label>
                                                    <select name="select" id="select" class="form-control" onChange={handlePagamento} >
                                                        <option value="">Escolha </option>
                                                        <option value="FREE" selected={dados && dados.tipoPagamento === "FREE" ? "selected" : null}>Free </option>
                                                        <option value="PAGO" selected={dados && dados.tipoPagamento === "PAGO" ? "selected" : null}>Pago </option>
                                                    </select>
                                                </div>
                                                { }
                                                <div className='col-md-2'>
                                                    <label>Período</label>
                                                    <select name="select" id="select" class="form-control" onChange={handlePeriodo} >
                                                        <option value="">Escolha </option>
                                                        <option value="MENSAL" selected={dados && dados.periodo === "MENSAL" ? "selected" : null}>Mensal </option>
                                                        <option value="POR USO" selected={dados && dados.periodo === "POR USO" ? "selected" : null}>Por uso </option>
                                                        <option value="ANUAL" selected={dados && dados.periodo === "ANUAL" ? "selected" : null}>Anual </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-3'>
                                                    <label>Data Aquisição</label>
                                                    <div class="form-group">
                                                        <input type="date" value={diaAquisicao} id="company" class="form-control" onChange={handleDiaAquisicao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Valor Aquisição</label>
                                                    <div class="form-group">
                                                        <input type="text" value={valorAquisicao} id="company" class="form-control" onChange={handleValorAquisicao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Dia do Pagamento</label>
                                                    <div class="form-group">
                                                        {periodo === "ANUAL" ?
                                                            <input type="date" value={dataPagamento} id="company" class="form-control" onChange={handleDataPagamento} />
                                                            :
                                                            <input type="text" value={dataPagamento} id="company" class="form-control" onChange={handleDataPagamento} />
                                                        }

                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Valor {periodo}</label>
                                                    <div class="form-group">
                                                        <input type="text" value={valorPagamento} id="company" class="form-control" onChange={handleValorPagamento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Forma Pagamento</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFormaPagamento} >
                                                        <option value="">Escolha </option>
                                                        <option value="BOLETO" selected={dados && dados.formaPagamento === "BOLETO" ? "selected" : null}>Boleto </option>
                                                        <option value="CARTAO" selected={dados && dados.formaPagamento === "CARTAO" ? "selected" : null}>Cartão Débito/Crédito </option>
                                                        <option value="PIX" selected={dados && dados.formaPagamento === "PIX" ? "selected" : null}>PIX </option>
                                                        <option value="TRANSFERENCIA" selected={dados && dados.formaPagamento === "TRANSFERENCIA" ? "selected" : null}>Transferência </option>
                                                        <option value="CONTA" selected={dados && dados.formaPagamento === "CONTA" ? "selected" : null}>Débito em Conta </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-12'>
                                                    <label>Descrição do Sistema</label>
                                                    <div class="form-group">
                                                        <textarea value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Responsável pelo Sistema</label>
                                                    <div class="form-group">
                                                        <input type="text" value={responsavel} id="company" class="form-control" onChange={handleResponsavel} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Email</label>
                                                    <div class="form-group">
                                                        <input type="text" value={email} id="company" class="form-control" onChange={handleEmail} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Status do Sistema</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Escolha </option>
                                                        <option value="POC" selected={dados && dados.status === "POC" ? "selected" : null}>POC (Análise e testes) </option>
                                                        <option value="ATIVO" selected={dados && dados.status === "ATIVO" ? "selected" : null}>Ativo </option>
                                                        <option value="INATIVO" selected={dados && dados.status === "INATIVO" ? "selected" : null}>Inativo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {dados ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Atualizando Sistema ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                            Atualizar Sistema
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Sistema ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraSistema()}>
                                                            Cadastrar Sistema
                                                        </button>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
