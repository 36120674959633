import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Home() {

    const [usuarios, setUsuarios] = useState([])
    const [usuariosTemp, setUsuariosTemp] = useState([])
    const [servicos] = useState({
        filiais: firebase.firestore().collection('filiais')
    })

    useEffect(() => {
        servicos.filiais.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setUsuarios(data)
        })
    }, [servicos.usuarios])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Filiais</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                            <th>Ruas</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {usuarios.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((filial) =>
                                                            <tr key={filial.id} >
                                                                <td>{filial.nome}</td>
                                                                <td>{filial.endereco ? filial.endereco.cidade: null}</td>
                                                                <td>{filial.endereco ? filial.endereco.uf : null}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/ruasLogisticaPromocional', state: { filial: filial } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {usuarios.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem usuários cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
