import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'

export default function Chamados() {

    const [chamados, setChamados] = useState([])
    const [chamadosTemp, setChamadosTemp] = useState([])
    const [admin, setAdmin] = useState(false)
    const [servicos] = useState({
        auth: firebase.auth(),
        usuario: firebase.firestore().collection('usuarios'),
        categoriaChamados: firebase.firestore().collection('categoriaChamados')
    })

    const [id, setId] = useState('')
    const [categoria, setCategoria] = useState('')
    const [setor, setSetor] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuario.doc(user.uid).get().then((doc) => {
                    setAdmin(true)
                    servicos.categoriaChamados.onSnapshot((snap) => {
                        const data = snap.docs.map(doc => doc.data())
                        setChamados(data)
                    })

                })
            }
        })

    }, [servicos.categoriaChamados])

    function cadastraCategoria() {
        if (!categoria) {
            mensagemAlerta('Favor digite a categoria')
            return
        }
        if (!setor) {
            mensagemAlerta('Favor selecione o setor')
            return
        }
        if (!status) {
            mensagemAlerta('Favor selecione o status')
            return
        }
        
        if (id) {
            mensagemLoading('Atualizando categoria...')
            servicos.categoriaChamados.doc(id).update({
                categoria: categoria,
                setor: setor,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRef) => {
                mensagemSucesso('Categoria atualizada com sucesso')
            }).catch(() => {
                mensagemErro('Erro ao atualizar categoria')
            })
        } else {
            mensagemLoading('Cadastrando categoria...')
            servicos.categoriaChamados.add({
                categoria: categoria,
                setor: setor,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((docRef) => {
                servicos.categoriaChamados.doc(docRef.id).update({
                    id: docRef.id
                }).then((docRef) => {
                    mensagemSucesso('Categoria cadastrada com sucesso')
                }).catch(() => {
                    mensagemErro('Erro ao atualizar categoria')
                })
            }).catch(() => {
                mensagemErro('Erro ao cadastrar categoria')
            })
        }

    }

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando Chamados')
            servicos.chamados.where('status', '==', event.target.value).onSnapshot((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())
                setChamados(data)
                setChamadosTemp(data)
            })
        } else {
            setChamados(chamadosTemp);
        }
    }

    function detalhe(c) {
        setId(c.id)
        setCategoria(c.categoria)
        setSetor(c.setor)
        setStatus(c.status)
    }

    function limpaInput() {
        setId('')
        setCategoria('')
        setSetor(null)
        setStatus(null)
    }

    function removeCategoria(c){
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover a categoria '+c.categoria+' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Removendo Categoria')
                servicos.categoriaChamados.doc(c.id).delete().then(()=>{
                    mensagemSucesso('Categoria removida com sucesso')
                })
            }
        });
    }

    const handleCategoria = (event) => {
        event.persist();
        setCategoria(event.target.value);
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            limpaInput()
        })
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Categorias</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-6'>
                                                    <label>Categoria</label>
                                                    <div class="form-group">
                                                        <input type="text" value={categoria} onChange={handleCategoria} id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Setor</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleSetor}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="DIRETORIA" selected={id && setor === 'DIRETORIA' ? 'selected' : null}>Diretoria </option>
                                                        <option value="CONTROLADORIA" selected={id && setor === 'CONTROLADORIA' ? 'selected' : null}>Controladoria </option>
                                                        <option value="CONTROLE" selected={id && setor === 'CONTROLE' ? 'selected' : null}>Torre de Controle </option>
                                                        <option value="LOGISTICA" selected={id && setor === 'LOGISTICA' ? 'selected' : null}>Logística </option>
                                                        <option value="LABS" selected={id && setor === 'LABS' ? 'selected' : null}>Labs </option>
                                                        <option value="MARKETING" selected={id && setor === 'MARKETING' ? 'selected' : null}>Marketing </option>
                                                        <option value="COMERCIAL" selected={id && setor === 'COMERCIAL' ? 'selected' : null}>Comercial </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus}>
                                                        <option value="">Escolha  </option>
                                                        <option value="ATIVO" selected={id && status === 'ATIVO' ? 'selected' : null} >Ativo  </option>
                                                        <option value="INATIVO" selected={id && status === 'INATIVO' ? 'selected' : null}>Inativo  </option>
                                                    </select>
                                                </div>
                                                {id ?
                                                    <>
                                                        <div className='col-md-2' style={{ marginTop: 30 }}>
                                                            <button type="button" onClick={() => cadastraCategoria()} class="btn btn-success mb-1" >
                                                                Atualizar
                                                            </button>
                                                        </div>
                                                        <div className='col-md-2' style={{ marginTop: 30 }}>
                                                            <button type="button" onClick={() => limpaInput()} class="btn btn-danger mb-1" >
                                                                Descartar
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='col-md-2' style={{ marginTop: 30 }}>
                                                        <button type="button" onClick={() => cadastraCategoria()} class="btn btn-success mb-1" >
                                                            Cadastrar
                                                        </button>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="DIRETORIA">Diretoria </option>
                                                        <option value="CONTROLADORIA">Controladoria </option>
                                                        <option value="CONTROLE">Torre de Controle </option>
                                                        <option value="LOGISTICA">Logística </option>
                                                        <option value="LABS">Labs </option>
                                                        <option value="MARKETING">Marketing </option>
                                                        <option value="COMERCIAL">Comercial </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Categoria</th>
                                                            <th>Setor</th>
                                                            <th>Status</th>
                                                            <th>Cadastro</th>
                                                            <th>Atualização</th>
                                                            <th>Editar</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {chamados.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((categoria) =>
                                                            <tr key={categoria.id} >
                                                                <td>{categoria.categoria}</td>
                                                                <td>{categoria.setor}</td>
                                                                {categoria.status === 'ATIVO' ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-success">ATIVO</span>
                                                                        </button>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-danger">INATIVO</span>
                                                                        </button>
                                                                    </td>
                                                                }
                                                                <td>{moment(categoria.dataCadastro).format('DD/MM/YYYY HH:mm')}</td>
                                                                <td>{moment(categoria.dataAtualizacao).format('DD/MM/YYYY HH:mm')}</td>
                                                                <td>
                                                                    <button onClick={() => detalhe(categoria)}>
                                                                        <span className="badge badge-warning"><i className='fa fa-pencil'></i></span>
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button  onClick={() => removeCategoria(categoria)}>
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {chamados.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem categorias cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
