import React, { useEffect, useState } from 'react';
import moment from 'moment'
import Swal from 'sweetalert2'
import { buscaEndereco } from '../services/Localidades'
import Sidebar from './componentes/Sidebar'
import firebase from 'firebase'
import InputMask from 'react-input-mask'
import { cnpj } from 'cpf-cnpj-validator';
import Bancos from '../services/Bancos.json';

export default function CadastraFornecedor() {
    const [servicos] = useState({
        fornecedores: firebase.firestore().collection('fornecedores'),
        mail:firebase.firestore().collection('mail')
    })

    useEffect(() => {
        setBancos(Bancos)
        // const corpo = {
        //     to: 'guilherme.si2012@gmail.com',
        //     from: 'controladoria@entregaja.com.br',
        //     message: {
        //         subject: 'Confirmação de Cadastro na Entrega Já',
        //         html: '<html>' +
        //             '<body>' +
        //             '<h1><b>Guilherme, </b></h1>' +
        //             '<h2>Cadastro da empresa <b>Empresa Teste</b> foi concluído com sucesso </h2>' +
        //             '<h2>Seu cadastro entrará em análise com a nossa equipe.  </h2>' +
        //             '<h2>Para mais detalhes entre em contato</h2>' +
        //             '<h4><b>Detalhe do Contato<b/></h4>' +
        //             '<h4>Whatsapp: (98)98412-7047</h4>' +
        //             '<h4>Email: controladoria@entregaja.com.br</h4>' +
        //             '<p><b>Equipe Entrega Ja Labs<b/></p>' +
        //             '</body>' +
        //             '</html>'
        //     }
        // }
        // servicos.mail.add(corpo).then(() => {
        //     mensagemSucesso('Cadastro realizado com sucesso...')
        // })
    }, [])

    function documentoContratoSocial(id) {
        Swal.close();
        mensagemLoading('Cadastrando o Contrato Social...')
        var uploadTask = firebase.storage().ref().child('fornecedores/' + id + '/contratosocial.pdf').put(contratoSocial[0], contratoSocial[0].type);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.fornecedores.doc(id).update({
                    documentoContratoSocial: downloadURL
                }).then(() => {
                    documentoCartaoCnpj(id)
                })
            });
        });
    }
    function documentoCartaoCnpj(id) {
        Swal.close();
        mensagemLoading('Cadastrando o Cartão CNPJ...')
        var uploadTask = firebase.storage().ref().child('fornecedores/' + id + '/cartaocnpj.pdf').put(cartaoCnpj[0], cartaoCnpj[0].type);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.fornecedores.doc(id).update({
                    documentoCartaoCnpj: downloadURL
                }).then(() => {
                    documentoDocumentoBancario(id)
                })
            });
        });
    }
    function documentoDocumentoBancario(id) {
        Swal.close();
        mensagemLoading('Cadastrando o Documento Bancário...')
        var uploadTask = firebase.storage().ref().child('fornecedores/' + id + '/documentobancario.pdf').put(documentoBancario[0], documentoBancario[0].type);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.fornecedores.doc(id).update({
                    documentoBancario: downloadURL
                }).then(() => {
                    cadastraSocios(id)
                })
            });
        });
    }

    function cadastraDocumentoSocio(id, nome, arquivo) {
        Swal.close();
        mensagemLoading('Cadastrando o Sócio ' + nome)
        var uploadTask = firebase.storage().ref().child('fornecedores/' + id + '/' + nome).put(arquivo[0], arquivo[0].type);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.fornecedores.doc(id).collection('socios').add({
                    nome: nome,
                    arquivo: downloadURL
                }).then((docRef) => {
                    servicos.fornecedores.doc(id).collection('socios').doc(docRef.id).update({
                        id: docRef.id
                    })
                })
            });
        });
    }

    function cadastraFornecedor() {
        if (!cnpj.isValid(textCnpj.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('Número CNPJ inválido')
            return
        }
        if (!nomeFantasia) {
            mensagemAlerta('Digite o Nome Fantasia da Empresa')
            return
        }
        if (!razaoSocial) {
            mensagemAlerta('Digite a Razão Social da Empresa')
            return
        }
        if (!ie) {
            mensagemAlerta('Digite a Inscrição Estadual da Empresa')
            return
        }
        if (!atuacao) {
            mensagemAlerta('Digite seu ramo de atuação')
            return
        }
        if (!cep || cep.length < 8) {
            mensagemAlerta('Digite o cep da Empresa')
            return
        }
        if (!endereco) {
            mensagemAlerta('Digite o endereço  da Empresa')
            return
        }
        if (!bairro) {
            mensagemAlerta('Digite o bairro  da Empresa')
            return
        }
        if (!numero) {
            mensagemAlerta('Digite o número do endereço, caso não tenha, digite S/N')
            return
        }
        if (!cidade) {
            mensagemAlerta('Digite a cidade da Empresa')
            return
        }
        if (!uf) {
            mensagemAlerta('Digite o Estado da Empresa')
            return
        }
        if (!nomePrimario) {
            mensagemAlerta('Digite o nome do contato Primário')
            return
        }
        if (whatsappPrimario.replace(/[^a-z0-9]/gi, '').length < 11) {
            console.log(whatsappPrimario.replace(/[^a-z0-9]/gi, '').length)
            mensagemAlerta('Digite o número do contato Primário')
            return
        }
        if (!emailPrimario) {
            mensagemAlerta('Digite o email do contato Primário')
            return
        }
        if (!contratoSocial) {
            mensagemAlerta('Escolha o arquivo do Contrato Social')
            return
        }
        if (!cartaoCnpj) {
            mensagemAlerta('Escolha o arquivo do Cartão CNPJ')
            return
        }
        if (!banco.label) {
            mensagemAlerta('Escolha por favor o banco')
            return
        }
        if (!favorecido) {
            mensagemAlerta('Digite o Favorecido')
            return
        }
        if (!documentoBancario) {
            mensagemAlerta('Escolha o arquivo do Documento Banário')
            return
        }
        if (!agencia) {
            mensagemAlerta('Digite a agência da Conta')
            return
        }
        if (!cnpj.isValid(chavePix.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('Chave PIX inválida!')
            return
        }
        if (!observacao) {
            mensagemAlerta('Digite quais áreas de atendimento')
            return
        }
        if (socios.length === 0) {
            mensagemAlerta('Adicione todos os sócios da Empresa')
            return
        }
        mensagemLoading('Cadastrando Fornecedor...')
        servicos.fornecedores.where('cnpj', '==', textCnpj).get().then((snap) => {
            if (snap.size > 0) {
                mensagemAlerta('Este CNPJ já está vinculado no nosso Banco de Dados')
            } else {
                servicos.fornecedores.add({
                    cnpj: textCnpj,
                    nomeFantasia: nomeFantasia,
                    razaoSocial: razaoSocial,
                    ie:ie,
                    atuacao: atuacao,
                    bancario: {
                        favorecido: favorecido,
                        conta: conta,
                        agencia: agencia,
                        chavePix: chavePix,
                        banco: banco.label,
                        codigoBanco: banco.value
                    },
                    endereco: {
                        cep: cep,
                        endereco: endereco,
                        bairro: bairro,
                        numero: numero,
                        cidade: cidade,
                        uf: uf,
                        complemento: complemento,
                        referencia: referencia
                    },
                    contatoPrimario: {
                        nome: nomePrimario,
                        whatsapp: whatsappPrimario,
                        email: emailPrimario
                    },
                    contatoSecundario: {
                        nome: nomeSecundario,
                        whatsapp: whatsappSecundario,
                        email: emailSecundario
                    },
                    observacao:observacao,
                    site: site,
                    status:'CADASTRADO',
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                }).then((docRef) => {
                    servicos.fornecedores.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        documentoContratoSocial(docRef.id)
                    }).catch(() => {
                        mensagemErro('Erro ao atualizar o fornecedor')
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar o fornecedor')
                })
            }
        })

    }

    function adicionaSocio() {
        const list = []
        if (!nomeSocio) {
            mensagemAlerta('Por favor digite o nome do sócio')
            return
        }
        if (!documentoSocio) {
            mensagemAlerta('Por favor escolha o documento do sócio')
            return
        }
        list.push(
            ...socios,
            {
                id: socios.length + 1,
                nome: nomeSocio,
                arquivo: documentoSocio
            }
        )
        setSocios(list)
    }

    function cadastraSocios(id) {
        socios.forEach((d) => {
            cadastraDocumentoSocio(id, d.nome, d.arquivo)
        })
        setTimeout(() => {
            const corpo = {
                to: emailPrimario,
                from: 'controladoria@entregaja.com.br',
                message: {
                    subject: 'Confirmação de Cadastro na Entrega Já',
                    html: '<html>' +
                        '<body>' +
                        '<h1><b>' + nomePrimario + ', </b></h1>' +
                        '<h2>Cadastro da empresa <b>' + nomeFantasia + '</b> foi concluído com sucesso </h2>' +
                        '<h2>Seu cadastro entrará em análise com a nossa equipe.  </h2>' +
                        '<h2>Para mais detalhes entre em contato</h2>' +
                        '<h4><b>Detalhe do Contato<b/></h4>' +
                        '<h4>Whatsapp: (98)98412-7047</h4>' +
                        '<h4>Email: controladoria@entregaja.com.br</h4>' +
                        '<p><b>Equipe Entrega Ja Labs<b/></p>' +
                        '</body>' +
                        '</html>'
                }
            }
            servicos.mail.add(corpo).then(() => {
                mensagemSucesso('Cadastro realizado com sucesso...')
            })
        }, 10000);
    }

    function removeSocio(id) {
        const list = []
        socios.forEach((d) => {
            if (d.id !== id) {
                list.push(d)
            }
        })
        setSocios(list)
    }

    // Dados da Empresa
    const [textCnpj, setCnpj] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [ie, setIe] = useState('')
    const [atuacao, setAtuacao] = useState('')

    // Endereço
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [complemento, setComplemento] = useState('')
    const [referencia, setReferencia] = useState('')
    const [ibge, setIbge] = useState('')

    // Contatos
    const [nomePrimario, setNomePrimario] = useState('')
    const [whatsappPrimario, setWhatsAppPrimario] = useState('')
    const [emailPrimario, setEmailPrimario] = useState('')

    const [nomeSecundario, setNomeSecundario] = useState('')
    const [whatsappSecundario, setWhatsAppSecundario] = useState('')
    const [emailSecundario, setEmailSecundario] = useState('')
    const [site, setSite] = useState('')

    // Documentos Importantes
    var metadata = { contentType: 'image/jpeg' };
    const [contratoSocial, setContratoSocial] = useState('');
    const [cartaoCnpj, setCartaoCnpj] = useState('');

    // Documentos Bançarios
    const [bancos, setBancos] = useState([])
    const [banco, setBanco] = useState({})
    const [favorecido, setFavorecido] = useState('')
    const [agencia, setAgencia] = useState('')
    const [conta, setConta] = useState('')
    const [pix, setPix] = useState('')
    const [chavePix, setChavePix] = useState('')
    const [documentoBancario, setDocumentoBancario] = useState('');

    // Nome Socio
    const [nomeSocio, setNomeSocio] = useState('')
    const [documentoSocio, setDocumentoSocio] = useState('')
    const [socios, setSocios] = useState([])

    //Descrição da área
    const [observacao, setObservacao] = useState('')

    const handleCnpj = (event) => {
        event.persist();
        setCnpj(event.target.value);
    }
    const handleNomeFantasia = (event) => {
        event.persist();
        setNomeFantasia(event.target.value);
    }
    const handleRazaoSocial = (event) => {
        event.persist();
        setRazaoSocial(event.target.value);
    }
    const handleIe = (event) => {
        event.persist();
        setIe(event.target.value);
    }
    const handleAtuacao = (event) => {
        event.persist();
        setAtuacao(event.target.value);
    }

    // Endereço
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Buscando Endereço...')
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    mensagemAlerta('Endereço não localizado')
                    setEndereco('')
                    setBairro('')
                    setUf('')
                    setCidade('')
                    setIbge('')
                } else {
                    Swal.close()
                    setEndereco(dados.logradouro)
                    setBairro(dados.bairro)
                    setUf(dados.uf)
                    setCidade(dados.localidade)
                    setIbge(dados.ibge)
                }
            })
        } else {
            setEndereco('')
            setBairro('')
            setUf('')
            setCidade('')
            setIbge('')
        }
    }

    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }

    // Contatos Gerais
    const handleNomePrimario = (event) => {
        event.persist();
        setNomePrimario(event.target.value);
    }
    const handleWhatsappPrimario = (event) => {
        event.persist();
        setWhatsAppPrimario(event.target.value);
    }
    const handleEmailPrimario = (event) => {
        event.persist();
        setEmailPrimario(event.target.value);
    }
    const handleNomeSecundario = (event) => {
        event.persist();
        setNomeSecundario(event.target.value);
    }
    const handleWhatsappSecundario = (event) => {
        event.persist();
        setWhatsAppSecundario(event.target.value);
    }
    const handleEmailSecundario = (event) => {
        event.persist();
        setEmailSecundario(event.target.value);
    }
    const handleSite = (event) => {
        event.persist();
        setSite(event.target.value);
    }

    // Documentos Importantes
    const handleContratoSocial = (event) => {
        event.persist();
        setContratoSocial(event.target.files)
    }
    const handleCartaoCnpj = (event) => {
        event.persist();
        setCartaoCnpj(event.target.files)
    }

    // Conta Bancaria
    const handleBancos = (event) => {
        event.persist();
        if (event.target.value) {
            setBanco(bancos[event.target.value]);
        }
    }
    const handleFavorecido = (event) => {
        event.persist();
        setFavorecido(event.target.value);
    }
    const handleAgencia = (event) => {
        event.persist();
        setAgencia(event.target.value);
    }
    const handleConta = (event) => {
        event.persist();
        setConta(event.target.value);
    }
    const handlePix = (event) => {
        event.persist();
        setPix(event.target.value);
    }
    const handleChavePix = (event) => {
        event.persist();
        setChavePix(event.target.value);
    }
    const handleDocumentoBancario = (event) => {
        event.persist();
        setDocumentoBancario(event.target.files)
    }

    // Documentos Importantes
    const handleNomeSocio = (event) => {
        event.persist();
        setNomeSocio(event.target.value)
    }
    const handleDocumentoSocio = (event) => {
        event.persist();
        setDocumentoSocio(event.target.files)
    }

    // Dados descrição
    const handleObservacao = (event) => {
        event.persist();
        setObservacao(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location.reload()
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />

            <div id="right-panel" className="right-panel">

                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Atualização Cadastral de Fornecedores</h2>
                                            </div>
                                            <p>Olá fornecedor, abaixo digite suas informações para que o nosso banco de dados esteja atualizado.</p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-2'>
                                                    <label>CNPJ</label>
                                                    <div class="form-group">
                                                        {/* <input value={cnpj} onChange={handleCnpj} type="text" id="company" class="form-control" /> */}
                                                        <InputMask value={textCnpj} onChange={handleCnpj} class="form-control" mask="99.999.999/9999-99" maskChar=" " />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Nome Fantasia</label>
                                                    <div class="form-group">
                                                        <input value={nomeFantasia} onChange={handleNomeFantasia} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Razão Social</label>
                                                    <div class="form-group">
                                                        <input value={razaoSocial} onChange={handleRazaoSocial} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Inscrição Estadual</label>
                                                    <div class="form-group">
                                                        <input value={ie} onChange={handleIe} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Ramo de atuação</label>
                                                    <div class="form-group">
                                                        <input value={atuacao} onChange={handleAtuacao} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Dados de Endereço da Empresa</h2>
                                            </div>
                                            <p>Forneça abaixo o endereço referente a localização atual da Empresa.</p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-2'>
                                                    <label>CEP</label>
                                                    <div class="form-group">
                                                        <input value={cep} maxLength={8} onChange={handleCep} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input value={endereco} onChange={handleEndereco} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input value={bairro} onChange={handleBairro} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input value={numero} onChange={handleNumero} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input value={cidade} onChange={handleCidade} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input value={uf} onChange={handleUf} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input value={complemento} onChange={handleComplemento} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Ponto de Referência</label>
                                                    <div class="form-group">
                                                        <input value={referencia} onChange={handleReferencia} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Contatos Gerais</h2>
                                            </div>
                                            <p>Forneça abaixo os contatos gerais da empresa. Informações com * são obrigatórios.</p><br />
                                            <p><b>Observações *</b></p>
                                            <p>O <b>contato principal</b> se refere a pessoa responsável pelas negociações de compras e prestações de serviços da Entrega Já.</p>
                                            <p>O <b>contato secundário</b> se refere a pessoa responsável na ausência da pessoa principal.</p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-5'>
                                                    <label>Nome do contato principal *</label>
                                                    <div class="form-group">
                                                        <input value={nomePrimario} onChange={handleNomePrimario} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Celular(WhatApp) *</label>
                                                    <div class="form-group">
                                                        {/* <input value={whatsappPrimario} onChange={handleWhatsappPrimario} type="text" id="company" class="form-control" /> */}
                                                        <InputMask value={whatsappPrimario} onChange={handleWhatsappPrimario} class="form-control" mask="(99) 99999-9999" maskChar=" " />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>E-mail *</label>
                                                    <div class="form-group">
                                                        <input value={emailPrimario} onChange={handleEmailPrimario} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row col-md-12' >
                                                <div className='col-md-5'>
                                                    <label>Nome do contato secundário </label>
                                                    <div class="form-group">
                                                        <input value={nomeSecundario} onChange={handleNomeSecundario} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Celular(WhatApp)</label>
                                                    <div class="form-group">
                                                        <InputMask value={whatsappSecundario} onChange={handleWhatsappSecundario} class="form-control" mask="(99) 99999-9999" maskChar=" " />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>E-mail</label>
                                                    <div class="form-group">
                                                        <input value={emailSecundario} onChange={handleEmailSecundario} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Site da Empresa</label>
                                                    <div class="form-group">
                                                        <input value={site} onChange={handleSite} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Documentos Importantes</h2>
                                            </div>
                                            <p>Forneça abaixo os documentos solicitados para atualização cadastral em nosso banco de dados. </p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Documento Contrato Social (.pdf)</label>
                                                    <div class="form-group">
                                                        <input accept='.pdf' onChange={handleContratoSocial} type="file" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Documento Cartão CNPJ (.pdf)</label>
                                                    <div class="form-group">
                                                        <input accept='.pdf' onChange={handleCartaoCnpj} type="file" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Conta Bancária</h2>
                                            </div>
                                            <p>Forneça abaixo as informações bancárias </p><br />
                                            <p><b>Observações *</b></p>
                                            <p><b>Documento Bancário: </b>Refere-se a imagem da conta bancária favorecida. </p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Banco</label>
                                                    <div class="form-group">
                                                        <select name="select" id="select" class="form-control" onChange={handleBancos} >
                                                            <option value="">Selecione </option>
                                                            {bancos.sort((a, b) => {
                                                                if (a.label < b.label)
                                                                    return -1;
                                                                if (a.label > b.label)
                                                                    return 1;
                                                            }).map((b, index) =>
                                                                <option value={index} >{b.label} </option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Favorecido</label>
                                                    <div class="form-group">
                                                        <input value={favorecido} onChange={handleFavorecido} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Documento Bancário (imagens)</label>
                                                    <div class="form-group">
                                                        <input accept='.jpg, .jpeg, .png' onChange={handleDocumentoBancario} type="file" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Agência</label>
                                                    <div class="form-group">
                                                        <input value={agencia} onChange={handleAgencia} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Conta</label>
                                                    <div class="form-group">
                                                        <input value={conta} onChange={handleConta} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Chave Pix (CNPJ)</label>
                                                    <div class="form-group">
                                                        <InputMask value={chavePix} onChange={handleChavePix} class="form-control" mask="99.999.999/9999-99" maskChar=" " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Área de Atuação</h2>
                                            </div>
                                            <p>Digite abaixo em quais localidades você atende </p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-12'>
                                                    <label>Descrição </label>
                                                    <div class="form-group">
                                                        <textarea value={observacao} onChange={handleObservacao} type="text" id="company" class="form-control" placeholder='Exemplo: Nossa empresa presta serviço para todo o Maranhão, Belém, todo Brasil, etc...' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h2 className="box-title">Documentos dos Sócios</h2>
                                            </div>
                                            <p>Cadastre abaixo as informações básicas dos seus sócios </p>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Nome Completo</label>
                                                    <div class="form-group">
                                                        <input value={nomeSocio} onChange={handleNomeSocio} type="text" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Documento Sócio</label>
                                                    <div class="form-group">
                                                        <input onChange={handleDocumentoSocio} type="file" id="company" class="form-control" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    <button onClick={() => adicionaSocio()} type="button" class="btn btn-success mb-1">
                                                        Adicionar Sócio
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Arquivo</th>
                                                            <th>Nome do Sócio</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {socios.map((socio, index) =>
                                                            <tr key={index} >
                                                                <td>{socio.nome}</td>
                                                                <td>{socio.nome}</td>
                                                                <td>
                                                                    <button onClick={() => removeSocio(socio.id)}>
                                                                        <span className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {socios.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem sócios adicionados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div >
                                        <button onClick={() => cadastraFornecedor()} type="button" class="btn btn-success mb-1">
                                            Salvar informações
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Header /> */}
            </div>
        </div>

    );
}
