import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment';
import { Link } from 'react-router-dom'
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

export default function PlanilhaLeads() {

    const [sistemas, setSistemas] = useState([])
    const [file, setFile] = useState('')
    const [servicos] = useState({
        leeds: firebase.firestore().collection('leads')
    })

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        setFile(fileObj)
        const list = []
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                const arquivo = {
                    cols: resp.cols,
                    rows: resp.rows
                };
                var count = arquivo.rows.length;
                console.log(resp.rows[0]);
                arquivo.rows.forEach(element => {
                    list.push({
                        nome: element[0],
                        cpf: element[1],
                        data: element[2],
                        casa: element[3],
                        carro: element[4],
                    })
                });
                setSistemas(list)
            }
        });
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Importação de Planilha</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-6">
                                                    <label>Selecione o arquivo Excel para ser carregado</label>
                                                    <input accept=".xlsx, .xls" onChange={fileHandler} type="file" name="name" placeholder="Escolha os arquivos " />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>CPF</th>
                                                            <th>Data</th>
                                                            <th>Casa</th>
                                                            <th>Carro</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sistemas.sort((a, b) => {
                                                            return b.nivel - a.nivel;
                                                        }).map((sistema) =>
                                                            <tr key={sistema.cpf} >
                                                                <td>{sistema.nome}</td>
                                                                <td>{sistema.cpf}</td>
                                                                <td>{sistema.data}</td>
                                                                <td>{sistema.casa}</td>
                                                                <td>{sistema.carro}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {sistemas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem Leads cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
