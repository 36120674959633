import React, { useEffect, useState } from 'react';
import Header from '../../../../componentes/Header'
import Sidebar from '../../../../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask';
import 'suneditor/dist/css/suneditor.min.css';

export default function CadastroAvaria() {

    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        avarias: firebase.firestore().collection('avarias'),
        filiais: firebase.firestore().collection('filiais'),
    })
    const [usuario, setUsuario] = useState({})
    const [filial, setFilial] = useState({})

    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [contato, setContato] = useState('')

    const [tipoCliente, setTipoCliente] = useState('')
    const [clientes, setClientes] = useState([])
    const [cliente, setCliente] = useState({})
    const [alocacao, setAlocao] = useState('')

    const [placa, setPlaca] = useState('')
    const [servico, setServico] = useState('')
    const [pedido, setPedido] = useState('')
    const [notaFiscal, setNotaFiscal] = useState('')
    const [dataRecebimento, setDataRecebimento] = useState('')
    const [descricao, setDescricao] = useState('')
    const [status, setStatus] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [imagem1, setImagem1] = useState('');
    const [imagem2, setImagem2] = useState('');
    const [imagem3, setImagem3] = useState('');
    const [imagem4, setImagem4] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
            servicos.clientes.where('status', '==', 'ATIVO').get().then((snap) => {
                const data = snap.docs.map((d) => d.data())
                setClientes(data);
            })
            servicos.filiais.where('responsavel.id', '==', user.uid).get().then((snap) => {
                if (snap.size > 0) {
                    const data = snap.docs.map((d) => d.data())[0]
                    setFilial(data);
                } else {
                    mensagemAlerta('Este usuario não está vinculado a nenhuma filial')
                }
            })
        })
    }, [servicos.usuarios, servicos.avarias])


    function cadastraImagem1(id) {
        mensagemLoading('Salvando a imagem 01...')
        var uploadTask = firebase.storage().ref().child('avarias/' + id + 'imagem1' + id).put(imagem1[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.avarias.doc(id).update({
                    imagem1: downloadURL
                }).then(() => {
                    Swal.close();
                    cadastraImagem2(id)
                })
            });
        });
    }
    function cadastraImagem2(id) {
        mensagemLoading('Salvando a imagem 2...')
        var uploadTask = firebase.storage().ref().child('avarias/' + id + 'imagem2' + id).put(imagem2[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');

                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.avarias.doc(id).update({
                    imagem2: downloadURL
                }).then(() => {
                    Swal.close();
                    if (imagem3) {
                        cadastraImagem3(id)
                    } else {
                        mensagemSucesso('Avaria cadastrada com sucesso! Vocẽ será redirecionado a lista de avarias para gerar o contrato ')
                    }

                })
            });
        });
    }
    function cadastraImagem3(id) {
        mensagemLoading('Salvando a imagem 3...')
        var uploadTask = firebase.storage().ref().child('avarias/' + id + 'imagem3' + id).put(imagem3[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');

                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.avarias.doc(id).update({
                    imagem3: downloadURL
                }).then(() => {
                    Swal.close();
                    if (imagem4) {
                        cadastraImagem4(id)
                    } else {
                        mensagemSucesso('Avaria cadastrada com sucesso! Vocẽ será redirecionado a lista de avarias para gerar o contrato ')
                    }
                })
            });
        });
    }
    function cadastraImagem4(id) {
        mensagemLoading('Salvando a imagem 4...')
        var uploadTask = firebase.storage().ref().child('avarias/' + id + 'imagem4' + id).put(imagem4[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');

                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.avarias.doc(id).update({
                    imagem4: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Avaria cadastrada com sucesso! Vocẽ será redirecionado a lista de avarias para gerar o contrato ')

                })
            });
        });
    }

    function cadastraAvaria() {
        if (imagem1 === '') {
            mensagemAlerta('Favor selecione a imagem 1')
            setLoading(false)
        } else if (imagem2 === '') {
            mensagemAlerta('Favor selecione a imagem 2')
            setLoading(false)
        } else if (nome === '') {
            mensagemAlerta('Favor digitar o nome do motorista')
            setLoading(false)
        } else if (cpf === '') {
            mensagemAlerta('CPF do motorista vazio ou incompleto')
            setLoading(false)
        } else if (contato === '') {
            mensagemAlerta('Contato do motorista vazio ou incompleto')
            setLoading(false)
        } else if (tipoCliente === '') {
            mensagemAlerta('Favor selecione se é Entrega Já ou Cliente externo')
            setLoading(false)
        } else if (tipoCliente === 'externo' && !cliente.id) {
            mensagemAlerta('Favor selecione o cliente')
            setLoading(false)
        } else if (alocacao === '') {
            mensagemAlerta('Favor selecione a alocação do Motorista')
            setLoading(false)
        } else if (placa === '') {
            mensagemAlerta('Favor digite a Placa do Veículo')
            setLoading(false)
        } else if (servico === '') {
            mensagemAlerta('Favor selecione o tipo de serviço')
            setLoading(false)
        } else if (notaFiscal === '') {
            mensagemAlerta('Favor digite o número da Nota Fiscal')
            setLoading(false)
        } else if (descricao === '') {
            mensagemAlerta('Favor descreva o ocorrido ')
            setLoading(false)
        } else {
            mensagemLoading('Aguarde.. Cadastrando Avaria')
            servicos.avarias.add({
                nome: nome,
                cpf: cpf,
                contato: contato,
                tipoCliente: tipoCliente,
                cliente: cliente,
                alocacao: alocacao,
                placa: placa,
                servico: servico,
                pedido: pedido,
                notaFiscal: notaFiscal,
                dataRecebimento: moment().format('YYYY-MM-DD HH:mm'),
                descricao: descricao,
                status: 'ABERTO',
                contrato: null,
                filial: filial,
                responsavel: {
                    id: usuario.id,
                    nome: usuario.nome
                },
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
            }).then((docRef) => {
                servicos.avarias.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    Swal.close()
                    cadastraImagem1(docRef.id)
                }).catch(() => {
                    mensagemErro('Erro ao atualizar a avaria')
                })
            }).catch(() => {
                mensagemErro('Erro ao cadastrar a avaria')
            })
        }
    }


    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleTipoCliente = (event) => {
        event.persist();
        if (event.target.value) {
            setTipoCliente(event.target.value);
        }
    }
    const handleCliente = (event) => {
        event.persist();
        if (event.target.value) {
            setCliente(clientes[event.target.value]);
        }
    }
    const handleAlocacao = (event) => {
        event.persist();
        if (event.target.value) {
            setAlocao(event.target.value);
        }
    }
    const handlePlaca = (event) => {
        event.persist();
        setPlaca(event.target.value);
    }
    const handleServico = (event) => {
        event.persist();
        setServico(event.target.value);
    }
    const handlePedido = (event) => {
        event.persist();
        setPedido(event.target.value);
    }
    const handleNotaFiscal = (event) => {
        event.persist();
        setNotaFiscal(event.target.value);
    }
    const handleDataRecebimento = (event) => {
        event.persist();
        setDataRecebimento(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }

    const handleImagem1 = (event) => {
        event.persist();
        setImagem1(event.target.files)
    }
    const handleImagem2 = (event) => {
        event.persist();
        setImagem2(event.target.files)
    }
    const handleImagem3 = (event) => {
        event.persist();
        setImagem3(event.target.files)
    }
    const handleImagem4 = (event) => {
        event.persist();
        setImagem4(event.target.files)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back();
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    {erro ?
                        <div class="alert alert-danger" role="alert">{erro}</div>
                        :
                        null
                    }
                    {sucesso ?
                        <div class="alert alert-success" role="alert">{sucesso}</div>
                        :
                        null
                    }
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Nova Avaria</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-4'>
                                                    <label>Motorista</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>CPF</label>
                                                    <div class="form-group">
                                                        <InputMask mask="999.999.999-99" class="form-control" maskChar={null} value={cpf} onChange={handleCpf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <InputMask mask="(99) 99999-9999" class="form-control" maskChar={null} value={contato} onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Tipoo de Cliente</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTipoCliente} >
                                                        <option value="">Escolha  </option>
                                                        <option value="interno">Entrega Já </option>
                                                        <option value="externo">Cliente Externo </option>
                                                    </select>
                                                </div>
                                                {tipoCliente && tipoCliente === 'externo' ?
                                                    <div className='col-md-3'>
                                                        <label>Clientes</label>
                                                        <select name="select" id="select" class="form-control" onChange={handleCliente} >
                                                            <option value="">Escolha  </option>
                                                            {clientes.map((d, index) =>
                                                                <option value={index}>{d.nome}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className='col-md-2'>
                                                    <label>Alocação</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleAlocacao} >
                                                        <option value="">Escolha  </option>
                                                        <option value="proprio">Próprio </option>
                                                        <option value="agregado">Agregado </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Placa do Veículo</label>
                                                    <div class="form-group">
                                                        <InputMask mask="aaa-****" class="form-control" maskChar={null} value={placa} onChange={handlePlaca} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Tipo de Serviço</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleServico} >
                                                        <option value="">Escolha  </option>
                                                        <option value="VENDA">Venda </option>
                                                        <option value="ABA">Abastecimento </option>
                                                        <option value="PUXADA">Puxada </option>
                                                        <option value="RODOVIARIO">Rodoviário </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>N° Pedido</label>
                                                    <div class="form-group">
                                                        <input type="text" value={pedido} id="company" class="form-control" onChange={handlePedido} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Nota Fiscal</label>
                                                    <div class="form-group">
                                                        <input type="text" value={notaFiscal} id="company" class="form-control" onChange={handleNotaFiscal} />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label>Descrição</label>
                                                    <div class="form-group">
                                                        <textarea type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Imagem 01 * (OBRIGATÓRIA)</label>
                                                    <div class="form-group">
                                                        <input type="file" id="company" class="form-control" onChange={handleImagem1} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Imagem 02 * (OBRIGATÓRIA)</label>
                                                    <div class="form-group">
                                                        <input type="file" id="company" class="form-control" onChange={handleImagem2} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Imagem 03</label>
                                                    <div class="form-group">
                                                        <input type="file" id="company" class="form-control" onChange={handleImagem3} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Imagem 04</label>
                                                    <div class="form-group">
                                                        <input type="file" id="company" class="form-control" onChange={handleImagem4} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Responsável da Abertura</label>
                                                    <div class="form-group">
                                                        <input type="text" value={usuario.nome} id="company" class="form-control" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            {filial.id ?
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Avaria ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraAvaria()}>
                                                            Cadastrar Avaria
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
