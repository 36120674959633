import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Logística() {
    const [filiais, setFiliais] = useState([])
    const [catalogo, setCatlogo] = useState([])
    const [solicitacoes, setSolicitacoes] = useState([])
    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth(),
        filiais: firebase.firestore().collection('filiais'),
        reembalados: firebase.firestore().collection('reembalados'),
        usuarios: firebase.firestore().collection('usuarios'),
        vendas: firebase.firestore().collection('vendas'),
    })

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    if (doc.data().usuario === 'MASTER') {
                        servicos.filiais.where('status', '==', 'ATIVO').onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setFiliais(data)
                        })
                        servicos.vendas.where('status', '==', 'ABERTO').onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setSolicitacoes(data)
                        })
                    } else {
                        servicos.reembalados.where('qtd', '>', 0).onSnapshot((snap) => {
                            const data = snap.docs.map(doc => doc.data())
                            setCatlogo(data)
                        })
                    }
                })
            }
        })
    }, [servicos.filiais])

    const handleFiltro = (event) => {
        event.persist();
        servicos.vendas.where("status", "==", "" + event.target.value).get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setSolicitacoes(data)
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            {usuario && usuario.usuario === "MASTER" ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-10">
                                                        <h4 className="box-title">Reembalados</h4>
                                                        <p>Escolha abaixo a filial para verificar os produtos</p>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Link to={{ pathname: '/catalogoExterno' }} target="_blank" >
                                                            <button type="button" class="btn btn-success mb-1" >
                                                                Link do Catalogo
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                                        {filiais.map((filial) =>
                                                            <div className="col-lg-4 col-md-6">
                                                                <Link to={{ pathname: '/produtosReembalados', state: { filial: filial } }} >
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="stat-widget-five">
                                                                                <div className="stat-icon dib flat-color-3">
                                                                                    <i className="pe-7s-box2"></i>
                                                                                </div>
                                                                                <div className="stat-content">
                                                                                    <div className="text-left dib">
                                                                                        <div className="stat-text">{filial.nome}</div>
                                                                                        <div className="stat-heading">Responsável: {filial.responsavel.nome} {filial.responsavel.sobrenome}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-12">
                                                        <h4 className="box-title">Catálogo</h4>
                                                        <p>Escolha abaixo os produtos para venda</p>
                                                    </div>
                                                    <div className="row col-sm-12" style={{ marginTop: 20 }}>
                                                        {catalogo.map((catalogo) =>
                                                            <div className="col-lg-4 col-md-6">
                                                                <Link to={{ pathname: '/catalogoReembalados', state: { catalogo: catalogo } }} >
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                                                                                <img class="card-img-top" style={{ height: 150, width: 150 }} src={catalogo.avatar} alt='carregando...' />
                                                                            </div>
                                                                            <div style={{ flexDirection: "column", alignItems: "center", textAlign: "center", marginTop: 20 }}>
                                                                                <h4 ><b>{catalogo.descricao}</b></h4>
                                                                            </div>
                                                                            <div style={{ flexDirection: "column", alignItems: "center", textAlign: "center", marginTop: 20 }} >
                                                                                <h4 >Qtd: {catalogo.qtd}</h4>
                                                                            </div>
                                                                            <div style={{ flexDirection: "column", alignItems: "center", textAlign: "center", marginTop: 10 }} >
                                                                                <div className="col-md-12">
                                                                                    <h4 >Un: {catalogo.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {usuario && usuario.usuario === "MASTER" ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-12">
                                                        <h4 className="box-title">Solicitações</h4>
                                                        <p>Abaixo a lista de produtos para avaliação de compras</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-3">
                                                        <h4 className="box-title">Filtro</h4>
                                                        <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                            <option value="">Escolha  </option>
                                                            <option value="ABERTO">Abertos  </option>
                                                            <option value="VENDIDO">Vendidos </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className="table-stats order-table ov-h">
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Produto</th>
                                                                <th>Filial</th>
                                                                <th>Pagamento</th>
                                                                <th>Cliente</th>
                                                                <th>Qtd</th>
                                                                <th>Valor</th>
                                                                <th>Detalhe</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {solicitacoes.map((solicitacao) =>
                                                                <tr key={solicitacao.id} >
                                                                    <td>{solicitacao.produto.descricao}</td>
                                                                    <td>{solicitacao.filial.nome}</td>
                                                                    <td>{solicitacao.pagamento}</td>
                                                                    <td>{solicitacao.cliente.nome}</td>
                                                                    <td>{solicitacao.qtd}</td>
                                                                    {solicitacao.pagamento === 'vista' ?
                                                                        <td>{((solicitacao.valor * solicitacao.qtd) - ((solicitacao.valor * solicitacao.qtd) * 0.1)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                        :
                                                                        <td>{(solicitacao.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                    }

                                                                    <td>
                                                                        <Link to={{ pathname: '/detalheCatalogoReembalados', state: { produto: solicitacao } }} >
                                                                            <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {solicitacoes.length === 0 ?
                                                        <p style={{ textAlign: 'center' }} >Sem solicitações cadastrados</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
