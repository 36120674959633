import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Home() {

    const [agregados, setAgregados] = useState([])
    const [agregadosTemp, setAgregadosTemp] = useState([])
    const [servicos] = useState({
        agregados: firebase.firestore().collection('formulario').where('status','==',2)
    })

    useEffect(() => {
        servicos.agregados.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setAgregados(data)
            setAgregadosTemp(data)
        })
    }, [servicos.agregados])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            if (event.target.value === '0') {
                setAgregados(agregadosTemp.filter((d) => !d.status));
            } else if (event.target.value === '1') {
                setAgregados(agregadosTemp.filter((d) => d.status === 1));
            } else if (event.target.value === '2') {
                setAgregados(agregadosTemp.filter((d) => d.status === 2));
            }else {
                setAgregados(agregadosTemp);
            }
        } else {
            setAgregados(agregadosTemp);
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-1">
                                                <i className="pe-7s-id"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{agregados.length}</div>
                                                    <div className="stat-heading">Cadastros Geral</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{agregados.filter((d) => d.status === 1).length}</div>
                                                    <div className="stat-heading">Motoristas Cadastrados</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{agregados.filter((d) => d.status === 2).length}</div>
                                                    <div className="stat-heading">Cadastros Aceitos</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-display2"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{aulas.length}</div>
                                                    <div className="stat-heading">Filiais</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-note2"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{matriculas.length}</div>
                                                    <div className="stat-heading">Serviços</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h4 className="box-title">Motoristas Cadastrados</h4>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-4'>
                                                    <label>Filtros</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="0">Não finalizados </option>
                                                        <option value="1">Recebidos </option>
                                                        <option value="2">Aceitos </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Celular</th>
                                                            <th>Email</th>
                                                            <th>Cidade</th>
                                                            <th>Tipo de Veículo</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {agregados.sort((a, b) => {
                                                            if (a.nome < b.nome) {
                                                                return -1;
                                                            }
                                                            if (a.nome > b.nome) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((agregado) =>
                                                            <tr key={agregado.id} >
                                                                <td>{agregado.nome}</td>
                                                                <td>{agregado.ddd} {agregado.celular}</td>
                                                                <td>{agregado.email}</td>
                                                                <td>{agregado.cidade}</td>
                                                                <td>{agregado.veiculo}</td>
                                                                <td>
                                                                    <Link to={{ pathname: '/motorista', state: { agregado: agregado } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {agregados.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem casatrados recebidos</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
