import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import firebase from 'firebase'

export default function Sidebar() {
    useEffect(() => {
        console.log(window.location.pathname)
    }, [])
    return (
        <>
            <div class="modal fade" id="modalExemplo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Vídeo Institucional</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <iframe width={460} height={315} src="https://www.youtube-nocookie.com/embed/Pu-T48lQ8as?si=OlK1jUg1QjPjhHI8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            <aside id="left-panel" className="left-panel">

                <nav className="navbar navbar-expand-sm navbar-default">
                    <div id="main-menu" className="main-menu collapse navbar-collapse">

                        {window.location.pathname.replace('/', '') === 'cadastroFornecedor' ?
                            <ul className="nav navbar-nav">
                                <li className="menu-title">Cadastro Fornecedor</li>
                                <li>
                                    <p>Bem vindo Fornecedor </p>
                                </li>
                                <li>
                                    <p>O objetivo deste formulário é realizar a atualização cadastral de todos os nossos fornecedores para que possamos ter nosso banco de dados, informações mais atualizadas para melhor atendê-los.</p>
                                </li>

                            </ul>
                            : window.location.pathname.replace('/', '') === 'envianfe' ?
                                <ul className="nav navbar-nav">
                                    <li className="menu-title">Envio de Nota Fiscal</li>
                                    <li>
                                        <a href='https://api.whatsapp.com/send/?phone=551134750544&text=Olá tenho dúvidas para enviar minha Nota Fiscal&type=phone_number&app_absent=0'><i className="menu-icon fa fa-phone"></i>Suporte </a>
                                    </li>

                                </ul>
                                :
                                <ul className="nav navbar-nav">
                                    <li className="menu-title">Cotação</li>
                                    <li>
                                        <Link to="#">Painel de cotação em tempo real </Link>
                                    </li>
                                    <li>
                                        <a href='https://api.whatsapp.com/send/?phone=551134750544&text=Olá gostaria de falar com o comercial sobre Cotação&type=phone_number&app_absent=0'><i className="menu-icon fa fa-whatsapp"></i>Comercial </a>
                                    </li>
                                    <li>
                                        <a href='https://entregaja.com.br/'><i className="menu-icon fa fa-globe"></i>Voltar ao Site  </a>
                                    </li>
                                    <li>
                                        <a href={() => null} data-toggle="modal" data-target="#modalExemplo"><i className="menu-icon fa fa-video-camera"></i>Nossa Empresa </a>
                                    </li>

                                </ul>
                        }

                    </div>
                </nav>
            </aside>
        </>
    );
}
