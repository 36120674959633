import React, { useEffect, useState } from 'react';
import firebase from 'firebase'
import moment from 'moment'
import { encode, decode } from 'js-base64'
import Swal from 'sweetalert2'
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import QrReader from 'react-qr-scanner'

export default function CadastraCupom() {
    const [width, setWidth] = useState(window.innerWidth);
    let isDesktop = (width > 768);
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        cupons: firebase.firestore().collection('cupons'),
    })
    const [usuario, setUsuario] = useState({})
    const [camera, setCamera] = useState(false)
    const [cpf, setCpf] = useState('')

    const [nota, setNota] = useState('')
    const [scan, setScans] = useState(false)
    const [delay, setDelay] = useState(100)
    const previewStyle = {
        height: 240,
        width: 320,
    }

    var metadata = { contentType: 'image/png' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)


    function cadastraAvatar(id) {
        mensagemLoading('Salvando a imagem do Cupom...')
        var uploadTask = firebase.storage().ref().child('cupons/' + id).putString(avatar, 'base64', metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.cupons.doc(id).update({
                    arquivo: downloadURL
                }).then(() => {
                    Swal.close();
                    Swal.fire({
                        title: 'Sucesso',
                        text: 'Cupom cadastrado com sucesso! Deseja cadastrar mais um cupom?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            setCamera(false)
                            setUpload('')
                            setNota('')
                        } else {
                            setCpf('')
                            setUsuario({})
                        }
                    });
                })
            });
        });
    }

    function cadastraCliente() {
        if (!upload) {
            mensagemAlerta('Favor tire a foto do cupom')
            return
        }
        if (!nota) {
            mensagemAlerta('Favor scaneie o QrCode')
            return
        }
        mensagemLoading('Aguarde cadastrando nota...')
        servicos.cupons.where('cupom', '==', nota).get().then((res) => {
            if (res.size > 0) {
                mensagemAlerta('Cupom já cadastrado no sistema!')
                setNota('')
            } else {
                servicos.cupons.add({
                    cupom: nota,
                    usuario: {
                        nome: usuario.nome,
                        id: usuario.id,
                        contato: usuario.contato,
                        cpf: usuario.cpf,
                        avatar: usuario.avatar
                    },
                    admin: {
                        id: usuario.admin.id,
                        nome: usuario.admin.nome
                    },
                    dataCadastro: moment().format('YYYY-MM-DD'),
                    dataFull: moment().format('YYYY-MM-DD HH:mm'),
                }).then((docRef) => {
                    servicos.cupons.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        Swal.close();
                        cadastraAvatar(docRef.id)
                    }).catch((erro) => {
                        mensagemErro('Erro ao atualizar nota', erro)
                    })
                }).catch(() => {
                    mensagemErro('Erro ao cadastrar nota')
                })
            }
        })
    }

    function handleTakePhoto(dataUri) {
        if (dataUri) {
            setUpload(dataUri)
            setAvatar(dataUri.replace(/^data:image\/(png|jpg);base64,/, ""))
            setCamera(false)
        }
    }
    function handleCameraStart(stream) {
        console.log('handleCameraStart');
    }

    function handleCameraStop() {
        console.log('handleCameraStop');
    }
    function handleScan(data) {
        if (data) {
            setNota(data.text)
            setScans(false)
            console.log(data);
        }
    }
    function handleError(err) {
        console.error(err)
    }

    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            mensagemLoading('Verificando CPF...')
            servicos.usuarios.where('cpf', '==', event.target.value).get().then((snap) => {
                if (snap.size > 0) {
                    Swal.close()
                    setUsuario(snap.docs.map((u) => u.data())[0])
                } else {
                    Swal.close();
                    mensagemAlerta('Usuário não cadastrado no sistema')
                    setUsuario({})
                }
            })
        }
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/cadastraCupom'
        })
    }
    function sucessoCpf(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Novo Cupom</h4>
                                                    <p>Área para cadastro de cupons retirados os postos de combustíveis</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='row col-md-12' >
                                                <div className='col-md-3'>
                                                    <label>CPF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cpf} id="company" class="form-control" onChange={handleCpf} />
                                                    </div>
                                                </div>
                                                {usuario.id ?
                                                    <>
                                                        <div className='col-md-3'>
                                                            <img class="card-img-top" style={{ height: 300, width: 300 }} src={usuario.avatar} alt='carregando...' />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label>Nome</label>
                                                            <p><b>{usuario.nome}</b></p>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {usuario.id ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="row m-0">
                                                    <h4 className="box-title">Novo Cupom</h4>
                                                </div>
                                            </div>
                                            <div className="card-body--">
                                                <div className='col-md-12 row' >
                                                    <div className='col-md-4'>
                                                        {upload ?
                                                            <img onClick={() => setCamera(true)} class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                            :
                                                            <img onClick={() => setCamera(true)} class="card-img-top" style={{ height: 300, width: 300 }} src='https://i.pinimg.com/736x/ef/8a/ed/ef8aed9be35de33fa44f3b5b94fdc285.jpg' alt='carregando...' />
                                                        }
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label>Imagem do Cupom</label>
                                                        <p>Clique nas imagens para abrir a câmera</p>
                                                        {camera ?
                                                            <Camera
                                                                idealResolution={{ width: 300, height: 300 }}
                                                                imageCompression={0.97}
                                                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                                                isImageMirror={false}
                                                                isSilentMode={false}
                                                                onCameraStart={(dataUri) => { setCamera(true) }}
                                                                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                                                onCameraStart={(stream) => { handleCameraStart(stream); }}
                                                                onCameraStop={() => { handleCameraStop(); }}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>

                                                <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                    <div className='col-md-12'>
                                                        <label>N° do Cupom</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nota} id="company" class="form-control" onChange={handleScan} />
                                                        </div>
                                                        {scan ?
                                                            <QrReader
                                                                delay={delay}
                                                                style={previewStyle}
                                                                onError={handleError}
                                                                onScan={handleScan}
                                                                constraints={
                                                                    isDesktop
                                                                        ? undefined
                                                                        : {
                                                                            video: {
                                                                                facingMode: { exact: `environment` }
                                                                            }
                                                                        }
                                                                }
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <button type="button" class="btn btn-info mb-1" onClick={() => setScans(true)}>
                                                            Escanear o QrCode da Nota
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                    {loading ?
                                                        <button type="button" class="btn btn-default mb-1" >
                                                            Cadastrando Nota ...
                                                        </button>
                                                        :
                                                        <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                            Cadastrar Nota
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
