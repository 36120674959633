import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Header'
import Sidebar from '../../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Pagamentos() {

    const [pagamentos, setPagamentos] = useState([])
    const [servicos] = useState({
        pagamentos: firebase.firestore().collection('pagamentos')
    })

    useEffect(() => {
        servicos.pagamentos.get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setPagamentos(data)
        })
    }, [servicos.estoque])

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value) {
            mensagemLoading('Buscando Pagamentos')
            servicos.pagamentos.where('status', '==', event.target.value).get().then((snap) => {
                Swal.close();
                const data = snap.docs.map(doc => doc.data())
                setPagamentos(data)
            })
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-10">
                                                    <h4 className="box-title">Pagamentos Pendentes de Agregados / Parceiros</h4>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Link to={{ pathname: '/cadastroEstoque' }} >
                                                        <button type="button" class="btn btn-success mb-1" >
                                                            Novo Produto
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className='col-md-3'>
                                                    <label>Filtro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleFiltro}>
                                                        <option value="">Escolha  </option>
                                                        <option value="all">Todos  </option>
                                                        <option value="CADASTRADO">Enviados </option>
                                                        <option value="PAGO">Pagos </option>
                                                        <option value="NAOPAGO">Não Pagos </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Conta</th>
                                                            <th>Documento</th>
                                                            <th>Período / Mes</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pagamentos.map((produto) =>
                                                            <tr key={produto.id} >
                                                                <td>{produto.nome}</td>
                                                                <td>{produto.tipoDocumento}</td>
                                                                <td>{produto.documento}</td>
                                                                <td>
                                                                    {produto.mes === '01' ?
                                                                        produto.periodo + 'º / Janeiro'
                                                                        : produto.mes === '02' ?
                                                                            produto.periodo + 'º / Fevereiro'
                                                                            : produto.mes === '03' ?
                                                                                produto.periodo + 'º / Março'
                                                                                : produto.mes === '04' ?
                                                                                    produto.periodo + 'º / Abril'
                                                                                    : produto.mes === '05' ?
                                                                                        produto.periodo + 'º / Maio'
                                                                                        : produto.mes === '06' ?
                                                                                            produto.periodo + 'º / Junho'
                                                                                            : produto.mes === '07' ?
                                                                                                produto.periodo + 'º / Julho'
                                                                                                : produto.mes === '08' ?
                                                                                                    produto.periodo + 'º / Agosto'
                                                                                                    : produto.mes === '09' ?
                                                                                                        produto.periodo + 'º / Setembro'
                                                                                                        : produto.mes === '10' ?
                                                                                                            produto.periodo + 'º / Outubro'
                                                                                                            : produto.mes === '11' ?
                                                                                                                produto.periodo + 'º / Novembro'
                                                                                                                : produto.mes === '12' ?
                                                                                                                    produto.periodo + 'º / Dezembro'
                                                                                                                    : null
                                                                    }
                                                                </td>
                                                                {produto.status === "CADASTRADO" ?
                                                                    <td>
                                                                        <button >
                                                                            <span className="badge badge-info">ENVIADO</span>
                                                                        </button>
                                                                    </td>
                                                                    : produto.status === "PAGO" ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-success">PAGO</span>
                                                                            </button>
                                                                        </td>
                                                                        :produto.status === "VALIDADO" ?
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-warning">VALIDADO</span>
                                                                            </button>
                                                                        </td>
                                                                        :
                                                                        <td>
                                                                            <button >
                                                                                <span className="badge badge-danger">NÃO PAGO</span>
                                                                            </button>
                                                                        </td>

                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/detalhePagamento', state: { pagamento: produto } }} >
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {pagamentos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem pagamentos pendentes</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
