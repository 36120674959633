import moment from 'moment';
import React, { useState } from 'react';

export default function Contrato(props) {
    const [dado] = useState(props.location.state ? props.location.state.sistema : "");
    return (
        <div>
            <div id="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src="images/logo.png" alt="" />
                                                <h2 className="box-title" style={{ marginTop: 20 }}>Recibo de {dado.acao}</h2>
                                                <h5 className="box-title">Documento de responsabilidade</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12" style={{ textAlign: "center",marginTop:30 }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <img width={300} src={dado.equipamento.avatar} alt="" />
                                                <h5 className="box-title">Produto Para {dado.acao}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12" style={{ marginTop: 20 }}>
                                {/* {dado.acao === "MANUTENCAO" ?
                                    <div className="card-body-- row col-md-12" >
                                        <div className='col-md-12'>
                                            <h4 style={{ textAlign: "justify", }}>Eu, <b>{dado.solicitante.nome}</b>, portador do CPF <b>n° {dado.solicitante.cpf}</b>, declaro que estou solicitando manutenção ao equipamento <b>{dado.equipamento.nome} {dado.equipamento.marca} {dado.equipamento.modelo}</b> que será analisado pela <b>Equipe de TI da Entrega Já</b>.</h4>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: 50 }}>
                                            <h2 ><b>Do Serviço</b></h2>
                                            <h4 style={{ marginTop: 30,textAlign: "justify" }}>{dado.descricao}</h4>
                                        </div>
                                    </div>
                                    :
                                    null
                                } */}
                                <div className="card-body-- row col-md-12" >
                                        <div className='col-md-12'>
                                            <h4 style={{ textAlign: "justify", }}>Eu, <b>{dado.solicitante.nome}</b>, portador do CPF <b>n° {dado.solicitante.cpf}</b>, declaro que estou solicitando o equipamento <b>{dado.equipamento.nome} {dado.equipamento.marca} {dado.equipamento.modelo}</b> que será de uso pessoal para suprir demandas de plantão.</h4>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: 50 }}>
                                            <h2 ><b>Do Serviço</b></h2>
                                            <h4 style={{ marginTop: 30,textAlign: "justify" }}>{dado.descricao}</h4>
                                        </div>
                                    </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6" style={{ textAlign: "center",marginTop:60 }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>_______________________________________________</b></h4>
                                            </div>
                                            <div className="col-sm-12">
                                            <h4 className="box-title" style={{ marginTop: 20 }}><b>Equipe Labs Entrega Já</b></h4>
                                                <h5 className="box-title" >Equipe de Tecnologia</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6" style={{ textAlign: "center",marginTop:60 }}>
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>_______________________________________________</b></h4>
                                            </div>
                                            <div className="col-sm-12">
                                                <h4 className="box-title" style={{ marginTop: 20 }}><b>{dado.solicitante.nome}</b></h4>
                                                <h5 className="box-title" >{dado.solicitante.cpf}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};