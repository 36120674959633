import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Header'
import Sidebar from '../../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Setores() {
    const [sistemas] = useState([
        { titulo: "Diretoria", descricao: "Setor Geral da Empresa", ico: "pe-7s-portfolio", link: '/setor', setor: 'DIRETORIA' },
        { titulo: "Controladoria", descricao: "Setor de Recursos Humanos", ico: "pe-7s-note2", link: '/setor', setor: 'CONTROLADORIA' },
        { titulo: "T. de Controle", descricao: "Monitoramento e atendimento", ico: "pe-7s-monitor", link: '/setor', setor: 'TORRECONTROLE' },
        { titulo: "Logística ", descricao: "Setor Inteligência logística", ico: "pe-7s-plane", link: '/setor', setor: 'LOGISTICA' },
        { titulo: "Labs", descricao: "Setor de Tecnologia e inovação", ico: "pe-7s-rocket", link: '/setor', setor: 'LABS' },
        { titulo: "Agregados", descricao: "Motoristas, parceiros e agregados", ico: "pe-7s-users", link: '/setor', setor: 'AGREGADO' },
        { titulo: "Marketing", descricao: "Criação de conteúdos", ico: "pe-7s-pen", link: '/setor', setor: 'MARKETING' },
        { titulo: "Comercial", descricao: "Comercial da empresa", ico: "pe-7s-mail-open-file", link: '/setor', setor: 'COMERCIAL' },
    ])
    const [temp, setTemp] = useState([])

    useEffect(() => {
        setTemp(sistemas)
    }, [])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <h4 className="box-title">Setores Internos</h4>
                                                    <p>Lista de setores innternos que fazem parte da Entrega Já</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row col-sm-12" >
                                                {temp.map((sistema) =>
                                                    <div className="col-lg-4 col-md-6">
                                                        <Link to={{ pathname: sistema.link, state: {dados:sistema.setor} }} >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="stat-widget-five">
                                                                        <div className="stat-icon dib flat-color-3">
                                                                            <i className={sistema.ico}></i>
                                                                        </div>
                                                                        <div className="stat-content">
                                                                            <div className="text-left dib">
                                                                                <div className="stat-text">{sistema.titulo}</div>
                                                                                <div className="stat-heading">{sistema.descricao}</div>
                                                                                {/* <p>{sistema.setor}</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {temp.length === 0 ?
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-12" style={{ alignItems: "center" }}>
                                                        <h4 className="box-title">Sem sistemas</h4>
                                                        <p>O setor ainda não possui sistema</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
