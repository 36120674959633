import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { verificaAutenticado } from '../services/Firebase'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { decode } from 'js-base64'
import moment from 'moment'

export default function Login() {
    const [documento, setDocumento] = useState({});
    const [senha, setSenha] = useState('');
    const [email, setEmail] = useState(window.location.href.replace('https://administrador-entregaja.firebaseapp.com/novoAcesso?', ''));
    // const [email, setEmail] = useState(window.location.href.replace('http://localhost:3000/novoAcesso?', ''));
    const [formSenha, setFormSenha] = useState(false);
    const [inputSenha, setInputSenha] = useState(false);
    const [usuarioLogado, setUsuario] = useState({});
    const [loading, setLoading] = useState(false);

    const [senhaAtual, setSenhaAtual] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [novaSenha, setNovaSenha] = useState('');

    useEffect(() => {
        firebase.firestore().collection('usuarios')
            .where('email', '==', email)
            .get().then((snap) => {
                if (snap.size > 0) {
                    firebase.firestore().collection('usuarios')
                        .where('email', '==', email)
                        .where('status', '==', 0)
                        .get().then((snap) => {
                            if (snap.size > 0) {
                                setDocumento(snap.docs.map((doc) => doc.data())[0])
                                setFormSenha(true)
                            } else {
                                setInputSenha(true)
                            }
                        })
                } else {
                    mensagemAlerta('Email não consta em nosso sistema')
                }
            })
    }, [])

    function novoAcesso() {
        if (email === '') {
            mensagemAlerta('Email não pode ser vazio')
        } else if (senhaAtual === '') {
            mensagemAlerta('Confirme a senha atual')
        } else if (novaSenha === '') {
            mensagemAlerta('Informe a Nova Senha')
        } else if (confirmaSenha === '') {
            mensagemAlerta('Confirme a Nova Senha')
        } else if (confirmaSenha !== novaSenha) {
            mensagemAlerta('As senhas não conferem')
        } else if (decode(documento.pass).replace(email, '') !== senhaAtual) {
            console.log(novaSenha)
            mensagemAlerta('Favor verifique a senha atual')
        } else {
            firebase.auth().createUserWithEmailAndPassword(email, confirmaSenha)
                .then(() => {
                    mensagemLoading("Aguarde confirmando usuário")
                    var usuario = firebase.auth().currentUser;
                    console.log(documento);
                    firebase.firestore().collection('usuarios').doc(usuario.uid).set({
                        id: usuario.uid,
                        avatar: documento.avatar ? documento.avatar : null,
                        nome: documento.nome,
                        sobrenome: documento.sobrenome,
                        contato: documento.contato,
                        cpf: documento.cpf,
                        endereco: documento.endereco,
                        status: parseInt(1),
                        admin: documento.admin,
                        email: documento.email,
                        usuario: documento.usuario,
                        cargo: documento.cargo,
                        setor: documento.setor,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                    }).then(() => {
                        firebase.firestore().collection('usuarios').doc(documento.id).delete().then(() => {
                            firebase.firestore().collection('usuarios').doc(usuario.uid).get().then((snap) => {
                                if (snap.data().usuario === 'MASTER') {
                                    window.location = '/administrador';
                                } else if (snap.data().usuario === 'COMUM') {
                                    window.location = '/comum';
                                }
                            })
                        })
                    }).catch((erro) => {
                        mensagemErro("Erro ao cadastrar Usuario")
                    })
                }).catch((erro) => {
                    console.log(erro);
                    var errorCode = erro.code;
                    var errorMessage = erro.message;
                    if (errorCode == "auth/invalid-email") {
                        mensagemAlerta("O formato do Email é inválido!")
                    } else if (errorCode == "auth/email-already-in-use") {
                        mensagemAlerta("O email já está em uso pelo Entrega Já")
                    } else if (errorCode == "auth/weak-password") {
                        mensagemAlerta("Escolha uma senha mais forte!")
                    }
                })
        }
    }

    const handleInputEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleInputSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleSenhaAtual = (event) => {
        event.persist();
        setSenhaAtual(event.target.value);
    }
    const handleNovaSenha = (event) => {
        event.persist();
        setNovaSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="login100-more" >
                <img src='/images/background.png' alt="" />
                </div>
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <span className="login100-form-title p-b-19">Novo Acesso</span>
                    <span className="login90-form-title" >Coloque o email e senha para o novo acesso ao sistema</span>

                    <div className="wrap-input100 validate-input" data-validate="Name is required" style={{ marginTop: 20 }} >
                        <input className="input100" value={email} autoCapitalize="none" onChange={handleInputEmail} type="text" name="name" placeholder="Email" />
                        <span className="focus-input100"></span>
                    </div>
                    {inputSenha ?
                        <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input className="input100" value={senha} autoCapitalize="none" onChange={handleInputSenha} type="password" name="email" placeholder="Senha" />
                            <span className="focus-input100"></span>
                        </div>
                        :
                        null
                    }
                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                        <input className="input100" value={senhaAtual} autoCapitalize="none" onChange={handleSenhaAtual} type="password" name="email" placeholder="Senha Atual" />
                        <span className="focus-input100"></span>
                    </div>
                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                        <input className="input100" value={novaSenha} autoCapitalize="none" onChange={handleNovaSenha} type="password" name="email" placeholder="Nova Senha" />
                        <span className="focus-input100"></span>
                    </div>
                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                        <input className="input100" value={confirmaSenha} autoCapitalize="none" onChange={handleConfirmaSenha} type="password" name="email" placeholder="Confirme a Nova Senha" />
                        <span className="focus-input100"></span>
                    </div>

                    <div className="container-login100-form-btn">
                        <div className="wrap-login100-form-btn">
                            <div className="login100-form-bgbtn"></div>
                            {loading ?
                                <button className="login100-form-btn" style={{ backgroundColor: 'red' }} >Aguarde...</button>
                                :
                                <button className="login100-form-btn" style={{ backgroundColor: 'red' }} onClick={novoAcesso}>Confirmar e Entrar</button>
                            }
                        </div>
                    </div>
                    <div className='row col-md-12' style={{ marginTop: 100 }}>
                        <img src='/images/entrega.png' alt="" />
                    </div>
                </div>
            </div>
        </div>

    );
}
